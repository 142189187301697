import {STeamsAPI} from '../../../backendApi/backendApi';
import { app } from '../../initApp';
import {cleanPhoneNumber} from '../../utility/cleanPhoneNumber';
import {RejectionReason, RejectKey} from '../../utility/RejectionReason';
import { UseCase } from '../usecase/UseCase';
import { report } from '../../../gui/util/rollbar';
export interface RequestPhoneCodeOptions {
  phone: string;
  // sentOnStart?: boolean;
}

export const requestPhoneCodeUseCase: UseCase = {
  type: 'REQUEST_PHONE_CODE',

  run: async ( options: RequestPhoneCodeOptions ) => {
    try{
      const phone = cleanPhoneNumber(options.phone);
      const apiRequestResult = await STeamsAPI.coachRequestAccessByPhone({phone});
      const dataToDispatch = Object.assign({
        // sentOnStart: options.sentOnStart,
        phone,
        success: true
      }, apiRequestResult);

      return Cx.resolve(dataToDispatch);
    }
    catch(e){
      report(e);
      if(RejectionReason.was(RejectKey.STEAMS_API_RESPONSE, e)){
        if(e.Data && e.Data.previousRequestNotExpired){
          return Cx.reject(new RejectionReason({
            Data: e.Data,
            Primary: RejectKey.CANCELLED,
            Secondary: e, // TODO: Test this.
            UserMessage: 'Too many requests for phone code.  Try again in a few moments.'
          }));
        }
      }

      throw e;
    }
  }
};

export function requestPhoneCode( options: RequestPhoneCodeOptions ) {
  app.runUseCase( requestPhoneCodeUseCase, options );
}
