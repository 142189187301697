import { UseCase } from './usecase/UseCase';
import { app } from '../initApp';

export interface setPatternComboVisiblityUseCaseOptions {
  visible: boolean;
}

export const setPatternComboVisiblityUseCase: UseCase = {
  type: 'SET_PATTERN_COMBO_VISIBILITY',

  run: ( options: setPatternComboVisiblityUseCaseOptions ) => {
    return Cx.resolve({
      patternsComboVisible: options.visible
    });
  }
};

/**
 * @category Can
 */
export function canSetPatternComboVisiblity( state ) {
  return setPatternComboVisiblityUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setPatternComboVisiblity( options: setPatternComboVisiblityUseCaseOptions ) {
  app.runUseCase( setPatternComboVisiblityUseCase, options );
}
