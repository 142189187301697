import { DesignStyle, designStyleFromActionResult } from '../domain/Design/DesignStyle';
import { cloneFillTarget, FillTarget, getFillTargetBrush } from '../domain/Design/FillTarget';
import { GrayGood, grayGoodFromActionResult } from '../domain/Design/GrayGood';
import { Sport, sportFromActionResult } from '../domain/Design/Sport';
import { TeamColor } from '../domain/Design/TeamColor';
import { CustomizeTab } from '../domain/Gui/CustomizeTab';
import { GuiSteps } from '../domain/Gui/GuiSteps';
import { GetSpotColorBasesWithMap } from '../domain/Pattern/colorZoneHelper'
import { isPatternBrush, isRasterPatternBrush } from '../domain/Pattern/Pattern'
import { activePatternFromBrush, emptyPattern, Pattern } from '../domain/Pattern/Pattern'
import { addOutlineUseCase } from '../usecases/addOutline';
import { addProductUseCase } from '../usecases/addProduct';
import { editProductUseCase } from '../usecases/editProduct';
import { editRosterUseCase } from '../usecases/editRoster';
//import { grayGoodGetThumb3DUseCase } from '../usecases/grayGoodGetThumb3DUseCase';
import { grayGoodPageNumDecrementUseCase } from '../usecases/grayGoodPageNumDecrementUseCase'
import { grayGoodPageNumIncrementUseCase } from '../usecases/grayGoodPageNumIncrementUseCase'
import { initPartnerWithColorsUseCase } from '../usecases/init/initPartnerWithColors';
import { loadSubmittedOrderUseCase } from '../usecases/loadSubmittedOrder';
import { openSavedOrderUseCase } from '../usecases/openSavedOrder';
import { removeOutlineUseCase } from '../usecases/removeOutline';
import { setBatchModeUseCase } from '../usecases/setBatchMode'
import { setColorTypeMainColorSelectorUseCase } from '../usecases/setColorTypeMainColorSelector';
import { setFillUseCase, SetFillUseCaseOptions } from '../usecases/setFill';
import { setFillTargetUseCase } from '../usecases/setFillTarget';
import { setFillTargetByClickUseCase } from '../usecases/setFillTargetByClick';
import { setGrayGoodUseCase } from '../usecases/setGrayGood';
import { setJerseySchemmeColorListIndexUseCase } from '../usecases/setJerseySchemmeColorListIndex'
import { setPatternComboVisiblityUseCase } from '../usecases/setPatternComboVisibility'
import { setSelectedPatternColorIndexUseCase } from '../usecases/setSelectedPatternColorIndex'
import { setSportUseCase } from '../usecases/setSport';
import { setTeamColorUseCase } from '../usecases/setTeamColor';
import { showAddRosterStepUseCase } from '../usecases/showAddRosterStep';
import { showColorZonesTabUseCase } from '../usecases/showColorZonesTab';
import { showCustomizeStepUseCase } from '../usecases/showCustomizeStep';
import { showDesignStylesStepUseCase } from '../usecases/showDesignStylesStep';
import { showGraphicsTabUseCase } from '../usecases/showGraphicsTab';
import { showGrayGoodsStepUseCase } from '../usecases/showGrayGoodsStep';
import { showNameNumberTabUseCase } from '../usecases/showNameNumberTab';
import { showReviewStepUseCase } from '../usecases/showReviewStep';
import { showSportsStepUseCase } from '../usecases/showSportsStep';
import { setInitialOrderUseCase } from '../usecases/setInitialOrder';
import { createCoachContactInfoUseCase, coachContactInfo } from '../usecases/createCoachContactInfo';
import { getCoachDataReadByKeyUseCase } from '../usecases/getCoachDataReadByKey';
import global from '../../gui/services/global'
import { showEditColorOptionsUseCase } from '../usecases/actions/showEditColorOptions';
import { copySubmittedOrderUseCase } from '../usecases/copySubmittedOrder';
import { selectCoachContactInfoUseCase } from '../usecases/selectCoachContactInfo';
import { isLoadingOrderUseCase } from '../usecases/loadingOrder';
import { reportMessage } from '../../gui/util/rollbar';
import { prepareOrderForSavingUseCase } from '../usecases/prepareOrderForSaving';
export interface GuiReducerState {
  activeCustomizeTab: CustomizeTab;
  activeFillTarget: FillTarget;
  activeFillTargetBrush: Cx.Brush,
  activeFillTargetBrushIsPattern: boolean;
  activeFillTargetBrushIsRasterPattern: boolean;
  activeJerseySchemmeColorIndex: Number;
  activeGrayGood: GrayGood;
  activeGrayGoodDesignStyles: DesignStyle[];
  activeGrayGoodPageNum: Number;
  activeGrayGoodPageTotal: Number;
  activeGrayGoodDesignStylesInPage: DesignStyle[];
  activeStep: GuiSteps;
  activeSport: Sport;
  activeSportGrayGoods: GrayGood[];
  allDesignStyles: DesignStyle[];
  allGrayGoods: GrayGood[];
  allSports: Sport[];
  loadingOrder: boolean,
  mainColorSelectorHidden: boolean;
  partnerData: any;
  selectedStoreLocation: any; 
  partnerColorScheme: string[];
  partnerLogoUrl: string;
  productLogo: any;
  partnerLogoDocId: string;
  ready: boolean;
  teamColors: TeamColor[];
  // pattern combo GUI visibility flags
  patternComboSelectorVisible: boolean;
  patternsComboVisible: boolean;
  // selected pattern
  activePattern: Pattern;
  grayGoodsThumb3DCache: string[];
  showEditColorOptions: boolean;
  isPatternSelectable: boolean;
  isBatchMode: boolean;
  initialOrder: string;
  initialOrderOpenAsCopy: string;
  coachContactInfo: coachContactInfo[];
  coachContactInfoSelected: coachContactInfo;
};

const initState = {
  activeCustomizeTab: CustomizeTab.COLORZONES,
  activeFillTarget: undefined,
  activeFillTargetBrush: undefined,
  activeFillTargetBrushDocId: undefined,
  activeFillTargetBrushIsPattern: false,
  activeFillTargetBrushIsRasterPattern: false,
  activeGrayGood: undefined,
  activeGrayGoodDesignStyles: undefined,
  activeGrayGoodDesignStylesInPage: undefined,
  activeGrayGoodPageNum: 1,
  activeGrayGoodPageTotal: undefined,
  activeJerseySchemmeColorIndex: 0,
  activePattern: emptyPattern(),
  activeSport: undefined,
  activeSportGrayGoods: undefined,
  activeStep: GuiSteps.SPORT,
  allDesignStyles: [],
  allGrayGoods: [],
  allSports: [],
  grayGoodsThumb3DCache: [],
  loadingOrder: false,
  mainColorSelectorHidden: true,
  partnerData: undefined,
  selectedStoreLocation: undefined, 
  partnerColorScheme: undefined,
  partnerLogoDocId: undefined,
  partnerLogoUrl: undefined,
  patternComboSelectorVisible: false,
  patternsComboVisible: false,
  productLogo: undefined,
  ready: false,
  showEditColorOptions: false,
  teamColors: undefined,
  isPatternSelectable: true,
  isBatchMode: false,
  initialOrder: undefined,
  initialOrderOpenAsCopy: undefined,
  coachContactInfo: [],
  coachContactInfoSelected: undefined
};

export function GuiReducer(state: GuiReducerState = initState, actionResult): GuiReducerState {
  switch (actionResult.type) {

    case setInitialOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }
      if (actionResult.success()) {

        return Object.assign({}, state, {
          initialOrder: actionResult.data.orderId,
          initialOrderOpenAsCopy: actionResult.data.initialOrderOpenAsCopy
        });
      }

      return state;

    case selectCoachContactInfoUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          coachContactInfoSelected: actionResult.data.coachContactInfo
        });
      }
      return state;

    case getCoachDataReadByKeyUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          coachContactInfo: actionResult.data.coachData?.CoachContactInfo ?? []
        });
      }
      return state;

    case setBatchModeUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {

        return Object.assign({}, state, {
          isBatchMode: true
        });
      }

      return state;

    case createCoachContactInfoUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {

        return Object.assign({}, state, {
          coachContactInfo: [...state.coachContactInfo, actionResult.data.contactInfo]
        });
      }

      return state;

    case initPartnerWithColorsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const partnerData = actionResult.data.partnerData;

        partnerData.Partner[0].addressOptions = [ {
          "name": actionResult.data.partnerData.Partner[0].address1
          ,"address1":actionResult.data.partnerData.Partner[0].address1
          ,"address2":actionResult.data.partnerData.Partner[0].address2
          ,"postalCode":actionResult.data.partnerData.Partner[0].postalCode
          ,"city":actionResult.data.partnerData.Partner[0].city
          ,"state":actionResult.data.partnerData.Partner[0].state
          ,"salesTaxType":actionResult.data.partnerData.Partner[0].salesTaxType
        }, ...((partnerData.Partner[0].addressOptions) ? partnerData.Partner[0].addressOptions : []) ];

        return Object.assign({}, state, {
          allDesignStyles: partnerData.PartnerDesignStyle.map((item) => {
            return designStyleFromActionResult(item);
          }),
          allGrayGoods: partnerData.PartnerGrayGood.map((item) => {
            return grayGoodFromActionResult(item);
          }),
          allSports: partnerData.PartnerSport.map((item) => {
            return sportFromActionResult(item);
          }),
          partnerData: partnerData.Partner[0],
          partnerColorScheme: partnerData.PartnerBranding[0].colorScheme,
          partnerLogoUrl: getBrandingLogoUrl(partnerData),
          productLogo: getProductLogo(partnerData),
          ready: true,
          teamColors: getTeamColors(partnerData)
        });
      }

      return state;

    case loadSubmittedOrderUseCase.type:
    case openSavedOrderUseCase.type:
    case copySubmittedOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const product = actionResult.data.product;
        const grayGood = product.GrayGood;
        const sportId = grayGood.SportId;
        const sport = state.allSports.find((s) => {
          return s.SportId === sportId;
        });
        
        const activeStep = getActiveStep(actionResult.data);
        const newState = Object.assign({}, state, {
          activeFillTarget: actionResult.data.fillTarget,
          activeStep,
          mainColorSelectorHidden: (activeStep === GuiSteps.ROSTER || activeStep === GuiSteps.REVIEW) ? true : false,
          isPatternSelectable: true,
          loadingOrder: false
        });

        updateStateOnSportchange(newState, sport);
        updateStateOnGrayGoodchange(newState, grayGood);
        return newState;
      }

      return state;

    case setColorTypeMainColorSelectorUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          patternComboSelectorVisible: actionResult.data.isPattern// ,
          // activePattern: emptyPattern()
        });
      }

      return state;

    case showSportsStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.SPORT,
          mainColorSelectorHidden: true
        });
      }

      return state;

    case showGrayGoodsStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.PRODUCT,
          mainColorSelectorHidden: true
        });
      }

      return state;

    case showDesignStylesStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.STYLE,
          mainColorSelectorHidden: false,
          isPatternSelectable: false
        });
      }

      return state;

    case showCustomizeStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.CUSTOMIZE,
          mainColorSelectorHidden: false,
          isPatternSelectable: true
        });
      }

      return state;

    case showColorZonesTabUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeCustomizeTab: CustomizeTab.COLORZONES
        });
      }

      return state;

    case showGraphicsTabUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeCustomizeTab: CustomizeTab.GRAPHICS
        });
      }

      return state;

    case showNameNumberTabUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeCustomizeTab: CustomizeTab.NAME_NUMBER
        });
      }

      return state;

    case showAddRosterStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.ROSTER,
          mainColorSelectorHidden: true
        });
      }

      return state;

    case setSportUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const newState = Object.assign({}, state, {
          activeStep: GuiSteps.PRODUCT,
          mainColorSelectorHidden: true
        });

        updateStateOnSportchange(newState, actionResult.data.sport);
        return newState;
      }

      return state;

    case setGrayGoodUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const grayGood = actionResult.data.grayGood;
        const newState = Object.assign({}, state, {
          activeStep: GuiSteps.STYLE,
          mainColorSelectorHidden: false,
          isPatternSelectable: false
        });

        updateStateOnGrayGoodchange(newState, grayGood)
        return newState;
      }

      return state;

    case setTeamColorUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }
      
      if (actionResult.success()) {
        const argb = actionResult.data.color.ARGB;
        const newState = Object.assign({}, state, {
          teamColors: [].concat(state.teamColors)
        });

        newState.teamColors[actionResult.data.index] = {
          Alpha: parseInt(argb.substr(0, 2), 16),
          Hex: argb.substring(2)
        };

        return newState;
      }

      return state;

    case addProductUseCase.type:
    case editProductUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeFillTarget: actionResult.data.fillTarget,
          activeStep: GuiSteps.CUSTOMIZE,
          mainColorSelectorHidden: false,
          isPatternSelectable: true
        });
      }

      return state;

    case editRosterUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.ROSTER,
          mainColorSelectorHidden: true
        });
      }

      return state;

    //case showReviewStepUseCase.type:
    case prepareOrderForSavingUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeStep: GuiSteps.REVIEW,
          mainColorSelectorHidden: true
        });
      }

      return state;

    // case toggleDecorationAreaHiddenUseCase.type:
    //   if (actionResult.success()) {
    //     return Object.assign({}, state, {
    //       playerNameDisabled: !state.playerNameDisabled
    //     });
    //   }

    //   return state;

    case removeOutlineUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success() && actionResult.data.isActiveFillTarget) {
        return Object.assign({}, state, {
          activeFillTarget: undefined,
          activeFillTargetBrush: undefined,
          activeFillTargetBrushIsPattern: false
        });
      }

      return state;

    case addOutlineUseCase.type:
    case setFillTargetUseCase.type:
    case setFillTargetByClickUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }
      
      if (actionResult.success()) {
        const fillTarget = actionResult.data.fillTarget;
        const activeFillTarget = fillTarget ? cloneFillTarget(fillTarget) : undefined;
        const activeFillTargetBrush = activeFillTarget ? getFillTargetBrush(activeFillTarget) : undefined;
        const targetIsPattern = isPatternBrush(activeFillTargetBrush);
        const targetIsRasterPattern = isRasterPatternBrush(activeFillTargetBrush);
        const activePattern = !targetIsPattern ? emptyPattern() : activePatternFromBrush(activeFillTargetBrush);

        return Object.assign({}, state, {
          activePattern,
          activeFillTarget,
          activeFillTargetBrush,
          activeFillTargetBrushIsPattern: targetIsPattern,
          activeFillTargetBrushIsRasterPattern: targetIsRasterPattern,
          patternsComboVisible: targetIsPattern,
          patternComboSelectorVisible: targetIsPattern
        });
      }

      return state;

    case setFillUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const data = actionResult.data;
        const isPattern = isPatternBrush(data.brush);
        const isRasterPattern = isRasterPatternBrush(data.brush);
        // const target = cloneFillTarget(data.fillTarget);

        // setFillTargetBrush(target, data.brush);
        const target = {
          activeFillTargetBrush: data.brush,
          activeFillTargetBrushIsPattern: isPattern,
          activeFillTargetBrushIsRasterPattern: isRasterPattern
        }

        if (isPattern) {
          Object.assign(target, {
            activePattern:
            {
              docId: data.docId ? data.docId : state.activePattern.docId,
              colorZones: data.zones ? data.zones : state.activePattern.colorZones,
              activeColors: !isRasterPattern ? GetSpotColorBasesWithMap(data.brush.colorMap.getColors(), data.colorZoneMap) : [],
              colorIndexZoneMap: data.colorZoneMap ? data.colorZoneMap : state.activePattern.colorIndexZoneMap,
              activeColorSetIndex: -1
            }
          });
        }
        return Object.assign({}, state, target);
      }

      return state;

    case setSelectedPatternColorIndexUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activePattern:
          {
            docId: actionResult.docId ? actionResult.docId : state.activePattern.docId,
            colorZones: actionResult.zones ? actionResult.zones : state.activePattern.colorZones,
            activeColors: state.activePattern.activeColors,
            activeColorSetIndex: actionResult.data.selectedPatternColorIndex,
            colorIndexZoneMap: state.activePattern.colorIndexZoneMap
          }
        });
      }
      return state;


    case setJerseySchemmeColorListIndexUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {

        return Object.assign({}, state, {
          activeJerseySchemmeColorIndex: actionResult.data.jerseySchemmeColorListIndex // getFillTargetBrush(target)
        });
      }

      return state;

    case setPatternComboVisiblityUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {

        return Object.assign({}, state, {
          patternsComboVisible: actionResult.data.patternsComboVisible // getFillTargetBrush(target)
        });
      }

      return state;

    case grayGoodPageNumDecrementUseCase.type:
    case grayGoodPageNumIncrementUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        updateStateOnGrayGoodPageChange(state, actionResult.data.activeGrayGoodPageNum);
        return state;
      }

      return state;

    // case grayGoodGetThumb3DUseCase.type:
    //   if (actionResult.success()) {

    //     return {
    //       ...state,
    //       grayGoodsThumb3DCache: {
    //         ...state.grayGoodsThumb3DCache, [actionResult.data.thumb3DKey]: actionResult.data.thumb3DValue
    //       }
    //     }
    //   }
    //   return state;

    case showEditColorOptionsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          showEditColorOptions: true
        });
      }

      return state;

    case isLoadingOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          loadingOrder: true
        });
      }

      return state;
  }
  return state;
}

// I could subscribe to emitCanvasEventUseCase to pull namedColorZones to Gui state but it will be more performant to lookup in the target canvas directly
// function getFillTargetfromZone(target:any) {
//   const t = target as ColorZoneFillTarget;
//   let fill ;
//   if (t)
//   {
//     let activeColors = getNamedColorZones(target.canvasId, true) ;
//     activeColors = activeColors.filter( (z) =>  { return z.Name === target.colorZoneId });
//     fill = activeColors ? activeColors[0].Value : null ;
//   }
//   return fill;
// }

function updateStateOnSportchange(state, sport) {
  Object.assign(state, {
    activeSport: sport,
    activeSportGrayGoods: getGrayGoods(state, sport.SportId)
  });
}

function updateStateOnGrayGoodchange(state, grayGood) {
  const designStyles = getDesignStyles(state, grayGood.Id);
  const pageTotal = Math.ceil(designStyles.length / global._3D_THUMBS_PER_PAGE_);
  Object.assign(state, {
    activeGrayGood: grayGood,
    activeGrayGoodDesignStyles: designStyles,
    activeGrayGoodPageNum: 1,
    // activeGrayGoodDesignStylesInPage: getDesignStylesInPage(designStyles, 1, pageTotal),
    activeGrayGoodPageTotal: pageTotal
  });
}

function updateStateOnGrayGoodPageChange(state, pageNum) {
  Object.assign(state, {
    activeGrayGoodPageNum: pageNum
    // activeGrayGoodDesignStylesInPage: getDesignStylesInPage(state.activeGrayGoodDesignStyles, pageNum, state.activeGrayGoodPageTotal)
  });
}

function getDesignStylesInPage(designStyles, pageNum, totalNum) {
  const start = pageNum - 1;
  return designStyles.slice(start, Math.min(start + global._3D_THUMBS_PER_PAGE_, totalNum));
}

function getDesignStyles(state, grayGoodId: string) {
  const data = state.allDesignStyles;

  return data.filter(function (item) {
    return item.PartnerGrayGoodId === grayGoodId;
  });
}

function getGrayGoods(state, sportId: string) {
  const data = state.allGrayGoods;

  return data.filter(function (item) {
    return item.SportId === sportId;
  });
}

function getTeamColors(data): TeamColor[] {
  const partnerId = data.Partner[0].id;
  const branding = data.PartnerBranding.find(function (item) {
    return item.partnerId === partnerId;
  });
  const colors = branding.colorScheme || {};
  const teamColors: TeamColor[] = [];
  let key;

  for (key in colors) {
    teamColors.push({
      Alpha: 255,
      Hex: colors[key]
    });
  }

  return teamColors;
}

function getActiveStep(data: any) {
  if (!data.editDesign && !data.editRoster) {
    return GuiSteps.REVIEW;
  } else {
    return data.editDesign ? GuiSteps.CUSTOMIZE : GuiSteps.ROSTER;
  }
}

const getBrandingLogoUrl = (config: any) => {
  if (config && config.PartnerBranding && config.PartnerBranding.length > 0) {
    return config.PartnerBranding[0].webLogoUrl
  }

  return undefined;
};

const getProductLogo = (config: any) => {
  if (config && config.PartnerBranding && config.PartnerBranding.length > 0) {
    return config.PartnerBranding[0].productLogo
  }

  return undefined;
};
