import { isDesignReady } from '../domain/Design/Conditions';
import { CanvasAreaFactory } from '../../canvas/CanvasArea';
import { DesignElement } from '../domain/Design/DesignElement';
import { Validator } from '../screen/Validator';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { scale$, checkCanScale } from './actions/scale$';
import { UseCase } from './usecase/UseCase';
import { getDecorationArea, getInDecorationAreaName } from '../usecases/actions/canvas';

interface ScaleOptions {  
  designElement: DesignElement;
  factor: number;
}

const isValidScale = new Validator({
  check: (options:any) => {
    const canvas = CanvasAreaFactory.getCanvasById(options.designElement.canvasId);
    const areaId = options.designElement.decorationAreaId;  

    const target = <any>getDecorationArea(canvas, areaId);

    const figure = <any>canvas.figures.find((f) => {
      return getInDecorationAreaName(f) === areaId;
    });

    if (!checkCanScale(figure, target, options.factor))
      return "Cannot scale outside target boundaries"

    return undefined ;
  }
});

export const scaleDesignElementUseCase:UseCase = {
  type: 'SCALE_DESIGN_ELEMENT',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: (options: ScaleOptions) => {
    const canvas = CanvasAreaFactory.getCanvasById(options.designElement.canvasId);
    const areaId = options.designElement.decorationAreaId;

    const target = <any>getDecorationArea(canvas, areaId);

    const figure = <any>canvas.figures.filter((f) => {
      return getInDecorationAreaName(f) === areaId;
    })[0];

    return scale$(figure, target, options.factor)
    .then(function(){
      return Cx.resolve(options);
    })
  }
};

export function canScaleDesignElement(state): boolean {
  return scaleDesignElementUseCase.checkConditions( state ).isAllowed;
}

export function scaleDesignElement(options: ScaleOptions){
  const ruleResult = Validator.evaluate(options, [isValidScale]);

  if(ruleResult.isAllowed){
    app.runUseCase( scaleDesignElementUseCase, options );
  }
}
