import React from 'react';

// Styles
import styles from './FAQPopup.module.css';

const FAQPopup = () => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.body}>
                    <h4>What is the minimum order?</h4>
                    <span>There is no minimum order, although there is a $10 processing fee for orders under 5 pieces.  This is not a per item fee, only an initial fee.</span>
                    <h4>Can I reorder from a previous order placed with you on your uniform creator?</h4>
                    <span>Yes.  You can reorder any quantity needed by logging in and selecting submitted orders from the My Account selection.
                        You would then select Copy from the order that you want to reorder.
                        You can also bring up a Saved Order from My Account as well.  Minimum order processing fee would also apply.</span>
                    <h4>Why am I being asked to login or create an account?</h4>
                    <span>This will provide you with the ability to save your order, recall an order, place a reorder, 
                        and screen capture your custom design to be able to share for approvals. 
                        Your information also provides us with the details to contact you for your order.</span>
                    <h4>How long does it take for my order to be completed?</h4>
                    <span>Your order will be completed in 15 business days, plus shipping to arrive to us.</span>
                    <h4>Where is my order made and shipped from?</h4>
                    <span>Orders are made and shipped to us from Michigan.</span>
                    <h4>Can I change my order once it has been submitted and approved?</h4>
                    <span>Once your order has been submitted, that is the final indication that it is approved and ready for production, and no changes can be made at that point. 
                        Any new or add on orders, can be submitted as a separate order, as there is no order minimum. 
                        Minimum order processing fee would also apply.</span>
                    <h4>What is the cost for shipping?</h4>
                    <span>Shipments are sent out with UPS Ground.  Pricing is as follows - $15 under 10 pieces, $25 under 50 pieces, $40 over 50 pieces.</span>
                    <h4>Can I use my own artwork or logo when creating my custom jersey?</h4>
                    <span>Yes. Logos can be uploaded during the customization process in the defined decoration areas.  Supported file types are PDF and PNG.</span>
                    <h4>Why am I getting a warning for my artwork?</h4>
                    <span>Uploaded logos should be 300 DPI or higher at actual size. 
                        If you receive a warning when uploading artwork, it means that your logo is less than 300 DPI and may potentially appear blurry or pixelated on the final product.</span>
                    <h4>Is there an upcharge when using the patterns for customizing my jersey?</h4>
                    <span>There are no additional charges for using the available patterns in our uniform creator.</span>

                </div>
            </div>
        </>
    )
}

export default FAQPopup;
