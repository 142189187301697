import { CanvasAreaFactory } from '../../../canvas/CanvasArea';
import { CanvasHelper } from '../../../canvas/helpers/canvas';

export function updateDocumentColorScheme$ ( canvasId:string, colorGroups: any, colorScheme:any ) {
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const colorZones = Cx.uniqueColorZones(canvas.figures);

  let targetZones = [];
  let targetColors = [];

  Object.keys(colorGroups).forEach((group, i) =>{
    const colors = colorGroups[group];
    colors.forEach((color) =>{
      const filteredColorZones = colorZones.filter( (c) => { return c.value.argb === color });
      filteredColorZones.forEach((z) =>{
        targetZones.push(z);
        const target = colorScheme[i];
        let targetColor = Cx.Color.fromRgb(target.RGB)
        targetColor.spotColorName = target.SpotColorName ;
        targetColors.push(targetColor);
      });
    });
  });
  return CanvasHelper.updateCanvasColorZones$(canvas, targetZones, targetColors)
}