import React from 'react';

import styles from './BumpArrows.module.css';

interface Props {
  disabled: boolean;
  onClick: Function;
}

const Arrow = (props) => {
  let className = props.disabled ? styles.arrowDisabled : styles.arrow;
  const onClick = props.disabled ? () => {} : () => props.onClick(props.direction);

  switch (props.direction) {
    case 'down': className += ' ' + styles.arrowDown; break;
    case 'left': className += ' ' + styles.arrowLeft; break;
    case 'right': className += ' ' + styles.arrowRight; break;
    case 'up': className += ' ' + styles.arrowUp; break;
  }

  return <div className={className} onClick={onClick}>
    <div className={styles.arrowIcon}></div>
  </div>;
}

const BumpArrows = (props: Props) => {
  const disabled = props.disabled;

  return (
    <div className={styles.bumpArrows}>
      <Arrow
        direction="left"
        disabled={disabled}
        onClick={props.onClick} />
      <div className={styles.upDownWrap}>
        <Arrow
          direction="up"
          disabled={disabled}
          onClick={props.onClick} />
        <div className={disabled ? styles.labelDisabled : styles.label}>MOVE</div>
        <Arrow
          direction="down"
          disabled={disabled}
          onClick={props.onClick} />
      </div>
      <Arrow
        direction="right"
        disabled={disabled}
        onClick={props.onClick} />
    </div>
  );

}

export default BumpArrows