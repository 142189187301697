import { coachHasSession } from '../domain/Login/Conditions';
import { loadCanvasDocument$ } from './actions/loadCanvasDocument$';
import { getInitialFillTarget } from './actions/getInitialFillTarget';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { getOrderByKey$ } from './actions/getOrderByKey$';
import { UseCase } from './usecase/UseCase';

interface LoadSubmittedOrderUseCaseOptions {
  canvasId: string;
  ordersKey: string;
  editDesign: boolean;
  editRoster: boolean;
}

export const loadSubmittedOrderUseCase:UseCase = {
  type: 'LOAD_SUBMITTED_ORDER',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [coachHasSession]);
  },

  run: (options: LoadSubmittedOrderUseCaseOptions) => {
    return getOrderByKey$(options.ordersKey)
    .then((data) => {
        return loadCanvasDocument$(options.canvasId, data.Order[0].orderState[0].SavedDocId)
        .then((loadResult) => {
          const fillTarget = getInitialFillTarget(options.canvasId);
          return Cx.resolve({
            canvasId: options.canvasId,
            docId: loadResult.docId,
            editDesign: options.editDesign,
            editRoster: options.editRoster,
            fillTarget,
            product: data.Order[0].orderState[0],
            rosterSizeMap: loadResult.rosterSizeMap,
            rosterSizeOptions: loadResult.rosterSizeOptions,
            savedOrder: data.Order[0]
          });
      });
    });
  }
}

export function canLoadSubmittedOrder(state): boolean {
  return loadSubmittedOrderUseCase.checkConditions( state ).isAllowed;
}

export function loadSubmittedOrder(options: LoadSubmittedOrderUseCaseOptions){
  app.runUseCase(loadSubmittedOrderUseCase, options);
}
