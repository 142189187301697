import { coachHasSession } from '../domain/Login/Conditions';
import { copyCanvasDocument$ } from './actions/copyCanvasDocument$'
import { getInitialFillTarget } from './actions/getInitialFillTarget';
import { loadCanvasDocument$ } from './actions/loadCanvasDocument$';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { getOrderByKey$ } from './actions/getOrderByKey$';
import { UseCase } from './usecase/UseCase';
import global from '../../gui/services/global'

interface copySubmittedOrderUseCaseOptions {
  ordersKey: string;
}

export const copySubmittedOrderUseCase:UseCase = {
  type: 'COPY_SUBMITTED_ORDER',

  // checkConditions: ( state ) => {
  //   //return Condition.evaluate(state, [coachHasSession]);
  // },

  run: (options: copySubmittedOrderUseCaseOptions) => {
    return getOrderByKey$(options.ordersKey)
    .then((data) => {
        return copyCanvasDocument$(data.Order[0].orderState[0].SavedDocId)
        .then((copiedCanvasDocId) => {
          const canvasId = global.__MAIN_CANVAS_ID__ ;
          return loadCanvasDocument$(canvasId, copiedCanvasDocId)
          .then((loadResult) => {
            const fillTarget = getInitialFillTarget(canvasId);
            return Cx.resolve({
              canvasId: canvasId,
              docId: copiedCanvasDocId,
              fillTarget,
              editDesign: true,
              editRoster: true,
              originallyCopiedFrom: options.ordersKey,
              product: data.Order[0].orderState[0],
              rosterSizeMap: data.Order[0].orderState[0].RosterSizeMap,
              rosterSizeOptions: data.Order[0].orderState[0].RosterSizeOptions,
              savedOrder: data.Order[0]
            });
        });
      });
    });
  }
}

export function canCopySubmittedOrder(state): boolean {
  return copySubmittedOrderUseCase.checkConditions( state ).isAllowed;
}

export function copySubmittedOrder(options: copySubmittedOrderUseCaseOptions){
  app.runUseCase(copySubmittedOrderUseCase, options);
}
