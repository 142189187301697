import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export interface EmitCanvasClickEventUseCaseOptions {
  canvasId: string;
  figure: Cx.Figure;
}

export const emitCanvasClickEventUseCase: UseCase = {
  type: 'EMIT_CANVAS_CLICK_EVENT',

  run: ( options: EmitCanvasClickEventUseCaseOptions ) => {
    return Cx.resolve({
      canvasId: options.canvasId,
      figure: options.figure
    });
  }
};

/**
 * @category Use Case
 */
export function emitCanvasClickEvent( options: EmitCanvasClickEventUseCaseOptions ) {
  app.runUseCase( emitCanvasClickEventUseCase, options );
}
