import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const promptForSelectVerificationUseCase: UseCase = {
  type: 'LOGIN_PROMPT_SELECT_VERIFICATION',

  run: () => {
    return Cx.resolve({
      promptForSelectVerification: true
    });
  }
};

/**
 * @category Use Case
 */
export function promptForSelectVerification() {
  app.runUseCase( promptForSelectVerificationUseCase );
}
