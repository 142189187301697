import {checkForSessionAtStartup} from '../../app/domain/Session/checkForSessionAtStartup';
import { coachHasSession$ } from '../../app/usecases/actions/login'

export const poll = (fn, args, finishedCb, timeout, interval) => {
  var startTime = (new Date()).getTime();
  interval = interval || 1000;
  let canPoll = true;
  let pollResult = false ;
  (function p() {
      canPoll = ((new Date).getTime() - startTime ) <= timeout;
      pollResult = fn(args) ;
      if (!pollResult && canPoll)  { // ensures the function exucutes
          setTimeout(p, interval);
      }
      else {
        startTime = 0 ;
        finishedCb(pollResult);
      }
  })();
}

export const pollServer = async (interval) => {

  // TODO: Review this and test for email magic link

  const result = await checkForSessionAtStartup();

  if (!result || !result.hasSession) {
    const callback = () => { pollServer(interval) };
    return setTimeout(callback, interval);
  }
  else {
    return Cx.resolve(result);
  }

}
