import { RuleResult } from './RuleResult';

/**
 * Usage:
 *
 * export const orderNotesValid = new Validator({
 *   check: (options:any) => {
 *     let notes = options.notes;
 *
 *     if (notes && notes.length > 970) {
 *       // Return the fail message
 *       return 'Maximum length for user notes has been exceeded';
 *     }
 *
 *     // Allows for multiple fail messages
 *    if (...) {
 *       return 'Some error...';
 *     }
 *
 *     // Success
 *     return undefined;
 *   }
 * });
 */

class ValidatorSchema {
  public check: Function;
}

export class Validator extends ValidatorSchema {

  constructor(args:ValidatorSchema){
    super();
    Object.assign(this, args);
  }

  public static evaluate(options:any, validators:Validator[]):RuleResult {
    const len = validators.length;
    let i = 0;
    let failMessage;

    for (; i < len; i++) {
      failMessage = validators[i].check(options);

      if (failMessage) {
        return RuleResult.notAllowed(failMessage);
      }
    }

    return RuleResult.allowed();
  }

}
