import { hasDesign, isDesignReady } from '../domain/Design/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';

interface SaveDesignUseCaseOptions {
  canvasId: string;
}

export const saveDesignUseCase: UseCase = {
  type: 'SAVE_DESIGN',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady, hasDesign]);
  },

  run: ( options: SaveDesignUseCaseOptions ) => {
    // const canvas = CanvasAreaFactory.getCanvasById(options.canvasId);

    return Cx.resolve(options);
  }
};

/**
 * @category Can
 */
export function canSaveDesign( state ): boolean {
  return saveDesignUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function saveDesign( options: SaveDesignUseCaseOptions ) {
  app.runUseCase( saveDesignUseCase, options );
}
