import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../app/Application';
import PatternSelector from '../../design/PatternSelector';
import { FillTarget } from '../../../../app/domain/Design/FillTarget';
import { canSetFill, setFill } from '../../../../app/initApp';
import { SetFillUseCaseOptions } from "../../../../app/usecases/setFill"
import ColorsCarousel from '../../design/ColorsCarousel';
import { setColorTypeMainColorSelector, SetColorTypeMainColorSelectorOptions } from '../../../../app/usecases/setColorTypeMainColorSelector';
import { BrushFactoryType, BrushFactoryOptions } from '../../../util/brushFactory'
import { setPatternComboVisiblity, setPatternComboVisiblityUseCaseOptions } from '../../../../app/usecases/setPatternComboVisibility'
import { setJerseySchemmeColorSelected } from '../../../../app/usecases/setJerseySchemmeColorSelected'
import Radio from '../../misc/field/Radio';
import { GuiSteps } from '../../../../app/domain/Gui/GuiSteps'

import styles from './MainColorSelector.module.css';
import _ from 'lodash';

interface Props {
  activeBrushName?: string;
  activeFillTarget: FillTarget;
  activeStep?: Number;
  activeJerseySchemmeColorIndex?: Number;
  canSetFill: boolean;
  hidden?: boolean;
  onPatternRadioClick: Function;
  onSolidsRadioClick: Function;
  onToggleClick: Function;
  setSelectedColorFill: Function;
  showPatternsCombo: boolean;
  colorList: Cx.Color[];
  isPattern?: boolean;
  patternsComboVisible?: boolean;
  activePatternConfig: any;
  isPatternSelectable: boolean;
}

// Bugs me I can't convert to functional component but it's not the end of the world, spent too much time on this one.
// Tried setting up callback in mergeProps first, per what we've done in the past
// Then tried using various hooks, useCallback, useRefs etc.
const MainColorSelector = (props: Props) => {
  const showAsPopout = props.isPattern && props.patternsComboVisible;
  const isPatternSelectable = props.isPatternSelectable;

  return (
    <div className={props.hidden ? styles.mainColorSelectorHidden : styles.mainColorSelector}>
      <div className={showAsPopout ? styles.bodyPopout : styles.body}>
        <div className={showAsPopout ? styles.toggleBar : styles.toggleBarHidden} onClick={() => props.onToggleClick()}>
          <span className={styles.toggleIcon + ' fas fa-caret-down'}></span>
        </div>
        {isPatternSelectable
          ?
          <div className={styles.header}>
            <Radio
              checked={!props.isPattern}
              className={props.isPattern ? styles.radioField : styles.radioFieldBold}
              label="SOLIDS"
              onClick={props.onSolidsRadioClick} />
            <Radio
              checked={props.isPattern}
              className={props.isPattern ? styles.radioFieldBold : styles.radioField}
              label="PATTERNS"
              onClick={props.onPatternRadioClick} />
            <div className={styles.brushName}>{props.activeBrushName}</div>
          </div>
          :
          null}
        <div className={styles.body}>
          <ColorsCarousel
            colorList={props.colorList}
            disabled={!canSetFill}
            hidden={props.isPattern}
            onColorClick={(color) => props.setSelectedColorFill(color, props.activeJerseySchemmeColorIndex)} />
          <PatternSelector
            hidden={!props.isPattern} />
        </div>
      </div>
    </div>
  );
}

const getActivePatternName = (state) => {
  if (state.GUI.activeFillTargetBrushIsPattern)
    return state.GUI.activePattern && state.GUI.activePattern && state.GUI.activePattern.config && state.GUI.activePattern.config.metadata && state.GUI.activePattern && state.GUI.activePattern.config.metadata.name
      ? state.GUI.activePattern.config.metadata.name : undefined;
  else
    return state.GUI.activeFillTargetBrush ? state.GUI.activeFillTargetBrush.color.Name : undefined;
}

const mapStateToProps = (state: RootState) => {

  return {
    activeBrushName: getActivePatternName(state),
    activeFillTarget: state.GUI.activeFillTarget,
    showPatternsCombo: state.GUI.patternsComboVisible,
    canSetFill: canSetFill(state),
    colorList: state.Config.colorList,
    hidden: state.GUI.mainColorSelectorHidden,
    isPattern: state.GUI.patternComboSelectorVisible,
    patternsComboVisible: state.GUI.patternsComboVisible,
    activeStep: state.GUI.activeStep,
    activeJerseySchemmeColorIndex: state.GUI.activeJerseySchemmeColorIndex,
    isPatternSelectable: state.GUI.isPatternSelectable
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, ownProps, stateProps, {

    onToggleClick: () => {
      const visibilityOptions: setPatternComboVisiblityUseCaseOptions = {
        visible: false
      };

      setPatternComboVisiblity(visibilityOptions);
    },

    onPatternRadioClick: () => {
      const options: SetColorTypeMainColorSelectorOptions = {
        isPattern: true
      };

      setColorTypeMainColorSelector(options);
    },

    onSolidsRadioClick: () => {
      const options: SetColorTypeMainColorSelectorOptions = {
        isPattern: false
      };

      setColorTypeMainColorSelector(options);

      const visibilityOptions: setPatternComboVisiblityUseCaseOptions = {
        visible: false
      };

      setPatternComboVisiblity(visibilityOptions);
    },

    setSelectedColorFill: (color, index) => {
      if (stateProps.activeStep != GuiSteps.STYLE) {
        if (stateProps.canSetFill) {

          const brushOptions: BrushFactoryOptions = {
            type: BrushFactoryType.SOLID_COLOR,
            color: color
          };

          const setFillOptions: SetFillUseCaseOptions = {
            brushOptions: brushOptions,
            fillTarget: stateProps.activeFillTarget
          };

          setFill(setFillOptions);
        }
      } else {
        setJerseySchemmeColorSelected({
          index: index,
          color: color
        });
      }
    }
  });
};

export default connect(mapStateToProps, null, mergeProps)(MainColorSelector);
