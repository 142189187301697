import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../../app/Application';
import { DecorationArea, isSingleGraphicDecorationArea, isMainLogoDecorationArea, sortDecorationAreas, uniqueDecorationAreasByType, isMultiGraphicDecorationArea } from '../../../../../app/domain/Design/DecorationArea';
import { FillTarget } from '../../../../../app/domain/Design/FillTarget';
import GraphicEditor from './GraphicEditor';
import MainLogoEditor from './MainLogoEditor';

interface Props {
  activeFillTarget: FillTarget;
  canvasId: string;
  className?: string;
  eventPatches?: DecorationArea[];
  allFonts?: any;
  nameFonts?: any[];
  hiddenFontsIds?: any;
  mainLogo?: DecorationArea;
  multiLogo?: DecorationArea[];
}

const Graphics = (props: Props) => {

  const canvasId = props.canvasId;
  let className = 'graphics';

  if (props.className) {
    className += ' ' + props.className;
  }

  return (
    <div className={className}>
      {
        props.mainLogo
        ?
        <MainLogoEditor
          activeFillTarget={props.activeFillTarget}
          canvasId={canvasId}
          decorationArea={props.mainLogo}
          allFonts={props.allFonts}
          nameFonts={props.nameFonts}
          hiddenFontsIds={props.hiddenFontsIds} />
        :
        null
      }
      {
        props.eventPatches.length > 0
        ?
        <GraphicEditor
          selectedDecorationAreaIndex={0}
          canvasId={canvasId}
          isMulti={false}
          collapsed={true}
          decorationAreas={props.eventPatches} />
        :
        null
      }
      {
        props.multiLogo.length > 0
        ?
        <GraphicEditor
          selectedDecorationAreaIndex={0}
          canvasId={canvasId}
          isMulti={true}
          collapsed={true}
          decorationAreas={props.multiLogo} />
        :
        null
      }
    </div>
  );

}

const mapStateToProps = (state: RootState) => {
  const allDecorationAreas = state.CanvasFullState.decorationAreas;
  const eventPatches = allDecorationAreas.filter((item) => {
    return isSingleGraphicDecorationArea(item);
  });
  const multiLogo = allDecorationAreas.filter((item) => {
    return isMultiGraphicDecorationArea(item);
  });
  const mainLogo = allDecorationAreas.find((item) => {
    return isMainLogoDecorationArea(item);
  });

  return {
    activeFillTarget: state.GUI.activeFillTarget,
    eventPatches,
    allFonts: state.Fonts.allFonts,
    mainLogo,
    multiLogo,
    nameFonts: state.Fonts.nameFonts
  }
}

export default connect(mapStateToProps, null)(Graphics)
