import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const resetLoginUseCase: UseCase = {
  type: 'RESET_LOGIN',

  run: async ( ) => {
    // Nothing to do, just keeps UI dev consistent, calls a use case.
    // Just dispatches "RESET_LOGIN".
    return Cx.resolve();
  }
};

export function resetLogin( ) {
  app.runUseCase( resetLoginUseCase );
}


