import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const putNextOnQueueBatchThumbUseCase: UseCase = {
  type: 'PUT_NEXT_ON_QUEUE_BATCH_THUMB',

  run: () => {    
    return Cx.resolve();
  }
};

/**
 * @category Use Case
 */
export function putNextOnQueueBatchThumb() {
  app.runUseCase( putNextOnQueueBatchThumbUseCase );
}
