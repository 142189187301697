import { app } from '../initApp';
import { CanvasAreaFactory } from '../../canvas/CanvasArea';
import { CanvasHelper } from '../../canvas/helpers/canvas';
import { UseCase } from './usecase/UseCase';

interface ZoomToFitUseCaseOptions {
  canvasId:string
}

export const zoomToFitUseCase:UseCase = {
  type: 'ZOOM_TO_FIT',

  run: (options: ZoomToFitUseCaseOptions) => {
    const canvas = CanvasAreaFactory.getCanvasById(options.canvasId);
    return CanvasHelper.zoomOutToAll$(canvas).then(canvas.render$);
  }
};

export function zoomToFit(options: ZoomToFitUseCaseOptions){
  app.runUseCase(zoomToFitUseCase, options);
}
