import { isDesignReady } from '../domain/Design/Conditions';
import { DesignElement } from '../domain/Design/DesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { bump$ } from './actions/bump$';
import { UseCase } from './usecase/UseCase';

interface BumpLeftOptions {
  designElement: DesignElement;
}

export const bumpLeftUseCase:UseCase = {
  type: 'BUMP_LEFT',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: (options: BumpLeftOptions) => {
    return bump$(options.designElement, 'left', 5);
  }
};

export function canBumpLeft(state): boolean {
  return bumpLeftUseCase.checkConditions( state ).isAllowed;
}

export function bumpLeft(options: BumpLeftOptions){
  app.runUseCase(bumpLeftUseCase, options);
}
