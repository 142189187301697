import { RosterSizeMap } from '../../domain/Roster/RosterSizeMap';
import { loadDocument$ } from './canvas';

export function loadCanvasDocument$ (canvasId: string, docId: string, sessionId?: string) {
  return loadDocument$(canvasId, docId, sessionId)
  .then(function (result) {
    const originalDoc = result.originalDoc;
    const rosterSizeMap = getRosterSizeMap(originalDoc);
    const rosterSizeOptions = getRosterSizeOptions(originalDoc);

    return Cx.resolve({
      canvasId,
      docId,
      rosterSizeMap,
      rosterSizeOptions
    });
  });
}

function getRosterSizeMap (doc: Cx.Document): RosterSizeMap[] {
  // @ts-ignore
  const options: RosterSizeMap[] = doc.pages.map((page) => {
    const name = page.name;
    const split = name.split('-');
    const item: RosterSizeMap = {
      OriginalDocPageName: name,
      Size: split[0]
    };

    return item;
  }).filter((option: RosterSizeMap) => {
    return (option.OriginalDocPageName.toLowerCase() !== 'base') ? true : false;
  });

  return options;
}

function getRosterSizeOptions (doc: Cx.Document): string[] {
  // @ts-ignore
  return doc.pages.map((page) => {
    const split = page.name.split('-');
    return split[0];
  }).filter((size) => {
    return (size && size.toLowerCase() !== 'base') ? true : false;
  });
}