import { CanvasAreaFactory } from '../../../canvas/CanvasArea'
import { DecorationArea } from '../../domain/Design/DecorationArea';
import { DesignElement } from '../../domain/Design/DesignElement';
import { clearDecorationArea, enumDecorationAreas } from './canvas';

export function clearDecorationArea$ (designElement: DesignElement, clearGroupDecorationAreas: boolean = true ) {
  const canvasId = designElement.canvasId;
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const allDecorationAreas = enumDecorationAreas(canvas);
  const decorationArea = allDecorationAreas.find((area) => {
    return area.Id === designElement.decorationAreaId;
  });
  let decorationAreasToClear = [];

  if (clearGroupDecorationAreas) {
    decorationAreasToClear = allDecorationAreas.filter((a: DecorationArea) => {
      // Updating a group? Or by Id
      return ( decorationArea.GroupId && a.GroupId === decorationArea.GroupId ) && a.Id !== decorationArea.Id;
    });  
  }

  decorationAreasToClear.push(decorationArea);

  decorationAreasToClear.forEach((area) => {
    clearDecorationArea(canvas, area.Id);
  });

  return canvas.commit$();

}
