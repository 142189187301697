import { isDesignReady } from '../domain/Design/Conditions';
import { DesignElement } from '../domain/Design/DesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { bump$ } from './actions/bump$';
import { UseCase } from './usecase/UseCase';

interface BumpUpOptions {
  designElement: DesignElement;
}

export const bumpUpUseCase:UseCase = {
  type: 'BUMP_UP',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: (options: BumpUpOptions) => {
    return bump$(options.designElement, 'up', 5);
  }
};

export function canBumpUp(state): boolean {
  return bumpUpUseCase.checkConditions( state ).isAllowed;
}

export function bumpUp(options: BumpUpOptions){
  app.runUseCase(bumpUpUseCase, options);
}
