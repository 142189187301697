import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';
import uniqueKey from '../../util/uniqueKey';
import FolderTree from 'react-folder-tree';
import 'react-folder-tree/dist/style.css';


interface BatchProcessorProps {
  data?: any;     
  remaining?: number
} 

const BatchProcessor = (props: BatchProcessorProps) => {

  const onTreeStateChange = (state, event) => console.log(state, event);
  

  if (props.data) 
    return (<div> <div>Remaining: {props.remaining}</div> <br/> Folder Tree: <FolderTree
      data={ { name: 'thumbs', children: props.data } }
      onChange={ onTreeStateChange }
    /></div>  )
  else
    return (<div>Data loading ...</div>);
}

const renderList = (list) => {
  return <React.Fragment><div>{list.map((e)=>{ return <div key={uniqueKey()}>{e}</div>})}</div></React.Fragment>
}

const mapStateToProps = (state: RootState, ownProps) => {

  return {
    data: state.Thumb3D.designStylesTree,    
    currentInstances: state.Thumb3D.currentInstances,    
    remaining: state.Thumb3D.remaining
  };
}


export default connect(mapStateToProps, null)(BatchProcessor);