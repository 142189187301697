import { Component, createRef } from 'react';
import React from 'react';
import Field from './Field';
import { SelectInputMenu } from './SelectInputMenu';

import styles from './SelectInput.module.css';

/**
 * Usage:
 *
 * <SelectInput
 *   handler=function (value, field, option) {...}
 *   label="..."
 *   options= [{ text: '...', value: ... }, ...]
 *   placeholder='...'
 *   value=... />
 */

interface Props {
  className?: string;
  handler: Function;
  label?: string;
  onFocus?: any;
  options: any;
  optionsHeader?: string;
  placeholder?: string;
  value: string;
  hiddenIds?: string[];
}

class SelectInput extends Component<Props> {

  private inputRef:any

  constructor(props){
    super(props);
    this.inputRef = createRef();
  }

  public render () {
    let className = 'select-field';
    let inputClassName = styles.selectSelected;
    const label = this.props.label;
    const displayValue = this.findDisplayValue();

    if (!this.props.value) {
      inputClassName += this.props.placeholder ? (' ' + styles.placeholder) : ' empty';
    }

    if (this.props.className) {
      className += ' ' + this.props.className;
    }

    // Since this field is read only we can use input instead of Input component
    return (
      <Field className={className} label={label}>
        <input
          className={inputClassName}
          onClick={() => this.showMenu()}
          readOnly={true}
          value={ displayValue }
          ref={this.inputRef} />
        <div className={styles.trigger}></div>
      </Field>
    );
  }

  private findDisplayValue () : any {
    const value = this.props.value;
    const options = this.props.options || [];
    const len = options.length;
    let i = 0;

    for (; i < len; i++) {
      if (options[i].value === value) {
        return options[i].text;
      }
    }

    // Return the value if an option is not found
    // Replace clipart field sets the value as the clipart id
    // not to an actual option value
    return value || this.props.placeholder || '';
  }

  public toggle () {
    this.showMenu();
  }

  private evaluateVisible(v) {
    if ( ! this.props.hiddenIds )
      return true 
    else 
      return this.props.hiddenIds.indexOf(v) == -1 ;
  }

  private showMenu () {
    SelectInputMenu.show({
      label: this.props.label || this.props.optionsHeader,
      options: this.props.options.filter( (o) => { return this.evaluateVisible(o.value); }),//filter out hidden ones
      selectHandler: this.select.bind(this),
      showByTarget: this.inputRef.current,
      value: this.props.value
    });

    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  public select (value) {
    if(this.props.handler) {
      this.props.handler(value, this, this.findOptionByValue(value));
    }
  }

  private findOptionByValue (value) {
    const options = this.props.options || [];
    const len = options.length;
    let i = 0;

    for (; i < len; i++) {
      if (options[i].value === value) {
        return options[i];
      }
    }

    return undefined;
  }

}

export default SelectInput;