import { isConfigReady } from '../domain/Config/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';

interface SetSportUseCaseOptions {
  sport: any;
}

export const setSportUseCase: UseCase = {
  type: 'SET_SPORT',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isConfigReady]);
  },

  run: ( options: SetSportUseCaseOptions ) => {
    return Cx.resolve({
      sport: options.sport
    });
  }
};

/**
 * @category Can
 */
export function canSetSport( state ) {
  return setSportUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setSport( options: SetSportUseCaseOptions ) {
  app.runUseCase( setSportUseCase, options );
}
