import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { initPatternConfigUseCase } from '../usecases/init/initPatternConfig';
import { loadPatternsUseCase } from '../usecases/loadPatterns'
import { PatternConfig, emptyPatternConfig } from '../domain/Pattern/Pattern'
import { setFillUseCase } from '../usecases/setFill';
import { addOutlineUseCase } from '../usecases/addOutline';
import { setFillTargetUseCase } from '../usecases/setFillTarget';
import { setFillTargetByClickUseCase } from '../usecases/setFillTargetByClick';
import { GetSpotColorDefaultIndexesZoneMap, GetDefaultColorZones } from "../domain/Pattern/colorZoneHelper"
import { reportMessage } from '../../gui/util/rollbar';
export interface PatternReducerState {
  patterns: any;
  selectedPatternConfig: PatternConfig
};

const initState = {
  patterns: [],
  selectedPatternConfig: emptyPatternConfig()
};

export function PatternReducer (state: any = initState, actionResult: UseCaseResult) {

  switch (actionResult.type) {

    case loadPatternsUseCase.type:
    {
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success())
      {
        return Object.assign({}, state, {
            patterns: actionResult.data.patterns
        });
      }
      return state ;
    }

    case addOutlineUseCase.type:
      case setFillTargetUseCase.type:
      case setFillTargetByClickUseCase.type:
      {
        if (actionResult.failure()) {
          reportMessage(actionResult.type, actionResult.rejectionReason)
        }

        if (actionResult.success())
        {
          if ( actionResult.data.fillTargetBrushIsPattern )
          {
            const config = state.patterns.find((p)=>{ return p.Config.metadata.patternLink == actionResult.data.fillTargetBrush._link });
            const seletedPatternId = config.Id;
            const metadata = config?.Config.metadata ;
            const colors = config?.Config.colors ;
            const map = GetSpotColorDefaultIndexesZoneMap(actionResult.data.fillTargetBrush.getColors());
            const defaultColorZones = GetDefaultColorZones(Cx.uniqueColorZones(actionResult.data.fillTargetBrush), colors[0], map);
            return Object.assign({}, state, {
              selectedPatternConfig: {
                seletedPatternId,
                metadata,
                colors,
                defaultColorZones,
                colorMap: map
              }
            });
          }else {
            return Object.assign({}, state, {
              selectedPatternConfig: state.selectedPatternConfig ? state.selectedPatternConfig : emptyPatternConfig()
            });
          }  
        }
        return state ;
      } 
  
      case setFillUseCase.type:
      {
        if (actionResult.failure()) {
          reportMessage(actionResult.type, actionResult.rejectionReason)
        }

        if (actionResult.success())
        {
          if ( actionResult.data.docId )
          {
            const config = state.patterns.find((p)=>{ return p.Config.metadata.patternLink == actionResult.data.brush._link });
            const seletedPatternId = config.Id;
            const metadata = config?.Config.metadata ;
            const colors = config?.Config.colors ;
            const map = GetSpotColorDefaultIndexesZoneMap(actionResult.data.brush.getColors());
            const defaultColorZones = GetDefaultColorZones(Cx.uniqueColorZones(actionResult.data.brush), colors[0], map);
            return Object.assign({}, state, {
              selectedPatternConfig: {
                seletedPatternId,
                metadata,
                colors,
                defaultColorZones: defaultColorZones,
                colorMap: map
              }
            });
          }
          else {
            return Object.assign({}, state, {
              selectedPatternConfig: state.selectedPatternConfig ? state.selectedPatternConfig : emptyPatternConfig()
            });
          }  
        }
        else {
          return state; 
        }      
      }
  }
  return state;
}
