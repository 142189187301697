import { UserArtDesignElement } from '../domain/Design/UserArtDesignElement';
import { SavedOrder } from '../domain/Order/SavedOrder';
import { openSavedOrderUseCase } from '../usecases/openSavedOrder';
import { saveOrderUseCase } from '../usecases/saveOrder';
import { showReviewStepUseCase } from '../usecases/showReviewStep';
import { submitOrderUseCase } from '../usecases/submitOrder';
import { uploadLogoUseCase } from '../usecases/uploadLogo';
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { resetSubmittedOrderUseCase } from '../usecases/resetSubmittedOrder';
import { reportMessage } from '../../gui/util/rollbar';

export interface AppReducerState {
  actionResult: UseCaseResult;
  openedSavedOrder: SavedOrder;
  savingOrderState: boolean;
  submittingOrder: boolean;
  submittedOrder: boolean;
  uploadingLogo: boolean;
  uploadingLogoToDecorationAreaId: UserArtDesignElement;
  gettingPriceByOrder: boolean;
}

const initialState: AppReducerState = {
  actionResult: undefined,
  openedSavedOrder: undefined,
  savingOrderState: false,
  submittedOrder: false,
  submittingOrder: false,
  uploadingLogo: false,
  uploadingLogoToDecorationAreaId: undefined,
  gettingPriceByOrder: false
};

export function AppReducer(state: any = { ...initialState }, actionResult: UseCaseResult): AppReducerState {
  state = Object.assign(state, { // Ensure we return a new state
    actionResult
  });

  switch (actionResult.type) {

    case uploadLogoUseCase.type:
      if (actionResult.start()) {
        return Object.assign({}, state, {
          uploadingLogo: true,
          uploadingLogoToDecorationAreaId: actionResult.data.decorationAreaId
        });
      }

      if (actionResult.failure() || actionResult.success()) {
        if (actionResult.failure())
          reportMessage(actionResult.type, actionResult.rejectionReason);
        return Object.assign({}, state, {
          uploadingLogo: false,
          uploadingLogoToDecorationAreaId: undefined
        });
      }

      return state;

    case saveOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason);
        // TODO: show alert?
        return Object.assign({}, state, {
          savingOrderState: false
        });
      }

      if (actionResult.start()) {
        return Object.assign({}, state, {
          savingOrderState: true
        });
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          savingOrderState: false
        });
      }

      return state;

    case resetSubmittedOrderUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, state, {
          submittedOrder: false
        });
      }

      return state ;

    case submitOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason);
        // TODO: show alert?
        return Object.assign({}, state, {
          submittingOrder: false,
          submittedOrder: false
        });
      }

      if (actionResult.start()) {
        return Object.assign({}, state, {
          submittingOrder: true,
          submittedOrder: false
        });
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          submittingOrder: false,
          submittedOrder: true
        });
      }

      return state;

    case openSavedOrderUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, state, {
          openedSavedOrder: actionResult.data.savedOrder
        });
      }

      return state;

    case showReviewStepUseCase.type:
      if (actionResult.failure()) {
        // TODO: show alert?
        reportMessage(actionResult.type, actionResult.rejectionReason);
        return Object.assign({}, state, {
          gettingPriceByOrder: false
        });
      }

      if (actionResult.start()) {
        return Object.assign({}, state, {
          gettingPriceByOrder: true
        });
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          gettingPriceByOrder: false,
          submittedOrder: false
        });
      }

      return state;
  }

  return state;
}