import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const isLoadingOrderUseCase: UseCase = {
  type: 'LOADING_ORDER',

  run: async ( ) => {
    return Cx.resolve();
  }
};

export function isLoadingOrder( ) {
  app.runUseCase( isLoadingOrderUseCase );
}