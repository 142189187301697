import { pollForAccessStartUseCase, pollForAccessStopUseCase } from '../usecases/pollForAccess'
import { pollServerForAccessUseCase } from '../usecases/pollServerForAccess'
import { initialPolling, Polling } from '../domain/Polling/Polling';
import { reportMessage } from '../../gui/util/rollbar';

export function PollingReducer (state: Polling = initialPolling(), actionResult) {

    switch (actionResult.type) {

      case pollForAccessStartUseCase.type:
      {
        if (actionResult.failure()) {
          reportMessage(actionResult.type, actionResult.rejectionReason)
        }

        if (actionResult.success()) {
          return Object.assign({}, state, {
            isPollingActive: true
          });
        }
        return state;
      }

      case pollForAccessStopUseCase.type:
      {
        if (actionResult.failure()) {
          reportMessage(actionResult.type, actionResult.rejectionReason)
        }

        if (actionResult.success()) {
          return Object.assign({}, state, {
            isPollingActive: false ,
            pollingResult: actionResult.data.pollingResult
          });
        }
        return state;
      }

      case pollServerForAccessUseCase.type:
        if (actionResult.failure()) {
          reportMessage(actionResult.type, actionResult.rejectionReason)
        }

        if (actionResult.success()) {
          return Object.assign({}, state, {
            pollingResult: actionResult.data.pollingResult
          });
        }
        return state;
    }

    return state ;
}