import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const showFAQPopupUseCase: UseCase = {
  type: 'SHOW_FAQ_POPUP',

  run: () => {
    return Cx.resolve();
  }
};

export function showFAQPopup() {
  app.runUseCase(showFAQPopupUseCase);
}
