import { isDesignReady } from '../domain/Design/Conditions';
import { DesignElement } from '../domain/Design/DesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { clearDecorationArea$ } from './actions/clearDecorationArea$'
import { UseCase } from './usecase/UseCase';

export interface ClearDecorationAreaOptions {
  designElement: DesignElement,
  clearGroupDecorationAreas?: boolean
}

export const clearDecorationAreaUseCase: UseCase = {
  type: 'CLEAR_DECORATION_AREA',

  checkConditions: (state) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: ( options: ClearDecorationAreaOptions ) => {
    return clearDecorationArea$(options.designElement, options.clearGroupDecorationAreas).then(() => {      
      return Cx.resolve(options);
    });
  }
};

/**
 * @category Can
 */
export function canClearDecorationArea( state ) {
  return clearDecorationAreaUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function clearDecorationArea( options: ClearDecorationAreaOptions ) {
  app.runUseCase( clearDecorationAreaUseCase, options );
}
