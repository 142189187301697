import { isConfigReady } from '../domain/Config/Conditions';
import { hasActiveSport } from '../domain/Order/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';

interface SetGrayGoodUseCaseOptions {
  grayGood: number;
}

export const setGrayGoodUseCase: UseCase = {
  type: 'SET_GRAY_GOOD',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isConfigReady, hasActiveSport]);
  },

  run: ( options: SetGrayGoodUseCaseOptions ) => {
    return Cx.resolve({
      grayGood: options.grayGood
    });
  }
};

/**
 * @category Can
 */
export function canSetGrayGood( state ) {
  return setGrayGoodUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setGrayGood( options: SetGrayGoodUseCaseOptions ) {
  app.runUseCase( setGrayGoodUseCase, options );
}
