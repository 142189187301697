import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { poll } from '../../gui/util/poll'
import { saveAccessStart, checkForAccess } from '../../gui/services/storage'
import { coachHasSession } from "../usecases/coachHasSession"

export interface pollForAccessStartUseCaseOptions {
  timeout: number,
  interval: number,
  hash: string
}

export interface pollForAccessStopUseCaseOptions { 
  result: boolean
}

const stopCallback = (result) => {
  let options : pollForAccessStopUseCaseOptions = {
    result: result
  }
  if (result) 
  {
    coachHasSession();
  }
  pollForAccessStop(options);
}


export const pollForAccessStartUseCase: UseCase = {
  type: 'POLL_FOR_ACCESS_START',

  run: ( options: pollForAccessStartUseCaseOptions ) => {    
    saveAccessStart(options.hash);
    poll(checkForAccess, options.hash, stopCallback, options.timeout, options.interval);  
    return Cx.resolve();
  }
};

export const pollForAccessStopUseCase: UseCase = {
  type: 'POLL_FOR_ACCESS_STOP',

  run: ( options: pollForAccessStopUseCaseOptions ) => {    
    return Cx.resolve({ pollingResult: options.result });
  }
};

/**
 * @category Use Case
 */
export function pollForAccessStart( options: pollForAccessStartUseCaseOptions ) {
  app.runUseCase( pollForAccessStartUseCase, options );
}

function pollForAccessStop( options: pollForAccessStopUseCaseOptions ) {
  app.runUseCase( pollForAccessStopUseCase, options );
}
