import { CanvasAreaFactory } from '../../../canvas/CanvasArea';
import { DesignElement } from '../../domain/Design/DesignElement';
import { getDecorationArea, getInDecorationAreaName } from './canvas';

export function bump$ (designElement: DesignElement, direction: string, increment: number) {
  const areaId = designElement.decorationAreaId;
  const canvas = CanvasAreaFactory.getCanvasById(designElement.canvasId);
  const target = getDecorationArea(canvas, areaId);
  const figure = canvas.figures.filter((f) => {
    return getInDecorationAreaName(f) === areaId;
  })[0];

  return target.frame$()
  .then(function (targetFrame) {
    return figure.frame$().then((frame) => {
      let x = 0;
      let y = 0;
      let diff = 0;

      switch (direction) {
        case 'down':
          diff = frame.bounds.ly - targetFrame.bounds.ly;
          y = -(increment > diff ? 0 : increment);
          break;

        case 'left':
          diff = frame.bounds.lx - targetFrame.bounds.lx;
          x = -(increment > diff ? 0 : increment);
          break;
        case 'right':
          diff = targetFrame.bounds.hx - frame.bounds.hx;
          x = increment > diff ? 0 : increment;
          break;
        case 'up':
          diff = targetFrame.bounds.hy - frame.bounds.hy;
          y = increment > diff ? 0 : increment;
          break;
      }

      if (x !== 0 || y !== 0) {

        figure.metadata.set('centerDeltaX', (targetFrame.bounds.mx - frame.bounds.mx).toFixed(4) );
        figure.metadata.set('centerDeltaY', (targetFrame.bounds.my - frame.bounds.my).toFixed(4) );  
        
        canvas.bump(figure, x, y);
        return canvas.preview$();
      } else {
        return Cx.resolve();
      }
    });
  });
}