import { CharReplacement } from '../../domain/Font/CharReplacement'
import global from '../../../gui/services/global';
import { Units } from '../../../canvas/helpers/units';
import { TextDesignElement  } from '../../domain/Design/TextDesignElement';

export function getContourThicknessForSize(fontSize:number)
{
  let thickness : number = 0;
  Object.keys(global._FONT_OUTLINE_).forEach( (k) => {
    if ( fontSize >= parseFloat(k))
      thickness = global._FONT_OUTLINE_[k].toFixed(2)
  });
  return thickness ;
}

export function getOutlineSum(textElement:TextDesignElement)
{
  return textElement.outlines.reduce( (r,v) =>{
    return r + v.Offset;
  },0 )
}

export function getFontIdByStyleName(name: string,fontConfigs: any)
{
  return fontConfigs.filter( (c) => { return c.data.fontName.toUpperCase() === name.toUpperCase() } ).pop().value ;
}

export function applyConfig(textElement:TextDesignElement, configs:any)
{
  if ( ! textElement.fontId || textElement.fontId.length == 0 )
    textElement.fontId = getFontIdByStyleName( textElement.fontFamily, configs);

  let config = getFontConfigForSize(textElement.fontId, Units.toUser(textElement.fontSize), configs);
  
  if ( config )
  {
    textElement.fontId = config.fontId;
    textElement.text = processText(textElement.text, config)    
    textElement.outlineJoin = config.lineJoin ;
    textElement.fontIsScript = config.isScript;
  }

  return textElement ;
}

export function processText(text:string, config:any): string
{
  if ( config && config.lowerCaseReplace )
    text = CharReplacement.lower_case_replace(text);
  if ( config )
    text = CharReplacement.ligatures_replace(config.fullName, text);
  if ( config && config.upperCaseOnly )
    text = text.toUpperCase();
  return text ;
}

export function getFontConfigForSize(fontId: string, fontSize: number, config: any )
{
  let fontConfig = config.filter( (c) => { return c.value === fontId } ).pop().data;
  if ( fontConfig )
  {
    Object.assign(fontConfig, { fontId: fontId });

    let sizeBasedConfig = config.filter( (c) => { return c.data.fontName === fontConfig.fontName && c.data.type === fontConfig.type } )
    
    sizeBasedConfig = sizeBasedConfig.sort((a, b) => (a.data.scaleSize > b.data.scaleSize) ? 1 : -1);
    sizeBasedConfig = sizeBasedConfig.filter((c) => { return c.data.scaleSize >= fontSize ; });
    if ( sizeBasedConfig.length > 0 )
    {
      fontConfig = sizeBasedConfig[0].data;
      fontConfig.fontId = sizeBasedConfig[0].value;
    }
  }
  return fontConfig;
}

export function loadFontConfig$()
{
  let model = { Name: global._FONT_CONFIG_NAME_
              , Shared: true
              , Owned: false
              , Tags:[{"Name": global._FONT_CONFIG_TAG_NAME_
              ,"Owned":false
              ,"Shared":true
              ,"Archived":false
              ,"Type":"Config"
              ,"Links":null }]};

  // @ts-ignore
  return Cx.Core.Config.search$({ Match: model });
}
