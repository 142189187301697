import { RootState } from '../Application';
import { isDesignReady } from '../domain/Design/Conditions';
import { TextDesignElement  } from '../domain/Design/TextDesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { updateTextDesignElement$ } from './actions/updateTextDesignElement$';
import { UseCase } from './usecase/UseCase';

export interface SetTextOptions {
  designElement: TextDesignElement;
  text: string;
}

export const setTextUseCase: UseCase = {
  type: 'SET_TEXT',

  checkConditions: (state: RootState) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: ( options: SetTextOptions ) => {
    const designElement = options.designElement;
    designElement.text = options.text;

    // update on figure
    return updateTextDesignElement$(designElement).then(() => {
      return Cx.resolve(options);
    });
  }
};

/**
 * @category Can
 */
export function canSetText( state: RootState ) {
  return setTextUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setText( options: SetTextOptions ) {
  app.runUseCase( setTextUseCase, options );
}
