import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';
import { hideModal } from '../../../app/initApp';
import { resetLogin } from '../../../app/usecases/resetLogin';
import hash from '../../util/hash';
import Orders from '../orders/Orders';
import OrderSubmissionConfirmation from '../orders/OrderSubmissionConfirmation';
import OrderStateSavedConfirmation from '../savedOrders/OrderStateSavedConfirmation';
import SavedOrders from '../savedOrders/SavedOrders';
import Login from '../steps/login/Login';
import RosterItemEditor from '../steps/roster/RosterItemEditor';
import Alert from './Alert';
import Modal from './Modal';
import MoreMenu from './MoreMenu';
import ImportRosterPopup from '../steps/roster/ImportRosterPopup';
import CoachContactInfo from '../steps/login/CoachContactInfo';
import RemoveProductPopup from './RemoveProductPopup';
import styles from './ModalManager.module.css';
import SelectCoachContactInfo from '../steps/login/SelectCoachContactInfo'
import { evaluateSubmittingOrder } from '../../../app/usecases/evaluateSubmittingOrder';
import OrderOpenCopyConfirmation from '../orders/OrderOpenCopyConfirmation';
import { isMobile } from '../../util/isMobile';
import CustomMsgPopup from './CustomMsgPopup';
import FAQPopup from './FAQPopup';

const ModalManager = (props) => {

  // if (props.addPhonePromptOpen) {
  //   return <Modal side="right" title="Add Phone" closeHandler={() => hideModal()}>
  //     <AddPhonePrompt />
  //   </Modal>
  // }

  if (props.loginOpen) {
    const closeLoginModal = () => {
      resetLogin();
      hideModal();
    };

    return <Modal side="right" closeHandler={closeLoginModal}>
      {/*TODO: token not used on client.*/}
      {/*It's passed  around, ultimately to the use case for magic link and google and used in the URL*/}
      {/*Want to check why it's created here versus as part of the use case (and put on state if needed).*/}
      <Login token={hash(5)} />
    </Modal>
  }

  if (props.alertOpen) {
    return <Modal side="center" title="Alert" closeHandler={() => hideModal()}>
      <Alert {...props.alertProps} />
    </Modal>
  }

  if (props.moreMenuOpen) {
    return <Modal side="right" title="My Account" closeHandler={() => hideModal()}>
      <MoreMenu />
    </Modal>
  }

  if (props.orderStateSavedConfirmationOpen) {
    return <Modal cls={styles.orderConfirmation} side="center" closeHandler={() => hideModal()}>
      <OrderStateSavedConfirmation  />
    </Modal>
  }

  if (props.orderSubmittionConfirmationOpen) {
    return <Modal cls={styles.orderConfirmation} side="center" closeHandler={() => hideModal()}>
      <OrderSubmissionConfirmation />
    </Modal>
  }

  if (props.savedOrdersOpen) {
    let side = isMobile() ? "rigth" : "center";

    return <Modal
      cls={styles.savedOrders}
      side={side}
      title="Saved Orders"
      closeHandler={() =>
        hideModal()}>
      <SavedOrders />
    </Modal>
  }

  if (props.submittedOrdersOpen) {
    let side = isMobile() ? "rigth" : "center";

    return <Modal
      cls={styles.submittedOrders}
      side={side}
      title="Submitted Orders"
      closeHandler={() =>
        hideModal()}>
      <Orders />
    </Modal>
  }

  if (props.rosterItemEditorOpen) {
    return <Modal side="right" title="Edit" closeHandler={() => hideModal()}>
      <RosterItemEditor {...props.rosterItemEditorProps} />
    </Modal>;
  }

  if (props.importRosterOpen) {
    return <Modal cls={styles.importRosterPopup} side="center" title="Import Roster" closeHandler={() => hideModal()}>
      <ImportRosterPopup {...props.importRosterProps} />
    </Modal>
  }

  if (props.contactInfoOpen) {
    const closeModal = () => {
      evaluateSubmittingOrder();
      hideModal();
    };

    return <Modal cls={styles.importRosterPopup} side="center" title="Coach Contact Info" closeHandler={() => closeModal()}>
      <CoachContactInfo {...props.contactInfoOpenProps} />
    </Modal>
  }

  if (props.removeProductOpen) {
    return <Modal side="center" closeHandler={() => hideModal()}>
      <RemoveProductPopup {...props.removeProductProps} />
    </Modal>
  }

  if (props.selectContactInfoOpen) {
    return <Modal cls={styles.selectCoachInfoPopup} side="center" title="Select Coach Contact" closeHandler={() => hideModal()}>
      <SelectCoachContactInfo {...props.selectContactInfoOpenProps} />
    </Modal>
  }

  if (props.orderOpenCopyInfoOpen) {
    return <Modal cls={styles.selectCoachInfoPopup} side="center" title="Open Order" closeHandler={() => hideModal()}>
      <OrderOpenCopyConfirmation {...props.orderOpenCopyInfoOpenProps}  />
    </Modal>
  }

  if (props.customMsgPopupOpen) {
    return <Modal cls={styles.customMsgPopup} side="center" closeHandler={() => hideModal()}>
      <CustomMsgPopup {...props.customMsgPopupProps} />
    </Modal>
  }

  if (props.errorDialogOpen) {
    return <Modal cls={styles.customMsgPopup} side="center" closeHandler={() => hideModal()}>
      <CustomMsgPopup {...props.customMsgPopupProps} />
    </Modal>
  }

  if (props.faqPopupOpen) {
    return <Modal cls={styles.faqPopup} side="center" title="Uniform Creator FAQ" closeHandler={() => hideModal()}>
      <FAQPopup />
    </Modal>
  }

  return null;
}

const mapStateToProps = (state: RootState) => {
  const modalState = state.Modal;

  return {
    addPhonePromptOpen: modalState.addPhonePromptOpen,
    alertOpen: modalState.alertOpen,
    alertProps: modalState.alertProps,
    importRosterOpen: modalState.importRosterOpen,
    importRosterProps: modalState.importRosterProps,
    loginOpen: modalState.loginOpen,
    moreMenuOpen: modalState.moreMenuOpen,
    orderStateSavedConfirmationOpen: modalState.orderStateSavedConfirmationOpen,
    orderSubmittionConfirmationOpen: modalState.orderSubmittionConfirmationOpen,
    errorDialogOpen: modalState.errorDialogOpen,
    removeProductOpen: modalState.removeProductOpen,
    removeProductProps: modalState.removeProductProps,
    rosterItemEditorOpen: modalState.rosterItemEditorOpen,
    rosterItemEditorProps: modalState.rosterItemEditorProps,
    savedOrdersOpen: modalState.savedOrdersOpen,
    submittedOrdersOpen: modalState.submittedOrdersOpen,
    contactInfoOpen: modalState.contactInfoOpen,
    contactInfoOpenProps: modalState.contactInfoOpenProps,
    selectContactInfoOpen: modalState.selectContactInfoOpen,
    selectContactInfoOpenProps: modalState.selectContactInfoOpenProps,
    orderOpenCopyInfoOpen: modalState.orderOpenCopyInfoOpen,
    orderOpenCopyInfoOpenProps: modalState.orderOpenCopyInfoOpenProps,
    customMsgPopupOpen: modalState.customMsgPopupOpen,
    customMsgPopupProps: modalState.customMsgPopupProps,
    faqPopupOpen: modalState.faqPopupOpen
  }
}

export default connect(mapStateToProps, null)(ModalManager);
