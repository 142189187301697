import { coachHasSessionUseCase } from '../usecases/coachHasSession';
import {signOutUseCase} from '../usecases/signOut';
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { verifyPhoneCodeUseCase } from '../usecases/login/verifyPhoneCode';
import { setInitialCoachUseCase } from '../usecases/setInitialCoachKey';
import { verifyEmailHashUseCase } from '../usecases/login/verifyEmail';
import { setInitialPartnerUseCase } from '../usecases/setInitialPartner';
import { reportMessage } from '../../gui/util/rollbar';
import { initPartnerWithColors } from '../usecases';
import { initPartnerWithColorsUseCase } from '../usecases/init/initPartnerWithColors';

export interface SessionReducerState {
  sessionToken: string;
  coachesKey: string;
  partnersKey: string;
  cadworxSessionId: string;
  partnerCadworxSessionId: string;
  ddAccountNumber: string;
  getSessionFailed: boolean; // Last Attempt Failed?
  verificationStarted: boolean;
}

const initialState: SessionReducerState = {
  sessionToken: undefined,
  partnersKey: undefined,
  coachesKey: undefined,
  cadworxSessionId: undefined,
  ddAccountNumber: undefined,
  partnerCadworxSessionId: undefined,
  getSessionFailed: false,
  verificationStarted: false,
};

export function SessionReducer (state:any = {...initialState}, actionResult: UseCaseResult): SessionReducerState {

  // NOTE: Rare cases here where we will want to reset everything in this state to initialState.  Generally, we want to avoid this.

  switch (actionResult.type) {

    case verifyPhoneCodeUseCase.type:
    case coachHasSessionUseCase.type:
    case verifyEmailHashUseCase.type:
      if (actionResult.start()) {
        return Object.assign({}, state, {
          verificationStarted: true
        });
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          coachesKey: actionResult.data.coach.coachesKey,
          partnersKey: state.partnersKey,
          cadworxSessionId: actionResult.data.coach.cadworxSessionId,
          ddAccountNumber: actionResult.data.coach.ddAccountNumber,
          sessionToken: actionResult.data.sessionToken
        });
      }

      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
        return Object.assign({}, state, {
          getSessionFailed: true,
          verificationStarted: state.verificationStarted 
        });
      }

      return state;

    case setInitialCoachUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          coachesKey: actionResult.data
        });
      }

      return state ;


    case setInitialPartnerUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          partnersKey: actionResult.data.partnerKey
        });
      }

      return state ;

    case signOutUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, state, {
          coachesKey: undefined,
          partnersKey: state.partnersKey,
          cadworxSessionId: undefined,
          ddAccountNumber: undefined,
          sessionToken: undefined
        });
      }

      return state ;

    case initPartnerWithColorsUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, state, {
          partnerCadworxSessionId: actionResult.data.partnerData.Partner[0].cadworxSessionId
        });
      }

      return state ;

  }

  return state;
}
