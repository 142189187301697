import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

interface CustomMsgPopupUseCaseOptions {
  title?: string;
  onClick?: Function;
  showCancel?: boolean;
  showConfirm?: boolean;
  showCancelMsg?: string;
  showConfirmMsg?: string;
}

export const customMsgPopupUseCase: UseCase = {
  type: 'SHOW_CUSTOM_MSG_POPUP',

  run: (options: CustomMsgPopupUseCaseOptions) => {
    return Cx.resolve(options);
  }
};

export function showCustomMsgPopup(options: CustomMsgPopupUseCaseOptions) {
  app.runUseCase(customMsgPopupUseCase, options);
}