import { isConfigReady } from '../domain/Config/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { coachHasSession } from './coachHasSession';
import { UseCase } from './usecase/UseCase';

interface SetInitialOrderUseCaseOptions {
  orderId: string;
  initialOrderOpenAsCopy: boolean;
}

export const setInitialOrderUseCase: UseCase = {
  type: 'SET_INITIAL_ORDER',

  run: ( options: SetInitialOrderUseCaseOptions ) => {
    return Cx.resolve({
      orderId: options.orderId,
      initialOrderOpenAsCopy: options.initialOrderOpenAsCopy
    });
  }
};

/**
 * @category Can
 */
export function canSetInitialOrder( state ) {
  return setInitialOrderUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setInitialOrder( options: SetInitialOrderUseCaseOptions ) {
  app.runUseCase( setInitialOrderUseCase, options );
}
