export function copyCanvasDocument$(docId)
{
  return Cx.Core.Document.read$({ Id: docId })
  .then((loadedDoc)=>{
    // @ts-ignore
    return Cx.Core.Document.create$(loadedDoc)
    .then( function(createdDocModel) {
      return Cx.resolve(createdDocModel.Id);
    });
  })
}