import { app } from '../initApp';
import { coachContactInfo } from './createCoachContactInfo';
import { UseCase } from './usecase/UseCase';

export interface SelectCoachContactInfoUseCaseOptions {
  coachesKey: string,
  info: coachContactInfo[]
}

export const showSelectContactInfoModalUseCase: UseCase = {
  type: 'SHOW_SELECT_CONTACT_INFO_MODAL',

  run: (props: SelectCoachContactInfoUseCaseOptions) => {
    return Cx.resolve(props);
  }
};

export function showSelectContactInfoModal(props: SelectCoachContactInfoUseCaseOptions) {
  app.runUseCase( showSelectContactInfoModalUseCase, props );
}
