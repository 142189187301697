import { Component } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import {
  showCustomizeStep,
  showReviewStep,
  canAddRosterItem,
  canShowReviewStep,
  showAddRosterStep,
  canSubmitOrder,
  hideModal
}
  from '../../../../app/initApp';
import global from '../../../services/global';
import Canvas from '../../design/Canvas';
import Button from '../../misc/Button';
import RosterGrid from '../roster/RosterGrid';
import CustomizeHud from './CustomizeHud';
import CustomizeTabs from './CustomizeTabs';
import CustomizeToolsHud from './CustomizeToolsHud';
import { activeProductFromApplicationState } from '../../../../app/domain/Product/Product';
import { RosterItem } from '../../../../app/domain/Roster/RosterItem';
import { validateRosterItems } from '../../../../app/usecases/roster/validateRosterItems';
import { showCustomMsgPopup } from '../../../../app/usecases/customMsgPopupUseCase';

// Styles
import './Customize.css';

interface Props {
  canAddRosterItem: boolean;
  canShowReviewStep: boolean;
  hidden: boolean;
  itemNumber: string;
  showAddRoster: boolean;
  canShowPatternsCombo: boolean;
  canvasIsReady?: boolean;
  jerseyThumbColorMap?: any
  activeStep: string;
  rosterItems?: RosterItem[];
  rosterSizeOptions: any[];
  products?: any[],
  partnerKey?: string,
  stateTax?: string,
  gettingPriceByOrder?: boolean
}

class Customize extends Component<Props> {
  public state = {
    canvasLoaded: false
  };

  private setRenderedState = (state) => {
    this.setState({
      canvasLoaded: state
    });
  }

  private onAddRosterClick(itemNumber) {
    showAddRosterStep({ itemNumber });
  }

  public render() {
    const hidden = this.props.hidden;
    const activeStep = this.props.activeStep;
    let className = 'customize flex-one' + (activeStep === "customize" ? " no-footer" : "");
    const itemNumber = this.props.itemNumber;
    const showAddRoster = this.props.showAddRoster;
    const rosterItems = this.props.rosterItems;
    const rosterSizeOptions = this.props.rosterSizeOptions;
    const gettingPriceByOrder = this.props.gettingPriceByOrder;
    const reviewButtonDisabled = gettingPriceByOrder;
    const reviewButtonIconCls = gettingPriceByOrder ? 'fas fa-circle-notch fa-spin' : "fa-caret-right";
    const reviewButtonLabel = gettingPriceByOrder ? '' : 'REVIEW';
    const products = this.props.products;
    const partnerKey = this.props.partnerKey;
    const stateTax = this.props.stateTax;

    if (hidden) {
      className += ' customize-hidden';
    }

    const goToReviewStep = () => {
      const hasIncorrectSizeOptions =
        rosterItems.filter(items => (items.Size !== "") && !rosterSizeOptions.includes(items.Size));

      const hastEmptyNameOrQuantity = rosterItems.filter(items => (items.Name === "" || items.Number === ""));

      if (!this.props.canShowReviewStep || hasIncorrectSizeOptions.length > 0) {
        showCustomMsgPopup({
          title: 'Please select a size and quantity.',
          onClick: checkRosterItems,
          showCancel: false,
          showConfirm: true
        });
      }
      else if (!this.props.canShowReviewStep || hastEmptyNameOrQuantity.length > 1) {
        showCustomMsgPopup({
          title: 'You have a roster entry with no name and/or number. If a name and/or number is not included in the roster entry, that product will be produced without a name and/or number. Would you like to continue?',
          onClick: redirect,
          showCancel: true,
          showConfirm: true,
          showConfirmMsg: "Yes",
          showCancelMsg: "No, I’m not done." 
        });
      } else {
        showReviewStep({ products, partnerKey, stateTax });
      }
    }

    function checkRosterItems() {
      validateRosterItems({ itemNumber: itemNumber, rosterItem: rosterItems });
      showAddRosterStep({ itemNumber });
      hideModal();
    }

    function redirect() {
      hideModal();
      showReviewStep({ products, partnerKey, stateTax })
    }

    return (
      <div className={className}>
        <div className="customize-body flex-one">
          <div className="canvas-wrapper flex-row">
            <Canvas
              elementId={global.__MAIN_CANVAS_ID__}
              className="main-canvas-el column-one flex-one"
              backgroundColor="000000"
              defaultCameraState={global.__DEFAULT_CAMERA_STATE__}
              isReady={this.props.canvasIsReady}
              parentCallback={this.setRenderedState}
            />
            <div className="canvas-header">Click And Drag To Rotate</div>
            <CustomizeHud />
            <CustomizeToolsHud />
            <Button
              className="add-roster-button ui-primary partner-background-color1"
              disabled={!this.props.canAddRosterItem}
              iconAlign="right"
              iconCls="fa-caret-right"
              label="Add Roster"
              onClick={() => this.onAddRosterClick(itemNumber)} />
          </div>
          <RosterGrid
            canvasRotated={false}
            hidden={!showAddRoster} />
          <CustomizeTabs
            hidden={showAddRoster} />
        </div>
        <div className={'customize-footer flex-row' + (showAddRoster ? '' : ' hidden')}>
          <Button
            className="ui-gray btn-primary"
            iconCls="fa-caret-left"
            label="CUSTOMIZE"
            onClick={(e) => {
              showCustomizeStep();
            }
            } />
          <div className="flex-one"></div>
          <Button
            className="ui-primary btn-primary partner-background-color1"
            iconAlign="right"
            disabled={reviewButtonDisabled}
            iconCls={reviewButtonIconCls}
            label={reviewButtonLabel}
            onClick={() => goToReviewStep()} />
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  const product = activeProductFromApplicationState(state);
  const rosterItems = product ? product.RosterItems : [];
  const rosterSizeOptions = product ? product.RosterSizeOptions : [];

  return {
    canSubmitOrder: canSubmitOrder(state),
    canAddRosterItem: canAddRosterItem(state),
    canShowPatternsCombo: state.GUI.selectedPatternDocumentId !== undefined,
    canShowReviewStep: canShowReviewStep(state),
    itemNumber: state.Order.activeItemNumber,
    canvasIsReady: state.CanvasFullState.isReady,
    jerseyThumbColorMap: state.Thumb3D.jerseyThumbColorMap,
    activeStep: state.GUI.activeStep,
    rosterItems: rosterItems,
    rosterSizeOptions,
    products: state.Order.products,
    partnerKey: state.Session.partnersKey,
    gettingPriceByOrder: state.App.gettingPriceByOrder
  };
};

export default connect(mapStateToProps)(Customize);
