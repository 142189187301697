import { Units } from '../../canvas/helpers/units'
import { RootState } from '../Application';
import { isDesignReady } from '../domain/Design/Conditions';
import { TextDesignElement  } from '../domain/Design/TextDesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { updateOutlineOffsets } from './actions/updateOutlineOffsets';
import { updateTextDesignElement$ } from './actions/updateTextDesignElement$';
import { Validator } from '../screen/Validator';
import { UseCase } from './usecase/UseCase';
import { applyConfig } from '../usecases/actions/font'
import { getDecorationArea, getInDecorationAreaName } from '../usecases/actions/canvas';
import { CanvasAreaFactory } from '../../canvas/CanvasArea'

export interface IncrementTextHeightOptions {
  designElement: TextDesignElement;
  increment: number;
  fontConfigs: any;
}

function calculateMaxContour (designElement: TextDesignElement): number {
  let maxContour = 0;

  designElement.outlines.forEach((o) => {
    maxContour += o.Offset
  });

  return maxContour;
}

const isValidIncrement = new Validator({
  check: (options:IncrementTextHeightOptions) => {
    const designElement = options.designElement;
    const value = Units.toUser(designElement.fontSize) + options.increment;

    const canvas = CanvasAreaFactory.getCanvasById(options.designElement.canvasId);
    const areaId = options.designElement.decorationAreaId;  

    const target = <any>getDecorationArea(canvas, areaId);

    const figure = <any>canvas.figures.find((f) => {
      return getInDecorationAreaName(f) === areaId;
    });

    if (value <= 0) {
      return 'increment text expects greater than zero values';
    }

    if ( Units.toDocument(value) > ( target.bounds_().height * 1.025 ) ) //2.5% tolerance
      return 'increment text is greater than target decoration area height'

    return undefined;
  }
});

export const incrementTextHeightUseCase: UseCase = {
  type: 'INCREMENT_TEXT_HEIGHT',

  checkConditions: (state: RootState) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: ( options: IncrementTextHeightOptions ) => {
    const designElement = options.designElement;
    const value = Units.toUser(designElement.fontSize) + options.increment;
    const spacing: number = +(designElement.fontSize * 0.06575 + calculateMaxContour(designElement)).toFixed(2);

    designElement.fontSize = Units.toDocument(value);

    applyConfig(designElement, options.fontConfigs);

    if (( designElement.outlines.length > 0) && (!designElement.fontIsScript))
      designElement.spacing = spacing / 25.4;

    updateOutlineOffsets(designElement);

    // update on figure
    return updateTextDesignElement$(designElement).then(() => {
      return Cx.resolve(options);
    });
  }
};

/**
 * @category Can
 */
export function canIncrementTextHeight( state: RootState ) {
  return incrementTextHeightUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function incrementTextHeight( options: IncrementTextHeightOptions ) {
  const ruleResult = Validator.evaluate(options, [isValidIncrement]);

  if(ruleResult.isAllowed){
    app.runUseCase( incrementTextHeightUseCase, options );
  }
}
