import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const showSavedOrdersUseCase:UseCase = {
  type: 'SHOW_SAVED_ORDERS',

  run: () => {
    return Cx.resolve();
  }
};

export function showSavedOrders() {
  app.runUseCase(showSavedOrdersUseCase);
}
