import { CanvasAreaFactory } from '../../../canvas/CanvasArea'
import { CanvasHelper } from '../../../canvas/helpers/canvas'
import { addToDecorationArea, setPositionInDecorationArea$, normalizedBoundsOfFrame } from './canvas';

export function setFigureInDecorationArea$( canvasId:string, figure: any, target: Cx.Figure, effects:string[], effectOptions: any[], fit: boolean = true )
{
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  let promise = Cx.resolve();

  addToDecorationArea(figure, target);

  if (effects.length > 0 )
  {
    let promise = setEffect$(figure, target, effects[0], effectOptions[0]);
    for (let i = 1; i < effects.length; i++)
        promise = promise.then(setEffect$(figure, target, effects[i], effectOptions[i]));
  }  

  return promise.then( (r) => {
    //check if it's larger than target
    return figure.bounds$().then((fb)=>{
      return target.bounds$().then((tb) =>{
        return Promise.resolve()
        .then(fit && figure.fit$( tb ))
        .then(setPositionInDecorationArea$(figure, target)
        .then( (r) => {
          CanvasHelper.resetSelection(canvasId);
          return canvas.commit$();
        }));       
      })
    });

  });
}

function areaNormalizedBounds$(target: Cx.Figure){
  return target.frame$().then(function(frame){
    return normalizedBoundsOfFrame(frame);
  });
}

function areaBoundsForEffects$(target: Cx.Figure){
   return areaNormalizedBounds$(target).then(function(bounds){

    // Force tall rectangles
    const effectBounds = bounds.clone();
    const factor = 2;
    const expandedHeight = bounds.width / factor;
    if( bounds.height < expandedHeight ){
      effectBounds.height = expandedHeight ;
    }

    return effectBounds;
   });
 }

 const ARCH_TABLE = {
  Rows: [
    {
      FontSize: 0.625,
      MaxWidth: 3.5,
      Radius: 2.765
    },
    {
      FontSize: 0.625,
      MaxWidth: 5,
      Radius: 4.253
    },
    {
      FontSize: 0.75,
      MaxWidth: 3.5,
      Radius: 3.35
    },
    {
      FontSize: 0.75,
      MaxWidth: 5,
      Radius: 4.5
    },
    {
      FontSize: 1,
      MaxWidth: 5,
      Radius: 4.524
    },
    {
      FontSize: 1,
      MaxWidth: 9,
      Radius: 7.5
    },
    {
      FontSize: 1,
      MaxWidth: 12,
      Radius: 10
    },
    {
      FontSize: 1,
      MaxWidth: 14,
      Radius: 10
    },
    {
      FontSize: 1.5,
      MaxWidth: 12,
      Radius: 10
    },
    {
      FontSize: 1.5,
      MaxWidth: 14,
      Radius: 10
    },
    {
      FontSize: 2,
      MaxWidth: 12,
      Radius: 9.125
    },
    {
      FontSize: 2,
      MaxWidth: 14,
      Radius: 9.125
    },
    {
      FontSize: 2.5,
      MaxWidth: 12,
      Radius: 11
    },
    {
      FontSize: 2.5,
      MaxWidth: 14,
      Radius: 11
    },
    {
      FontSize: 2.75,
      MaxWidth: 12,
      Radius: 12.25
    },
    {
      FontSize: 2.75,
      MaxWidth: 14,
      Radius: 12.25
    },
    {
      FontSize: 3,
      MaxWidth: 12,
      Radius: 13.75
    },
    {
      FontSize: 3,
      MaxWidth: 14,
      Radius: 13.75
    },
    {
      FontSize: 3.5,
      MaxWidth: 12,
      Radius: 14
    },
    {
      FontSize: 3.5,
      MaxWidth: 14,
      Radius: 14
    },
    {
      FontSize: 4,
      MaxWidth: 12,
      Radius: 14.5
    },
    {
      FontSize: 4,
      MaxWidth: 14,
      Radius: 14.5
    }
  ]
};

function getRadiusInterpolation(size, maxWidth)
{
  const f = ARCH_TABLE.Rows.filter( function (r) { return ( r.FontSize <= size) ; }) ;

  let lRadiusL = 0 ;
  let lRadiusH = 0 ;

  let lMaxWidthL = 0 ;
  let lMaxWidthH = 0 ;

  let rR ;

  for (let i = 0; i < f.length ; i ++ ) {
    const lEntry = f[i];
    let lMW = lEntry.MaxWidth ;

    if ( lMW >= maxWidth )
    {
      lMaxWidthH = lEntry.MaxWidth ;
      lRadiusH   = lEntry.Radius ;
      return false ;
    }

    lMaxWidthL = lEntry.MaxWidth ;
    lRadiusL   = lEntry.Radius ;
  }

  if ( lRadiusL == 0 && lRadiusH > 0 )
  {
    rR = lRadiusH ;
  }
  else if ( lRadiusL > 0 && lRadiusH == 0 )
  {
    rR = lRadiusL ;
  }
  else if ( lRadiusL > 0 && lRadiusH > 0 )
  {
    let lRatio = ( maxWidth - lMaxWidthL ) / ( lMaxWidthH - lMaxWidthL ) ;
    rR = lRadiusL + ( ( lRadiusH - lRadiusL ) * lRatio ) ;
  }
  return rR;
}
function getArchParamsForFigure(figure: Cx.Figure, maxWidth: number) 
{
    //code ported from prespaced
    var font_size_in= (<Cx.Text>figure).fontSize/25.4 ;
    var r = getRadiusInterpolation(font_size_in, maxWidth);
    var r_mm = r*25.4;

    const angle = Math.acos( ( maxWidth / 2.0 ) / ( r_mm ) ) ;
    const start = (Math.PI - angle) ;
    const end   = angle ;

    return { height: r_mm*2
          , width:  r_mm*2
          , from: Cx.rad2deg(start)
          , to:   Cx.rad2deg(end)
          , mode: 'Ellipse'
          , isometric: false }; 
 }

 function effectForText$(text: Cx.Figure, target: Cx.Figure, effectName:string, effectOptions: any){

  return areaBoundsForEffects$(target).then(function(bounds){

    let archParams = getArchParamsForFigure(text, bounds.width);

    switch( effectName ){
      case 'None':
        // @ts-ignore TODO: does not exist on Process
        return Cx.Process.FitEnvelope({ envelope: Cx.Envelope.createFromBounds(bounds),
                                        vPos: 'Center', hPos: 'Center' });

      case 'Constrain':
        return Cx.Process.Constrain({ maxWidth: bounds.width, maxHeight: bounds.height, isometric: false });

      case 'ClassicArc':
        // @ts-ignore TODO: does not exist on Process
        return Cx.Process.ClassicArc(archParams);

      case 'VerticalArch':
        // @ts-ignore TODO: does not exist on Process
        return Cx.Process.VerticalArch(archParams);

      case 'InvertedClassicArc':
        // @ts-ignore TODO: does not exist on Process
        return Cx.Process.ClassicArc(archParams);

      case 'Contour':
        return Cx.Process.Contour({
          brush: effectOptions.brush,
          offset: effectOptions.offset,
          advancedSpacing: true,
          lineJoin: effectOptions.lineJoin
        });
    }

  });
}

function setEffect$ (text: Cx.Figure, target: Cx.Figure, effectName: string, effectOptions: any) {

  return effectForText$(text, target, effectName, effectOptions).then(function(effect){
    // @ts-ignore TODO: does not exist on Process
    text.processes.add(effect);
    //return centerInDecorationArea$(text, target);
    return Cx.resolve();
  });

}