import React from 'react';
import { connect } from 'react-redux';
import { activeProductFromApplicationState, Product } from '../../../../app/domain/Product/Product';
import {
  canSaveDesign,
  canSaveOrder,
  hideModal,
  removeProduct,
  saveOrder,
  SaveOrderUseCaseOptions,
  showDesignStylesStep,
  showLogin,
  showSavedOrders,
  updateOrder,
  UpdateOrderUseCaseOptions
}
  from '../../../../app/initApp';
import { showCustomMsgPopup } from '../../../../app/usecases/customMsgPopupUseCase';
import { resetCanvas } from '../../../../app/usecases/resetCanvas';
import global from '../../../services/global';
import Button from '../../misc/Button';

// Styles
import './CustomizeHud.css';

interface Props {
  canDelete: boolean;
  canOpen: boolean;
  canSaveDesign: boolean;
  canShare: boolean;
  canvasId: string;
  designStyleName: string;
  grayGoodName: string;
  isLoggedIn: boolean;
  product: Product;
  products?: Product[];
  coachKey?: string;
  partnersKey?: string;
  canUpdateOrder?: boolean;
  canSaveOrder?: boolean;
  savedOrderId?: string;
  savingOrderState?: boolean;
  retailPrice?: number;
};

const CustomizeHud = (props: Props) => {
  const savingOrderState = props.savingOrderState;
  const saveButtonDisabled = savingOrderState;
  const saveButtonIconCls = savingOrderState ? 'fas fa-circle-notch fa-spin' : "fa-save";
  const isLoggedIn = props.isLoggedIn;
  const product = props.product;

  const onOpenSavedOrders = () => {
    if (isLoggedIn) {
      showSavedOrders();
    } else {
      showLogin();
    }
  }

  const downloadDesign = () => {
    let link = document.createElement('a');
    link.setAttribute('download', props.grayGoodName + " - " + props.designStyleName);
    let canvas = document.getElementById('main-canvas-el_3D');
    //@ts-ignore
    canvas.toBlob(function (blob) {
      let url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.click();
    });
  }

  const goToDesignStep = () => {
    hideModal();
    removeProduct({ ItemNumber: product.ItemNumber });
    resetCanvas();
    showDesignStylesStep();
  }

  const onTrashClick = () => {
    showCustomMsgPopup({
      title: 'Are you sure you’d like to return to the design styles? Any customization will be lost.',
      onClick: goToDesignStep,
      showCancel: true,
      showConfirm: true
    });
  }

  const onSaveClick = () => {
    if (props.canUpdateOrder) {
      updateOrder({
        coachesKey: props.coachKey,
        partnersKey: props.partnersKey,
        id: props.savedOrderId,
        products: props.products,
        shippingMethod: undefined
      } as UpdateOrderUseCaseOptions);
    } else if (props.canSaveOrder) {
      saveOrder({
        coachesKey: props.coachKey,
        partnersKey: props.partnersKey,
        products: props.products,
        shippingMethod: undefined
      } as SaveOrderUseCaseOptions);
    } else {
      showLogin();
    }
  }

  return <div className="customize-hud">
    <div className="title">
      <span className="label gray-good partner-color2">
        {props.grayGoodName}
      </span>
      <span className="label design-style">
        {props.designStyleName}
      </span>
      <span className="label price">
        ${props.retailPrice}
      </span>
    </div>
    <Button
      className="ui-clear icon-size"
      disabled={!props.canDelete}
      iconCls="fa-trash-alt"
      onClick={() => {
        onTrashClick()
      }} />
    <Button
      className="ui-clear"
      disabled={saveButtonDisabled || !props.canSaveDesign}
      iconCls={saveButtonIconCls}
      onClick={() => onSaveClick()} />
    <Button
      className="ui-clear"
      disabled={!props.canOpen}
      iconCls="fa-folder-open"
      onClick={() => onOpenSavedOrders()} />
    <Button
      className="ui-clear"
      disabled={false}
      iconCls="fa-camera"
      onClick={() => downloadDesign()} />
    {/* <Button
      className="ui-clear"
      disabled={!props.canShare}
      iconCls="fa-share-alt"
      onClick={() => {}} /> */}
  </div>
}

const mapStateToProps = (state) => {
  const canvasId = global.__MAIN_CANVAS_ID__;
  const product = activeProductFromApplicationState(state);
  const products = state.Order.products;
  const openedSavedOrder = state.App.openedSavedOrder;

  return {
    canDelete: true,
    canOpen: true,
    canSaveDesign: canSaveDesign(state),
    canShare: false,
    canvasId,
    designStyleName: product ? product.DesignStyle.Name : '',
    grayGoodName: product ? product.GrayGood.Name : '',
    product: product,
    isLoggedIn: state.Session.coachesKey ? true : false,
    products,
    coachKey: state.Session.coachesKey,
    partnersKey: state.Session.partnersKey,
    canSaveOrder: canSaveOrder(state),
    savedOrderId: openedSavedOrder ? openedSavedOrder.id : undefined,
    savingOrderState: state.App.savingOrderState,
    retailPrice: product ? product.GrayGood.Price : 0
  }
}

export default connect(mapStateToProps, null)(CustomizeHud);
