import { isDesignReady } from '../../domain/Design/Conditions';
import { hasActiveProduct } from '../../domain/Order/Conditions';
import { defaultRosterItem, RosterItem } from '../../domain/Roster/RosterItem';
import { app } from '../../initApp';
import { Condition } from '../../screen/Condition';
import { UseCase } from '../usecase/UseCase';

interface AddRosterItemUseCaseOptions {
  itemNumber: string;
  rosterItem?: RosterItem;
}

export const addRosterItemUseCase: UseCase = {
  type: 'ADD_ROSTER_ITEM',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady, hasActiveProduct]);
  },

  run: ( options: AddRosterItemUseCaseOptions ) => {
    const rosterItem = defaultRosterItem(options.rosterItem);

    return Cx.resolve({
      itemNumber: options.itemNumber,
      rosterItem
    });
  }
};

/**
 * @category Can
 */
export function canAddRosterItem( state ) {
  return addRosterItemUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function addRosterItem( options: AddRosterItemUseCaseOptions ) {
  app.runUseCase( addRosterItemUseCase, options );
}
