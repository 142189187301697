import React, { useState } from 'react';
import { DecorationArea } from '../../../../app/domain/Design/DecorationArea';
import { toggleDecorationAreaHidden } from '../../../../app/initApp';

import styles from './DecorationAreaPanel.module.css';

/**
 * Usage:
 *
 * <DecorationAreaPanel decorationArea={decorationArea} title={title}>
 *   <NameEditorFields ... />
 * </DecorationAreaPanel>
 */

interface Props {
  children: any;
  collapsed?: boolean;
  decorationArea: DecorationArea;
}

const DecorationAreaPanel = (props: Props) => {

  const [collapsed, setCollapsed] = useState(!!props.collapsed);

  const decorationArea = props.decorationArea;
  const hidden = decorationArea.DesignElement.hidden;
  const title = decorationArea.GroupLabel || decorationArea.Label;

  return (
    <div className="decoration-area-panel">
      <div className={styles.title + ' partner-color2'}>
        <div
          className={styles.titleCheckmark + (hidden ? ' far fa-square' : ' fas fa-check-square')}
          onClick={() => onToggleDisabled(decorationArea)}></div>
        <div
          className={styles.titleText}
          onClick={() => setCollapsed(!collapsed)}>{title}</div>
        <div
          className={styles.titleCollapse + (collapsed ? ' fas fa-caret-left' : ' fas fa-caret-down')}
          onClick={() => setCollapsed(!collapsed)}></div>
      </div>
      <div className={'body ' + (collapsed ? styles.bodyCollapsed : '')}>
        {props.children}
      </div>
    </div>
  );

}

function onToggleDisabled (decorationArea: DecorationArea) {
  toggleDecorationAreaHidden({
    decorationArea
  });
}

export default DecorationAreaPanel;
