import { isDesignReady } from '../../domain/Design/Conditions';
import { hasActiveProduct } from '../../domain/Order/Conditions';
import { defaultRosterItem } from '../../domain/Roster/RosterItem';
import { app } from '../../initApp';
import { Condition } from '../../screen/Condition';
import { UseCase } from '../usecase/UseCase';

interface AddMultipleRosterItemUseCaseOptions {
  itemNumber: string;
  rosterFileContent: string;
  columns: any;
}

export const addMultipleRosterItemsUseCase: UseCase = {
  type: 'ADD_MULTIPLE_ROSTER_ITEM',

  checkConditions: (state) => {
    return Condition.evaluate(state, [isDesignReady, hasActiveProduct]);
  },

  run: (options: AddMultipleRosterItemUseCaseOptions) => {

    const rosterItems = rosterItemsFromFileContent(options.rosterFileContent, defaultRosterItem, options.columns);

    return Cx.resolve({
      itemNumber: options.itemNumber,
      rosterItems,
      columns: options.columns
    });
  }
};

/**
 * @category Can
 */
export function canAddMultipleRostersItem(state) {
  return addMultipleRosterItemsUseCase.checkConditions(state).isAllowed;
}

/**
 * @category Use Case
 */
export function addMultipleRosterItems(options: AddMultipleRosterItemUseCaseOptions) {
  app.runUseCase(addMultipleRosterItemsUseCase, options);
}

function rosterItemsFromFileContent(content, defaultRosterItem, columns) {
  let rosterItems = [];
  let lines = content.split('\n');
  let regex = /\b(?:CA|Name|Number|Size|Quantity)\b/gi;

  if (lines && lines[0].match(regex))
    lines.shift();

  lines = lines.filter((f) => { return f.trim() !== "" });

  lines.forEach((line) => {
    const rosterValues = line.split(',');
    let arr = Object.assign(defaultRosterItem(), { rosterValues });
    rosterItems.push(columns.reduce((acc: any, value: any, i: number) => {
      if (value.header == 'C/A') {
        value.header = 'CA';
      }
      // @ts-ignore
      acc[value.header] = rosterValues[i]?.trim();
      acc['Id'] = arr.Id;
      return acc;
    }, {}));
  });

  rosterItems = rosterItems.filter((r) => { return r !== defaultRosterItem() });
  rosterItems.push(defaultRosterItem());
  return rosterItems;
}
