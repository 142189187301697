import { backendClient } from '../../../gui/services/backend';

export async function priceByOrder$ (  products: any, 
                                       partnerKey: string, 
                                       stateTax: string,
                                       priceModelType: string) {

  return await backendClient.post<any>({
    endPoint: 'pricing',
    method: 'priceByOrder',
    payload: {
      'products': JSON.stringify(products),
      'partnerKey': partnerKey,
      'stateTax': stateTax,
      'priceModelType': 'coach'
    }
  })
}