import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../app/Application';
import { requestPhoneCode, RequestPhoneCodeOptions, verifyPhoneCode } from '../../../../app/initApp';
import {dismissLoginError} from '../../../../app/usecases/dismissLoginError';
import Button from '../../misc/Button';
import TextInput from '../../misc/field/TextInput';

import styles from './PhoneVerification.module.css';

interface Props{
  verifyFailed?: boolean;
  verifyingCode?: boolean;
  errorMessage?: string;
  phone?: string;
  sendingCode?: boolean;
  sendFailed?: boolean;
}

const PhoneVerification = (props: Props) => {

  const [fieldValue, setFieldValue] = useState('');

  const sendingCode = props.sendingCode;
  const verifyFailed = props.verifyFailed;
  const verifyingCode = props.verifyingCode;
  const buttonDisabled = verifyingCode || fieldValue.length !== 4;
  const buttonIconCls = verifyingCode ? 'fas fa-circle-notch fa-spin' : 'far fa-arrow-alt-circle-right';
  const buttonLabel = verifyingCode ? 'VERIFYING...' : 'VERIFY';
  const errorMessage = props.errorMessage;
  const phone = props.phone;

  function onKeyUp (event) {
    setFieldValue(event.target.value);

    if (event.keyCode === 13 && !buttonDisabled) {
      onButtonClick();
    }
  }

  function onButtonClick () {
    onVerifyPhoneNumber(fieldValue, phone);
  }

  return (
    <div className={styles.loginMain}>
      <div className={styles.loginMainTitle + ' partner-color1'}>VERIFY PHONE</div>
      <div className={styles.message}>
        <div className={styles.smsIcon + ' fas fa-sms'}></div>
        <div className={styles.smsSentMessage}>
          Code has been sent.<br />
          Please check your text messages.
        </div>
        {
          sendingCode
          ?
          <div className={styles.sendNewCode + ' partner-color2'}>Sending...</div>
          :
          (
            phone
            ?
            <div className={styles.sendNewCode + ' partner-color2'} onClick={() => sendNewCode(phone)}>Send new code</div>
            :
            null
          )
        }
      </div>
      <div className={verifyFailed ? styles.errorMessageContainer : styles.loginFailedHidden}>
        <div className={styles.errorTitle}>Verification failed!</div>
        {
          errorMessage
          ?
          <div className={styles.errorMessage}>{errorMessage}</div>
          :
          null
        }
      </div>
      <div className={props.sendFailed ? styles.errorMessageContainer : styles.loginFailedHidden}>
        <div className={styles.errorTitle}>Failed to send code!</div>
        {
          errorMessage
          ?
              <div className={styles.errorMessage}>{errorMessage} <Button onClick={onDismissError} className="ui-link center-text" label="Dismiss"></Button></div>
          :
          null
        }
      </div>
      <div>
        <div className={styles.inputContainer + ' partner-color2'}>
          <TextInput
            className={styles.loginField}
            disabled={verifyingCode}
            onKeyUp={onKeyUp}
            placeholder="Enter your code..."
            value={fieldValue} />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.loginButtonRed + ' partner-color1 partner-border-color1'}
            disabled={buttonDisabled}
            iconCls={buttonIconCls}
            label={buttonLabel}
            onClick={onButtonClick} />
        </div>
      </div>
    </div>
  );
}

const sendNewCode = (phone: string) => {
  const options: RequestPhoneCodeOptions = { phone };
  requestPhoneCode(options);
}

const onVerifyPhoneNumber = (code: string, phone: string) => {
  if (code) {
    verifyPhoneCode({ code, phone });
  }
}

const onDismissError = () => {
  dismissLoginError();
}

const mapStateToProps = (state: RootState) => {
  const phoneVerificationState = state.PhoneVerification;

  return {
    errorMessage: phoneVerificationState.errorMessage,
    phone: phoneVerificationState.phone,
    sendingCode: phoneVerificationState.sendingCode,
    sendFailed: phoneVerificationState.sendFailed,
    verifyFailed: phoneVerificationState.verifyFailed,
    verifyingCode: phoneVerificationState.verifyingCode
  };
};

export default connect(mapStateToProps, null)(PhoneVerification);
