import {coachHasSession} from '../../usecases/coachHasSession';
import {isEmpty} from 'lodash';

export async function checkForSessionAtStartup() {
  if ( Cx.Storage.isAvailable() ) {
    // TODO: Bring in CanvasLibTS so we can quickly edit types.  We can copy that lib around short term until Grafika TS is ready.
    // @ts-ignore
    const sessionToken = Cx.Storage.get('ubdesigner2.sessionToken');

    if(!isEmpty(sessionToken)){
      return await coachHasSession({sessionToken});
    }
  }

  return { hasSession: false };
}


