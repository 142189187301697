import { getCoachDataReadByKeyUrl } from "../../../backendApi/backendApi";

export async function getCoachDataReadByKey$ (key: string) {
  const url = getCoachDataReadByKeyUrl(key);
  const resp = await fetch(url, {
    credentials: 'include'
  })

  if(resp.ok) {
    const jsonResp = await resp.json();
    return Promise.resolve(jsonResp?.items)
  }

  return Promise.reject('Problem with getCoachDataReadByKey');
}