import hash from '../../../gui/util/hash';
import { Product } from '../Product/Product';

export interface RosterItem {
  CA?: string;
  Id?: string;
  Name?: string;
  Number?: string;
  OriginalDocPageName?: string;
  Price?: number;
  Quantity?: string;
  Size?: string;
  SizePriceOffset?: number;
  errorMessage?: {};
}

const defaultRosterItemProps = {
  CA: '',
  Name: '',
  Number: '',
  Quantity: '',
  Size: ''
};

export const defaultRosterItem = (props?: RosterItem): RosterItem => {
  return {
    ...defaultRosterItemProps,
    ...props,
    Id: hash(5)
  };
};

export const filterForValidProductRosterItems = (product: Product): RosterItem[] => {
  const defaultItem = defaultRosterItem(product.RosterSizeOptions && { Size: '' });
  const validRosterItems = filterForValidRosterItems(product.RosterItems, defaultItem);
  return validRosterItems;
}

export const filterForValidRosterItems = (rosterItems: RosterItem[], defaultItem?: any): RosterItem[] => {
  return rosterItems.filter((item) => {
    return rosterItemHasDefaultValues(item, defaultItem) === false;
  });
}

export const rosterItemHasDefaultValues = (rosterItem: RosterItem, defaultItem?: any): boolean => {
  const defaultRosterItemForProduct = defaultItem ? defaultItem : defaultRosterItemProps;

  for (const key in defaultRosterItemForProduct) {
    if (rosterItem[key] !== defaultRosterItemForProduct[key] && key !== 'Id') {
      return false;
    }
  }

  return true;
};