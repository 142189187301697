export interface DesignStyle {
  // archived: any;
  // artRefs: any;
  // designStyle: any;
  // disabled: any;
  DocId: string;
  Id: string;
  // modelType: any;
  Name: any;
  // partnerId: any;
  // partnersKey: any;
  // sport: any;
  PartnerGrayGoodId: any;
};

export function designStyleFromActionResult (props: any): DesignStyle {
  return {
    DocId: props.artRefs.publicDocId,
    Id: props.id,
    Name: props.name,
    PartnerGrayGoodId: props.partnerGrayGoodId
  };
}