import { CanvasAreaFactory } from '../canvas/CanvasArea';
import global from '../gui/services/global';
import { RootState } from './Application';
import { findCADecorationArea, findPartnerLogoDecorationAreas } from './domain/Design/DecorationArea'
import { DesignElement } from './domain/Design/DesignElement';
import { userArtDesignElementFromFileUpload } from './domain/Design/UserArtDesignElement';
import { activeTabIsColorZones, activeTabIsNameNumber } from './domain/Gui/Conditions';
import { activeProductFromApplicationState } from './domain/Product/Product';
import { filterForValidProductRosterItems } from './domain/Roster/RosterItem';
import { loadSubmittedOrder } from './initApp';
import { setDecorationAreaActiveUseCase, loadSubmittedOrders, previewRosterItem, showContactInfo, showLogin, showOrderStateSavedConfirmation, submitOrder, hideModal, showAddRosterStep } from './usecases';
import { getColorZoneName, getInDecorationAreaName, setupCanvasClickEvent, setupCanvasEvents } from './usecases/actions/canvas';
import { addProductUseCase } from './usecases/addProduct'
import { coachHasSession, coachHasSessionUseCase } from './usecases/coachHasSession';
import { copySubmittedOrder, copySubmittedOrderUseCase } from './usecases/copySubmittedOrder'
import { createCoachContactInfoUseCase } from './usecases/createCoachContactInfo'
import { emitCanvasClickEvent, emitCanvasClickEventUseCase } from './usecases/emitCanvasClickEvent';
import { emitCanvasEvent, emitCanvasEventUseCase, emitCanvasEventUseCaseOptions } from './usecases/emitCanvasEvent';
import { evaluateSubmittingOrder, evaluateSubmittingOrderUseCase } from './usecases/evaluateSubmittingOrder'
import { getCoachDataReadByKey } from './usecases/getCoachDataReadByKey'
import { initPartnerWithColorsUseCase } from './usecases/init/initPartnerWithColors';
import { initCanvasUseCase } from './usecases/initCanvas';
import { loadSavedOrderStates } from './usecases/loadSavedOrderStates';
import { pollForAccessStart, pollForAccessStartUseCaseOptions, pollForAccessStopUseCase } from './usecases/pollForAccess';
import { pollServerForAccess, pollServerForAccessUseCase, pollServerForAccessUseCaseOptions } from './usecases/pollServerForAccess';
import { requestGoogleLoginUseCase } from './usecases/requestGoogleLogin';
import { requestForAccessByEmailUseCase } from './usecases/login/requestForAccessByEmail';
import { saveOrderUseCase } from './usecases/saveOrder';
import { selectCoachContactInfoUseCase } from './usecases/selectCoachContactInfo';
import { canSetFillTargetByClick, setFillTargetByClick } from './usecases/setFillTargetByClick';
import { setInitialCoachUseCase } from './usecases/setInitialCoachKey';
import { showCustomizeStep, showCustomizeStepUseCase } from './usecases/showCustomizeStep';
import { showOrderSubmissionConfirmation } from './usecases/showOrderSubmissionConfirmation';
import { showReviewStepUseCase } from './usecases/showReviewStep';
import { showSelectContactInfoModal } from './usecases/showSelectContactInfoModal';
import { submitOrderUseCase } from './usecases/submitOrder';
import { updateDecorationArea } from './usecases/updateDecorationArea'
import { updateOrderUseCase } from './usecases/updateOrder';
import isEmpty from 'lodash/isEmpty';
import { resetCanvasSelection } from './usecases/resetCanvasSelection';
import { setCanvasCameraPosition } from './usecases/setCanvasCameraPosition';
import { verifyPhoneCodeUseCase } from './usecases/login/verifyPhoneCode';
import { verifyEmailHashUseCase } from './usecases/login/verifyEmail';
import { isLoadingOrder } from './usecases/loadingOrder';
import { showCustomMsgPopup } from './usecases/customMsgPopupUseCase';
import { getPriceByOrder } from './usecases/getPriceByOrder';
import { setFillTargetUseCase } from './usecases/setFillTarget'
import { setHighlightedTarget } from './usecases/setHighlightedTarget';
import { prepareOrderForSaving } from './usecases/prepareOrderForSaving';
import { openSavedOrderUseCase } from './usecases/openSavedOrder';
import { showReviewStep } from './usecases/showReviewStep';
import { GuiSteps } from './domain/Gui/GuiSteps';
import { removeProductUseCase } from './usecases/removeProduct';
import { updateAfterRemovingProductUseCase, updateOrderAfterRemoving } from './usecases/updateAfterRemovingProduct';
import { loadCanvasDocId } from './usecases/loadCanvasDocId';
import { loadSubmittedOrderUseCase } from './usecases/loadSubmittedOrder';

export function monitorStore(store: any) {
  store.subscribe(function () {
    const state: RootState = store.getState();
    const actionResult = state.App.actionResult;

    switch (actionResult.type) {

      case setDecorationAreaActiveUseCase.type:
        if (actionResult.success()) {
          const toFront = (['Rib-Number'].indexOf(actionResult.data.decorationArea.Type) > -1); //this is temp
          //will be passing the camera position as a decorationArea property in future implementations
          setCanvasCameraPosition({
            canvasId: global.__MAIN_CANVAS_ID__,
            positionToBack: !toFront,
            positionToFront: toFront
          })
        }

        break;

      case requestForAccessByEmailUseCase.type:
      case requestGoogleLoginUseCase.type:
      case pollForAccessStopUseCase.type:
      case pollServerForAccessUseCase.type:
        if (actionResult.success()) {
          monitorForSession(state);
        }

        break;

      case createCoachContactInfoUseCase.type:
        if (actionResult.success()) {
          evaluateSubmittingOrder();
        }

        break;

      case verifyPhoneCodeUseCase.type:
      case verifyEmailHashUseCase.type:
      case coachHasSessionUseCase.type:
        if (actionResult.success()) {
          if (actionResult.data.coach.coachesKey) {
            getCoachDataReadByKey({ coachesKey: actionResult.data.coach.coachesKey });
            loadSubmittedOrders({ coachesKey: actionResult.data.coach.coachesKey, partnersKey: state.Session.partnersKey });
            loadSavedOrderStates({ coachesKey: actionResult.data.coach.coachesKey, partnersKey: state.Session.partnersKey });
          }

          if (state.GUI.initialOrder) {
            if (state.GUI.initialOrderOpenAsCopy) {
              copySubmittedOrder({ ordersKey: state.GUI.initialOrder });
              isLoadingOrder();
            }
            else {
              loadSubmittedOrder({
                ordersKey: state.GUI.initialOrder,
                canvasId: global.__MAIN_CANVAS_ID__,
                editDesign: true,
                editRoster: true
              });
            }
          }
        }

        break;

      case initPartnerWithColorsUseCase.type:
        if (actionResult.success()) {
          const branding = actionResult.data.partnerData.PartnerBranding;

          if (branding.length > 0) {
            const colorScheme = branding[0].colorScheme;
            setPartnerColor(colorScheme);
          }

          if (state.GUI.initialOrder) {
            if (state.GUI.initialOrderOpenAsCopy) {
              copySubmittedOrder({ ordersKey: state.GUI.initialOrder });
              isLoadingOrder();
            }
            else {
              loadSubmittedOrder({
                ordersKey: state.GUI.initialOrder,
                canvasId: global.__MAIN_CANVAS_ID__,
                editDesign: true,
                editRoster: true
              });
            }
          }
        }

        break;

      case addProductUseCase.type:
        if (actionResult.success()) {
          const partnerLogoDecorationAreas = findPartnerLogoDecorationAreas(state.CanvasFullState.decorationAreas);
          if (Array.isArray(partnerLogoDecorationAreas) && partnerLogoDecorationAreas.length > 0) {
            partnerLogoDecorationAreas.forEach((da) => {
              const rootDesignElement: DesignElement = {
                canvasId: state.CanvasFullState.canvasId,
                decorationAreaId: da.Id,
                fit: true
              }

              const partnerLogoDesignElement = userArtDesignElementFromFileUpload(rootDesignElement
                , state.GUI.productLogo.coreImgRefId
                , state.GUI.productLogo.url);

              updateDecorationArea({
                designElement: partnerLogoDesignElement
              });
            });
          }
        }
        break;

      case showCustomizeStepUseCase.type:
        if (actionResult.success()) {
          resetCanvasSelection({ canvasId: global.__MAIN_CANVAS_ID__ });
          const CADecorationArea = findCADecorationArea(state.CanvasFullState.decorationAreas);
          if (CADecorationArea) {
            const CAdesignElement = CADecorationArea.DesignElement
            CAdesignElement.hidden = false;
            updateDecorationArea({
              designElement: CAdesignElement
            });
          }
        }

        break;

      case showReviewStepUseCase.type:
      case updateAfterRemovingProductUseCase.type:
        if (actionResult.success()) {

          const products = state.Order.products.filter((p) => {
            const validRosters = filterForValidProductRosterItems(p);
            return validRosters.length > 0;
          });

          prepareOrderForSaving({
            partnerStyleData: { colorList: state.GUI.partnerColorScheme, url: state.GUI.partnerLogoUrl },
            products,
            shippingMethod: state.Order.shippingMethod,
            storeLocation: state.Order.storeLocation,
            contactInfo: state.GUI.coachContactInfoSelected,
            partnerInfo: state.GUI.partnerData,
            retailTotal: state.Order.totalPrice,
            costTotal: state.Order.totalCost,
            salesTax: state.Order.totalSalesTax,
            minOrderFee: state.Order.minOrderFee
          });
        }
        break;

      case showCustomizeStepUseCase.type:
        if (actionResult.success()) {

          if (!state.CanvasFullState.hasRosterItemFigures) {
            const canvasId = global.__MAIN_CANVAS_ID__;
            const product = activeProductFromApplicationState(state);

            const CADecorationArea = findCADecorationArea(state.CanvasFullState.decorationAreas);
            if (CADecorationArea)
              CADecorationArea.DesignElement.hidden = true;

            previewRosterItem({
              canvasId,
              decorationAreas: state.CanvasFullState.decorationAreas,
              rosterItem: product.RosterItems[0]
            });
          }
        }

        break;

      case saveOrderUseCase.type:
      case updateOrderUseCase.type:
        if (actionResult.success()) {
          showOrderStateSavedConfirmation();
        }

        break;

      case selectCoachContactInfoUseCase.type:
        if (actionResult.success()) {
          if (state.GUI.coachContactInfoSelected) {

            const orderStateSnippet = Object.assign(state.Order.orderStateSnippet
              , {
                coachContactInfo: state.GUI.coachContactInfoSelected
                , shippingMethod: state.Order.shippingMethod
              });

            submitOrder({
              coachesKey: state.Session.coachesKey,
              partnersKey: state.Session.partnersKey,
              customerInfoKey: state.GUI.coachContactInfoSelected.id,
              originallyCopiedFrom: state.Order.originallyCopiedFrom,
              orderState: state.Order.products,
              orderStateSnippet
            });
          }
        }
        break;

      case evaluateSubmittingOrderUseCase.type:
        if (actionResult.success()) {
          if (!state.Session.coachesKey) {
            showLogin();
          } else {
            if (state.GUI.coachContactInfoSelected) {
              const products = state.Order.products.filter((p) => {
                const validRosters = filterForValidProductRosterItems(p);
                return validRosters.length > 0;
              });

              showCustomMsgPopup({
                title: 'Order cannot be edited once submitted.',
                onClick: confirmSubmittingOrder,
                showCancel: true,
                showConfirm: true
              });

              function confirmSubmittingOrder() {
                submitOrder({
                  coachesKey: state.Session.coachesKey,
                  partnersKey: state.Session.partnersKey,
                  customerInfoKey: state.GUI.coachContactInfoSelected.id,
                  originallyCopiedFrom: state.Order.originallyCopiedFrom,
                  orderState: state.Order.products,
                  orderStateSnippet: state.Order.orderStateSnippet
                });

                hideModal();
              }
            }
            else if (!isEmpty(state.GUI.coachContactInfo)) {
              const contactInfoProps = {
                coachesKey: state.Session.coachesKey,
                info: state.GUI.coachContactInfo
              }

              showSelectContactInfoModal(contactInfoProps);
            }
            else {
              !state.Modal.contactInfoOpen && showContactInfo({ coachKey: state.Session.coachesKey, coachContactInfo: null });
            }
          }
        }
        break;

      case submitOrderUseCase.type:
        if (actionResult.success()) {
          //update pricing with tax
          getPriceByOrder({
            products: state.Order.products
            , partnerKey: state.Session.partnersKey
            , stateTax: getStateZip(state)
            , priceModelType: 'coach'
          });

          showOrderSubmissionConfirmation();
        }

        if (actionResult.failure()) {
          showCustomMsgPopup({
            title: 'Submitting order failed. Please contact support.',
            showCancel: true
          });
        }

        break;

      case initCanvasUseCase.type:
        if (actionResult.success() && actionResult.data.inited) {
          const canvasId = actionResult.data.canvasId;
          const canvas = CanvasAreaFactory.getCanvasById(canvasId);
          const onCanvasStateChange = (events) => {
            emitCanvasEvent({
              canvasId,
              events
            } as emitCanvasEventUseCaseOptions);
          };

          setupCanvasEvents(canvas, onCanvasStateChange);
          setupCanvasClickEvent(canvas, (figure) => {
            emitCanvasClickEvent({ canvasId, figure });
          });
        }

        break;

      case setInitialCoachUseCase.type:
        if (actionResult.success()) {
          getCoachDataReadByKey({ coachesKey: actionResult.data.coachKey });
          loadSubmittedOrders({ coachesKey: actionResult.data.coachKey, partnersKey: state.Session.partnersKey });
          loadSavedOrderStates({ coachesKey: actionResult.data.coachKey, partnersKey: state.Session.partnersKey })
        }

        break;

      case setFillTargetUseCase.type:
        if (actionResult.success()) {
          const fillTarget = actionResult.data?.fillTarget;

          if (fillTarget) {
            setHighlightedTarget({ fillTarget: actionResult.data.fillTarget, highlighted: true });
            setTimeout(() => {
              setHighlightedTarget({ fillTarget: actionResult.data.fillTarget, highlighted: false });
            }, 750);
          }
        }

        break;

      case loadSubmittedOrderUseCase.type:
      case openSavedOrderUseCase.type:
      case copySubmittedOrderUseCase.type:
        if (actionResult.success()) {
          const products = state.Order.products.filter((p) => {
            const validRosters = filterForValidProductRosterItems(p);
            return validRosters.length > 0;
          });
          const partnerKey = state.Session.partnersKey;
          const stateTax = getStateZip(state);

          const activeStep = state.GUI.activeStep;
          if (activeStep === GuiSteps.CUSTOMIZE) {
            showCustomizeStep();
          } else if (activeStep === GuiSteps.ROSTER) {
            showAddRosterStep({ itemNumber: state.Order.activeItemNumber });
          } else {
            showReviewStep({ products, partnerKey, stateTax });
          }
        }

        break;

      case emitCanvasClickEventUseCase.type:
        if (actionResult.success() && canSetFillTargetByClick(state)) {
          const figure = actionResult.data.figure;
          const isTextElement = figure.isText && getInDecorationAreaName(figure) ? true : false;
          const isColorZone = !isTextElement && getColorZoneName(figure) ? true : false;

          if (
            (isColorZone && activeTabIsColorZones.check(state)) ||
            (isTextElement && activeTabIsNameNumber.check(state))
          ) {
            setFillTargetByClick({
              canvasId: actionResult.data.canvasId,
              figure
            });
          }
        }

        break;

      case removeProductUseCase.type:
        if (actionResult.success()) {
          const products = state.Order.products;
          const partnerKey = state.Session.partnersKey;
          const stateTax = getStateZip(state);
          const activeStep = state.GUI.activeStep;

          if (products.length > 0 && state.Order.activeItemNumber === products[0].ItemNumber)
            loadCanvasDocId({ canvasId: global.__MAIN_CANVAS_ID__, docId: products[0].SavedDocId });

          if (activeStep === GuiSteps.REVIEW)
            updateOrderAfterRemoving({ products, partnerKey, stateTax });
        }

        break;
    }
  });
}

const getStateZip = (state: RootState) => {
  const salesTaxType: string = state.GUI.partnerData?.salesTaxType;

  switch (salesTaxType) {
    case 'destination':
      return state.GUI.coachContactInfoSelected?.zip; //might not be selected yet
    case 'origin':
      return state.GUI.partnerData.postalCode;
    default:
      break;
  }
}

function monitorForSession(state: RootState) {
  if (state.Polling.pollingResult) { // user hit back confirmation page
    coachHasSession();
  }

  if (
    state.Login.inSystem &&
    state.Login.lastRequestForAccess > 0 &&
    !state.Polling.isPollingActive) {
    // start polling
    const options: pollForAccessStartUseCaseOptions = {
      hash: state.Login.hash,
      interval: global._POLL_INTERVAL_,
      timeout: global._POLL_TIMEOUT_
    };
    pollForAccessStart(options);

    const serverPollOptions: pollServerForAccessUseCaseOptions = {
      interval: global._POLL_SERVER_INTERVAL_
    }
    pollServerForAccess(serverPollOptions);
  }
}

function setPartnerColor(colorScheme: any) {
  const color1 = colorScheme.color1;
  const color2 = colorScheme.color2;
  const color3 = colorScheme.color3;
  const element = document.createElement('style');
  document.head.appendChild(element);
  const sheet = element.sheet;

  sheet.insertRule('.partner-color1 { color: ' + color1 + ' !important }', 0);
  sheet.insertRule('.partner-color2 { color: ' + color2 + ' !important }', 1);
  sheet.insertRule('.partner-color3 { color: ' + color3 + ' !important }', 2);
  sheet.insertRule('.partner-background-color1 { background-color: ' + color1 + ' !important }', 2);
  sheet.insertRule('.partner-background-color2 { background-color: ' + color2 + ' !important }', 3);
  sheet.insertRule('.partner-border-color1 { border-color: ' + color1 + ' !important }', 4);
  sheet.insertRule('.partner-border-color2 { border-color: ' + color2 + ' !important }', 5);
  sheet.insertRule('.partner-border-bottom-color2 { border-bottom-color: ' + color2 + ' !important }', 6);
  sheet.insertRule('.partner-outline-color1 { color: ' + color1 + ' !important }', 7);
  sheet.insertRule('.partner-selected-fill-target-color1 { background: ' + color1 + '; background: linear-gradient(90deg, ' + color1 + ' 0%, ' + color1 + ' 50%, #fff 100%); }', 8)
}
