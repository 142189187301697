import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import SearchFactory from '../../gui/services/search'
import { loadPatternConfig$ } from './actions/pattern'
import { patternBrushFromServerResponse  } from '../domain/Pattern/Pattern';

export interface loadPatternsUseCaseOptions {}

export const loadPatternsUseCase: UseCase = {
  type: 'LOAD_PATTERNS',

  run: ( options: loadPatternsUseCaseOptions ) => {
    let patternsSearchService = new SearchFactory('Patterns');
    return loadPatternConfig$().then((c)=>{
      return patternsSearchService.search().then((patterns)=>{
        const configs = JSON.parse(c.Data) ;
        let parsedPatterns = [];
        patterns.forEach((p)=>{
          const config = configs[p.Id];
          const pattern = patternBrushFromServerResponse(p, config);
          parsedPatterns.push(pattern);
        });
        return Cx.resolve({ patterns: parsedPatterns });
      })
    });
  }
};

/**
 * @category Use Case
 */
export function loadPatterns( options: loadPatternsUseCaseOptions ) {
  app.runUseCase( loadPatternsUseCase, options );
}
