import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const hideModalUseCase:UseCase = {
  type: 'HIDE_MODAL',

  run: () => {
    return Cx.resolve();
  }
};

export function hideModal() {
  app.runUseCase(hideModalUseCase);
}
