import { isDesignReady } from '../domain/Design/Conditions';
import { DesignElement } from '../domain/Design/DesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { bump$ } from './actions/bump$';
import { UseCase } from './usecase/UseCase';

interface bumpRightOptions {
  designElement: DesignElement;
}

export const bumpRightUseCase:UseCase = {
  type: 'BUMP_RIGHT',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: (options: bumpRightOptions) => {
    return bump$(options.designElement, 'right', 5);
  }
};

export function canBumpRight(state): boolean {
  return bumpRightUseCase.checkConditions( state ).isAllowed;
}

export function bumpRight(options: bumpRightOptions){
  app.runUseCase(bumpRightUseCase, options);
}
