import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import {app} from './app/initApp';
import resizeMonitor from './gui/util/resizeMonitor';

import './index.css';
import './resources/icomoon/style.css'; // Custom icons

const root = document.getElementById( 'root' );
const store = app.getReduxStore();

ReactDOM.render(
    <Provider store={store}>
      <App/>
    </Provider>,
    root
);

resizeMonitor( onWindowResize, 150 );
onWindowResize();

function onWindowResize() {
  // Fix for mobile browsers were the 100%/100vh height does not take into account the address bar
  const height = window.innerHeight || document.documentElement.clientHeight;
  root.style.height = height + 'px';
}
