import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Product } from '../../../app/domain/Product/Product';
import { showCustomizeStep, showAddRosterStep, showGrayGoodsStep } from '../../../app/initApp';
import Button from '../misc/Button';
import ReviewFooter from './ReviewFooter';
import ReviewItem from './ReviewItem';
import uniqueKey from '../../util/uniqueKey';
import { setActiveProductItemNumber } from '../../../app/usecases/setActiveProductItemNumber';
import { filterForValidProductRosterItems } from '../../../app/domain/Roster/RosterItem';
import { showRemoveProductPopup } from '../../../app/usecases/removeProductPopupUseCase';
import { loadCanvasDocId } from '../../../app/usecases/loadCanvasDocId';
import global from '../../services/global';
// Styles
import styles from './Review.module.css';

interface Props {
  hidden: boolean;
  products: Product[];
  total?: number;
  totalCount?: number;
  activeItemNumber?: string;
  activeStep: string;
  priceSizeOffsetTotal?: number;
  minOrderFee?: number;
  cadworxSessionId?: string;
  totalSalesTax?: number;
}

class Review extends Component<Props> {

  public render() {
    const activeStep = this.props.activeStep;
    let className = styles.review + (activeStep === "review" ? " " + styles.addFooter : "");
    const products = this.props.products;
    const haveProducts = products.length > 0;
    const cadworxSessionId = this.props.cadworxSessionId;

    if (!this.props.hidden) {
      return (
        <div className={className}>

          <div className={styles.header}>
            <div className={styles.headerText + ' partner-color1'}>Review My Order</div>
            {/* Temporarily hidden */}
            {/* <Button
              className={styles.headerButton}
              iconCls="fa-download"
              onClick={() => { }} />
            <Button
              className={styles.headerButton}
              iconCls="fa-print"
              onClick={() => { }} />
            <Button
              className={styles.headerButton}
              iconCls="fa-share-alt"
              onClick={() => { }} /> */}
          </div>
          <div className={styles.wrapper}>
            <div className={styles.leftContent}>
              <div className={styles.header2 + ' partner-background-color2'}>
                <div className={styles.header2Text}>My Products</div>
                <AddProductButton className={styles.addProductButtonBlue + ' partner-background-color2'} />
              </div>
              <div className={styles.body}>
                {
                  haveProducts
                    ?
                    <React.Fragment>
                      {products.map((product, index) => {
                        return <ReviewItem
                          key={product.ItemNumber ?? uniqueKey()}
                          onEditDesignClick={() => {
                            setActiveProductItemNumber({ activeItemNumber: product.ItemNumber })
                            loadCanvasDocId({ canvasId: global.__MAIN_CANVAS_ID__, docId: product.SavedDocId })
                            showCustomizeStep();
                          }}
                          onEditRosterClick={() => {
                            setActiveProductItemNumber({ activeItemNumber: product.ItemNumber })
                            loadCanvasDocId({ canvasId: global.__MAIN_CANVAS_ID__, docId: product.SavedDocId })
                            showAddRosterStep({ itemNumber: product.ItemNumber });
                          }}
                          onDeleteProductClick={() => {
                            const itemNumber: string = product.ItemNumber;
                            showRemoveProductPopup({
                              props: { itemNumber }
                            });
                          }}
                          product={product}
                          cadworxSessionId={cadworxSessionId} />
                      })}
                    </React.Fragment>
                    :
                    <div className={styles.noProductsMessage}>
                      You currently have no products...
                    </div>
                }
              </div>
              <div className={styles.addProductRow}>
                <Button
                  className={styles.addBtn + ' btn-primary partner-color1 partner-border-color1'}
                  iconCls="fa-plus"
                  label="Add Another Product"
                  onClick={() => showGrayGoodsStep()} />
              </div>
            </div>
            <div className={styles.rightContent}>
              <ReviewFooter
                products={this.props.products}
                total={this.props.total}
                totalCount={this.props.totalCount}
                priceSizeOffsetTotal={this.props.priceSizeOffsetTotal}
                minOrderFee={this.props.minOrderFee}
                totalSalesTax={this.props.totalSalesTax}
                key={uniqueKey()} />
            </div>
          </div>
        </div>
      );
    } else {
      return (<div className={className}></div>);
    }
  }
}

const AddProductButton = (props) => {
  return <Button
    className={props.className}
    iconCls="fa-plus"
    label="Add Another Product"
    onClick={() => showGrayGoodsStep()} />;
}

const mapStateToProps = (state, props) => {
  const products = state.Order.products.filter((p) => {
    const validRosters = filterForValidProductRosterItems(p);
    return validRosters.length > 0;
  });

  const total = state.Order.totalPrice;
  const totalCount = state.Order.totalCount;
  const priceSizeOffsetTotal = state.Order.totalSizePriceOffset;
  const cadworxSessionId = state.Session.partnerCadworxSessionId;
  const minOrderFee = state.Order.minOrderFee;
  const totalSalesTax = state.Order.totalSalesTax;

  return {
    products,
    total,
    totalCount,
    priceSizeOffsetTotal,
    minOrderFee,
    activeItemNumber: state.Order.activeItemNumber,
    activeStep: state.GUI.activeStep,
    cadworxSessionId,
    totalSalesTax
  };
};

export default connect(mapStateToProps, null)(Review);
