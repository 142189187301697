import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { createCoach$ } from './actions/login'

export interface createCoachUseCaseOptions {
  email:string,
  name:string,
  phone:string,
  cadworxid:string
}

export const createCoachUseCase: UseCase = {
  type: 'CREATE_COACH',

  run: ( options: createCoachUseCaseOptions ) => {
    return createCoach$(options.email, options.name, options.phone, options.cadworxid).then( (c) =>{
      return Cx.resolve(Object.assign({ success: true }, options));
    }
    , (e) => {
      let r = Object.assign({ success: false , requestFailed: true }, e)
      r = Object.assign(r, options);
      return Cx.resolve(r);
    })       
  }
};

export function createCoach( options: createCoachUseCaseOptions ) {
  app.runUseCase( createCoachUseCase, options );
}
