import React from 'react';
import { connect } from 'react-redux';
import { FillTarget } from '../../../app/domain/Design/FillTarget';
import { setFill, SetFillUseCaseOptions } from '../../../app/usecases/setFill';
import { BrushFactoryOptions, BrushFactoryType } from '../../util/brushFactory';
import { setPatternComboVisiblity, setPatternComboVisiblityUseCaseOptions } from '../../../app/usecases/setPatternComboVisibility';
import { GetSpotColorBases } from '../../../app/domain/Pattern/colorZoneHelper'
import { Pattern } from '../../../app/domain/Pattern/Pattern'
import ColorsCarousel from './ColorsCarousel';
import PatternCombo from './PatternCombo';
import ThumbsCarousel from './ThumbsCarousel';
import global from '../../services/global';

import styles from './PatternSelector.module.css';

interface Props {
  activeFillTarget?: FillTarget;
  activeFillTargetBrush?: Cx.Brush;
  colorList?: Cx.Color[];
  hidden?: boolean;
  patterns?: any[];
  patternsConfig?: any;
  showPatternsCombo?: boolean;
  activePattern?: Pattern
}

const PatternSelector = (props: Props) => {

  const showColorCarousel = props.activePattern && props.activePattern.activeColorSetIndex > -1

  const onColorClick = async (color) => {

    let activeColors = props.activePattern.activeColors;
    activeColors[props.activePattern.activeColorSetIndex] = color ;

    const brushOptions: BrushFactoryOptions = {
      docId: props.activePattern.docId,
      type: BrushFactoryType.PATTERN,
      target: activeColors,
      map: props.activePattern.colorIndexZoneMap
    };
    
    const setFillOptions: SetFillUseCaseOptions = {
      brushOptions: brushOptions,
      fillTarget: props.activeFillTarget
    };

    setFill(setFillOptions);
  }

  const onPatternClick = async (docId: string) => {
      const activeFillTarget = props.activeFillTarget;

      const brushOptions: BrushFactoryOptions = {
        docId: docId,
        type: BrushFactoryType.PATTERN
      };

      const setFillOptions: SetFillUseCaseOptions = {
        brushOptions: brushOptions,
        fillTarget: activeFillTarget,
        docId: docId
      };
  
      setFill(setFillOptions);
  
      const visibilityOptions: setPatternComboVisiblityUseCaseOptions = {
        visible: true
      };
  
      setPatternComboVisiblity(visibilityOptions);
  }

  return <div className={props.hidden ? styles.patternSelectorHidden : styles.patternSelector}>
    <PatternCombo
      canvas={global.__MAIN_CANVAS_ID__}
      hidden={!props.showPatternsCombo}
    />
    <ColorsCarousel
      colorList={props.colorList}
      hidden={!showColorCarousel}
      onColorClick={(color) => {
        onColorClick(color);
      }} />
    <ThumbsCarousel 
      docList={props.patterns} 
      config={props.patternsConfig}
      hidden={showColorCarousel}
      onColorClick={(docId: string) => {
        onPatternClick(docId)
      }
    } />
  </div>
}

const mapStateToProps = (state) => {
  return {
    activeFillTarget: state.GUI.activeFillTarget,
    activeFillTargetBrush: state.GUI.activeFillTargetBrush,
    showPatternsCombo: state.GUI.patternsComboVisible && !state.GUI.activeFillTargetBrushIsRasterPattern,
    colorList: state.Config.colorList,
    patterns: state.Patterns.patterns,
    patternsConfig: state.Patterns.patternsConfig,
    activePattern: state.GUI.activePattern
  };
};


export default connect(mapStateToProps, null, null)(PatternSelector);