import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export interface SetColorTypeMainColorSelectorOptions {
  isPattern:boolean
}

export const setColorTypeMainColorSelectorUseCase:UseCase = {
  type: 'SET_COLOR_TYPE_MAIN_COLOR_SELECTOR',

  run: (options: SetColorTypeMainColorSelectorOptions) => {
    return Cx.resolve({ isPattern: options.isPattern })
  }
};

export function setColorTypeMainColorSelector(options: SetColorTypeMainColorSelectorOptions){
  app.runUseCase(setColorTypeMainColorSelectorUseCase, options);
}
