import React from 'react';

import styles from './Radio.module.css';

interface Props {
  checked?: boolean;
  className?: string;
  label: string;
  onClick: any;
}

const Radio = (props: Props) => {
  let className = styles.radio;
  const iconCls = (props.checked ? styles.radioIconChecked : styles.radioIcon) + ' ' + (props.checked ? 'fas fa-circle' : 'far fa-circle') + ' partner-color1';

  if (props.className) {
    className += ' ' + props.className;
  }

  return <div className={className} onClick={props.onClick}>
    <span className={iconCls}></span>
    <span className={styles.radioLabel}>{props.label}</span>
  </div>;
}

export default Radio;