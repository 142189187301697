import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export interface emitCanvasEventUseCaseOptions {
  canvasId: string,
  events: string[]
}

export const emitCanvasEventUseCase: UseCase = {
  type: 'EMIT_CANVAS_EVENT',

  run: ( options: emitCanvasEventUseCaseOptions ) => {
    return Cx.resolve({ canvasId: options.canvasId, events: options.events });
  }
};

/**
 * @category Use Case
 */
export function emitCanvasEvent( options: emitCanvasEventUseCaseOptions ) {
  app.runUseCase( emitCanvasEventUseCase, options );
}
