import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';
import { SubmittedOrder } from '../../../app/domain/Order/SubmittedOrder';
import { Product } from '../../../app/domain/Product/Product';
import Button from '../misc/Button';
import ReviewItem from '../steps/ReviewItem';

import styles from './OrdersDetail.module.css';

interface Props {
  className: string;
  onBackClick: Function;
  order?: SubmittedOrder;
  ordersKey: string;
}

const OrdersDetail = (props: Props) => {
  const order = props.order;

  return <div className={props.className || ''}>
    {
      order
      ?
      <React.Fragment>
        <div className={styles.header}>
          <Button
            className={styles.backButton + ' partner-background-color2 partner-border-color2'}
            iconCls="fa-arrow-left"
            label="Back"
            onClick={props.onBackClick} />
          </div>
        {order.orderState.map((product: Product) => {
          return <ReviewItem
            key={product.Id}
            product={product} />;
        })}
      </React.Fragment>
      :
      <div>Loading...</div>
    }
  </div>
};

const mapStateToProps = (state: RootState, props) => {
  const orderState = state.SubmittedOrders;
  const orderItemLoading = orderState.orderItemLoading;
  const order = orderItemLoading ? undefined : orderState.orders.find((item) => {
    return item.order.ordersKey === props.ordersKey;
  });

  return { order };
}

export default connect(mapStateToProps, null)(OrdersDetail);