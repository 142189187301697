export enum unit {
  mm = 'mm',
  cm = 'cm',
  inch = 'in',
  pt = 'pt'
}

export class Deg {

  static r2d = 360.0 / ( 2.0 * Math.PI );
  static d2r = 1.0 / Deg.r2d;

  static rad2deg(radians) {
    return radians * Deg.r2d;
  }

  static deg2rad(degrees) {
    return degrees * Deg.d2r;
  }
}

export class Units {

  //TODO: Move to app state
  static _userUnit = unit.inch;

  static toMilliMeter = function(userValue:number, fromUnit:unit): number {
    let value;

    switch (fromUnit) {
      case unit.mm:
        value = userValue;
        break;
      case unit.cm:
        value = userValue * 10;
        break;
      case unit.inch:
        value = userValue * 25.4;
        break;
      case unit.pt:
        value = userValue * 25.4 / 72;
        break;
    }

    return parseFloat(value.toFixed(2));
  };

  static fromMilliMeter = function(mmValue:number, toUnit:unit): number {
    let value;

    switch (toUnit) {
      case unit.mm:
        value = mmValue;
        break;
      case unit.cm:
        value = mmValue / 10;
        break;
      case unit.inch:
        value = mmValue / 25.4;
        break;
      case unit.pt:
        value = mmValue / 25.4 * 72;
        break;
    }

    return parseFloat(value.toFixed(2));
  };

  static toDocument(userValue:number, unit?:unit) {
    return Units.toMilliMeter(userValue, unit || Units._userUnit);
  }

  static toUser (mmValue:number) {
    return Units.fromMilliMeter(mmValue, Units._userUnit);
  }

  static convert(value:number, fromUnit:unit, toUnit:unit){
    return Units.fromMilliMeter(Units.toMilliMeter(value, fromUnit), toUnit);
  }

  //TODO: This state should be in a domain (user unit) I suppose

  static setUserUnit(unit:unit){
    Units._userUnit = unit;
  }

  static getUserUnit(){
    return Units._userUnit;
  }
}


