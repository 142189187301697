import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/Application';
import { succeedAccessStart } from '../../gui/services/storage'
import { verifyEmailHash } from '../../app/usecases/login/verifyEmail'

import styles from './Confirmation.module.css';

interface Props {
  hash: string;
}

const Confirmation = (props: Props) => {

  const hashedValues = props.hash.split('|');
  const coachesKey =  useSelector( (state: RootState)  => state.Session.coachesKey );
  const loginFailed = useSelector( (state: RootState)  => state.Session.getSessionFailed );; 

  if (!loginFailed && !coachesKey)
    verifyEmailHash({ email: hashedValues[1], hash: hashedValues[0] });

  if (coachesKey) {
    succeedAccessStart(props.hash);

    return <div className={styles.confirmation}>
      <div className={styles.message}>
        <div className={styles.loggedIn}>You are now logged in!</div>
        <div>You can return to the designer and continue!</div>
      </div>
    </div>;
  }

  if (loginFailed) {
    return <div className={styles.confirmation}>
    <div className={styles.message}>Sorry, we are <span className={styles.emphasize}>not able</span> to log you in</div>
  </div>;
  }

  return <div className={styles.confirmation}>
    <div className={styles.message}>Logging you in...</div>
  </div>;
}

export default Confirmation ;
