import { Units } from '../../canvas/helpers/units'
import { isDesignReady } from '../domain/Design/Conditions';
import { TextDesignElement  } from '../domain/Design/TextDesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { Validator } from '../screen/Validator';
import { updateTextDesignElement$ } from './actions/updateTextDesignElement$';
import { UseCase } from './usecase/UseCase';
import { getContourThicknessForSize, getOutlineSum } from './actions/font';

export interface IncrementOutlineOptions {
  designElement: TextDesignElement;
  increment: number;
  incrementAtIndex: number;
}

const isValidIncrement = new Validator({
  check: (options:any) => {
    const designElement = options.designElement;
    const outline = designElement.outlines[options.incrementAtIndex];
    const value = Units.toUser(outline.Offset) + options.increment;

    if (value <= 0) {
      return 'incrementOutline expects NON zero values';
    }

    return undefined;
  }
});

export const incrementOutlineUseCase: UseCase = {
  type: 'INCREMENT_OUTLINE',

  checkConditions: (state) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: ( options: IncrementOutlineOptions ) => {
    const designElement = options.designElement;
    const outline = designElement.outlines[options.incrementAtIndex];
    const value = Units.toUser(outline.Offset) + options.increment;
    const baseSize = Units.toDocument(getContourThicknessForSize(Units.toUser(designElement.fontSize)));
    const offset = Units.toDocument(value) ;

    outline.Offset = offset ;
    designElement.outlineProportions[options.incrementAtIndex] =  offset / baseSize ;

    //use pre calculated (or config value) text separation
    if(!designElement.fontIsScript)
      designElement.spacing = +(Units.toUser(getOutlineSum(designElement))).toFixed(2) ;

    // update on figure
    return updateTextDesignElement$(designElement).then(() => {
      return Cx.resolve(options);
    });
  }
};

/**
 * @category Can
 */
export function canIncrementOutline( state ) {
  return incrementOutlineUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function incrementOutline( options: IncrementOutlineOptions ) {
  // We will not show any message to the user
  const ruleResult = Validator.evaluate(options, [isValidIncrement]);

  if(ruleResult.isAllowed){
    app.runUseCase( incrementOutlineUseCase, options );
  }
}
