import { Product } from '../domain/Product/Product';
import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

interface EditRosterUseCaseOptions {
  canvasId: string;
  product: Product;
}

export const editRosterUseCase: UseCase = {
  type: 'EDIT_ROSTER',

  // checkConditions: ( state ) => {
  //   return Condition.evaluate(state, [isDesignReady, isFigureSelected]);
  // },

  run: ( options: EditRosterUseCaseOptions ) => {
    return Cx.resolve(options);
  }
};

/**
 * @category Can
 */
// export function canEditRoster( state ) {
//   return editRosterUseCase.checkConditions( state ).isAllowed;
// }

/**
 * @category Use Case
 */
export function editRoster( options: EditRosterUseCaseOptions ) {
  app.runUseCase( editRosterUseCase, options );
}
