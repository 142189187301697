import { isDesignReady } from '../domain/Design/Conditions';
import { FillTarget } from '../domain/Design/FillTarget';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { setHighlightedTarget$ } from './actions/setHighlightedTarget$';
import { UseCase } from './usecase/UseCase';
import { fillTargetIsColorZone } from '../domain/Design/ColorZoneFillTarget';

export interface SetHighlightedTargetUseCaseOptions {
  fillTarget: FillTarget;
  highlighted: boolean;
}

export const setHighlightedTargetUseCase: UseCase = {
  type: 'SET_HIGHLIGHTED_TARGET',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: ( options: SetHighlightedTargetUseCaseOptions ) => {
    if (fillTargetIsColorZone(options.fillTarget)) {
      return setHighlightedTarget$(options.fillTarget, options.highlighted)
    }
    else { 
      return Cx.resolve();
    }
  }
};

/**
 * @category Can
 */
export function canSetHighlightedTarget( state ) {
  return setHighlightedTargetUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setHighlightedTarget( options: SetHighlightedTargetUseCaseOptions ) {
  app.runUseCase( setHighlightedTargetUseCase, options );
}
