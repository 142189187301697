import { canPreviewRoster, isDesignReady } from '../../domain/Design/Conditions';
import { DecorationArea } from '../../domain/Design/DecorationArea';
import { RosterItem } from '../../domain/Roster/RosterItem';
import { app } from '../../initApp';
import { Condition } from '../../screen/Condition';
import { previewRosterItem$ } from '../actions/previewRosterItem$';
import { UseCase } from '../usecase/UseCase';

interface PreviewRosterItemOptions {
  canvasId: string;
  decorationAreas: DecorationArea[];
  rosterItem: RosterItem;
}

export const previewRosterItemUseCase:UseCase = {
  type: 'PREVIEW_ROSTER_ITEM',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady, canPreviewRoster]);
  },

  run: (options: PreviewRosterItemOptions) => {
    return previewRosterItem$(options.rosterItem, options.canvasId, options.decorationAreas)
    .then(() => {
      return Cx.resolve(options);
    })
  }
};

export function canPreviewRosterItem(state): boolean {
  return previewRosterItemUseCase.checkConditions( state ).isAllowed;
}

export function previewRosterItem(options: PreviewRosterItemOptions){
  app.runUseCase(previewRosterItemUseCase, options);
}
