import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';
import { loadSubmittedOrder } from '../../../app/initApp';
import OrdersDetail from './OrdersDetail';
import OrdersSummary from './OrdersSummary';
import global from '../../services/global';

// Styles
import styles from './Orders.module.css';

const Orders = (props) => {
  const [activeOrdersKey, setActiveOrdersKey] = useState(null);
  const detailClassName = activeOrdersKey ? '' : styles.hidden;
  const loadingClassName = props.ordersLoaded ? styles.hidden : '';
  const summaryClassName = !activeOrdersKey && props.ordersLoaded ? '' : styles.hidden;
  const [loadOrder, setLoadOrder] = useState<boolean>(false);
  
  function openDetail(key) {
    setLoadOrder(true);

    loadSubmittedOrder({
      ordersKey: key,
      canvasId: global.__MAIN_CANVAS_ID__,
      editDesign: false,
      editRoster: false
    });
  }

  function closeDetail() {
    setActiveOrdersKey(undefined);
  }

  return <div className={`${loadOrder ? styles.orders + ' ' + styles.overflowHidden : styles.orders}`}>
    <OrdersDetail
      className={detailClassName}
      onBackClick={closeDetail}
      ordersKey={activeOrdersKey} />
    <OrdersSummary
      className={summaryClassName}
      onOrderClick={(ordersKey) => openDetail(ordersKey)}
      orders={props.orders}
      partnerData={props.partnerData} />
    <div className={loadingClassName}>Loading...</div>
  </div>
}

const mapStateToProps = (state: RootState) => {
  const orderState = state.SubmittedOrders;

  return {
    orderItemLoading: orderState.orderItemLoading,
    orders: orderState.orders,
    ordersLoaded: orderState.ordersLoaded,
    partnerData: state.GUI.partnerData,
  }
}

export default connect(mapStateToProps, null)(Orders);
