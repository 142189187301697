import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const showSubmittedOrdersUseCase:UseCase = {
  type: 'SHOW_ORDERS',

  run: () => {
    return Cx.resolve();
  }
};

export function showSubmittedOrders() {
  app.runUseCase(showSubmittedOrdersUseCase);
}
