import * as App_ from 'lodash';
import { ValidatorResult } from './ValidatorResult';
declare let Cx: any;

class RejectionReasonSchema {
  Primary: string;
  Secondary?: any[]; //An array of strings
  userMessage?: string;
  UserMessage?: string;
  Data?: any;
}

export class RejectionReason extends RejectionReasonSchema {
  constructor(args:RejectionReasonSchema = { Primary: 'Unknown', Secondary: [] }) {
    super();

    //TODO: Eliminate
    if(args.userMessage){
      Cx.warning('RejectionReason userMessage is depreciated.  Use UserMessage (casing).  See trace for usage.');
      args.UserMessage = args.userMessage;
      console.trace();
    }

    Object.assign(this, args);
  }

  appendSecondaryCause(cause) {
    this.Secondary.push(cause);
  }

  was(cause) {
    if(this.Primary === cause)
      return true;

    return App_.includes(this.Secondary, cause);
  }

  hasUserMessage () {
    return !!(this.UserMessage);
  }

  asObject() {
    return {
      Primary: this.Primary,
      Secondary: this.Secondary
    };
  }

  static fromFailedValidatorResult (validatorResult:ValidatorResult) {
    let rejectionReason = RejectionReason.appendCause(RejectKey.VALIDATION_FAILED, validatorResult.reason);
    rejectionReason.UserMessage = validatorResult.UserMessage;
  
    return rejectionReason;
  }

  //Convenience method to check if a rejected promise reason (string, error, RejectionReason) was a reason
  //i.e. RejectionReason.was(reason, RejectKey.CANCELLED);
  static was (possibleRejectionReason:any, reason:any){
    if(possibleRejectionReason && possibleRejectionReason instanceof RejectionReason)
      return (possibleRejectionReason.was.bind(possibleRejectionReason, reason));

    return possibleRejectionReason === reason;
  }

  //Shortcut for creating CANCELLED rejection reason
  static CANCELLED (args:any = { Primary: RejectKey.CANCELLED, Secondary: [] }):RejectionReason{
    return new RejectionReason(args);
  }

  //Shortcut for taking a rejected "reason" (string, error, RejectionReason)
  //and returning a promise rejection with a RejectionReason instance
  static withSecondaryCause (possibleRejectionReason:RejectionReason, secondaryCause:string){
    var rejectionReason = RejectionReason.appendCause(possibleRejectionReason, secondaryCause);
    return Cx.reject(rejectionReason);
  }

  //Shortcut for taking a rejected "reason" (string, error, RejectionReason) and appending a secondary cause
  //Returns RejectionReason
  static appendCause ( possibleRejectionReason, cause:string):RejectionReason {
    let rejectionReason;

    if(!possibleRejectionReason) {
      rejectionReason = new RejectionReason({Primary: cause});
    }
    else if(possibleRejectionReason instanceof RejectionReason){
      rejectionReason = possibleRejectionReason;
      rejectionReason.appendSecondaryCause(cause);
    }
    else{
      rejectionReason = new RejectionReason({
        Primary: possibleRejectionReason,
        Secondary: [cause]
      });
    }

    return rejectionReason;
  }

}

//Primary reasons, IDE friendly
export enum RejectKey {
  STEAMS_API_RESPONSE = 'STEAMS_API_RESPONSE',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  CANCELLED = 'CANCELLED',
  ADD_APPAREL_TO_CART = 'ADD_APPAREL_TO_CART',
  TX_WEBSERVICE_NETWORK = 'TX_WEBSERVICE_NETWORK',
  TX_WEBSERVICE_RESPONSE = 'TX_WEBSERVICE_RESPONSE',
  TX_WEBSITE_NETWORK = 'TX_WEBSITE_NETWORK',
  TX_WEBSITE_RESPONSE = 'TX_WEBSITE_RESPONSE',
  TX_API_RESPONSE = 'TX_API_RESPONSE',
  TX_API_NETWORK = 'TX_API_NETWORK',
  CORE_NETWORK = 'CORE_NETWORK',
  CORE_RESPONSE = 'CORE_RESPONSE',
  DOC_NOT_ALLOWED = 'DOC_NOT_ALLOWED',
  FAILED_TO_LOOKUP_CUSTOM_COLOR = 'FAILED_TO_LOOKUP_CUSTOM_COLOR'
}
