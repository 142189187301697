import React, { Component, createRef } from 'react';
import { RosterItem } from '../../../../../app/domain/Roster/RosterItem';
import { stringStartsWith } from '../../../../../app/utility/stringStartsWith';
import SelectInputMenu from './SelectInputMenu';

// Styles
import styles from './SelectInputMenu.module.css';

interface Props {
  columnCfg: any;
  onChange: Function;
  onClick?: any;
  onFocus: any;
  rosterItem: RosterItem;
}

export default class SelectInputColumn extends Component<Props> {

  public state = {
    menuCollapsed: true,
    userIsEditing: false,
    userOption: undefined,
    userValue: undefined,
    selected: ""
  };

  private fieldRef: any;
  private inputRef: any;

  constructor(props) {
    super(props);
    this.fieldRef = createRef();
    this.inputRef = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event) {
    if (this.inputRef.current && !this.inputRef.current.contains(event.target)) {
      this.onInputBlur();
    }
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  };

  public render() {
    const userOption = this.state.userOption;
    const props = this.props;
    const columnCfg = props.columnCfg;
    const value = this.getValue();
    const options = columnCfg.editor.options;
    const haveOptions = options && options.length > 0;
    const onClick = haveOptions ? () => this.onInputClick() : undefined;
    const showMenu = haveOptions && !this.state.menuCollapsed;
    const errorMessage = props.rosterItem.errorMessage || {};

    // Since this field is read only we can use input instead of Input component
    return <td className={columnCfg.id} ref={this.fieldRef} style={{ position: 'relative' }}>
      {/*
      Setting the autocomplete attribute to “off” does not disable Chrome autofill in more recent versions of Chrome
      you can set autocomplete to anything besides “on” or “off” and it will disable Chrome autofill
      */}
      <input
        className={`${errorMessage[columnCfg.id.toLowerCase()]?.length > 0 ? styles.border : ""}`}
        autoComplete="offeveninchrome"
        onChange={(e) => this.onInputChange(e)}
        onClick={onClick}
        onFocus={props.onFocus}
        onKeyDown={(e) => this.onInputKeyDown(e)}
        readOnly={!haveOptions}
        ref={this.inputRef}
        type="text"
        value={value} />
      {
        showMenu
          ?
          <SelectInputMenu
            onItemSelect={(itemValue) => {
              this.onMenuSelect(itemValue)
              this.updateSelectedValue(itemValue)
            }}
            options={options}
            selectedOption={userOption} />
          :
          null
      }
    </td>
  }

  private getValue() {
    const userIsEditing = this.state.userIsEditing;
    const userValue = this.state.userValue;
    const value = userIsEditing && userValue !== undefined ? userValue : this.props.rosterItem[this.props.columnCfg.field];

    return value;
  }

  private onInputClick() {
    const value = this.props.rosterItem[this.props.columnCfg.field];
    this.startUserEdit(value);
  }

  private onInputChange(e) {
    this.startUserEdit(e.target.value);
  }

  private onInputBlur() {
    setTimeout(() => {
      this.cancelUserEdit();
    }, 250);
  }

  private onInputKeyDown(e) {
    const key = e.keyCode;

    // Tab or Enter?
    if (key === 9 || key === 13) {
      this.onEnterKey();
    }
  }

  private onEnterKey() {
    const option = this.state.userOption;

    if (option) {
      this.onMenuSelect(option.value);
    } else {
      this.cancelUserEdit();
    }

    this.inputRef.current.blur();
  }

  private updateSelectedValue(value) {
    const item = this.props.rosterItem;
    const columnCfg = this.props.columnCfg;
    item[columnCfg.field] = value;
    this.setState({
      selected: value
    });
  }

  private startUserEdit(value) {
    const userOption = this.findOption(value);

    this.setState({
      menuCollapsed: false,
      userIsEditing: true,
      userOption,
      userValue: value,
      selected: value
    });
  }

  private findOption(value) {
    const options = this.props.columnCfg.editor.options;
    const userOption = options.find((item) => {
      return value.length > 0 ?
        stringStartsWith(item.text, value) :
        item.text.trim() === value.trim();
    });

    return userOption;
  }

  private cancelUserEdit() {
    this.setState({
      menuCollapsed: true,
      userIsEditing: false,
      userOption: undefined,
      userValue: undefined,
      selected: ""
    });
  }

  private onMenuSelect(value) {
    const item = this.props.rosterItem;
    const columnCfg = this.props.columnCfg;

    item[columnCfg.field] = value;
    this.props.onChange(item, columnCfg);
    this.cancelUserEdit();
  }
}