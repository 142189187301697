import React from 'react';
import { hideModal } from '../../../app/initApp';
import Button from '../misc/Button';

import styles from './OrderSubmissionConfirmation.module.css';

interface OrderOpenCopyConfirmationProps {
  orderId: string ;
}

const OrderOpenCopyConfirmation = (props: OrderOpenCopyConfirmationProps) => {

  return (
    <div className={styles.confirmation}>
      <div className={styles.header + ' partner-color2'}>OPEN SAVED ORDER</div>
      <div className={styles.secondaryColorText +  ' partner-color1'}>You’re viewing a copy of order {props.orderId}.  Any changes you make will not be reflected on order {props.orderId} but you can save or submit this copy.  Saving or submitting this copy will create a new order.</div>
      <Button
        className={styles.button + ' partner-background-color2 partner-border-color2'}
        label="OK!"
        onClick={() => {
          hideModal();
        }} />
    </div>
  );

}

export default OrderOpenCopyConfirmation;
