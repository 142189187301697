import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const showLoginUseCase:UseCase = {
  type: 'SHOW_LOGIN',

  run: () => {
    return Cx.resolve();
  }
};

export function showLogin() {
  app.runUseCase(showLoginUseCase);
}
