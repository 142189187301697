import { RootState } from '../Application';
import { isConfigReady } from '../domain/Config/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';
import { saveCanvasDocument$ } from './actions/canvas';
import global from '../../gui/services/global'

export interface ShowAddRosterStepOptions {
  itemNumber: string;
}

export const showAddRosterStepUseCase:UseCase = {
  type: 'SHOW_ADD_ROSTER_STEP',

  checkConditions: ( state: RootState ) => {
    return Condition.evaluate(state, [isConfigReady]);
  },

  run: (options: ShowAddRosterStepOptions) => {
    // @ts-ignore
    return saveCanvasDocument$(global.__MAIN_CANVAS_ID__)
    .then((doc)=>{
      return Cx.resolve(Object.assign(options, { savedDocId: doc.id }));
    })
  }
};

export function canShowAddRosterStep (state: RootState) {
  return showAddRosterStepUseCase.checkConditions( state ).isAllowed;
}

export function showAddRosterStep (options: ShowAddRosterStepOptions) {
  app.runUseCase(showAddRosterStepUseCase, options);
}
