import { RootState } from '../../Application';
import { Condition } from '../../screen/Condition';
import { activeProductFromApplicationState } from '../Product/Product';
import { defaultRosterItem, filterForValidRosterItems } from '../Roster/RosterItem';

// Do we have an open SavedOrder
export const hasOpenedSavedOrder = new Condition({
  check: (state: RootState) => {
    return state.App.openedSavedOrder ? true : false;
  },
  failMessage: 'App does not have an opened order'
});

export const hasActiveSport = new Condition({
  check: (state: RootState) => {
    return state.GUI.activeSport ? true : false;
  },
  failMessage: 'A sport has not been selected'
});

export const hasActiveGrayGood = new Condition({
  check: (state: RootState) => {
    return state.GUI.activeGrayGood ? true : false;
  },
  failMessage: 'A gray good has not been selected'
});

export const hasActiveProduct = new Condition({
  check: (state: RootState) => {
    const product = activeProductFromApplicationState(state);
    return product ? true : false;
  },
  failMessage: 'A product has not been added'
});

export const hasRosterItems = new Condition({
  check: (state: RootState) => {
    const product = activeProductFromApplicationState(state);
    return product && product.RosterItems.length > 0 ? true : false;
  },
  failMessage: 'No roster items found'
});

const hasIncompleteItems = (rosterItems) =>
{
  return rosterItems.filter((r)=> { return r.Quantity.length == 0 || r.Quantity == 0 || r.Size.length == 0 }).length > 0 //filter those without quantity or size
}

export const hasValidRosterItems = new Condition({
  check: (state: RootState) => {
    const product = activeProductFromApplicationState(state);
    const defaultItem = defaultRosterItem(product.RosterSizeOptions && { Size: ''}) ;
    const rosterItems = product ? filterForValidRosterItems(product.RosterItems, defaultItem) : [];
    const hasIncomplete = hasIncompleteItems(rosterItems);

    return rosterItems.length > 0 && ! product.RosterHasErrors && !hasIncomplete;
  },
  failMessage: 'No valid roster items found'
})