// import { coachHasSessionUseCase } from '../usecases/coachHasSession';
import { createCoachUseCase } from '../usecases/createCoach';
import {dismissLoginErrorUseCase} from '../usecases/dismissLoginError';
import { promptForSelectVerificationUseCase } from '../usecases/promptForSelectVerification';
import { requestGoogleLoginUseCase } from '../usecases/requestGoogleLogin';
import { requestForAccessByEmailUseCase } from '../usecases/login/requestForAccessByEmail';
import { requestPhoneCodeUseCase } from '../usecases/login/requestPhoneCode';
import { resetLoginUseCase} from '../usecases/resetLogin';import { signOutUseCase } from '../usecases/signOut'
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { verifyPhoneCodeUseCase } from '../usecases/login/verifyPhoneCode';
import {RejectionReason} from '../utility/RejectionReason';
import { reportMessage } from '../../gui/util/rollbar';
export enum AuthMethod {
  Email = 'Email',
  Google = 'Google',
  Phone = 'Phone'
}

export interface LoginState {
  email: string;
  errorMessage: string;
  hash: string; // TODO: Learn what this is for
  inSystem: boolean;
  // loggedIn:boolean; // TODO: Not sure we want this here.
  // emailVerified: boolean; // TODO: Don't think we care for Login, not being used
  lastRequestForAccess: number;
  name: string;
  phone: string;
  // requestResult: boolean; // TODO: What was this for?  Not being used
  promptForPhoneVerification: boolean;
  promptForEmailVerification: boolean;
  promptForSelectVerification: boolean;
  requestInProgress: boolean;
  requestFailed: boolean;
  requestingAccessBy?: AuthMethod.Email | AuthMethod.Phone | AuthMethod.Google,
  // requestingAccessByEmail: boolean;
  // requestingAccessByGoogle: boolean;
  // requestingAccessByPhone: boolean;
  tokenExpired: boolean;
}

const initState: LoginState = {
  email: '',
  errorMessage: '',
  hash: '',
  inSystem: false,
  // emailVerified: false,
  lastRequestForAccess: 0,
  name: '',
  phone: '',
  promptForPhoneVerification: false,
  promptForEmailVerification: false,
  promptForSelectVerification: false,
  requestFailed: false,
  requestInProgress: false,
  // requestResult: false,
  requestingAccessBy: undefined,
  // requestingAccessByEmail: false,
  // requestingAccessByGoogle: false,
  // requestingAccessByPhone: false,
  tokenExpired: true
  // loggedIn: false
};

const clearPreviousRequestResult = (state) => {
  return Object.assign({}, state, {
    errorMessage: initState.errorMessage,
    requestFailed: initState.requestFailed,
    requestInProgress: initState.requestInProgress
    // requestResult: initState.requestResult
  });
};

export function LoginReducer (state: LoginState = initState, actionResult: UseCaseResult): LoginState {

  switch (actionResult.type) {

    case resetLoginUseCase.type:
    {
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, initState);
      }

      return state;
    }

    case dismissLoginErrorUseCase.type:
    {
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if(actionResult.success()){
        return Object.assign({}, clearPreviousRequestResult(state));
      }

      return state;
    }

    case requestGoogleLoginUseCase.type:
    {
      if (actionResult.start()) {
        return Object.assign({}, initState, {
          requestInProgress: true,
          // requestingAccessByGoogle: true
          requestingAccessBy: AuthMethod.Google
        });
      }

      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)

        return Object.assign({}, initState, {
          lastRequestForAccess: (new Date()).valueOf(),
          requestFailed: true,
          errorMessage: actionResult.rejectionReason.response.data.error
        });
      }

      else if (actionResult.success()) {
        return Object.assign({}, state, {
          hash: actionResult.data.hash,
          inSystem: true,
          lastRequestForAccess: (new Date()).valueOf(),
          requestInProgress: false
          // requestResult: true
        });
      }

      return state;
    }

    case requestForAccessByEmailUseCase.type:
    {
      if (actionResult.start()) {
        return Object.assign({}, initState, {
          promptForEmailVerification: actionResult.data && actionResult.data.promptForEmailVerification,
          promptForSelectVerification: state.promptForSelectVerification,
          requestInProgress: true,
          requestingAccessBy: AuthMethod.Email
          // requestingAccessByEmail: true
        });
      }

      else if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
        return Object.assign({}, initState, {
          requestFailed: true,
          errorMessage: actionResult.rejectionReason.response.data.error
        });
      }

      else if (actionResult.success()) {
        const time = (new Date()).valueOf();

        if ( actionResult.data.success ) {
          return Object.assign({}, state, {
            email: actionResult.data.email,
            phone: state.phone,
            hash: actionResult.data.hash,
            inSystem: actionResult.data.inSystem,
            // emailVerified: actionResult.data.emailVerified,
            promptForSelectVerification: false,
            promptForEmailVerification: actionResult.data.inSystem || actionResult.data.promptForEmailVerification,
            lastRequestForAccess: time,
            requestInProgress: false
            // requestResult: true
          });
        }
        else {
          if (actionResult.failure()) {
            reportMessage(actionResult.type, actionResult.rejectionReason)
          }

          return Object.assign({}, state, {
            errorMessage: actionResult.data.response?.data?.error || "Error executing login request",
            lastRequestForAccess: time,
            requestInProgress: false,
            // requestResult: false,
            requestFailed: true
          });
        }
      }
      return state ;
    }

    case requestPhoneCodeUseCase.type:
    {
      // TODO: This should come in on action result data, app logic
      const time = (new Date()).valueOf();

      if (actionResult.start()) {
        return Object.assign({}, clearPreviousRequestResult(state), {
          // phone: actionResult.data.phone,
          // promptForSelectVerification: state.promptForSelectVerification,
          requestInProgress: true,
          requestingAccessBy: AuthMethod.Phone
          // requestingAccessByPhone: true
        });
      }

      else if (actionResult.failure() || actionResult.cancelled()) {
        if (actionResult.failure()) {
          reportMessage(actionResult.type, actionResult.rejectionReason)
        }

        const rejectionReason:RejectionReason = (actionResult.rejectionReason) ? actionResult.rejectionReason : undefined;
        const errorMessage = (rejectionReason) ? rejectionReason.UserMessage : undefined;

        return Object.assign({}, clearPreviousRequestResult(state), {
          errorMessage,
          lastRequestForAccess: time,
          // phone: state.phone,
          requestFailed: true,
          requestInProgress: false
        });
      }

      else if (actionResult.success()) {
        return Object.assign({}, clearPreviousRequestResult(state), {
          inSystem: actionResult.data.inSystem,
          phone: actionResult.data.phone,
          // email: state.email,
          promptForSelectVerification: false,
          promptForPhoneVerification: true,
          requestInProgress: false,
          lastRequestForAccess: time
        });
      }

      return state;
    }

    case verifyPhoneCodeUseCase.type:
    {
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          promptForPhoneVerification: false
          // loggedIn: true
        });
      }

      return state;
    }

    case promptForSelectVerificationUseCase.type:
    {
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          promptForEmailVerification: false,
          promptForSelectVerification: true,
          promptForPhoneVerification: false,
          lastRequestForAccess: 0
        });
      }

      return state;
    }

    case createCoachUseCase.type:
    {
      if (actionResult.start()) {
        return Object.assign({}, clearPreviousRequestResult(state), {
          requestInProgress: true
        });
      }

      else if (actionResult.failure()) {
        if (actionResult.failure()) {
          reportMessage(actionResult.type, actionResult.rejectionReason)
        }
        return Object.assign({}, clearPreviousRequestResult(state), {
          requestFailed: true,
          requestInProgress: false,
          errorMessage: actionResult.rejectionReason.response.data.error
        });
      }

      else if (actionResult.success()) {
        // TODO: Move to use case, dispatch as result
        const time = (new Date()).valueOf();

        if (actionResult.data.success) {
          return Object.assign({}, state, {
            email: actionResult.data.email,
            phone: actionResult.data.phone,
            inSystem: true, // we just created it so if it succeeded it's in System
            // requestResult: actionResult.data.success,
            lastRequestForAccess: 0,
            promptForSelectVerification: true,
            requestInProgress: false
          });
        }
        else {
          const responseData = actionResult.data.response.data ;

          // We'll need a more sophisticated process eventually.  For now, it's just an error.
          // if (responseData.phoneExists) {
          // Phone numbers can be recycled after 90-120 days.
          // We need policy to expire the phone code authentication method after 120 days without login via phone.
          // In this scenario, signing up with different email,  we have to figure out if they've just used a different email or if they've obtained recycled phone number.
          // -> "This phone is registered with [show part of email], is this you?"
          // -> If so, sign in and carry on with either email or phone (assuming option is available).
          // If it's not their email (recycled phone scenario), they create a new account and they "claim" the phone number by verifying phone code.
          // -> This would mean we remove the phone number from the existing account after they verify the number.  In this scenario, verification is required.
          //   return Object.assign({}, initState, {
          //     requestFailed: false,
          //     requestInProgress: false,
          //     lastRequestForAccess: 0,
          //     requestingAccessBy: AuthMethod.Phone,
          //     phone: actionResult.data.phone,
          //     inSystem: true
          //   });
          // }
          // else {
            return Object.assign({}, state, {
              errorMessage: responseData.error,
              lastRequestForAccess: time,
              requestInProgress: false
            });
          // }
        }
      }
      return state ;
    }

    case signOutUseCase.type:
    {
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, initState); // reset credentials
      }
      return state ;
    }
  }

  return state;
}
