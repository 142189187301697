import React from 'react';
import { RosterItem } from '../../../../../app/domain/Roster/RosterItem';
import Input from '../../../misc/field/Input';

// Styles
import styles from './SelectInputMenu.module.css';

interface Props {
  columnCfg: any,
  onChange: Function,
  onClick?: any,
  onFocus: any,
  rosterItem: RosterItem,
}

const NumberInputColumn = (props: Props) => {
  const columnCfg = props.columnCfg;
  const item = props.rosterItem;
  const errorMessage = item.errorMessage || {};

  return <td className={columnCfg.id}>
    <Input
      className={`${errorMessage[columnCfg.id.toLowerCase()]?.length > 0 ? styles.border : ""}`}
      onChange={(e) => {
        const value: string = e.target.value;

        if (value !== item[columnCfg.field]) {
          item[columnCfg.field] = value;
          props.onChange(item, columnCfg);
        }
      }}
      onFocus={props.onFocus}
      onKeyPress={(event: any) => {
        if (!/-?([0-9]+([.][0-9]*)?|[.][0-9]+)/.test(event.key)) {
          event.preventDefault();
        }
      }}
      type="text"
      value={item[columnCfg.field]} 
      name="number_input" 
      />
  </td>
}

export default NumberInputColumn;