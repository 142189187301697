import { coachHasSession } from '../domain/Login/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { getOrderStatesByCoachesKey$ } from './actions/getOrderStatesByCoachesKey$';
import { UseCase } from './usecase/UseCase';

interface LoadSavedOrderStatesUseCaseOptions {
  coachesKey: string;
  partnersKey: string;
}

export const loadSavedOrderStatesUseCase:UseCase = {
  type: 'LOAD_SAVED_ORDERS',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [coachHasSession]);
  },

  run: (options: LoadSavedOrderStatesUseCaseOptions) => {
    return getOrderStatesByCoachesKey$(options.coachesKey, options.partnersKey)
    .then((data) => {
      return Cx.resolve({
        savedOrders: data
      });
    });
  }
};

export function canLoadSavedOrderStates(state): boolean {
  return loadSavedOrderStatesUseCase.checkConditions( state ).isAllowed;
}

export function loadSavedOrderStates(options: LoadSavedOrderStatesUseCaseOptions){
  app.runUseCase(loadSavedOrderStatesUseCase, options);
}
