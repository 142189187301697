import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { pollServer } from '../../gui/util/poll'

export interface pollServerForAccessUseCaseOptions {
    interval: number
}

export const pollServerForAccessUseCase: UseCase = {
  type: 'POLL_SERVER_FOR_ACCESS',

  run: ( options: pollServerForAccessUseCaseOptions ) => {    
    return pollServer(options.interval);
  }
};

/**
 * @category Use Case
 */
export function pollServerForAccess( options: pollServerForAccessUseCaseOptions ) {
  app.runUseCase( pollServerForAccessUseCase, options );
}
