import { ColorZoneBrush } from './ColorZone';
import { FillTarget } from './FillTarget';

export interface ColorZoneFillTarget extends FillTarget {
  canvasId: string;
  colorZone: ColorZoneBrush;
}

// User-Defined Type Guard
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
export function fillTargetIsColorZone (target: FillTarget): boolean {
  const t = target as ColorZoneFillTarget;

  return t && t.type && 
    t.type === 'COLOR_ZONE' &&
    t.canvasId !== undefined &&
    t.colorZone && t.colorZone.Name !== undefined ? true : false;
}

export function colorZoneFillTarget (canvasId: string, colorZone: ColorZoneBrush): ColorZoneFillTarget {
  return {
    canvasId,
    colorZone,
    type: 'COLOR_ZONE'
  };
}

export const cloneColorZoneFillTarget = (props: ColorZoneFillTarget): ColorZoneFillTarget => {
  return {
    canvasId: props.canvasId,
    colorZone: {...props.colorZone},
    type: props.type
  };
}