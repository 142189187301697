import { uniqueId } from 'lodash';
import React from 'react';
import { SavedOrder } from '../../../app/domain/Order/SavedOrder';
import { Product } from '../../../app/domain/Product/Product';
import { hideModal, openSavedOrder } from '../../../app/initApp';
import { isLoadingOrder } from '../../../app/usecases/loadingOrder';
import global from '../../services/global';
import Button from '../misc/Button';
import ReviewItem from '../steps/ReviewItem';

// Styles
import styles from './SavedOrdersDetail.module.css';

interface Props {
  className: string;
  onBackClick: Function;
  savedOrder: SavedOrder;
  cadworxSessionId?: string;
}

const SavedOrdersDetail = (props: Props) => {
  const savedOrder = props.savedOrder;
  const orderState = savedOrder ? savedOrder.orderState : [];

  return <div className={props.className || ''}>
    <div className={styles.header}>
      <Button
        className={styles.backButton + ' partner-background-color2 partner-border-color2'}
        iconCls="fa-arrow-left"
        label="Back"
        onClick={props.onBackClick} />
    </div>
    {orderState.map((product: Product) => {
      return <div key={uniqueId()} className={styles.reviewItemContainer}><ReviewItem
        key={product.Id}
        onEditDesignClick={() => {
          openSavedOrder({
            canvasId: global.__MAIN_CANVAS_ID__,
            editDesign: true,
            product,
            savedOrder: props.savedOrder
          });
          hideModal();
          isLoadingOrder();
        }}
        onEditRosterClick={() => {
          openSavedOrder({
            canvasId: global.__MAIN_CANVAS_ID__,
            editRoster: true,
            product,
            savedOrder: props.savedOrder
          });
          hideModal();
          isLoadingOrder();
        }}
        product={product}
        cadworxSessionId={props.cadworxSessionId} />
        <div className={styles.footerBtn}>
          <Button
            className={styles.backButton + ' partner-background-color2 partner-border-color2'}
            label="Go to review step"
            onClick={() => {
              openSavedOrder({
                canvasId: global.__MAIN_CANVAS_ID__,
                editRoster: false,
                editDesign: false,
                product,
                savedOrder: props.savedOrder
              });
              hideModal();
              isLoadingOrder();
            }} />
        </div>
      </div>;
    })}
  </div>
};

export default SavedOrdersDetail;
