import React from 'react';

import styles from './Field.module.css';

/**
 * Usage
 *
 * <Field label="Preview">
 *   <Input
 *    onChange={ (e) => this.doSomething(e.target.value)}
 *    type="number" />
 * </Field>
 */

interface Props {
  children:any
  className?:string
  label?:string
  labelOnTop?:boolean
}

const Field = (props:Props) => {
  let className = props.labelOnTop ? styles.fieldColumn : styles.fieldRow;
  const label = props.label;

  if (props.className) {
    className += ' ' + props.className;
  }

  return (
    <div className={className}>
      {
        label
        ?
        <label className={styles.label}>{label}</label>
        :
        null
      }
      {props.children}
    </div>
  );

}

export default Field;