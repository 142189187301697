import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export interface showErrorDialogOptions {
  errorMessage: string;
}
export const showErrorDialogUseCase:UseCase = {
  type: 'SHOW_ERROR_DIALOG',

  run: (options: showErrorDialogOptions) => {
    return Cx.resolve({ errorMessage: options.errorMessage });
  }
};

export function showErrorDialog(options:showErrorDialogOptions ) {
  app.runUseCase(showErrorDialogUseCase, options);
}
