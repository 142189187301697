import { DecorationArea, findBackNumberDecorationArea, findNameDecorationArea, findSleeveNumberDecorationArea, isRibNumberDecorationArea, findCADecorationArea, findRibNumberDecorationArea } from '../../domain/Design/DecorationArea';
import { RosterItem } from '../../domain/Roster/RosterItem';
import { setCanvasCameraToBack } from './canvas';
import { updateDecorationArea$ } from './updateDecorationArea$';

export function previewRosterItem$ (rosterItem: RosterItem, canvasId: string, decorationAreas: DecorationArea[]) {
  const backNumberDecorationArea = findBackNumberDecorationArea(decorationAreas);
  const nameDecorationArea = findNameDecorationArea(decorationAreas);
  const sleeveNumberDecorationArea = findSleeveNumberDecorationArea(decorationAreas);
  const CADecorationArea = findCADecorationArea(decorationAreas);
  const ribNumberDecorationArea = findRibNumberDecorationArea(decorationAreas);
  const fn$ = [];

  if (isNameValid(rosterItem) && nameDecorationArea) {
    const nameElement = Object.assign({}, nameDecorationArea.DesignElement, {
      text: rosterItem.Name
    });

    fn$.push(updateDecorationArea$(nameElement));
  }

  if (isNumberValid(rosterItem)) {
    if (backNumberDecorationArea) {
      const backNumberElement = Object.assign({}, backNumberDecorationArea.DesignElement, {
        text: rosterItem.Number + '' // Need to pass a string
      });

      fn$.push(updateDecorationArea$(backNumberElement));
    }

    if (sleeveNumberDecorationArea) {
      const sleeveNumberElement = Object.assign({}, sleeveNumberDecorationArea.DesignElement, {
        text: rosterItem.Number + '' // Need to pass a string
      });

      fn$.push(updateDecorationArea$(sleeveNumberElement));
    }

    if ( CADecorationArea) {
      const CAElement = Object.assign({}, CADecorationArea.DesignElement, {
        text: rosterItem.CA
      });

      fn$.push(updateDecorationArea$(CAElement));
    }

    if (ribNumberDecorationArea) {
      const ribNumberElement = Object.assign({}, ribNumberDecorationArea.DesignElement, {
        text: rosterItem.Number
      });

      fn$.push(updateDecorationArea$(ribNumberElement));
    }
  }

  if (fn$.length > 0) {
    setCanvasCameraToBack(canvasId);
  }

  return Cx.whenAll(fn$);
}

const isNameValid = (rosterItem) => {
  return rosterItem.Name.length > 0;
}

const isNumberValid = (rosterItem) => {
  return Number.isInteger(Number(rosterItem.Number));
}