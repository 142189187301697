import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../app/Application';
import { canRedo, canSetOrbitMode, canSetPanMode, canUndo, canvasZoomIn, redo, setOrbitMode, setPanMode, undo, zoomIn, zoomOut, zoomToFit } from '../../../../app/initApp';
import global from '../../../services/global';
import Button from '../../misc/Button';

import './CustomizeToolsHud.css';

interface Props {
  canRedo: boolean;
  canSetOrbitMode: boolean;
  canSetPanMode: boolean;
  canUndo: boolean;
  canZoom: boolean;
  canvasId: string;
  isOrbitMode?: boolean;
  isPanMode?: boolean;
  onOrbitClick?: Function;
  onPanClick?: Function;
  onRedoClick?: Function;
  onUndoClick?: Function;
  onZoomInClick?: Function;
  onZoomOutClick?: Function;
  onZoomToFitClick?: Function;
};

const CustomizeToolsHud = (props: Props) => {

  return <div className="customize-tools-hud">
    <div className="flex-column">
      <Button
        className="ui-clear"
        disabled={!props.canUndo}
        iconCls="fa-undo"
        onClick={props.onUndoClick} />
      <Button
        className="ui-clear"
        disabled={!props.canRedo}
        iconCls="fa-redo"
        onClick={props.onRedoClick} />
      <Button
        className="ui-clear"
        disabled={!props.canZoom}
        iconCls="fa-search-plus"
        onClick={props.onZoomInClick} />
      <Button
        className="ui-clear"
        disabled={!props.canZoom}
        iconCls="fa-search-minus"
        onClick={props.onZoomOutClick} />
      <Button
        className="ui-clear"
        disabled={!props.canZoom}
        iconCls="fa-search-location"
        onClick={props.onZoomToFitClick} />
      <Button
        className="ui-clear"
        disabled={!props.canSetPanMode || props.isPanMode}
        iconCls={'fa-hand-paper'}
        onClick={props.onPanClick} />
      <Button
        className="ui-clear"
        disabled={!props.canSetOrbitMode || props.isOrbitMode}
        iconCls={'fa-sync-alt'}
        onClick={props.onOrbitClick} />
    </div>
  </div>

}

const mapStateToProps = (state: RootState) => {
  const canvasId = global.__MAIN_CANVAS_ID__;
  const canvasFullState = state.CanvasFullState;

  return {
    canRedo: canRedo(state),
    canSetOrbitMode: canSetOrbitMode(state),
    canSetPanMode: canSetPanMode(state),
    canUndo: canUndo(state),
    canZoom: canvasZoomIn(state),
    canvasId,
    isOrbitMode: canvasFullState.isOrbitMode,
    isPanMode: canvasFullState.isPanMode
  };
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const canvasId = global.__MAIN_CANVAS_ID__;

  return Object.assign({}, ownProps, stateProps, {
    onOrbitClick: () => setOrbitMode({ canvasId }),
    onPanClick: () => setPanMode({ canvasId }),
    onUndoClick: () => undo({ canvasId }),
    onRedoClick: () => redo({ canvasId }),
    onZoomInClick: () => zoomIn({ canvasId }),
    onZoomOutClick: () => zoomOut({ canvasId }),
    onZoomToFitClick: () => zoomToFit({ canvasId })
  });
}

export default connect(mapStateToProps, null, mergeProps)(CustomizeToolsHud);
