import { isDesignReady } from '../domain/Design/Conditions';
import { outlineFillTarget } from '../domain/Design/OutlineFillTarget';
import { TextDesignElement  } from '../domain/Design/TextDesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { addOutline$ } from './actions/addOutline$';
import { setFillTarget$ } from './actions/setFillTarget$';
import { UseCase } from './usecase/UseCase';
import { applyConfig } from '../usecases/actions/font'
import { getFillTargetBrush } from '../domain/Design/FillTarget';
import { isPatternBrush } from '../../app/domain/Pattern/Pattern'

export interface AddOutlineOptions {
  designElement: TextDesignElement;
  fontConfigs: any;
}

export const addOutlineUseCase: UseCase = {
  type: 'ADD_OUTLINE',

  checkConditions: (state) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: ( options: AddOutlineOptions ) => {
    const designElement = options.designElement;

    applyConfig(designElement, options.fontConfigs);

    return addOutline$(designElement)
    .then(() => {
      const index = designElement.outlines.length - 1;
      const fillTarget = outlineFillTarget(designElement, index);
      designElement.outlineProportions[index] = 1;

      //const fillTarget = cloneFillTarget(target);
      const fillTargetBrush = getFillTargetBrush(fillTarget);
      const fillTargetBrushIsPattern = isPatternBrush(fillTargetBrush);

      // return setFillTarget$(fillTarget)
      // .then((data) => {
      return Cx.resolve({
        designElement,
        fillTarget: fillTarget,
        fillTargetBrush,
        fillTargetBrushIsPattern//data.fillTargetBrushIsPattern
      });
    });
  }
};

/**
 * @category Can
 */
export function canAddOutline( state ) {
  return addOutlineUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function addOutline( options: AddOutlineOptions ) {
  app.runUseCase( addOutlineUseCase, options );
}
