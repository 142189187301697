import { RootState } from '../Application';
import { isConfigReady } from '../domain/Config/Conditions';
import { hasActiveProduct } from '../domain/Order/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';

export const showColorZonesTabUseCase:UseCase = {
  type: 'SHOW_COLORZONES_TAB',

  checkConditions: ( state: RootState ) => {
    return Condition.evaluate(state, [isConfigReady, hasActiveProduct]);
  },

  run: () => {
    // @ts-ignore
    return Cx.resolve();
  }
};

export function canShowColorZonesTab(state: RootState) {
  return showColorZonesTabUseCase.checkConditions( state ).isAllowed;
}

export function showColorZonesTab(){
  app.runUseCase(showColorZonesTabUseCase);
}
