import { isDesignReady } from '../domain/Design/Conditions';
import { DecorationArea } from '../domain/Design/DecorationArea';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { toggleDecoratinAreaHidden$ } from './actions/toggleDecorationAreaHidden$';
import { UseCase } from './usecase/UseCase';

export interface SetDecorationAreaActiveOptions {
  decorationArea: DecorationArea;
}

export const setDecorationAreaActiveUseCase:UseCase = {
  type: 'SET_DECORATION_AREA_ACTIVE',

  checkConditions: (state) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: (options: SetDecorationAreaActiveOptions) => {
    return Cx.resolve(options);
  }
};

export function canSetDecorationAreaActive(state): boolean {
  return setDecorationAreaActiveUseCase.checkConditions( state ).isAllowed;
}

export function setDecorationAreaActive(options: SetDecorationAreaActiveOptions){
  app.runUseCase(setDecorationAreaActiveUseCase, options);
}
