import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const showOrderSubmissionConfirmationUseCase:UseCase = {
  type: 'SHOW_ORDER_SUBMISSION_CONFIRMATION',

  run: () => {
    return Cx.resolve();
  }
};

export function showOrderSubmissionConfirmation() {
  app.runUseCase(showOrderSubmissionConfirmationUseCase);
}
