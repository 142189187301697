import { app } from '../../initApp';
import { UseCase } from '../usecase/UseCase';
import SearchFactory from '../../../gui/services/search'
import { loadFontConfig$ } from '../../usecases/actions/font'

export interface initFontListWithConfigUseCaseOptions {}

export const initFontListWithConfigUseCase: UseCase = {
  type: 'INIT_FONT_LIST',

  run: ( options: initFontListWithConfigUseCaseOptions ) => {
    let fontSearchService = new SearchFactory("Font")
    return fontSearchService.search().then((fonts) => {
      return loadFontConfig$().then((config) => {
        return Cx.resolve({ fonts: fonts, config: config })
      })
    });
  }
};

/**
 * @category Use Case
 */
export function initFontListWithConfig( options: initFontListWithConfigUseCaseOptions ) {
  app.runUseCase( initFontListWithConfigUseCase, options );
}
