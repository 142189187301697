import { Units } from '../../../canvas/helpers/units';
import { TextDesignElement } from '../../domain/Design/TextDesignElement';
import { getContourThicknessForSize } from './font';

export function updateOutlineOffsets (designElement: TextDesignElement ) {
  const outlines = designElement.outlines;
  const proportions = designElement.outlineProportions;
  const offset = Units.toDocument(getContourThicknessForSize(Units.toUser(designElement.fontSize)));

  outlines.forEach((o,i) => {
    const prop = proportions[i] || 1 ;
    o.Offset = offset * prop ;
  });
}