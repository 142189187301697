import { RootState } from '../Application';
import { isDesignReady } from '../domain/Design/Conditions';
import { TextDesignElement  } from '../domain/Design/TextDesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { updateTextDesignElement$ } from './actions/updateTextDesignElement$';
import { UseCase } from './usecase/UseCase';
import { applyConfig } from '../usecases/actions/font'

export interface SetFontOptions {
  designElement: TextDesignElement;
  fontId: string;
  fontName: string;
  fontConfigs: any;
}

export const setFontUseCase: UseCase = {
  type: 'SET_FONT',

  checkConditions: (state: RootState) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: ( options: SetFontOptions ) => {
    const designElement = options.designElement;
    designElement.fontFamily = options.fontName;
    designElement.fontId = options.fontId;
    
    applyConfig(designElement, options.fontConfigs);

    // update on figure
    return updateTextDesignElement$(designElement).then(() => {
      return Cx.resolve(options);
    });
  }
};

/**
 * @category Can
 */
export function canSetFont( state: RootState ) {
  return setFontUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setFont( options: SetFontOptions ) {
  app.runUseCase( setFontUseCase, options );
}
