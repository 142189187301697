import { Product } from './Product';

export interface ProductSnippet {
  //backSnapshotDocId: string
  cadworxDocId: string
  designStyleName: string
  //frontSnapshotDocId: string
  grayGoodName: string
  savedDocId: string
  total?: number // Deprecate
  totalPrice?: number
}

export function productSnippetFromProduct (product: Product): ProductSnippet {
  return {
    //backSnapshotDocId: product.BackSnapshot.DocId,
    cadworxDocId: product.Id,
    designStyleName: product.DesignStyle.Name,
    //frontSnapshotDocId: product.FrontSnapshot.DocId,
    grayGoodName: product.GrayGood.Name,
    savedDocId: product.SavedDocId,
    // total: product.TotalPrice,
    totalPrice: product.TotalPrice
  };
}

export function productSnippetFrontThumbnailUrl (productSnippet: ProductSnippet, imageSize: number): string {
  // const imageUrl = Cx.Core.Doc3D.thumbnailUrl({
  //   Doc: { Id: productSnippet.frontSnapshotDocId },
  //   Format: 'png',
  //   Height: imageSize,
  //   Width: imageSize
  // });

  //return imageUrl;
  return undefined ;
}
