import { app } from '../initApp';
import { CanvasAreaFactory } from '../../canvas/CanvasArea';
import { setupCanvasSelectTool } from './actions/canvas';
import { UseCase } from './usecase/UseCase';

export interface initCanvasUseCaseOptions {
  canvasId: string;
  inited: boolean;
}

export const initCanvasUseCase: UseCase = {
  type: 'INITED_CANVAS',

  run: ( options: initCanvasUseCaseOptions ) => {
    const canvasId = options.canvasId;
    const canvas = CanvasAreaFactory.getCanvasById(canvasId);

    setupCanvasSelectTool(canvas);

    return Cx.resolve({ canvasId, inited: options.inited });
  }
};

/**
 * @category Use Case
 */
export function initCanvas( options: initCanvasUseCaseOptions ) {
  app.runUseCase( initCanvasUseCase, options );
}
