import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GuiSteps } from '../../app/domain/Gui/GuiSteps';
import Header from './misc/Header';
import ModalManager from './misc/ModalManager';
import MainColorSelector from './steps/customize/MainColorSelector';
import Customize from './steps/customize/Customize';
import DesignStyles from './steps/DesignStyles';
import GrayGoods from './steps/GrayGoods';
import Review from './steps/Review';
import Sports from './steps/Sports';
import Steps from './steps/Steps';
import { setInitialOrder } from '../../app/usecases/setInitialOrder';
import BatchProcessor from './Thumb3D/BatchProcessor';
import setBatchMode from '../../app/usecases/setBatchMode';

// Styles
import './Main.css';

interface Props {
  activeStepClassName: string;
  customizeActive: boolean;
  designStyleActive: boolean;
  productTypesActive: boolean;
  reviewActive: boolean;
  rosterActive: boolean;
  sportsActive: boolean;
  mainColorSelectorHidden: boolean;
  isBatchProcessing: boolean;
  orderId?: string;
  initialOrderOpenAsCopy?: boolean;
  loadingOrder: boolean;
}

class Main extends Component<Props> {

  public state = {
    canvasLoaded: false
  };

  constructor(props) {
    super(props);
  }

  public setRenderedState = (state) => {
    this.setState({ canvasLoaded: state });
  }

  public componentDidMount() {
    if (this.props.isBatchProcessing)
      setBatchMode();

    if (this.props.orderId)
      setInitialOrder({ orderId: this.props.orderId, initialOrderOpenAsCopy: this.props.initialOrderOpenAsCopy });
  }

  public render() {
    let className = 'main';
    const props = this.props;

    className += ' active-step-' + props.activeStepClassName;

    if (props.isBatchProcessing)
      return <BatchProcessor></BatchProcessor>
    else
      return (
        <main role="main" className={className}>
          {props.loadingOrder ? <div className="loading-order">
            <span className='icon fas fa-circle-notch fa-spin'>
            </span> Loading order
          </div> : null}
          <div className="main-header">
            <Header></Header>
            <Steps></Steps>
          </div>

          <div className="main-panels flex-column flex-one">
            <Sports hidden={!props.sportsActive} />
            <GrayGoods hidden={!props.productTypesActive} />
            <DesignStyles hidden={!props.designStyleActive} />
            <Customize hidden={!props.customizeActive} showAddRoster={props.rosterActive} />
            <MainColorSelector hidden={props.mainColorSelectorHidden} />
            <Review hidden={!props.reviewActive} />
          </div>
          <ModalManager />
        </main>
      )
  }
}

const mapStateToProps = (state) => {
  const activeStep = state.GUI.activeStep;
  let activeStepClassName = '';

  if (activeStep === GuiSteps.STYLE) {
    state.GUI.mainColorSelectorHidden = !state.GUI.showEditColorOptions;
  }

  switch (activeStep) {
    case GuiSteps.CUSTOMIZE: activeStepClassName = 'customize'; break;
    case GuiSteps.PRODUCT: activeStepClassName = 'gray-goods'; break;
    case GuiSteps.REVIEW: activeStepClassName = 'review'; break;
    case GuiSteps.ROSTER: activeStepClassName = 'add-roster'; break;
    case GuiSteps.SPORT: activeStepClassName = 'sport'; break;
    case GuiSteps.STYLE: activeStepClassName = 'design-styles'; break;
  }

  return {
    activeStepClassName,
    customizeActive: (activeStep === GuiSteps.CUSTOMIZE || activeStep === GuiSteps.ROSTER),
    designStyleActive: activeStep === GuiSteps.STYLE,
    productTypesActive: activeStep === GuiSteps.PRODUCT,
    reviewActive: activeStep === GuiSteps.REVIEW,
    rosterActive: activeStep === GuiSteps.ROSTER,
    sportsActive: activeStep === GuiSteps.SPORT,
    mainColorSelectorHidden: state.GUI.mainColorSelectorHidden,
    loadingOrder: state.GUI.loadingOrder
  }
}

export default connect(mapStateToProps, null)(Main)
