import React, { createRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { appendString } from '../../util/appendString';
import { setGrayGood } from '../../../app/initApp';

import './GrayGoods.css';
import { uniqueId } from 'lodash';

declare let Cx: any;

interface Props {
  hidden: boolean
  grayGoods: any
}

const GrayGoods = (props: Props) => {
  let className = 'gray-goods flex-one centered';
  const hidden = props.hidden;
  const grayGoods = props.grayGoods;
  const count = grayGoods.length;

  if (hidden) {
    className += ' hidden';
  }

  // Section: render thumbnail once scrolled into view
  const rootRef = createRef<HTMLDivElement>();
  const itemRefs = grayGoods.reduce((acc: any, value: any) => {
    // @ts-ignore
    acc[value.Name] = {
      id: value.Name,
      ref: React.createRef(),
      urlRendered: false
    };

    return acc;
  }, {});

  const callback = (entries: any) => {
    // @ts-ignore
    entries.forEach((entry: any) => {
      const dom = entry.target;
      const name = dom.id;
      const thumb = itemRefs[name];

      if (entry.intersectionRatio > 0.5 && !thumb.rendered) {
        const foundDesign = grayGoods.find((f) => f.Name === name);

        if (foundDesign) {
          dom.firstChild.style.backgroundImage = 'url(' + getDocThumb(foundDesign.DocId) + ')';
          thumb.rendered = true;
        }
      }
    });
  };

  const observer = new IntersectionObserver(callback, {
    root: rootRef.current,
    threshold: new Array(101).fill(0).map((v, i) => i * 0.01)
  });

  useEffect(() => {
    Object.values(itemRefs).forEach((value: any) => {
      if (value.ref.current) {
        observer.observe(value.ref.current);
      }
    });
  }, [grayGoods, itemRefs]);

  if (count > 0) {
    return (
      <div className={className}>
        <div className="page-header partner-color1">Select A Product Type</div>

        <div className="list" ref={rootRef}>
          {grayGoods.map(function (item: any) {
            const name = item.Name;
            return <div
              className="item"
              id={name}
              key={uniqueId()}
              onClick={() => { onItemClick(item) }}
              // @ts-ignore
              ref={itemRefs[name].ref}
              title={name}>
              <div className="thumb" />
              <div className="name">{name}</div>
            </div>
          })}
        </div>

      </div>
    );
  } else {
    return <div className={className}>
      <div className="no-items-message">
        Coming soon...
      </div>
    </div>
  }

  function onItemClick(item) {
    setGrayGood({
      grayGood: item
    });
  }
}

const defaultThumbSize = 300;
function getDocThumb(id) {
  const thumbSize = defaultThumbSize;
  const requestModel = {
    Doc: { Id: id },
    Format: 'png',
    Height: thumbSize,
    Width: thumbSize
  };

  return Cx.Core.Doc3D.thumbnailUrl(requestModel);
}

const mapStateToProps = (state) => {
  const grayGoods = state.GUI.activeSportGrayGoods?.sort((a, b) => a.Name.localeCompare(b.Name)) || [];

  // Hack
  // grayGoods = grayGoods.concat(grayGoods);
  // grayGoods = grayGoods.concat(grayGoods);

  return {
    grayGoods
  };
}

export default connect(mapStateToProps, null)(GrayGoods);
