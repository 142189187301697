import React, { Component } from 'react';
import { appendString } from '../../util/appendString';

import './Button.css';

interface Props {
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
  iconAlign?: string;
  iconCls?: string;
  label?: string;
  onClick: any;
  tabIndex?: number;
  title?: string;
}

export default class Button extends Component<Props> {

  private iconClsHasPrefix (iconCls) {
    const prefixes = ['fa ', 'far ', 'fas '];
    const len = prefixes.length;
    let i = 0;

    for (; i < len; i++) {
      if (iconCls.indexOf(prefixes[i]) !== -1) {
        return true;
      }
    }

    return false;
  }

  public render() {
    const props = this.props;
    let cls = 'button';
    const disabled = props.disabled === true ? true : false;
    const hidden = props.hidden ? true : false;
    let iconCls = 'icon';
    const label = props.label;
    const onClick = disabled ? null : props.onClick;

    if (props.className) {
      cls = appendString(cls, props.className);
    }

    if (props.iconCls) {
      cls = appendString(cls, 'has-icon');

      if (props.iconAlign) {
        cls = appendString(cls, 'align-icon-' + props.iconAlign);
      }

      if (!this.iconClsHasPrefix(props.iconCls)) {
        iconCls = appendString(iconCls, 'fa');
      }

      iconCls = appendString(iconCls, props.iconCls);
    }

    if (label) {
      cls = appendString(cls, 'has-label');
    }

    return <button
      className={cls}
      disabled={disabled}
      hidden={hidden}
      onClick={onClick}
      tabIndex={props.tabIndex}
      title={props.title}>
        <span className={iconCls}></span> 
        <span className="label">{label}</span>
      </button>;
  }

}
