import React from 'react';
import { hideModal } from '../../../app/initApp';
import Button from '../misc/Button';

import styles from './OrderSubmissionConfirmation.module.css';

const OrderSubmissionConfirmation = () => {

  return (
    <div className={styles.confirmation}>
      <div className={styles.header + ' partner-color1'}>THANK YOU!</div>
      <div className={styles.secondaryColorText +  ' partner-color2'}>YOUR ORDER HAS BEEN SUCCESSFULLY SUBMITTED FOR REVIEW</div>
      <div className={styles.primaryColorText + ' partner-color1'}>A REPRESENTATIVE WILL CONTACT YOU SHORTLY FOR ORDER CONFIRMATION AND PAYMENT DETAILS</div>
      <Button
        className={styles.button + ' partner-background-color1 partner-border-color1'}
        label="AWESOME!"
        onClick={() => {
          hideModal();
        }} />
    </div>
  );

}

export default OrderSubmissionConfirmation;
