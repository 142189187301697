import { ColorZoneFillTarget, colorZoneFillTarget } from '../../domain/Design/ColorZoneFillTarget';
import { getNamedColorZones } from './canvas';

export function getInitialFillTarget (canvasId: string): ColorZoneFillTarget {
  const colorZoneBrushMap = getNamedColorZones(canvasId);

  let selectedTargetIdx = colorZoneBrushMap.findIndex((cz) => { 
    return (cz.Name.toUpperCase() === 'MAIN BODY' || cz.Name.toUpperCase() === 'ZONE 1');
  });

  if (selectedTargetIdx === -1 )
    selectedTargetIdx = 0 ;

  const fillTarget = colorZoneFillTarget(canvasId, colorZoneBrushMap[selectedTargetIdx]);

  return fillTarget;
}