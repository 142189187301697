
import React from 'react';
import FillArrow from './FillArrow';

import styles from './FillEditor.module.css';

interface Props {
  brush: Cx.Brush;
  label: string;
  onClick: any;
  onRemoveClick?: any;
  selected?: boolean;
}

function FillEditor (props: Props) {
  if (!props.brush) {
    return null;
  }

  const className = props.selected ? (styles.editorSelected + ' partner-selected-fill-target-color1') : styles.editor;

  return <div className={className}>
    <div className={styles.editorInner}>
      <div className={styles.editorLabel} onClick={props.onClick}>{props.label}</div>
      <FillArrow brush={props.brush} onClick={props.onClick} />
      {
        props.onRemoveClick
        ?
        <div className={styles.editorRemove + ' partner-color2'} onClick={props.onRemoveClick}>Remove</div>
        :
        null
      }
    </div>
  </div>;
}

export default FillEditor;