declare const Cx:any;

enum DesignEvents {
  COMMIT = 'commit',
  RENDER = 'render',
  USE_CASE_INVOKED_COMMIT = 'usecaseinvokedcommit',
  USE_CASE_INVOKED_RENDER = 'usecaseinvokedrender',
  COMMIT_STARTED = 'commitstarted',
  SELECTION_CHANGED = 'selectionchanged',
  DOUBLE_CLICK = 'dblclick'
}

export class ResizeListener {
  private static instance: ResizeListener;
  private static w: Window ;
  private static attachEvent:Function;
  private static isIE: boolean ;
  private constructor(w:Window) {
    ResizeListener.w = w ;
  }

  static getInstance(w:Window): ResizeListener {
    if (!this.instance) {
      this.instance = new ResizeListener(w);
    }

    return this.instance;
  }

  static getWindow():Window { return this.w }

  public static Init(){
    this.attachEvent = this.w.document.addEventListener ;
    let m = this.w.navigator.userAgent.match(/Trident/);
    this.isIE = m.length > -1 ;
  }

  private static requestFrame(fn:Function) {
    var raf = this.w.requestAnimationFrame 
              //|| this.w.mozRequestAnimationFrame  unsupported ?
              || this.w.webkitRequestAnimationFrame ||
      function(fn:FrameRequestCallback){ return window.setTimeout(fn, 20); };
      return function(fn:FrameRequestCallback){ return raf(fn); };
  }

  private static cancelFrame(fn:Function) {
    var cancel = this.w.cancelAnimationFrame 
                //|| window.mozCancelAnimationFrame unsupported?
                || this.w.webkitCancelAnimationFrame ||
                this.w.clearTimeout;
    return function(id:number){ return cancel(id); };
  }

  static objectLoad(e){
    //this.w.document.defaultView.__resizeTrigger__ = this.__resizeElement__;
    this.w.document.defaultView.addEventListener('resize', this.resizeListener);
  }

  static resizeListener(e){
    var win = e.target || e.srcElement;
    if (win.__resizeRAF__) this.cancelFrame(win.__resizeRAF__);
    win.__resizeRAF__ = this.requestFrame(function(){
      var trigger = win.__resizeTrigger__;
      trigger.__resizeListeners__.forEach(function(fn){
        fn.call(trigger, e);
      });
    });
  }

  static AttachEvents() {
    (<any>this.w).addResizeListener = (element, fn) => {
      if (!element.__resizeListeners__) {
        element.__resizeListeners__ = [];
        if (this.attachEvent) {
          element.__resizeTrigger__ = element;
          element.attachEvent('onresize', this.resizeListener);
        }
        else {
          if (getComputedStyle(element).position == 'static') element.style.position = 'relative';
          var obj = element.__resizeTrigger__ = document.createElement('object');
          obj.setAttribute('style', 'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden; pointer-events: none; z-index: -1;');
          obj['__resizeElement__'] = element;
          obj.onload = this.objectLoad;
          obj.type = 'text/html';
          if (this.isIE) element.appendChild(obj);
          obj.data = 'about:blank';
          if (!this.isIE) element.appendChild(obj);
        }
      }
      element.__resizeListeners__.push(fn);
    };
  
    (<any>this.w).removeResizeListener = function(element, fn){
      element.__resizeListeners__.splice(element.__resizeListeners__.indexOf(fn), 1);
      if (!element.__resizeListeners__.length) {
        if (this.attachEvent) element.detachEvent('onresize', this.resizeListener);
        else {
          element.__resizeTrigger__.contentDocument.defaultView.removeEventListener('resize', this.resizeListener);
          element.__resizeTrigger__ = !element.removeChild(element.__resizeTrigger__);
        }
      }
    }
  }
}

export class CanvasArea {
  id: string;
  el: Element;
  canvas: any;
  config: any;

  constructor(id:string, el:Element, canvas:any, config?:any)
  {
    this.id = id ;
    this.el = el;
    this.canvas = canvas ;
    this.config = config ; 
  }
}

export interface CanvasConfig {
  tool: any;
  appendTo:Element;
  selectableLayers:Function;
  allowCrossLayerSelection:boolean;
  dispatcher?: Function;
  dispatchCaseName?: string;
}

export class CanvasAreaFactory {

  static deleteCanvasById(id:string)
  {
    let canvas = CanvasAreaFactory.getCanvasById(id)
    if ( canvas )
    {
      let i = window.canvasInstances.indexOf(canvas, 0);
      if ( i > -1 )
        delete window.canvasInstances[i];
    }
  }
  static getCanvasById(id:string) {

    const canvases = window.canvasInstances ;    

    return Array.isArray(canvases) ? canvases.filter( c => c.id === id ).shift() : null ;
  }

  static Canvas(id: string, override : boolean = false, config?: CanvasConfig ) : any  {
    if ( ! window.canvasInstances )
      window.canvasInstances = [];

    if ( override )
      CanvasAreaFactory.deleteCanvasById(id);

    if ( !config )
    {
      config = {
        tool: undefined,
        appendTo: document.getElementById(id),
        selectableLayers: undefined,
        allowCrossLayerSelection: false,
        dispatcher: null,
        dispatchCaseName: ''
      } ;
    }
    //let canvas: Cx.ICanvas ;      
    let canvas: Cx.Canvas = CanvasAreaFactory.getCanvasById(id);

    if ( ! canvas )
    {

      //proper abstraction for canvas config - extend 3d
      // canvas = new Cx.Canvas({
      //   appendTo: document.getElementById(id),
      //   tool: Cx.Tool.Select()
      // });
      canvas = new Cx.Canvas3D(config);

      canvas.id = id ;
      CanvasAreaFactory.setupCanvasEvents(<Cx.Canvas>canvas, id, config.dispatcher, config.dispatchCaseName);
      window.canvasInstances.push(canvas);
    }

    return canvas ;
  }

  static setupCanvasEvents(canvas:Cx.Canvas, canvasAreaId:string, canvasDispatcher: Function, canvasDispatcherCaseName:string){
    //TODO: When clip art is present, commit fires multiple times for selection change?

    //let _dispatchChangeTask = DelayedTask();
    //let _pendingStateChangeEvents = [];
    let onCanvasStateChange = function(events, meta = {}){
      //let timeStampOfChange = Date.now();

      //Dispatcher.dispatch({
      //  type: ActionResultType.DESIGN_CANVAS_STATE_CHANGE_PENDING
      //  , data: { timeStampOfChange: timeStampOfChange }
      //});

      //_dispatchChangeTask.delay(50, function(){
      //  let eventsToDispatch = _pendingStateChangeEvents;
      //  _pendingStateChangeEvents = [];
      //
      //  //We still dispatch everything but now we have special render and commit events that let us know whether it was use case invoked.
      //  //Since use cases can trigger 1:many canvas events, we want to keep this pattern of dispatching a change with 1:many events.
      //  //It means our service sees "change" and can determine if it was use case triggered.
      //  //It can ignore changes triggered by use cases because we now can determine if canvas changed when the use case is dispatched and we handle at that point.
      //  //Dispatch.result(ActionResultType.CANVAS_STATE_CHANGE, {events: events, timeStampOfChange: timeStampOfChange});
      //  //Dispatch.result(ActionResultType.CANVAS_STATE_CHANGE, {events: events});
      //  console.log('state change', eventsToDispatch);
      //});
      //
      //_pendingStateChangeEvents.concat(events);

      if (canvasDispatcher)
        canvasDispatcher(canvasDispatcherCaseName, { events: events, canvas: canvas, canvasAreaId: canvasAreaId });
    };

    let _idsOfSelectedLastRender = [];
    canvas.on(DesignEvents.COMMIT, function(canvas){
      let events = [];
      let selectedFigures = canvas.selectedFigures;
      let idsOfSelected = selectedFigures.map( (f) => f['_localId']);
      //let selectionChanged = !(Array.isArray(App_.xor(idsOfSelected, _idsOfSelectedLastRender)));

      let selectionChanged = idsOfSelected
                            .filter(x => !_idsOfSelectedLastRender.includes(x))
                            .concat(_idsOfSelectedLastRender.filter(x => !idsOfSelected.includes(x)));

      if(selectionChanged){
        events.push(DesignEvents.SELECTION_CHANGED);
      }

      //Update for next check
      _idsOfSelectedLastRender = selectedFigures.map( (f) => f['_localId'] );

      //if(meta && meta.useCaseInvoked) {
      //  onCanvasStateChange(DesignEvents.USE_CASE_INVOKED_COMMIT, meta);
      //}
      //else{
      //  onCanvasStateChange(DesignEvents.COMMIT, meta);
      //}

      events.push(DesignEvents.COMMIT);

      console.log('TEST commit started, update distress/pattern');

      onCanvasStateChange(events);
    });

    //By itself, we don't care about render.  We look for commit and we look for selection change.
    //Render fires for EVERYTHING, even if you're just clicking off in the background repeatedly.
    //However, canvas 'selectionchange' does not work as expected
    //So, we use render event to check if selection changed and add the event here
    //Now selection change works as expected.
    //let _idsOfSelectedLastRender = [];
    //canvas.on(DesignEvents.RENDER, function(doc, meta){
    //  console.log('render');
    //
    //  let selectedFigures = doc.selectedFigures;
    //  let idsOfSelected = App_.map(selectedFigures, '_localId');
    //  let selectionChanged = !(App_.isEmpty(App_.xor(idsOfSelected, _idsOfSelectedLastRender)));
    //
    //  if(selectionChanged){
    //    onCanvasStateChange(DesignEvents.SELECTION_CHANGED);
    //  }
    //
    //  //Update for next check
    //  _idsOfSelectedLastRender = App_.map(selectedFigures, '_localId');
    //
    //  ////We really don't care about emitting this, direct interaction that only triggers render is not useful to listen for.
    //  //if(meta && meta.useCaseInvoked) {
    //  //  _onCanvasStateChange(DesignEvents.USE_CASE_INVOKED_RENDER, meta);
    //  //}
    //  //else{
    //  //  _onCanvasStateChange(DesignEvents.RENDER, meta);
    //  //}
    //});

    //Why trigger canvas "state" change... because selection change is handled after because of the delay :/
    //It is an all or nothing approach with these events...
    canvas.on(DesignEvents.DOUBLE_CLICK, onCanvasStateChange.bind(this, [DesignEvents.DOUBLE_CLICK]));

    //'selectionchange' does not work as expected
    // For example, when selected figure is deleted, it does not fire.
    // Also, fires when same figure is selected, you can keep selecting the same figure and it keeps firing.
    // -> This last issue is resolved in latest canvas but delete issue remains
    canvas.on(DesignEvents.SELECTION_CHANGED, onCanvasStateChange.bind(this, [DesignEvents.SELECTION_CHANGED]));

    //Sync Distress and patterns with size of design
    canvas.on(DesignEvents.COMMIT_STARTED, function(canvas, waitFor){
      //waitFor.push( DistressHelper.updateDistress$(canvas.document) );
      //waitFor.push( CeTx.updatePatterns$(canvas.document) ); TODO do we need this ?
    });
  }
};