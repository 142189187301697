import React from 'react';
import uniqueKey from '../../util/uniqueKey';
import Button from './Button';
import Portal from './Portal';

import './Modal.css';

interface Props {
  bodyCls?: string;
  children: any;
  closeHandler: any;
  cls?: string;
  side: string;
  title?: string;
  unmountCb?: Function;
}

const Modal = (props: Props) => {

  const animationCls = `modal-show-${props.side}`;
  const cls = `modal modal-${props.side} ${animationCls} ${props.cls || ''}`;
  const bodyCls = `body ${props.bodyCls || ''}`;

  return <Portal>
    <div className="modal-wrap">
      <div className="modal-overlay" onClick={props.closeHandler}></div>
      <div className={cls} key={uniqueKey()}>
        <div className="header partner-color2">
          <div className="title">{props.title}</div>
          <Button onClick={props.closeHandler} className="close-tool" iconCls="fa-times" />
        </div>
        <div className={bodyCls}>
          {props.children}
        </div>
      </div>
    </div>
  </Portal>

}

export default Modal;