import global from '../../../gui/services/global';
import { isPatternBrush } from '../../domain/Pattern/Pattern'
import { GetSpotColorDefaultIndexesZoneMap } from '../../domain/Pattern/colorZoneHelper'
import SparkMD5 from 'spark-md5';
import { getContainersFromLocalStorage, saveContainersToLocalStorage } from '../../../gui/services/storage'

export function loadPatternConfig$()
{
  // @ts-ignore
  return Cx.Core.Config.read$({ Id: global._PATTERN_CONFIG_DATA_ID_ });
}

export function getPatternFigures(figures, targetName){
  return figures.filter( (f) => { 
    if ( f.polyregions )
    {
      return (f.polyregions.filter( (p) => { 
        return isPatternBrush(p.brush) 
      })).length > 0
    }
    else return false ;
  })
}

export function getPatternBrushFromDocId$( docId: string )
{
  return Cx.Core.Document.read$({ Id: docId }).then(function(d) {
    const docXml = d.Cdl ;
    // @ts-ignore
    const doc =  Cx.fromXmlString(docXml);
    const zones = Cx.uniqueColorZones(doc); 
    return Cx.resolve({
      brush: doc[0].polyregions[0].brush,
      zones: zones,
      colorZoneMap: GetSpotColorDefaultIndexesZoneMap(doc[0].polyregions[0].brush.getColors())
    });
  });
}

export function getSelectedPatternData$( brushDocId: string )
{
  return Cx.Core.Document.read$({ Id: brushDocId }).then(function(d) {
    const docXml = d.Cdl ;  
    // @ts-ignore
    const doc =  Cx.fromXmlString(docXml);
    const zones = Cx.uniqueColorZones(doc); 
    return Cx.resolve({
      selectedPatternDocumentId: brushDocId,
      selectedPatternSelectedColorSet: zones
    })
  });
}

export function GetThumbImg$(doc_id, color_tuples, tagged, zoom, width, height, dpi, is_raster) {
  var patternZoom = 1, rasterZoom = 1 ;
  var d = Cx.Defer();
  if ( zoom != 1 ) 
  {
    patternZoom = is_raster ? 1 : zoom ;
    rasterZoom  = is_raster ? zoom : 1 ;
  }
  var qs = {
      // @ts-ignore
      config: Cx.Core.getConfig()
      , model: {
          Doc: {
              Id: doc_id
          }
      , Colorize: { SpotColorTuples: color_tuples, Type: 'SpotColor' }
      , format: 'Png'
      , PatternZoom: patternZoom //pattern definition
      , Zoom: rasterZoom //resulting raster (tiled and transformed)
      , Width: width 
      , Height: height 
      , DPI: dpi
      , OverwriteCache: true
      }
      // @ts-ignore
      , ident: { SessionId: Cx.User.getSessionId(), SuperUser: Cx.User.isSuperUser() }
  };

  const hash = SparkMD5.hash( JSON.stringify(qs) );
  let thumb = getContainersFromLocalStorage('thumb', hash);

  if(thumb)
    return d.resolve(thumb);

  var xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function() {
     if (this.readyState == 4 && this.status == 200)
     {
        var res = this.response;
        var reader = new window.FileReader();
        reader.readAsDataURL(res); 
        reader.onloadend = function() { 
            saveContainersToLocalStorage('thumb', hash, reader.result);
            return d.resolve(reader.result);
        }
     }
  }
  // @ts-ignore
  xhr.open('POST', Cx.Core.getServer() + '/Core2/Doc/ColorizeThumbnail');
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  xhr.send(JSON.stringify(qs));
  xhr.responseType = 'blob';

  return d ;
};

export function GetThumbImgFromXml$(xml, height, width)
{
  var d = Cx.Defer();
  var qs = {
    // @ts-ignore
    config: Cx.Core.getConfig()
    , model: {
        Brush: {
          Cdl: xml
        }
    , Width: width 
    , Height: height 
    }
    // @ts-ignore
    , ident: { SessionId: Cx.User.getSessionId(), SuperUser: Cx.User.isSuperUser() }
  };

  const hash = SparkMD5.hash( JSON.stringify(qs) );
  let thumb = getContainersFromLocalStorage('thumb', hash);

  if(thumb)
    return d.resolve(thumb);

  var xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200)
    {
        var res = this.response;
        var reader = new window.FileReader();
        reader.readAsDataURL(res); 
        reader.onloadend = function() { 
            saveContainersToLocalStorage('thumb', hash, reader.result);
            return d.resolve(reader.result);
        }
    }
  }
  // @ts-ignore
  xhr.open('POST', Cx.Core.Brush.thumbnailUrl());
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  xhr.send(JSON.stringify(qs));
  xhr.responseType = 'blob';

  return d ;
}

export function GetThumbUrl(doc_id, color_tuples, tagged, zoom, width, height, dpi, is_raster) {

  var patternZoom = 1, rasterZoom = 1 ;
  if ( zoom != 1 ) 
  {
    patternZoom = is_raster ? 1 : zoom ;
    rasterZoom  = is_raster ? zoom : 1 ;
  }
  var qs = {
      // @ts-ignore
      config: Cx.Core.getConfig()
      , model: {
          Doc: {
              Id: doc_id
          }
      , Colorize: { SpotColorTuples: color_tuples, Tagged: tagged }
      , format: 'Png'
      , PatternZoom: patternZoom //pattern definition
      , Zoom: rasterZoom //resulting raster (tiled and transformed)
      , Width: width 
      , Height: height 
      , DPI: dpi
      , OverwriteCache: true
      }
      // @ts-ignore
      , ident: { SessionId: Cx.User.getSessionId(), SuperUser: Cx.User.isSuperUser() }
  };

  // @ts-ignore
  return Cx.Core.getServer() + '/Core2/Doc/ColorizeThumbnail?' + encodeURIComponent(JSON.stringify(qs));
};