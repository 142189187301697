import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const evaluateSubmittingOrderUseCase:UseCase = {
  type: 'EVALUATE_SUBMITTING_ORDER',

  run: () => {
    return Cx.resolve();
  }
};

export function evaluateSubmittingOrder() {
  app.runUseCase(evaluateSubmittingOrderUseCase);
}
