export interface Sport {
  FontAwesomeClass: string;
  Id: string;
  Name: string;
  Raw: any;
  SportId: string;
};

export function sportFromActionResult (props: any): Sport {
  return {
    FontAwesomeClass: props.fontAwesomeClass,
    Id: props.id,
    Name: props.name,
    Raw: props,
    SportId: props.sport.id
  };
}