import { CanvasAreaFactory } from '../../../canvas/CanvasArea';
import { DecorationArea, isBackNumberDecorationArea, isCADecorationArea, isNameDecorationArea, isSleeveNumberDecorationArea, isRibNumberDecorationArea } from '../../domain/Design/DecorationArea';
import { defaultBackNumberDesignElement, defaultCADesignElement, defaultNameDesignElement, defaultSleeveNumberDesignElement, defaultRibNumberDesignElement } from '../../domain/Design/TextDesignElement';
import { Font } from '../../domain/Font/Font';
import { getDecorationAreaId, getFigureInDecorationArea } from './canvas';
import { applyConfig } from './font';
import { updateTextDesignElement$ } from './updateTextDesignElement$';
import { Units } from '../../../canvas/helpers/units'

export function initDecorationAreas$ (canvasId: string, decorationAreas: DecorationArea[], caFont: Font, nameFont: Font, numberFont: Font, allFonts: any, isLoadedOrder: boolean) {
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const fn$ = [];
  const initedTypes = [];

  decorationAreas.forEach((item: DecorationArea) => {
    const areaId = getDecorationAreaId(item.Figure);
    const designFigure = areaId ? getFigureInDecorationArea(canvas, areaId) : undefined;
    const typeId = item.Type ;
    const typeInited = initedTypes.indexOf(typeId) > -1 ;


    if (areaId) {
      if (isLoadedOrder) //init saved order with properties
      {
        let existingDesignElement = item.DesignElement ;
        existingDesignElement.hidden = false ;
        fn$.push(updateTextDesignElement$(existingDesignElement));
      }
      else {
        if(!designFigure)//does not exist, init with default properties
        {
          initedTypes.push(typeId);
          const baseCfg = {
            canvasId,
            decorationAreaId: areaId
          };
          let designElement;

          if (isCADecorationArea(item)) {
            designElement = defaultCADesignElement(Object.assign({}, baseCfg, {
              fontFamily: caFont.data.fontName,
              fontId: caFont.value
            }));
          }

          else if (isNameDecorationArea(item)) {
            let defaultNameProps = Object.assign({}, baseCfg, {
              fontFamily: nameFont.data.fontName,
              fontId: nameFont.value          
            });
            if ( item.Figure.metadata.get('daFontSize') )
              Object.assign(defaultNameProps, {
                fontSize: Units.toDocument(parseFloat(item.Figure.metadata.get('daFontSize')))
              })
            designElement = defaultNameDesignElement(defaultNameProps);
          }

          else if (isBackNumberDecorationArea(item)) {
            let defaultNumberProps = Object.assign({}, baseCfg, {
              fontFamily: numberFont.data.fontName,
              fontId: numberFont.value          
            });
            if ( item.Figure.metadata.get('daFontSize') )
              Object.assign(defaultNumberProps, {
                fontSize: Units.toDocument(parseFloat(item.Figure.metadata.get('daFontSize')))
              })
            designElement = defaultBackNumberDesignElement(defaultNumberProps);
          }

          else if (isSleeveNumberDecorationArea(item)) {
            let defaultNumberProps = Object.assign({}, baseCfg, {
              fontFamily: numberFont.data.fontName,
              fontId: numberFont.value          
            });
            if ( item.Figure.metadata.get('daFontSize') )
              Object.assign(defaultNumberProps, {
                fontSize: Units.toDocument(parseFloat(item.Figure.metadata.get('daFontSize')))
              })
            designElement = defaultSleeveNumberDesignElement(defaultNumberProps);
          }

          else if (isRibNumberDecorationArea(item)) {
            let defaultNumberProps = Object.assign({}, baseCfg, {
              fontFamily: numberFont.data.fontName,
              fontId: numberFont.value,
              hidden: !typeInited      
            });
            if ( item.Figure.metadata.get('daFontSize') )
              Object.assign(defaultNumberProps, {
                fontSize: Units.toDocument(parseFloat(item.Figure.metadata.get('daFontSize')))
              })
            designElement = defaultRibNumberDesignElement(defaultNumberProps);
          }

          if (designElement) {
            applyConfig(designElement, allFonts);
            // @ts-ignore
            if ( designElement.fontSize > item.Figure.frame_().height )
            // @ts-ignore
              designElement.fontSize = item.Figure.frame_().height ;
            fn$.push(updateTextDesignElement$(designElement));
          }
        }
      }
    }
  });

  return Cx.whenAll(fn$).then(() => {
    return canvas.commit$();
  });
}