import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export interface setActiveProductItemNumberOptions {
  activeItemNumber: string;
}

export const setActiveProductItemNumberUseCase: UseCase = {
  type: 'SET_ACTIVE_PRODUCT_ITEM_NUMBER',

  run: ( options: setActiveProductItemNumberOptions ) => {
    return Cx.resolve(options.activeItemNumber)
  }
};

/**
 * @category Use Case
 */
export function setActiveProductItemNumber( options: setActiveProductItemNumberOptions ) {
  app.runUseCase( setActiveProductItemNumberUseCase, options );
}
