import { isDesignReady } from '../domain/Design/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { setCanvasCameraToBack, setCanvasCameraToDefault } from './actions/canvas';
import { UseCase } from './usecase/UseCase';

interface SetCanvasCameraPositionUseCaseOptions {
  canvasId: string;
  positionToBack?: boolean;
  positionToFront?: boolean;
}

export const setCanvasCameraPositionUseCase:UseCase = {
  type: 'SET_CANVAS_CAMERA_POSITION',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: (options: SetCanvasCameraPositionUseCaseOptions) => {
    const canvasId = options.canvasId;

    if (options.positionToBack) {
      setCanvasCameraToBack(canvasId);
    }

    else if (options.positionToFront) {
      setCanvasCameraToDefault(canvasId);
    }

    return Cx.resolve(options);
  }
};

export function canSetCanvasCameraPosition(state): boolean {
  return setCanvasCameraPositionUseCase.checkConditions( state ).isAllowed;
}

export function setCanvasCameraPosition(options: SetCanvasCameraPositionUseCaseOptions){
  app.runUseCase(setCanvasCameraPositionUseCase, options);
}
