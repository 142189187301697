import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../app/Application';
import { DecorationArea } from '../../../../app/domain/Design/DecorationArea';
import { Font } from '../../../../app/domain/Font/Font';
import { CustomizeTab } from '../../../../app/domain/Gui/CustomizeTab';
import { initDecorationAreas, setFillTarget, showColorZonesTab, showGraphicsTab, showNameNumberTab } from '../../../../app/initApp';
import { setCanvasCameraToDefault } from '../../../../app/usecases/actions/canvas'
import global from '../../../services/global';
import ColorZoneList from '../../design/ColorZoneList';
import NameNumber from '../../design/namenumber/NameNumber';
import Graphics from './graphics/Graphics';

import './CustomizeTabs.css';

function Tab (props) {
  let cls = 'tab flex-row';
  const onClick = props.disabled ? () => {} : props.onClick;

  if (props.active) {
    cls += ' active partner-background-color2';
  }

  if (props.activeArrow) {
    cls += ' active-arrow';
  }

  if (props.disabled) {
    cls += ' disabled';
  }

  return <div className={cls} onClick={onClick}>
    <div className="label">{props.label}</div>
    <div className={'arrow' + (props.activeArrow ? ' partner-background-color2' : '')}>
      <div className={'arrow-inner' + (props.active ? ' partner-background-color2' : '')} />
    </div>
  </div>
}

interface Props {
  activeTabClassName: string;
  activeTabIsColorZones?: boolean;
  activeTabIsGraphics?: boolean;
  activeTabIsNameNumber?: boolean;
  decorationAreas?: DecorationArea[];
  defaultCAFont?: Font;
  defaultNameFont?: Font;
  defaultNumberFont?: Font;
  allFonts?: any;
  hidden: boolean;
  isLoadedOrder: boolean;
  onNameNumberTabClick?: Function;
}

const CustomizeTabs = (props: Props) => {
  const activeTabIsColorZones = props.activeTabIsColorZones;
  const activeTabIsGraphics = props.activeTabIsGraphics;
  const activeTabIsNameNumber = props.activeTabIsNameNumber;
  const className = 'customize-tabs flex-column column-two ' + props.activeTabClassName + (props.hidden ? ' hidden' : '');
  const canvasId = global.__MAIN_CANVAS_ID__;
  const nameAndNumberLabel = "Name " + "\u0026" + " Number";

  return <div className={className}>
    <div className="tab-bar flex-row partner-border-color2">
      <Tab
        active={activeTabIsColorZones}
        activeArrow={activeTabIsNameNumber}
        label="Color Zones"
        onClick={() => {
          showColorZonesTab();

          setFillTarget({
            fillTarget: undefined
          });

          setCanvasCameraToDefault(canvasId);
        }} />
      <Tab
        active={activeTabIsNameNumber}
        activeArrow={activeTabIsGraphics}
        label={nameAndNumberLabel}
        onClick={() => {
          showNameNumberTab();
          props.onNameNumberTabClick();
        }} />
      <Tab
        active={activeTabIsGraphics}
        activeArrow={false}
        label="Graphics"
        onClick={() => {
          showGraphicsTab();

          setFillTarget({
            fillTarget: undefined
          });

          setCanvasCameraToDefault(canvasId);
        }} />
    </div>
    <div className="tab-body flex-column flex-one">
      <ColorZoneList target={canvasId} />
      <NameNumber target={canvasId} ></NameNumber>
      <Graphics canvasId={canvasId} />
    </div>
  </div>;
}

const mapStateToProps = (state: RootState) => {
  const activeTab = state.GUI.activeCustomizeTab;
  let activeTabClassName;

  switch (activeTab) {
    case CustomizeTab.NAME_NUMBER: activeTabClassName = 'active-tab-2'; break;
    case CustomizeTab.GRAPHICS: activeTabClassName = 'active-tab-3'; break;
    default: activeTabClassName = 'active-tab-1';
  }

  return {
    activeTabClassName,
    activeTabIsColorZones: activeTab === CustomizeTab.COLORZONES,
    activeTabIsGraphics: activeTab === CustomizeTab.GRAPHICS,
    activeTabIsNameNumber: activeTab === CustomizeTab.NAME_NUMBER,
    decorationAreas: state.CanvasFullState.decorationAreas,
    defaultCAFont: state.Fonts.defaultCAFont,
    defaultNameFont: state.Fonts.defaultNameFont,
    defaultNumberFont: state.Fonts.defaultNumberFont,
    isLoadedOrder: state.Order.isLoadedOrder,
    allFonts: state.Fonts.allFonts
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, ownProps, stateProps, {
    onNameNumberTabClick: () => {
      initDecorationAreas({
        canvasId: global.__MAIN_CANVAS_ID__,
        decorationAreas: stateProps.decorationAreas,
        defaultCAFont: stateProps.defaultCAFont,
        defaultNameFont: stateProps.defaultNameFont,
        defaultNumberFont: stateProps.defaultNumberFont,
        allFonts: stateProps.allFonts,        
        isLoadedOrder: stateProps.isLoadedOrder
      });

      setFillTarget({
        fillTarget: undefined
      });
    }
  });
}

export default connect(mapStateToProps, null, mergeProps)(CustomizeTabs);
