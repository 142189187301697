import { isDesignReady } from '../domain/Design/Conditions';
import { FillTarget } from '../domain/Design/FillTarget';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { setFillTarget$ } from './actions/setFillTarget$';
import { UseCase } from './usecase/UseCase';

export interface SetFillTargetUseCaseOptions {
  fillTarget?: FillTarget;
}

export const setFillTargetUseCase: UseCase = {
  type: 'SET_FILL_TARGET',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: ( options: SetFillTargetUseCaseOptions ) => {
    if (options.fillTarget) {
      return setFillTarget$(options.fillTarget)
      .then((data) => {
        return Cx.resolve({
          fillTarget: data.fillTarget,
          fillTargetBrush: data.fillTargetBrush,
          fillTargetBrushIsPattern: data.fillTargetBrushIsPattern
        })
      });
    }

    else {
      return Cx.resolve({
        fillTarget: undefined,
        fillTargetBrush: undefined,
        fillTargetBrushIsPattern: undefined
      });
    }
  }
};

/**
 * @category Can
 */
export function canSetFillTarget( state ) {
  return setFillTargetUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setFillTarget( options: SetFillTargetUseCaseOptions ) {
  app.runUseCase( setFillTargetUseCase, options );
}
