import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { CoachContactInfoProps } from '../../gui/components/steps/login/CoachContactInfo'

export const showContactInfoUseCase:UseCase = {
  type: 'SHOW_CONTACT_INFO_FORM',

  run: (options:CoachContactInfoProps) => {
    return Cx.resolve(options);
  }
};

export function showContactInfo(options: CoachContactInfoProps) {
  app.runUseCase(showContactInfoUseCase, options);
}
