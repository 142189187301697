import React, { useState, useRef, useEffect } from "react";
import { setJerseySchemmeColorListIndex } from "../../../../app/usecases/setJerseySchemmeColorListIndex";
import { Container } from "./ColorSelectorStyles";
import useOutsideClick from "./useOutsideClick";
import { uniqueId } from "lodash";

type Option = {
    value: string;
    label: string;
    color?: Cx.Color;
};

type Props = {
    options: Option[];
    value?: string;
    name: string;
    selectedIndex?: number;
    selectedColor: any;
};

const ColorSelector = ({
    options,
    value,
    name,
    selectedIndex,
    selectedColor
}: Props) => {
    const [isOpen, setOpen] = useState(false);
    const [selected, setSelected] = useState(value);
    const selectRef = useRef(null);

    useOutsideClick(selectRef, () => {
        setOpen(false);
    });

    function setSelectedColorFill(item) {
        setJerseySchemmeColorListIndex({ jerseySchemmeColorListIndex: selectedIndex });
        selectedColor(item, selectedIndex);
        setSelected(item.value);
    };

    useEffect(() => {
        const element = document.getElementById(name);
        if (element) {
            (element as HTMLInputElement).value = selected as string;
        }
    }, [selected]);

    return (
        <Container isOpen={isOpen}>
            <select id={name} name={name} className="html-select">
                {options.map((item) => (
                    <option
                        key={uniqueId()}
                        value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>
            <div
                className="custom-select-wrapper"
                ref={selectRef}
                onClick={() => {
                    setOpen((prev) => !prev);
                }}>
                <div className={`custom-select ${isOpen && "open"}`}>
                    <div className="custom-select__trigger">
                        <span
                            className={`color ${selected === "#FFFFFFFF" && "white-border"}`}
                            style={{ backgroundColor: selected }}></span>
                        <span>
                            {options.find((item) => item.value === selected)?.label || ""}
                        </span>
                        <div className="arrow"></div>
                    </div>
                    <div className="custom-options">
                        {options.map((item) => (
                            <div
                                className="option-container"
                                key={uniqueId()}
                                onClick={() => {
                                    setSelectedColorFill(item);
                                }}>
                                <span
                                    className={`color ${item.value === "#FFFFFFFF" && "white-border"}`}
                                    style={{ backgroundColor: item.value }}></span>
                                <span
                                    className={`custom-option ${selected === item.value && "selected"}`}
                                    data-value={item.value}>
                                    {item.label}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default ColorSelector;
