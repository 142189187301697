import { fromPairs } from 'lodash';
import { DesignElement } from '../../domain/Design/DesignElement';

export function checkCanScale(figure, target, factor: number)
{
  figure = Cx.cloneDeep(figure);
  let frame = figure.frame_();
  const targetFrame = target.frame_();

  figure.transform( Cx.Matrix.scale(factor) );

  frame = figure.frame_();

  const figure_width = +parseFloat(frame.bounds.width).toFixed(4);
  const target_width = +parseFloat(targetFrame.bounds.width).toFixed(4);  
  const figure_height = +parseFloat(frame.bounds.height).toFixed(4);
  const target_height = +parseFloat(targetFrame.bounds.height).toFixed(4);
  
  const canTransform = figure_width <= target_width && figure_height <= target_height ;

  return canTransform ;
}

export function doScale$(figure, frame, factor: number)
{
  let toBounds = figure.bounds_().center;
  let m = Cx.Matrix.scale(factor);      
  figure.transform( m );
  let bounds = figure.bounds_() ;
  let targetPoint = toBounds.minus(bounds.center) ;
  figure.translate(targetPoint); 

  let figureBounds = figure.bounds_();
  const frameBounds = frame.bounds_();

  if (figureBounds.hy > frameBounds.hy )
    figure.translate(0, frameBounds.hy - figureBounds.hy)
  else if (figureBounds.ly < frameBounds.ly )
    figure.translate(0, (figureBounds.ly - frameBounds.ly) * -1)

  if (figureBounds.hx > frameBounds.hx )
    figure.translate(frameBounds.hx - figureBounds.hx, 0)
  else if (figureBounds.lx < frameBounds.lx )
    figure.translate(frameBounds.lx - figureBounds.lx, 0)    

  figureBounds = figure.bounds_();

  figure.metadata.set('centerDeltaX', (frameBounds.mx - figureBounds.mx).toFixed(8) );
  figure.metadata.set('centerDeltaY', (frameBounds.my - figureBounds.my).toFixed(8) );  

  return Cx.resolve();
}

export function scale$ (designElement: DesignElement, canvas: any, factor:number ) {
  
  return doScale$(designElement, canvas, factor).then(function(){
    return canvas.preview$();
  })  
}