import global from '../../../gui/services/global';

export interface Font {
  data: FontData;
  value: string;
};

export interface FontData {
  fontName: string;
  lineJoin: string;
  thumbHeight: number;
  thumbWidth: number;
  type: FontType;
  lowerCaseRaplace: boolean;
  playerPerfect: boolean;
  scaleSize: number;
  isScript: boolean;
  upperCaseOnly: boolean;
  hidden: boolean;
};

export enum FontType {
  NAMES = 'Names',
  NUMBERS = 'Numbers'
};

export function filterForNameFonts (fonts: Font[]): Font[] {
  return fonts.filter( (f) => { return f.data.type === FontType.NAMES });
}

export function filterForNumberFonts (fonts: Font[]): Font[] {
  return fonts.filter( (f) => { return f.data.type === FontType.NUMBERS });
}

export function findFontByName (name: string = '', fonts: any, type?: string) {
  name = name.toLowerCase();
  name = name.replace(/\s|[0-9]/g, '');

  return fonts.find((item) => {
    let fontName = item.data.fontName.toLowerCase().replace(/\s|[0-9]/g, '');
    if (type)
      return (fontName === name && item.data.type === type)
    else
      return fontName === name;
  });
}

export function parseFonts (fontList: any, fontConfig: any): Font[] {
  const fonts = fontList.filter( (font) => {
    return isNameOrNumberFont(font)
  });

  let parsedList = fonts.map((font) => {
    const nameTypeFont = font.Tags.find((t) => {
      return ( t.Name.toUpperCase().indexOf('/NAMES') > -1 )
    });

    let fontName = font.Name ;
    let lineJoin = '';

    const conf = fontConfig[font.Id];
    if (conf && conf.display_name )
    {
      fontName = conf.display_name ;
      lineJoin = conf.line_join
    }
    const parsedFont: Font = {
      data: {
        fontName,
        lineJoin,
        thumbHeight: 40,
        thumbWidth: 180,
        isScript: font.IsScript,
        lowerCaseRaplace: conf.lower_case_replace,
        playerPerfect: conf.player_perfect,
        scaleSize: conf.scale_size,
        upperCaseOnly:conf.upper_case_only,
        hidden: conf.hidden, 
        type: nameTypeFont ? FontType.NAMES : FontType.NUMBERS
      },
      value: font.Id
    };

    return parsedFont;
  });

  parsedList = parsedList.map((font) => {
    font.data.thumb = getThumbImage(font, parsedList, font.data.type);
    return font ;
  });

  //return parsedList.filter( (f) => { return !f.hidden });
  return parsedList;
}

function getThumbImage(font, fonts, type) {
  let nameMatchingStyleFont = findFontByName(font.data.fontName, fonts, type);

  const matchingFontModel = {
    Font: { Id: nameMatchingStyleFont.value, Name: nameMatchingStyleFont.data.fontName },
    Height:40,
    Text: type === FontType.NAMES ? font.data.fontName : '123',
    Width: 180
  };

  return Cx.Core.Font.thumbnailUrl(matchingFontModel);
}

function isNameOrNumberFont (f) {
  return f.Tags.find((t) => {
    return (t.Name.toUpperCase().indexOf('/NAMES') > -1 || t.Name.toUpperCase().indexOf('/NUMBER') > -1);
  });
}