import React from 'react';
import { Outline } from '../../../../../app/domain/Design/Outline';
import { TextDesignElement } from '../../../../../app/domain/Design/TextDesignElement';
import uniqueKey from '../../../../util/uniqueKey';
import OutlineEditor from './OutlineEditor';

interface Props {
  onFillClick: any;
  onPlusMinusClick: any;
  onRemoveClick: any;
  selectedOutlineIndex: number;
  textDesignElement: TextDesignElement;
};

const Outlines = (props: Props) => {
  if (!props.textDesignElement.outlines) {
    return null;
  }

  const textDesignElement = props.textDesignElement;
  const outlines = textDesignElement.outlines;
  const lastIndex = outlines.length - 1;

  return <React.Fragment>
    {outlines.map((outline: Outline, index: number) => {
      const onRemoveClick = index === lastIndex ? () => props.onRemoveClick(index) : null;

      return <OutlineEditor
        key={uniqueKey()}
        label={'Outline Color ' + (index + 1)}
        onFillClick={() => props.onFillClick(index)}
        onPlusMinusClick={(increment: number) => props.onPlusMinusClick(index, increment)}
        onRemoveClick={onRemoveClick}
        outline={outline}
        selected={index === props.selectedOutlineIndex} />
    })}
  </React.Fragment>

}

export default Outlines;