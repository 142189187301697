import { Units } from '../../../../canvas/helpers/units';
import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import { DecorationArea } from '../../../../app/domain/Design/DecorationArea';
import { FillTarget } from '../../../../app/domain/Design/FillTarget';
import { TextDesignElement, textDesignElementHasFillTarget } from '../../../../app/domain/Design/TextDesignElement';
import { textFillTarget } from '../../../../app/domain/Design/TextFillTarget';
import { findFontByName } from '../../../../app/domain/Font/Font';
import { setDecorationAreaActive, incrementTextHeight, IncrementTextHeightOptions, incrementTextSpacing, IncrementTextSpacingOptions, setEffect, SetEffectOptions, setFillTarget, setFont, SetFontOptions, setText, SetTextOptions } from '../../../../app/initApp';
import Field from '../../misc/field/Field';
import Input from '../../misc/field/Input';
import PlusMinusField from '../../misc/field/PlusMinusField';
import SelectInput from '../../misc/field/SelectInput';
import ThumbSelectInput from '../../misc/field/ThumbSelectInput';
import FillEditor from './FillEditor';
import OutlineManager from './outline/OutlineManager';

// Styles
import styles from './NameEditorFields.module.css';

interface Props {
  activeFillTarget: FillTarget;
  canvasId: string;
  decorationArea: DecorationArea;
  nameFonts: any;
  allFonts: any;
  hiddenFontsIds: any;
}

const NameEditorFields = (props: Props) => {
  const decorationArea = props.decorationArea;
  const nameFonts = props.nameFonts;
  const textDesignElement = decorationArea.DesignElement as TextDesignElement;
  let fontId = textDesignElement.fontId;
  const activeFillTarget = props.activeFillTarget;
  const fillIsSelected = activeFillTarget && textDesignElementHasFillTarget(textDesignElement, activeFillTarget);

  const effectOptions = [
    { text: 'None', value: 'None' },
    { text: 'Classic Arc', value: 'ClassicArc' },
    { text: 'Vertical Arch', value: 'VerticalArch' }//,
    //{ text: 'Inverted Classic Arc', value: 'InvertedClassicArc' }
  ];

  if (!fontId && textDesignElement.fontFamily) {
    const font = findFontByName(textDesignElement.fontFamily, nameFonts);

    if (font) {
      fontId = font.value;
    }
  }

  return (<React.Fragment>
    <Field className={styles.field}>
      <label>Preview</label>
      <Input
        onChange={(e) => {
          onTextChange(textDesignElement, e.target.value);
          setDecorationAreaActive({ decorationArea: props.decorationArea });
        }}
        placeholder="ANY NAME"
        value={textDesignElement.text}
        type="text"
        name="nm_name" />
    </Field>
    <ThumbSelectInput
      className={styles.field}
      handler={(value, field, option) => {
        onFontchange(textDesignElement, value, option.data.fontName, props.allFonts);
      }}
      label="Font Style"
      options={nameFonts}
      value={fontId}
      hiddenIds={props.hiddenFontsIds} />
    <PlusMinusField
      className={styles.field}
      label="Text Height"
      onMinusClick={() => onTextHeightChange(textDesignElement, -0.25, props.allFonts)}
      onPlusClick={() => onTextHeightChange(textDesignElement, 0.25, props.allFonts)}
      unit='"'
      value={Units.toUser(textDesignElement.fontSize)} />
    <PlusMinusField
      className={styles.field}
      label="Spacing"
      onMinusClick={() => {
        onSpacingChange(textDesignElement, -0.25);
      }}
      onPlusClick={() => {
        onSpacingChange(textDesignElement, 0.25);
      }}
      value={textDesignElement.spacing} />
    <SelectInput
      className={styles.field}
      handler={(value: string) => {
        onEffectChange(textDesignElement, value);
      }}
      label="Text Effect"
      options={effectOptions}
      value={textDesignElement.effect} />
    <FillEditor
      brush={textDesignElement.brush}
      label="Fill Color"
      onClick={() => {
        setFillTarget({
          fillTarget: textFillTarget(textDesignElement)
        });
      }}
      selected={fillIsSelected} />
    <OutlineManager
      activeFillTarget={activeFillTarget}
      allFonts={props.allFonts}
      textDesignElement={textDesignElement} />
  </React.Fragment>);
}

function onEffectChange(designElement: TextDesignElement, effect: string) {
  setEffect({
    designElement,
    effect
  } as SetEffectOptions);
}

function onFontchange(designElement: TextDesignElement, fontId: string, fontName: string, fontConfigs: any) {
  setFont({
    designElement,
    fontId,
    fontName,
    fontConfigs
  } as SetFontOptions);
}

function onSpacingChange(designElement: TextDesignElement, increment: number) {
  incrementTextSpacing({
    designElement,
    increment
  } as IncrementTextSpacingOptions);
}

function onTextChange(designElement: TextDesignElement, text: string) {
  setText({
    designElement,
    text
  } as SetTextOptions);
}

function onTextHeightChange(designElement: TextDesignElement, increment: number, fontConfigs: any) {
  incrementTextHeight({
    designElement,
    increment,
    fontConfigs
  } as IncrementTextHeightOptions);
}

export default NameEditorFields;
