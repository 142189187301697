import styled, { css } from "styled-components";

export const baseInput = `
  padding: 5px;
  outline: none;
`;

type Props = {
    isOpen: boolean;
    error: boolean;
};

export const Container = styled.div<Props>`
  .html-select {
    display: none;
  }
  
  .custom-select-wrapper {
    position: relative;
    user-select: none;
  }

  .custom-select {
    ${baseInput}
    padding-right: 8px;
    position: relative;
    cursor: pointer;
    border: 1px solid #ccc;
  }
  
  .custom-select__trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    text-transform: uppercase;
  }

  .custom-options {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;

    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 2;
    padding: 8px;
    max-height: 200px;
    overflow-y: auto;
  }

  .custom-select.open .custom-options {
    margin-top: 5px;
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    background: #fff;
  }

  .custom-option {
    position: relative;
    display: block;
    padding: 4px 8px;
    cursor: pointer;
    text-transform: uppercase;
  }

  .option-container {
    border: solid white 0.1px;
    display: flex;
    flex-direction: row;
    padding: 5px 0 5px 0;
  }

  .option-container:hover {
    background-color: #fff6da;
    .custom-option {
      cursor: pointer;
    }
  }

  .color {
    height: 25px;
    width: 50px;
    display: inline-block;
  }

  .white-border {
    border: 0.5px solid;
  }

  .arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #919191;
    height: 0;
    outline: none;
    pointer-events: none;
    right: 7px;
    top: calc(50% - 2px);
    width: 0;
  }

  @media only screen and (max-width: 750px) {
    .custom-select {
      width: unset;
    }
  }
`;
