import { CanvasAreaFactory } from '../../canvas/CanvasArea'
import { canRedo as canRedoCondition, isDesignReady } from '../domain/Design/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';

interface redoUseCaseOptions {
  canvasId:string
}

export const redoUseCase:UseCase = {
  type: 'REDO',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady, canRedoCondition]);
  },

  run: (options: redoUseCaseOptions) => {
    const canvas = CanvasAreaFactory.getCanvasById(options.canvasId);
    canvas.redo();
    return Cx.resolve();
  }
};

export function canRedo(state): boolean {
  return redoUseCase.checkConditions( state ).isAllowed;
}

export function redo(options: redoUseCaseOptions){
  app.runUseCase(redoUseCase, options);
}
