import { isConfigReady } from '../domain/Config/Conditions';
import { hasActiveProduct } from '../domain/Order/Conditions';
import { Product } from '../domain/Product/Product';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { getInitialFillTarget } from './actions/getInitialFillTarget';
import { loadCanvasDocument$ } from './actions/loadCanvasDocument$';
import { UseCase } from './usecase/UseCase';
import { CanvasHelper } from '../../canvas/helpers/canvas';

interface EditProductUseCaseOptions {
  canvasId: string;
  product: Product;
}

export const editProductUseCase: UseCase = {
  type: 'EDIT_PRODUCT',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isConfigReady, hasActiveProduct]);
  },

  run: ( options: EditProductUseCaseOptions ) => {
    const canvasId = options.canvasId;
    const fillTarget = getInitialFillTarget(canvasId);
    const product = options.product;

    CanvasHelper.clear3DView(canvasId);

    return loadCanvasDocument$(canvasId, product.SavedDocId)
    .then(() => {
      return Cx.resolve({
        canvasId,
        fillTarget,
        product
      });
    });
  }
};

/**
 * @category Can
 */
export function canEditProduct( state ) {
  return editProductUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function editProduct( options: EditProductUseCaseOptions ) {
  app.runUseCase( editProductUseCase, options );
}
