import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const showOrderStateSavedConfirmationUseCase:UseCase = {
  type: 'SHOW_ORDER_STATE_SAVED_CONFIRMATION',

  run: () => {
    return Cx.resolve();
  }
};

export function showOrderStateSavedConfirmation() {
  app.runUseCase(showOrderStateSavedConfirmationUseCase);
}
