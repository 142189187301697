export class CharReplacement {
  static lowerCasing = [
      {
        "Source": "*",
        "Target": "a"
      },
      {
        "Source": ">",
        "Target": "c"
      },
      {
        "Source": "^",
        "Target": "e"
      },
      {
        "Source": "|",
        "Target": "i"
      },
      {
        "Source": "\\",
        "Target": "o"
      },
      {
        "Source": "<",
        "Target": "u"
      }
  ];

  static scriptLigatures =
  { 
    "Scriptjet by Stahls fg" : [{
        "left": "o",
        "right": "r",
        "replacement": "="
      },
      {
        "left": "v",
        "right": "r",
        "replacement": "="
      },
      {
        "left": "o",
        "right": "s",
        "replacement": "+"
      },
      {
        "left": "v",
        "right": "s",
        "replacement": "+"
      },
      {
        "left": "o",
        "right": "x",
        "replacement": "_"
      },
      {
        "left": "v",
        "right": "x",
        "replacement": "_"
    }] 
  };

  public static inverted_lower_case_replace(s)
  {
    var o = '';
    for ( var i = 0 ; i < s.length; i ++ )
    {
      var s_char = CharReplacement.lowerCasing.find(function(l) { return ( l.Source === s[i] )});
      if ( s_char !== undefined )
        o += s_char.Target ;
      else
        o += s[i];
    }
    return o;
  }

  public static  is_replacement_allowed(s) //check for 2nd or 3rd char low and consecutive upper, like McDonalds or MrsSusy
  {
    return ( ( s.length >= 3 ) && ( ( s[1] === s[1].toLowerCase() ) && ( s[2] === s[2].toUpperCase() ) )
      || ( ( s.length >= 4 ) && ( s[2] === s[2].toLowerCase() ) && ( s[3] === s[3].toUpperCase() ) ) ) ;
  }

  public static lower_case_replace(s)
  {
    var o = '';
    for ( let i = 0 ; i < s.length; i ++ )
    {
      var s_char = CharReplacement.lowerCasing.find(function(l) { return ( l.Target === s[i] )});
      if ( ( s_char !== undefined ) && ( i == 1 || i == 2 ) && CharReplacement.is_replacement_allowed(s) )
        o += s_char.Source ;
      else
        o += s[i];
    }
    return o;
  }

  public static ligatures_replace(name, s)
  {
    var o = '';
    var r = CharReplacement.scriptLigatures[name];

    if ( r !== undefined )
    {
      o = s[0]; //first character is unmutable
      for ( var i = 0 ; i < s.length-1; i++ )
      {
        var s_char = r.find( function(l) { return ( l.left === s[i] && l.right === s[i+1] )});
        if ( s_char !== undefined )
          o += s_char.replacement ;
        else
          o += s[i+1] ;
      }
    }
    else
      o = s ;

    return o;
  }
}