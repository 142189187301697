import { SavedOrder, savedOrdersFromActionResult } from '../domain/Order/SavedOrder';
import { coachHasSessionUseCase } from '../usecases/coachHasSession';
import { loadSavedOrderStatesUseCase } from '../usecases/loadSavedOrderStates';
import { saveOrderUseCase } from '../usecases/saveOrder';
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { reportMessage } from '../../gui/util/rollbar';

export interface SavedOrdersReducerState {
  orders: SavedOrder[];
  ordersLoaded: boolean;
  ordersLoading: boolean;
}

const initState: SavedOrdersReducerState = {
  orders: [],
  ordersLoaded: false,
  ordersLoading: false
};

export function SavedOrdersReducer (state: SavedOrdersReducerState = initState, actionResult: UseCaseResult) {

  switch (actionResult.type) {

    case coachHasSessionUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)    
        return Object.assign({}, initState);
      }

      return state;

    case saveOrderUseCase.type:
    case loadSavedOrderStatesUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
        return Object.assign({}, initState);
      }

      if (actionResult.start()) {
        return Object.assign({}, initState, {
          ordersLoading: true
        });
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          orders: savedOrdersFromActionResult(actionResult.data.savedOrders),
          ordersLoaded: true,
          ordersLoading: false
        });
      }

      return state;

  }

  return state;

}
