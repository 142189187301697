import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const showAddPhonePromptUseCase: UseCase = {
  type: 'SHOW_ADD_PHONE_PROMPT',

  run: () => {
    return Cx.resolve();
  }
};

export function showAddPhonePrompt() {
  app.runUseCase(showAddPhonePromptUseCase);
}
