import { FillTarget } from './FillTarget';
import { TextDesignElement } from './TextDesignElement';

export interface TextFillTarget extends FillTarget {
  canvasId: string;
  designArea: string;
  textDesignElement: TextDesignElement;
}

// User-Defined Type Guard
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
export function fillTargetIsText (target: FillTarget): boolean {
  const t = target as TextFillTarget;

  return t && t.type && t.type === 'TEXT_DESIGN_ELEMENT' &&
    t.textDesignElement !== undefined ? true : false;
}

export function textFillTarget (textDesignElement: TextDesignElement): TextFillTarget {
  return {
    canvasId: textDesignElement.canvasId,
    designArea: textDesignElement.decorationAreaId,
    textDesignElement,
    type: 'TEXT_DESIGN_ELEMENT'
  };
}

export const cloneTextFillTarget = (props: TextFillTarget): TextFillTarget => {
  return {
    canvasId: props.canvasId,
    designArea: props.designArea,
    textDesignElement: {...props.textDesignElement},
    type: props.type
  };
}