
import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export interface SetJerseySchemmeColorListIndexOptions {
  jerseySchemmeColorListIndex: Number
}

export const setJerseySchemmeColorListIndexUseCase: UseCase = {
  type: 'SET_JERSEY_SCHEMME_COLOR_LIST_INDEX',


  run: ( options: SetJerseySchemmeColorListIndexOptions ) => {

    // update on figure
    return Cx.resolve({ jerseySchemmeColorListIndex: options.jerseySchemmeColorListIndex });
  }
};

/**
 * @category Use Case
 */
export function setJerseySchemmeColorListIndex( options: SetJerseySchemmeColorListIndexOptions ) {
  app.runUseCase( setJerseySchemmeColorListIndexUseCase, options );
}
