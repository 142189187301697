import { requestAccessByPhone, verifyPhoneCode, requestAccessByEmail, verifyEmailHash } from '../../../backendApi/authentication';
import {
  COACH_HAS_SESSION_URL,
  COACH_LOGOUT_URL,
  getGoogleSignInUrl$,
} from '../../../backendApi/backendApi';
import { backendClient } from '../../../gui/services/backend'
import { appendHashToUrl } from '../../utility/appendHashToUrl';
import { cleanPhoneNumber } from '../../utility/cleanPhoneNumber';
import { coachContactInfo } from '../createCoachContactInfo';

// TODO: Organize.  Go through BackendAPI interface.  Separate files.

export function createCoach$(email: string, name: string, phone: string, cadworxid: string) {
  // Numbers only
  // phone = phone.replace(/\D/g,'');

  return backendClient.post<any>({
    endPoint: 'coaches', method: 'create'
    , payload: { email, name, phone, cadworxSessionId: cadworxid } // TODO: Is session id here a super user id?
  });
}

export function createCoachContactInfo$(coachesKey: string,
                                        contactInfo: coachContactInfo) {
  // Numbers only
  // phone = phone.replace(/\D/g,'');

  return backendClient.post<any>({
    endPoint: 'coaches', method: 'createContactInfo'
    , payload: { coachesKey, ...contactInfo }
  });
}

export async function requestForAccessByEmail$(email: string, returnUrl: string, partnerInfo?: any) {
  return await requestAccessByEmail({ email, returnUrl, partnerInfo });
}

export async function requestForAccessByGoogle$(returnUrl: string, hash: string) {
  return getGoogleSignInUrl$(returnUrl, hash)
    .then((resp) => {
      window.open(resp.loginUrl.url, '_blank');
    });
}

export async function requestForAccessByPhone$(phone: string) {
  phone = cleanPhoneNumber(phone);
  return await requestAccessByPhone({ phone });
}

export async function verifyPhoneCode$(code: string, phone: string) {
  return await verifyPhoneCode({ code, phone });
}

export async function verifyEmailHash$(hash: string, email: string) {
  return await verifyEmailHash({ hash, email });
}

export async function logOut$(sessionToken: string) {
  const payload = {
    sessionToken
  };

  const logoutResult = await fetch(COACH_LOGOUT_URL, {
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });

  if (logoutResult.ok) {
    const jsonResp = await logoutResult.json();
    return jsonResp;
  }

  return Promise.reject('Problem occurred during logout.');
}

export async function coachHasSession$(sessionToken: string) {
  const payload = {
    sessionToken
  };

  const hasSessionResp = await fetch(COACH_HAS_SESSION_URL, {
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });

  if (hasSessionResp.ok) {
    const jsonResp = await hasSessionResp.json();
    return jsonResp;
  }

  return Promise.reject('Problem occurred when requesting session.');
}
