import React from 'react';
import { RosterItem } from '../../../../../app/domain/Roster/RosterItem';

interface Props {
  columnCfg: any,
  onChange: Function,
  onClick?: any,
  onFocus: any,
  rosterItem: RosterItem,
}

const DisplayColumn = (props: Props) => {
  const columnCfg = props.columnCfg;
  const item = props.rosterItem;
  const errorMessage = item.errorMessage || {};

  return <td
  className={columnCfg.id + `${errorMessage[columnCfg.id.toLowerCase()]?.length > 0 ? " border-item invalid row" : "row"}`}
    onClick={props.onClick} style={{ cursor: 'pointer' }}>
    <div
      className="display-input">
      {item[columnCfg.field]}
    </div>
  </td>
}

export default DisplayColumn;