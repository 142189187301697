import { getPatternBrushFromDocId$ } from "../../app/usecases/actions/pattern"
import { GetSpotColorReplacementMap } from "../../app/domain/Pattern/colorZoneHelper"

export enum BrushFactoryType
{
  SOLID_COLOR = 1,
  PATTERN = 2
}

export interface BrushFactoryOptions
{
  type : BrushFactoryType, 
  docId?: string, 
  color?: Cx.Color,
  target?: any,
  map?: any
}

export async function createBrush$(o: BrushFactoryOptions) 
{
  switch(o.type)
  {
    case BrushFactoryType.SOLID_COLOR:
    {
      return Cx.resolve({ brush: new Cx.Brush({ color: o.color }), zones: null });
    }
    case BrushFactoryType.PATTERN:
    {
      return getPatternBrushFromDocId$(o.docId)
      .then(function(r){
        if(r.brush.colorMap && o.target) //replacement is doable
        {
          const colorReplacementMap = GetSpotColorReplacementMap( o.target, r.brush.colorMap.getColors(), o.map );
          r.brush._colorMap = colorReplacementMap ; //this is hack, there's no interface for doing this replacement properly
        }
        return Cx.resolve(r);
      });
    }
  }
}