import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../app/Application';
import { DecorationArea, isCADecorationArea, isNameDecorationArea, isNumberDecorationArea, sortDecorationAreas, uniqueDecorationAreasByType } from '../../../../app/domain/Design/DecorationArea';
import { FillTarget } from '../../../../app/domain/Design/FillTarget';
import CAEditor from './CAEditor';
import NameEditor from './NameEditor';

import styles from './NameNumber.module.css';
import NumberEditor from './NumberEditor';

interface Props {
  activeFillTarget: FillTarget;
  className?: string;
  decorationAreas?: DecorationArea[];
  target: string;
  numberFonts?: any[];
  nameFonts?: any[];
  allFonts?: any;
  hiddenFontsIds?: any;
}

const NameNumber = (props: Props) => {

  const canvasId = props.target;
  let className = styles.nameNumber;

  if (props.className) {
    className += ' ' + props.className;
  }

  return (
    <div className={className}>
      {props.decorationAreas.map((item, index) => {
        const key = item.Id + ' ' + index;

        if (item.Active) {

          if (isNameDecorationArea(item)) {
            return <NameEditor
              canvasId={canvasId}
              decorationArea={item}
              key={key}
              nameFonts={props.nameFonts}
              allFonts={props.allFonts}
              activeFillTarget={props.activeFillTarget} 
              hiddenFontsIds={props.hiddenFontsIds} />
          }

          else if (isNumberDecorationArea(item)) {
            return <NumberEditor
              canvasId={canvasId}
              decorationArea={item}              
              key={key}
              numberFonts={props.numberFonts}
              allFonts={props.allFonts}
              activeFillTarget={props.activeFillTarget} 
              hiddenFontsIds={props.hiddenFontsIds} />
          }

          else if (isCADecorationArea(item)) {
            return <CAEditor
              canvasId={canvasId}
              decorationArea={item}
              key={key}
              nameFonts={props.nameFonts}
              allFonts={props.allFonts}
              hiddenFontsIds={props.hiddenFontsIds}
              activeFillTarget={props.activeFillTarget} />
          }
        }

        return null;
    })}
    </div>
  );

}

const mapStateToProps = (state: RootState) => {
  const decorationAreas = sortDecorationAreas(uniqueDecorationAreasByType(state.CanvasFullState.decorationAreas));

  return {
    activeFillTarget: state.GUI.activeFillTarget,
    decorationAreas,
    allFonts: state.Fonts.allFonts,
    nameFonts: state.Fonts.nameFonts,
    numberFonts: state.Fonts.numberFonts,
    hiddenFontsIds: state.Fonts.hiddenFontsIds
  }
}

export default connect(mapStateToProps, null)(NameNumber);
