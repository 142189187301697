export function getRosterTotalItems(items: any[]) {
  let total = 0;
  items.forEach((item) => {
    if ((item.Size.length > 0) && (item.Quantity.length > 0))
      total += parseInt(item.Quantity);
  });
  return total;
}

export function getTotals(products, stateSalesTax) {
  let count = 0;
  let total = 0;
  let cost = 0;
  let priceOffset = 0;
  let minOrderFee = 0;
  let salesTax = 0;

  products.forEach((p) => {
    count += parseFloat(p.TotalQuantity);
    total += parseFloat(p.TotalPrice);
    cost += parseFloat(p.CostPrice);
    priceOffset += p.TotalSizePriceOffset > 0 ? p.TotalSizePriceOffset : 0;
  });

  minOrderFee = count < 5 ? 10 : 0;
  total += priceOffset;
  total += minOrderFee;
  salesTax = (stateSalesTax !== 0) ? total * stateSalesTax : 0;
  total += salesTax;

  return { count, total, cost, priceOffset, minOrderFee, salesTax };
}