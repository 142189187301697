import { forEach } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { addMultipleRosterItems, hideModal } from '../../../../app/initApp';
import { exportToCsv } from '../../../util/exportToCSV';
import { downloadSizeGuidePDF } from '../../../util/fileDownload';
import Button from '../../misc/Button';

// Styles
import styles from './ImportRosterPopup.module.css';

interface Props {
    itemNumber: string;
    rosterSizeOptions: [];
    columns: any;
    grayGoodName: string;
    activeSport?: any;
}

const ImportRosterPopup = (props: Props) => {
    const fileInputRef = useRef(null);
    const availableSizes = props.rosterSizeOptions;
    const activeSport = props.activeSport;
    const [pdfUrl, setPdfUrl] = useState<string>("");

    //TODO: This values should be better set on Sport section (admin pages) and get them as params ???
    useEffect(() => {
        if (activeSport) {
            switch (activeSport?.Name.toLowerCase()) {
                case "hockey":
                    setPdfUrl(process.env.PUBLIC_URL + "/sizingguide_hockey.pdf");
                    break;
                default:
                    break;
            }
        }
    }, [activeSport]);

    return (
        <>
            <div className={styles.importRoster}>
                <div className={styles.container}>
                    <input
                        ref={fileInputRef}
                        className="add-button partner-color1"
                        accept=".csv,.txt"
                        type="file"
                        hidden
                        onChange={(e) => {
                            ImportRosterChange(e.target.files[0], props);
                        }}
                        name="import_roster"
                    />
                    <Button
                        className={styles.uploadRosterButton + ' partner-color2 partner-border-color2'}
                        label="UPLOAD YOUR ROSTER"
                        onClick={() => fileInputRef.current.click()}
                    />
                    <div className={styles.supportedFiles + ' partner-color3'}>*supported files types: CSV, TXT</div>
                    <div className={styles.downloadTemplateLink + ' partner-color1'}>
                        <a onClick={() => getCSVExampleWithAvailableSizes(availableSizes, props)}>DOWNLOAD ROSTER EXAMPLE TEMPLATE</a>
                    </div>
                </div>
                {availableSizes.length > 0
                    ? <div className={styles.sizesContainer}>
                        <div className={styles.availableSizes + ' partner-color2'}>Available Sizes</div>
                        <div className={styles.tableWrap}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Use these exact keys in your roster file</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {availableSizes.map(function (item, i) {
                                        return <tr key={i}>
                                            <td className="button-column">
                                                {item}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : null
                }
                {pdfUrl.length > 0 && <div className={styles.footer + ' partner-color2 partner-border-color2'}>
                    <span className={styles.text + ' partner-color1'}>UNSURE OF SIZING?</span>
                    <span className={styles.downloadLink} onClick={() => downloadSizeGuidePDF(pdfUrl, `Size_Guide_${activeSport?.Name}`)}>DOWNLOAD SIZE GUIDE</span>
                </div>}
            </div>

        </>
    );
}

const ImportRosterChange = (file, props) => {
    let options = {
        itemNumber: props.itemNumber,
        rosterFileContent: null,
        columns: props.columns
    }
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
        options.rosterFileContent = event.target.result;

        addMultipleRosterItems(options);
    });
    reader.readAsText(file);

    // Hide modal after upload file
    hideModal();
}

const getCSVExampleWithAvailableSizes = (availableSizes: object[], props: Props) => {
    let rows: object[] = [];

    const headers = props.columns.reduce((acc: any, value: any) => {
        acc.push(value.header.toLowerCase());
        return acc;
    }, []);

    if (headers.length) {
        // Add first row with headers
        let headerRow = headers.map(function (x) { return x.toUpperCase(); })
        rows.push(headerRow);

        // Add rows with examples
        let exampleRows = getMockData(availableSizes, headers);
        exampleRows && forEach(exampleRows, function (i) { rows.push(i) });

        // GrayGood name example template
        let name = props.grayGoodName.split(' ').join('_');
        return exportToCsv(name + "-template.csv", rows);
    }
}

function getMockData(availableSizes: object[], headers: any) {
    let arr = [];
    let name = headers.indexOf("name");
    let number = headers.indexOf("number");
    let size = headers.indexOf("size");
    let quantity = headers.indexOf("quantity");
    let ca = headers.indexOf("c/a");

    if (~size) {
        // @ts-ignore
        forEach(availableSizes, function (sizes, i) {
            arr.push([
                ~name && 'PlayerName',
                ~number && (20 + i),
                ~size && sizes,
                ~quantity && (5 + i),
                ~ca && (i === 1 ? 'C' : 'A')
            ]);

            if (i === 3)
                return false;
        });
    } else {
        arr.push([
            ~name && 'PlayerName',
            ~number && 20,
            ~quantity && 5,
            ~ca && 'C'
        ]);
    }

    return arr;
}


export default ImportRosterPopup;
