import { RootState } from '../../app/Application'
import { initPartnerWithColorsUseCase } from '../usecases/init/initPartnerWithColors';
import { putNextOnQueueBatchThumb, putNextOnQueueBatchThumbUseCase,  } from '../usecases/putNextOnQueueBatchThumbUseCase'
import updateJerseyThumbColorMap from '../usecases/updateJerseyThumbColorMap';

export function batchProcessorTrigger (store) {
  
  store.subscribe(() => {
    const state: RootState = store.getState();
    const actionResult = state.App.actionResult;

    switch (actionResult.type) {
      // case grayGoodGetThumb3DUseCase.type:
      //   if(actionResult.success()) {
      //     if ( state.Thumb3D.notProcessed.length == 0) //save to Config
      //     {
      //       updateJerseyThumbColorMap({ map: state.Thumb3D.jerseyThumbColorMap })
      //       return;
      //     }
      //     putNextOnQueueBatchThumb();
      //   }
      // break;
      case putNextOnQueueBatchThumbUseCase.type:
        if (actionResult.success() && state.GUI.isBatchMode ) { 
          //const nextInstances = state.Thumb3D.currentInstances ;
          const colorSchemme = state.Thumb3D.jerseyColors ;

          // nextInstances.forEach((hash)=>{    
          const hash = state.Thumb3D.currentInstances[0]; //simplified to single instance

          let docHash = hash.split(',')

          // grayGoodGetThumb3D({
          //   docId: docHash[0],
          //   colorSchemme: colorSchemme,
          //   hash: hash,
          //   size: 512,
          //   dispatchStartData: { hash: hash}
          // })
        //});
        }
      break;
      case initPartnerWithColorsUseCase.type:
        if(actionResult.success() && state.GUI.isBatchMode )
          putNextOnQueueBatchThumb(); //originally intended to be in engine        } 
          
        break;
    }
  });
}