import { defaultRosterItem, RosterItem } from '../domain/Roster/RosterItem';
import { addRosterItemUseCase } from '../usecases/roster/addRosterItem';
import { addMultipleRosterItemsUseCase } from '../usecases/roster/addMultipleRosterItems';
import { removeRosterItemUseCase } from '../usecases/roster/removeRosterItem';
import { updateRosterItemUseCase } from '../usecases/roster/updateRosterItem';
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { reportMessage } from '../../gui/util/rollbar';
export function RosterItemReducer (state: RosterItem = null, actionResult: UseCaseResult): RosterItem {

  switch (actionResult.type) {

    case addRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return actionResult.data.rosterItem;
      }

      return state;

    case addMultipleRosterItemsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return actionResult.data.rosterItems;
      }

      return state;

    case updateRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success() && actionResult.data.rosterItem.Id === state.Id) {
        return actionResult.data.rosterItem;
      }

      return state;

    case removeRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success() && actionResult.data.rosterItem.Id === state.Id) {
        return defaultRosterItem();
      }

      return state;
  }

  return state;

}