import { RootState } from '../Application';
import { isDesignReady } from '../domain/Design/Conditions';
import { TextDesignElement  } from '../domain/Design/TextDesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { updateTextDesignElement$ } from './actions/updateTextDesignElement$';
import { UseCase } from './usecase/UseCase';

export interface SetEffectOptions {
  designElement: TextDesignElement;
  effect: string;
}

export const setEffectUseCase: UseCase = {
  type: 'SET_EFFECT',

  checkConditions: (state: RootState) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: ( options: SetEffectOptions ) => {
    const designElement = options.designElement;
    designElement.effect = options.effect;

    // update on figure
    return updateTextDesignElement$(designElement).then(() => {
      return Cx.resolve(options);
    });
  }
};

/**
 * @category Can
 */
export function canSetEffect( state: RootState ) {
  return setEffectUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setEffect( options: SetEffectOptions ) {
  app.runUseCase( setEffectUseCase, options );
}
