import { isConfigReady } from '../domain/Config/Conditions';
import { hasActiveGrayGood } from '../domain/Order/Conditions';
import { defaultProduct } from '../domain/Product/Product';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { getInitialFillTarget } from './actions/getInitialFillTarget';
import { loadCanvasDocument$ } from './actions/loadCanvasDocument$';
import { CanvasHelper } from '../../canvas/helpers/canvas'
import { updateDocumentColorScheme$ } from './actions/updateDocumentColorScheme$';
import { getCanvasById, getDesignColorZonesGroups } from './actions/canvas'
import { UseCase } from './usecase/UseCase';

interface AddProductUseCaseOptions {
  canvasId: string;
  designStyle: any;
  itemNumber:string;
  grayGood: any;
  colorScheme: any;
}

export const addProductUseCase: UseCase = {
  type: 'ADD_PRODUCT',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isConfigReady, hasActiveGrayGood]);
  },

  run: ( options: AddProductUseCaseOptions ) => {
    const canvasId = options.canvasId;
    CanvasHelper.clear3DView(canvasId);

    return loadCanvasDocument$(canvasId, options.designStyle.DocId)
    .then((loadResult) => {
      const designStyle = options.designStyle;
      const grayGood = options.grayGood;
      const fillTarget = getInitialFillTarget(canvasId);

      const product = defaultProduct({
        DesignStyle: designStyle,
        GrayGood: grayGood,
        ItemNumber: options.itemNumber,
        Id: designStyle.DocId,
        Price: parseFloat(grayGood.Price),
        Cost: parseFloat(grayGood.Cost),
        CostPrice: parseFloat(grayGood.Cost) 
      });

      const canvas = getCanvasById(canvasId);
      const colorGroups = getDesignColorZonesGroups(canvas.figures);

      return updateDocumentColorScheme$(canvasId, colorGroups, options.colorScheme )
      .then((r) =>{
        return Cx.resolve({
          canvasId,
          docId: loadResult.docId,
          fillTarget,
          product,
          itemNumber: options.itemNumber,
          rosterSizeMap: loadResult.rosterSizeMap,
          rosterSizeOptions: loadResult.rosterSizeOptions
        });
      });
    });
  }
};

/**
 * @category Can
 */
export function canAddProduct( state ) {
  return addProductUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function addProduct( options: AddProductUseCaseOptions ) {
  app.runUseCase( addProductUseCase, options );
}
