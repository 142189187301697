import { cloneFillTarget, FillTarget, getFillTargetBrush } from '../../domain/Design/FillTarget';
import { isPatternBrush } from '../../domain/Pattern/Pattern';

export function setFillTarget$ (target: FillTarget) {
  const fillTarget = cloneFillTarget(target);
  const fillTargetBrush = getFillTargetBrush(fillTarget);
  const fillTargetBrushIsPattern = isPatternBrush(fillTargetBrush);

  return Cx.resolve({
    fillTarget,
    fillTargetBrush,
    fillTargetBrushIsPattern
  });
}