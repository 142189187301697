import { colorZoneFillTarget } from '../domain/Design/ColorZoneFillTarget';
import { canClickOnFillTarget, isDesignReady } from '../domain/Design/Conditions';
import { textDesignElementFromFigure } from '../domain/Design/TextDesignElement';
import { textFillTarget } from '../domain/Design/TextFillTarget';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { getColorZoneFromFigure, getColorZoneName, getInDecorationAreaName } from './actions/canvas';
import { setFillTarget$ } from './actions/setFillTarget$';
import { UseCase } from './usecase/UseCase';

interface SetFillTargetUseCaseOptions {
  canvasId: string;
  figure: Cx.Figure;
}

export const setFillTargetByClickUseCase: UseCase = {
  type: 'SET_FILL_TARGET_BY_CLICK',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady, canClickOnFillTarget]);
  },

  run: ( options: SetFillTargetUseCaseOptions ) => {
    const fillTarget = getFillTarget(options.canvasId, options.figure);

    if (fillTarget) {
      return setFillTarget$(fillTarget)
      .then((data) => {
        return Cx.resolve({
          fillTarget: data.fillTarget,
          fillTargetBrush: data.fillTargetBrush,
          fillTargetBrushIsPattern: data.fillTargetBrushIsPattern
        })
      });
    }

    else {
      return Cx.resolve({
        fillTarget: undefined,
        fillTargetBrush: undefined,
        fillTargetBrushIsPattern: undefined
      });
    }
  }
};

/**
 * @category Can
 */
export function canSetFillTargetByClick( state ) {
  return setFillTargetByClickUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setFillTargetByClick( options: SetFillTargetUseCaseOptions ) {
  app.runUseCase( setFillTargetByClickUseCase, options );
}

function getFillTarget (canvasId: string, figure: Cx.Figure) {
  const areaName = getInDecorationAreaName(figure);

  if (areaName) {
    const textDesignElement = textDesignElementFromFigure(canvasId, figure as Cx.TextFigure);
    return textFillTarget(textDesignElement);
  }

  const colorZoneName = getColorZoneName(figure);

  if (colorZoneName) {
    const colorZone = getColorZoneFromFigure(figure, null, canvasId);
    return colorZoneFillTarget(canvasId, colorZone);
  }

  return undefined;
}
