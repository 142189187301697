
import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export interface SetJerseySchemmeColorSelectedOptions {
  index: Number,
  color: Cx.Color
}

export const setJerseySchemmeColorSelectedUseCase: UseCase = {
  type: 'SET_JERSEY_SCHEMME_COLOR_SELECTED',


  run: ( options: SetJerseySchemmeColorSelectedOptions ) => {

    // update on figure
    return Cx.resolve({ index: options.index, color: options.color });
  }
};

/**
 * @category Use Case
 */
export function setJerseySchemmeColorSelected( options: SetJerseySchemmeColorSelectedOptions ) {
  app.runUseCase( setJerseySchemmeColorSelectedUseCase, options );
}
