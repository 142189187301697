import { UseCase } from './usecase/UseCase';
import { app } from '../initApp';

export interface grayGoodPageNumDecrementUseCaseOptions {
  pageNum: number;
}

export const grayGoodPageNumDecrementUseCase: UseCase = {
  type: 'GRAY_GOOD_PAGE_NUM_DECREMENT',

  run: ( options: grayGoodPageNumDecrementUseCaseOptions ) => {
    return Cx.resolve({
      activeGrayGoodPageNum: options.pageNum - 1
    });
  }
};


export function canGrayGoodPageNumIncrement( state ) {
  return state.activeGrayGoodPageNum - 1 >= 0 
}
/**
 * @category Use Case
 */
export default function grayGoodPageNumDecrement( options: grayGoodPageNumDecrementUseCaseOptions ) {
  app.runUseCase( grayGoodPageNumDecrementUseCase, options );
}
