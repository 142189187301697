import {app} from '../app/initApp';
import { appendHashToUrl } from '../app/utility/appendHashToUrl';
import {RejectionReason, RejectKey} from '../app/utility/RejectionReason';
import {requestAccessByPhone} from './authentication';
import {requestAccessByEmail} from './authentication';
import { isFunction } from 'lodash';

export const getBackendApiUrl = () => {
  const url = process.env.REACT_APP_API_URL || 'ddbackend-staging.azurewebsites.net'; 

  // Make it easier to work around CORS issues locally or when infrastructure is not setup
  if(process.env.REACT_APP_API_HTTP_ONLY)
    return `http://${url}/api`;

  return `https://${url}/api`;
};

interface ColorSchemeColor {
  RGB: string
  SpotColorName: string
}
export const get3DRenderThumbnailUrl = (docId, colors: ColorSchemeColor[]) => {
  const url = Cx.Config.app.render3dThumbUrl;
  let baseUrl = `//${url}`;

  // Make it easier to work around CORS issues locally or when infrastructure is not setup
  if(Cx.Config.app.render3dThumbOverHttp === true)
    baseUrl = `http://${url}`;

  // TODO: spelling, colorScheme (one m)
  return `${baseUrl}?docId=${docId}&colorSchemme=${JSON.stringify(colors)}&type=img&shown=true`;
};

export const get3DFuncThumbUrl= (docId, colors) => {
  return get3DRenderThumbnailUrl(docId, colors);
};

export const get3DRenderThumbUrl= (docId, colors) => {
  return get3DRenderThumbnailUrl(docId, colors);
};

const BACKEND_URL = getBackendApiUrl();

export const apiPath = {
  coachRequestAccessByPhone: '/auth2/coach/phone/requestForAccess',
  coachRequestAccessByEmail: '/auth2/coach/email/requestForAccess',
  verifyPhoneCode: '/auth2/coach/phone/verifyCode',
  verifyEmailCode: '/auth2/coach/email/verifyCode',
}

export const STeamsAPI = {
  coachRequestAccessByPhone: requestAccessByPhone,
  coachRequestAccessByEmail: requestAccessByEmail
}

export const COACH_HAS_SESSION_URL = BACKEND_URL + '/auth2/coach/hasSession';
export const AUTH_CSRF_URL = BACKEND_URL + '/auth/csrf';
export const COACH_LOGOUT_URL = BACKEND_URL + '/auth2/coach/logout';
export const GOOGLE_SIGN_IN_URL = BACKEND_URL + '/auth/signin/google';
export const UPLOAD_IMAGE_URL = BACKEND_URL + '/coaches/uploadImage';
export const ZIP_TAX_URL = BACKEND_URL + '/salesTax/getStateSalesTax';

export const getFullUrl = (path: string) => {
  return BACKEND_URL + path;
};

const getCoachDataFromStore = () => {
  const store = app.getReduxStore();
  const state = store.getState();
  const { coachesKey, cadworxSessionId, ddAccountNumber } = state.Session;
  return { coachesKey, cadworxSessionId, ddAccountNumber };
};

export const sendApiPostRequest = async (path: string, payload: any) => {
  const fullPath = getFullUrl(path);
  const coachData = getCoachDataFromStore();
  const resultData:any = await sendPostRequest(fullPath, payload, {
    // Don't want to send this forever but will be helpful for testing and initial launch
    'Coaches-Account': coachData.ddAccountNumber,
    'Coaches-Key': coachData.coachesKey,
    'Coaches-SID': coachData.cadworxSessionId,
    'Content-Type': 'application/json'
  });

  return resultData;
};

export const sendPostRequest = async (fullPath: string, payload: any, headers?: any) => {
  const result = await fetch(fullPath, {
    body: JSON.stringify(payload),
    credentials: 'include', // TODO: What is this?
    headers: headers || null,
    method: 'POST'
  })

  if (result.status === 200) {
    return await result.json();
  }
  else {
    const data:any = (isFunction(result.json)) ? await result.json() : {};
    await Promise.reject(new RejectionReason({
      Data: data,
      Primary: RejectKey.STEAMS_API_RESPONSE,
      UserMessage: data.error
    }));
  }
};

export const getDoRedirectUrl = (returnUrl: string): string => {
  return BACKEND_URL + '/doredirect?uri=' + returnUrl;
}

export const getOrdersReadByKeyUrl = (key: string): string => {
  return BACKEND_URL + '/orders/readByKey?key=' + key;
}

export const getOrdersByCoachesKeyUrl = (key: string, partnersKey: string): string => {
  return BACKEND_URL + '/coaches/getOrdersByCoachesKey?key=' + key + '&partnersKey=' + partnersKey;
}

export const getOrderStatesByCoachesKeyUrl = (key: string, partnersKey): string => {
  return BACKEND_URL + '/coaches/getOrderStatesByCoachesKey?key=' + key + '&partnersKey=' + partnersKey;
}

export const getCoachDataReadByKeyUrl = (key: string): string => {
  return BACKEND_URL + '/coaches/readByKey?key=' + key;
}

export async function getGoogleSignInUrl$ (returnUrl: string, hash: string) {
  const csrfToken = await getCsrfToken();
  const hashString = appendHashToUrl(hash, returnUrl);
  const callbackUrl = getDoRedirectUrl(returnUrl + hashString);

  if (csrfToken) {
    const resp1 = await fetch(GOOGLE_SIGN_IN_URL, {
      body: JSON.stringify({ callbackUrl, csrfToken, json: 'true' }),
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      method: 'POST'
    });

    if (resp1.ok) {
      const loginUrl = await resp1.json();
      return Promise.resolve({ loginUrl });
    }
  }

  return Promise.reject();
}

async function getCsrfToken () {
  const resp = await fetch(AUTH_CSRF_URL, {
    credentials: 'include',
    method: 'GET'
  });

  if (resp.ok) {
    const jresp = await resp.json();
    const { csrfToken } = jresp;

    return csrfToken;
  }

  return undefined;
}
