import {RootState} from '../Application';
import { app } from '../initApp';
import {logOut$} from './actions/login';
import { UseCase } from './usecase/UseCase';

export const signOutUseCase:UseCase = {
  type: 'SIGN_OUT',

  run: async () => {
    const store = app.getReduxStore();
    const state: RootState = store.getState();
    const sessionToken = state.Session.sessionToken;

    const logOutResult = await logOut$(sessionToken);

    if ( logOutResult && logOutResult.sessionDeleted && Cx.Storage.isAvailable() ) {
      // TODO: Bring in CanvasLibTS so we can quickly edit types.  We can copy that lib around short term until Grafika TS is ready.
      // @ts-ignore
      Cx.Storage.remove( 'ubdesigner2.sessionToken' );
    }
  }
};

export function signOut() {
  app.runUseCase(signOutUseCase);
}
