import React from 'react';
import { hideModal } from '../../../app/initApp';
import Button from './Button';

// Styles
import styles from './CustomMsgPopup.module.css';

interface Props {
    title?: string;
    onClick?: Function;
    showCancel: boolean;
    showConfirm: boolean;
    showCancelMsg?: string;
    showConfirmMsg?: string;
}

const CustomMsgPopup = (props: Props) => {
    const title = props.title;
    const onClick = props.onClick;
    const showCancel = props.showCancel;
    const showConfirm = props.showConfirm;
    const showCancelMsg = props.showCancelMsg;
    const showConfirmMsg = props.showConfirmMsg;

    return (
        <>
            <div className={styles.container}>
                <div className={styles.body}>
                    {title}
                </div>
                <div className={`${(!showCancel || !showConfirm) ? styles.oneButton : styles.twoButtons}`}>
                    {showCancel && <Button
                        className={styles.cancelButton + ' ui-gray btn-primary'}
                        label={showCancelMsg ? showCancelMsg : "CANCEL"}
                        onClick={() => { hideModal() }} />
                    }
                    {showConfirm && <Button
                        className={styles.confirmButton + ' ui-primary btn-primary partner-background-color1'}
                        label={showConfirmMsg ? showConfirmMsg : "CONFIRM"}
                        onClick={onClick} />
                    }
                </div>
            </div>
        </>
    )
}

export default CustomMsgPopup;
