import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export interface setSelectedPatternColorIndexUseCaseOptions {
  patternColorIndex: number;
}

export const setSelectedPatternColorIndexUseCase:UseCase = {
  type: 'SET_SELECTED_PATTERN_COLOR_INDEX',

  run: (options: setSelectedPatternColorIndexUseCaseOptions ) => {
    return Cx.resolve({ selectedPatternColorIndex: options.patternColorIndex });
  }
};

export function setSelectedPatternColorIndex(options: setSelectedPatternColorIndexUseCaseOptions) {
  app.runUseCase(setSelectedPatternColorIndexUseCase, options);
}
