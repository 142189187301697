import { Units } from '../../../../canvas/helpers/units';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DecorationArea } from '../../../../app/domain/Design/DecorationArea';
import uniqueKey from '../../../util/uniqueKey';
import { FillTarget } from '../../../../app/domain/Design/FillTarget';
import { TextDesignElement, textDesignElementHasFillTarget } from '../../../../app/domain/Design/TextDesignElement';
import { toggleDecorationAreaHidden } from '../../../../app/usecases';
import { textFillTarget } from '../../../../app/domain/Design/TextFillTarget';
import { findFontByName } from '../../../../app/domain/Font/Font';
import { incrementTextHeight, IncrementTextHeightOptions, incrementTextSpacing, IncrementTextSpacingOptions, setFillTarget, setFont, SetFontOptions, setText, SetTextOptions, setDecorationAreaActive } from '../../../../app/initApp';
import NumberInput from '../../misc/field/NumberInput';
import PlusMinusField from '../../misc/field/PlusMinusField';
import ThumbSelectInput from '../../misc/field/ThumbSelectInput';
import DecorationAreaPanel from '../../steps/customize/DecorationAreaPanel';
import FillEditor from './FillEditor';
import OutlineManager from './outline/OutlineManager';
import Radio from '../../misc/field/Radio';

import styles from './NumberEditor.module.css';
import { RootState } from '../../../../app/Application';

interface Props {
  activeFillTarget: FillTarget;
  canvasId: string;
  decorationArea: DecorationArea;
  numberFonts: any;
  allFonts: any;
  hiddenFontsIds?:string[];
};

const NumberEditor = (props: Props) => {

  const decorationArea = props.decorationArea;
  const numberFonts = props.numberFonts;
  const textDesignElement: any = decorationArea.DesignElement;
  let fontId = textDesignElement.fontId;
  const activeFillTarget = props.activeFillTarget;
  const fillIsSelected = activeFillTarget && textDesignElementHasFillTarget(textDesignElement, activeFillTarget);

  const decorationAreas = useSelector( (state: RootState)  => state.CanvasFullState.decorationAreas );
  const locations = locationsFromDecorationAreas(decorationAreas, props);

  if (!fontId) {
    const font = findFontByName(textDesignElement.fontFamily, numberFonts);

    if (font) {
      fontId = font.value;
    }
  }

  return <div className="number-editor">
    <DecorationAreaPanel collapsed={true} decorationArea={decorationArea}>
      { 
        locations?.length > 1 && 
        <div>
          <div className={styles.selectPlacementLabel}>SELECT PLACEMENT</div> 
          <div className={styles.radio}>
            {locations.map((option, index) => {
              return <Radio
                checked={ option.Id == decorationArea.Id }
                key={uniqueKey()}
                label={option.Label}
                onClick={() => {
                    setDecorationAreaActive({ decorationArea: option });   
                    resetDifferentThan(option, locations);             
                }} />
            })}
          </div>
        </div>
      }
      <NumberInput
        className={styles.field}
        label="Preview"
        onChange={(value) => {
          onTextChange(textDesignElement, value);
          setDecorationAreaActive({ decorationArea: props.decorationArea });
        }}
        placeholder="ANY NUMBER"
        value={textDesignElement.text} />
      <ThumbSelectInput
        className={styles.field}
        handler={ (value, field, option) => {
          onFontchange(textDesignElement, value, option.data.fontName, props.allFonts);
        }}
        label="Font Style"
        options={numberFonts}
        hiddenIds={props.hiddenFontsIds}
        value={fontId} />
      <PlusMinusField
        className={styles.field}
        label="Text Height"
        onMinusClick={() => onTextHeightChange(textDesignElement, -0.25, props.allFonts)}
        onPlusClick={() => onTextHeightChange(textDesignElement, 0.25, props.allFonts)}
        unit='"'
        value={Units.toUser(textDesignElement.fontSize)} />
      <PlusMinusField
        className={styles.field}
        label="Spacing"
        onMinusClick={() => {
          onSpacingChange(textDesignElement, -0.25);
        }}
        onPlusClick={() => {
          onSpacingChange(textDesignElement, 0.25);
        }}
        value={textDesignElement.spacing} />
      <FillEditor
        brush={textDesignElement.brush}
        label="Fill Color"
        onClick={() => {
          setFillTarget({
            fillTarget: textFillTarget(textDesignElement)
          });
        }}
        selected={fillIsSelected} />
      <OutlineManager
        activeFillTarget={activeFillTarget}
        allFonts={props.allFonts}
        textDesignElement={textDesignElement} />
    </DecorationAreaPanel>
  </div>

}

function locationsFromDecorationAreas(decorationAreas:any, props: Props) { 
  return decorationAreas.filter((d)=> d.Type == props.decorationArea.Type && !d.GroupId );
}

function resetDifferentThan(otherThan: DecorationArea, locations: DecorationArea[]) {
  locations.forEach((da)=>{
    toggleDecorationAreaHidden({ decorationArea: da, hidden: da.Id !== otherThan.Id})
  });
}

function onFontchange(designElement: TextDesignElement, fontId: string, fontName: string, fontConfigs: any) {
  setFont({
    designElement,
    fontId,
    fontName,
    fontConfigs
  } as SetFontOptions);
}

function onSpacingChange (designElement: TextDesignElement, increment:number) {
  incrementTextSpacing({
    designElement,
    increment
  } as IncrementTextSpacingOptions);
}

function onTextChange (designElement: TextDesignElement, text: string) {
  setText({
    designElement,
    text
  } as SetTextOptions);
}

function onTextHeightChange (designElement: TextDesignElement, increment: number, fontConfigs: any) {
  incrementTextHeight({
    designElement,
    increment,
    fontConfigs
  } as IncrementTextHeightOptions);
}

export default NumberEditor;
