import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import {  fillTargetIsColorZone } from '../../../app/domain/Design/ColorZoneFillTarget';
import FillArrow from './namenumber/FillArrow';

import styles from './ColorZoneChiclet.module.css';

interface Props {
  canvasId: string;
  name: string,
  brush: Cx.Brush,
  selected: boolean;
  onColorZoneClicked?: Function;
}

class ColorZoneChiclet extends Component<Props> {

  public render() {
    const className = this.props.selected ? (styles.chicletSelected + ' partner-selected-fill-target-color1') : styles.chiclet;
    const id = this.props.name;
    const onClick = this.props.onColorZoneClicked;

    return <div className={className} data-number={id} onClick={() => this.props.onColorZoneClicked() } title="">
      <div className={styles.inner}>
        <FillArrow brush={this.props.brush} />
        <div className={styles.label}>{id}</div>
      </div>
    </div>;
  }
}

const mapStateToProps = (state, props) => {
  const selected = props.selected;

  return { selected };
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, ownProps, stateProps, {

  });
}

export default connect(mapStateToProps, null, mergeProps)(ColorZoneChiclet);