import { CanvasAreaFactory } from '../../canvas/CanvasArea';
import { isDesignReady, isOrbitMode } from '../domain/Design/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';

interface SetPanModeOptions {
  canvasId: string;
}

export const setPanModeUseCase:UseCase = {
  type: 'SET_PAN_MODE',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady/*, isOrbitMode */]);
  },

  run: (options: SetPanModeOptions) => {
    const canvas = CanvasAreaFactory.getCanvasById(options.canvasId);
    canvas.setPanMode();
    return canvas.render$();
  }
};

export function canSetPanMode(state): boolean {
  return setPanModeUseCase.checkConditions( state ).isAllowed;
}

export function setPanMode(options: SetPanModeOptions){
  app.runUseCase(setPanModeUseCase, options);
}
