import { CanvasAreaFactory } from '../../canvas/CanvasArea';
import { canUndo as canUndoCondition, isDesignReady } from '../domain/Design/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';

interface undoUseCaseOptions {
  canvasId:string
}

export const undoUseCase:UseCase = {
  type: 'UNDO',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady, canUndoCondition]);
  },

  run: (options: undoUseCaseOptions) => {
    const canvas = CanvasAreaFactory.getCanvasById(options.canvasId);
    canvas.undo();
    return Cx.resolve();
  }
};

export function canUndo(state): boolean {
  return undoUseCase.checkConditions( state ).isAllowed;
}

export function undo(options: undoUseCaseOptions){
  app.runUseCase(undoUseCase, options);
}
