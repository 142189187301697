
import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const swapJerseyColorListUseCase: UseCase = {
  type: 'SWAP_JERSEY_COLOR_LIST',

  run:() => {
    return Cx.resolve({});
  }
};

export default function swapJerseyColorList() {
  app.runUseCase( swapJerseyColorListUseCase, {});
}
