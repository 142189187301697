export const queryString = (name:string) => {
  const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
  return urlParams.get(name)?.replace(' ', '+');
}

export const getLoginReturnUrl = () => {
  return window.location.protocol + '//' + window.location.host + window.location.pathname + '/' ;
}

export const getQueryStringParam = (surl: string, name:string) => {
  const url = new URL(surl);
  return  url.searchParams.get(name);
}

export const getQueryStringJson = (surl: string) => {
  return JSON.parse(decodeURIComponent(surl.split('?')[1]));
}