import { isDesignReady } from '../domain/Design/Conditions';
import { DecorationArea } from '../domain/Design/DecorationArea';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { toggleDecoratinAreaHidden$ } from './actions/toggleDecorationAreaHidden$';
import { UseCase } from './usecase/UseCase';

export interface ToggleDecorationAreaDisabledOptions {
  decorationArea: DecorationArea;
  hidden?: boolean;
}

export const toggleDecorationAreaHiddenUseCase:UseCase = {
  type: 'TOGGLE_DECORATION_AREA_Hidden',

  checkConditions: (state) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: (options: ToggleDecorationAreaDisabledOptions) => {
    return toggleDecoratinAreaHidden$(options.decorationArea).then(() => {
      return Cx.resolve(options);
    });
  }
};

export function canToggleDecorationAreaHidden(state): boolean {
  return toggleDecorationAreaHiddenUseCase.checkConditions( state ).isAllowed;
}

export function toggleDecorationAreaHidden(options: ToggleDecorationAreaDisabledOptions){
  app.runUseCase(toggleDecorationAreaHiddenUseCase, options);
}
