import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';
import { Product } from '../../../app/domain/Product/Product';
import { canSaveOrder, canSubmitOrder, canUpdateOrder, saveOrder, SaveOrderUseCaseOptions, showLogin, updateOrder, UpdateOrderUseCaseOptions } from '../../../app/initApp';
import Button from '../misc/Button';
import uniqueKey from '../../util/uniqueKey';
import { coachContactInfo } from '../../../app/usecases/createCoachContactInfo';
import { evaluateSubmittingOrder } from '../../../app/usecases/evaluateSubmittingOrder';
import { selectShippingMethod } from '../../../app/usecases/selectShippingMethod';
import { selectStoreLocation } from '../../../app/usecases/selectStoreLocation';
import SelectInput from '../misc/field/SelectInput';
// Styles
import styles from './ReviewFooter.module.css';

interface Props {
  canSaveOrder?: boolean;
  canSubmitOrder?: boolean;
  canUpdateOrder?: boolean;
  coachKey?: string;
  partnersKey?: string;
  products: Product[];
  savedOrderId?: string;
  savingOrderState?: boolean;
  submittingOrder?: boolean;
  submittedOrder?: boolean;
  initialOrder?: string;
  initialOrderOpenAsCopy?: string;
  originallyCopiedFrom?: string;
  coachContactInfo?: coachContactInfo;
  partnerData?: any;
  selectedStoreLocation?: any;
  orderShippingOptions?: any;
  shippingMethod?: any;
  selectedShippingOption?: any;
  priceSizeOffsetTotal: number;
  minOrderFee: number;
  total: number;
  totalCount: number;
  cadworxSessionId: string;
  totalSalesTax: any;
}

const ReviewFooter = (props: Props) => {
  const products = props.products;
  const savingOrderState = props.savingOrderState;
  const submittingOrder = props.submittingOrder;
  const total = props.total;
  const totalCount = props.totalCount;
  const salesTax = props.totalSalesTax;
  const saveButtonDisabled = savingOrderState || products.length === 0;
  const saveButtonIconCls = savingOrderState ? 'fas fa-circle-notch fa-spin' : undefined;
  const saveButtonLabel = savingOrderState ? 'Saving...' : 'Save For Later';
  const submittedOrder = props.submittedOrder ;
  const submitButtonDisabled = submittedOrder || submittingOrder || (props.initialOrder && !props.initialOrderOpenAsCopy) || !props.selectedShippingOption || products.length === 0;
  const submitButtonIconCls = submittingOrder ? 'fas fa-circle-notch fa-spin' : undefined;
  const submitButtonLabel = submittingOrder ? 'Submitting...' : 'Submit Order For Review';

  const shippingOptions = [{ text: 'Select a shipping method', value: undefined }].concat(props.orderShippingOptions?.map((o) => {
    return { text: o.name + ' ($' + parseFloat(o.retail) + ')', value: o.id }
  }));
  
  const storeLocations = props.partnerData.addressOptions?.map((o) => {
    return { text: o.name || o.address1 , value: JSON.stringify(o) }
  });

  const [isOpen, setOpen] = useState(true);
  const collapseListener = () => {
    setOpen((prvState) => !prvState);
  };
  let iconChevron = isOpen ? "icon fas fa-chevron-up" : "icon fas fa-chevron-down";

  function onSaveClick() {
    if (props.canUpdateOrder) {
      updateOrder({
        coachesKey: props.coachKey,
        partnersKey: props.partnersKey,
        id: props.savedOrderId,
        products,
        shippingMethod: props.selectedShippingOption,
        cadworxSessionId: props.cadworxSessionId,
        minOrderFee: props.minOrderFee
      } as UpdateOrderUseCaseOptions);
    } else if (props.canSaveOrder) {
      saveOrder({
        coachesKey: props.coachKey,
        partnersKey: props.partnersKey,
        products,
        shippingMethod: props.selectedShippingOption,
        cadworxSessionId: props.cadworxSessionId,
        minOrderFee: props.minOrderFee
      } as SaveOrderUseCaseOptions);
    } else {
      showLogin();
    }
  }

  function onSubmitClick() {
    evaluateSubmittingOrder();
  }

  return (
    <div className={styles.reviewFooter}>
      <div className={styles.header}>
        <div className={styles.title + ' partner-color1'}>Order Summary</div>
      </div>
      <div className={styles.summaryRow}>
        <table className={styles.productSummary}>
          <tbody>
            <div className={styles.shippingWrap}>
              <div className={styles.shippingHeader}>
                <div className={styles.subtitle + ' partner-color1'}>Shipping</div>

                <i onClick={collapseListener} className={iconChevron}></i>
              </div>
              {isOpen &&
                <div className={styles.showShippingDetails}>
                  <div className={styles.disclaimer + ' partner-color3'}>
                    <i className="icon fas fa-exclamation-circle"></i>
                    <div className={styles.importantText + ' partner-color3'}>
                      All products are custom made to order
                    </div>
                    <div className={styles.importantText + ' partner-color3'}>
                      Please allow 15 business days plus shipping to produce your order.
                    </div>
                  </div>
                  <tr className={styles.trRow + ' partner-color2'}>
                    <td className={styles.leftTd}>CHOOSE YOUR SHIPPING METHOD</td>
                    <td className={styles.rigthTd}>
                      <div className={styles.ml2}><SelectInput
                        className={styles.field}
                        handler={(value: string) => {
                          if (value) {
                            const toSelectShippingOption = props.orderShippingOptions.find(s => s.id == value);
                            selectShippingMethod({ shippingMethod: toSelectShippingOption });
                          }
                        }}
                        options={shippingOptions}
                        value={props.selectedShippingOption ? props.selectedShippingOption.id : shippingOptions[0].value}
                      /></div>
                    </td>
                  </tr>
                  <tr className={styles.trRow + ' partner-color2'}>
                    <td className={styles.leftTd}>SELECT STORE PICK-UP LOCATION</td>
                    <td className={styles.rigthTd}>
                      <div className={styles.ml2}><SelectInput
                        className={styles.field}
                        handler={(value: string) => {
                          if (value) {
                            selectStoreLocation({ storeLocation: JSON.parse(value) });
                          }
                        }}
                        options={storeLocations}
                        value={JSON.stringify(props.selectedStoreLocation)}
                      /></div>
                    </td>
                  </tr>
                  <tr className={styles.trRow + ' partner-color2'}>
                    <td className={styles.leftTd}>SELECTED STORE LOCATION</td>
                    <td className={styles.rigthTd}>{props.partnerData.name}</td>
                  </tr>
                  <tr className={styles.trRow + ' partner-color2'}>
                    <td className={styles.rigthTd}></td>
                    <td className={styles.rigthTd}>{props.selectedStoreLocation.address1}</td>
                  </tr>
                  <tr className={styles.trRow + ' partner-color2'}>
                    <td className={styles.rigthTd}></td>
                    <td className={styles.rigthTd}>{props.selectedStoreLocation.address2}, {props.selectedStoreLocation.state}, {props.selectedStoreLocation.city}, {props.selectedStoreLocation.postalCode}</td>
                  </tr>
                </div>}
            </div>
            <div className={styles.summaryPrices}>
              <div className={styles.shippingHeader}>
                <div className={styles.subtitle + ' partner-color1'}>Subtotal</div>
              </div>
              {products.map((product) => {
                const grayGood = product.GrayGood;
                const grayGoodName = grayGood ? grayGood.Name : '';

                return <>
                  <tr className={styles.trRow + ' partner-color2'} key={product.ItemNumber ?? uniqueKey()}>
                    <td className={styles.leftTd}>{grayGoodName}</td>
                    <div><td className={styles.rigthTd + styles.quantity}>Qty: {product.TotalQuantity}</td>
                      <td className={styles.rigthTd}>${product.Price?.toFixed(2)}</td>
                      <td className={styles.rigthTd}>${product.TotalPrice?.toFixed(2)}</td>
                    </div>
                  </tr></>
              })}
              <tr className={styles.trRow + ' partner-color2'}>
                <td className={styles.leftTd}>OVERSIZED JERSEY FEE</td>
                <td className={styles.rigthTd}>${props.priceSizeOffsetTotal}</td>
              </tr>
              {!!(props.minOrderFee && props.minOrderFee > 0) &&
                <tr className={styles.trRow + ' partner-color2'}>
                  <td className={styles.leftTd}>UNDER 5 PCS PROCESSING FEE</td>
                  <td className={styles.rigthTd}>${props.minOrderFee}</td>
                </tr>
              }
              <tr className={styles.trRow + ' partner-color2'}>
                <td className={styles.leftTd}>ESTIMATED TAX</td>
                <td className={styles.rigthTd}>{salesTax === 0 ? "*To be determined" : "$" + salesTax?.toFixed(2)}</td>
              </tr>
              <tr className={styles.trRow + ' partner-color2'}>
                <td className={styles.leftTd}>SHIPPING</td>
                <td className={styles.rigthTd}>{props.selectedShippingOption?.id ? "$" + parseFloat(props.selectedShippingOption.retail) : 'N/A'}</td>
              </tr>
            </div>
          </tbody>
        </table>
      </div>
      <div className={styles.totalRow}>
        <span className={styles.totalCount}>Total number of items: {totalCount}</span>
        <span className={styles.totalLabel}>{salesTax === 0 ? "Subtotal:" : "Total"}</span>
        <span className={styles.totalValue}>${total?.toFixed(2)}</span>
      </div>
      <div className={styles.smallText + ' partner-color3'}>
        {salesTax === 0 && "*Subtotal does not include tax. Tax will be calculated at the time of final sale."}
        <br /> Once your order is placed, a representative
        <br /> will contact you shortly for order confirmation and payment details
      </div>
      <div className={styles.saveButtonsRow}>
        <Button
          className={styles.saveButton + ' btn-primary partner-color1 partner-border-color1'}
          disabled={saveButtonDisabled}
          iconCls={saveButtonIconCls}
          label={saveButtonLabel}
          onClick={onSaveClick} />
        <Button
          className={styles.submitButton + ' btn-primary partner-background-color1 partner-border-color1'}
          disabled={submitButtonDisabled}
          iconCls={submitButtonIconCls}
          label={submitButtonLabel}
          onClick={onSubmitClick} />
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  const openedSavedOrder = state.App.openedSavedOrder;

  return {
    canSaveOrder: canSaveOrder(state),
    canSubmitOrder: canSubmitOrder(state),
    canUpdateOrder: canUpdateOrder(state),
    coachKey: state.Session.coachesKey,
    partnersKey: state.Session.partnersKey,
    partnerData: state.GUI.partnerData,
    selectedStoreLocation: state.Order.storeLocation,
    orderShippingOptions: state.Order.orderShippingOptions,
    selectedShippingOption: state.Order.shippingMethod,
    savedOrderId: openedSavedOrder ? openedSavedOrder.id : undefined,
    savingOrderState: state.App.savingOrderState,
    submittingOrder: state.App.submittingOrder,
    submittedOrder: state.App.submittedOrder,
    initialOrder: state.GUI.initialOrder,
    initialOrderOpenAsCopy: state.GUI.initialOrderOpenAsCopy,
    originallyCopiedFrom: state.Order.originallyCopiedFrom,
    cadworxSessionId: state.Session.cadworxSessionId,
    coachContactInfo: state.GUI.coachContactInfoSelected
  };
};

export default connect(mapStateToProps, null)(ReviewFooter);
