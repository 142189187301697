import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

interface ImportRosterPopupUseCaseOptions {
  props?: any;
}

export const showImportRosterUseCase: UseCase = {
  type: 'SHOW_IMPORT_ROSTER_POPUP',

  run: (options: ImportRosterPopupUseCaseOptions) => {
    return Cx.resolve(options);
  }
};

export function showImportRosterPopup(options: ImportRosterPopupUseCaseOptions) {
  app.runUseCase(showImportRosterUseCase, options);
}
