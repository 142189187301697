import React from 'react';
import { FillTarget } from '../../../../../app/domain/Design/FillTarget';
import { OutlineFillTarget, outlineFillTarget } from '../../../../../app/domain/Design/OutlineFillTarget';
import { TextDesignElement, textDesignElementHasOutlineFillTarget } from '../../../../../app/domain/Design/TextDesignElement';
import { addOutline, AddOutlineOptions, incrementOutline, IncrementOutlineOptions, removeOutline, RemoveOutlineOptions, setFillTarget } from '../../../../../app/initApp';
import Button from '../../../misc/Button';
import Outlines from './Outlines';

import styles from './OutlineManager.module.css';

interface Props {
  activeFillTarget: FillTarget;
  allFonts: any;
  textDesignElement: TextDesignElement;
}

const OutlineManager = (props: Props) => {

  const activeFillTarget = props.activeFillTarget;
  const textDesignElement = props.textDesignElement;
  const hasOutlines = textDesignElement.outlines ? textDesignElement.outlines.length > 0 : false;
  const outlineIsSelected = activeFillTarget && textDesignElementHasOutlineFillTarget(textDesignElement, activeFillTarget);
  const selectedOutlineIndex = outlineIsSelected ? (activeFillTarget as OutlineFillTarget).outlineIndex : undefined;

  return <React.Fragment>
    <div className={styles.solidLine}></div>
    <Outlines
      onFillClick={(index: number) => {
        setFillTarget({
          fillTarget: outlineFillTarget(textDesignElement, index)
        });
      }}
      onPlusMinusClick={(index: number, increment: number) => {
        onOutlineChange(textDesignElement, index, increment);
      }}
      onRemoveClick={(index: number) => {
        const isActiveFillTarget = index === selectedOutlineIndex;
        onOutlineRemoveClick( textDesignElement, isActiveFillTarget);
      }}
      selectedOutlineIndex={selectedOutlineIndex}
      textDesignElement={textDesignElement} />
    {
      hasOutlines
      ?
      <div className={styles.dashedLine}></div>
      :
      null
    }
    <Button
      className={styles.addOutlineButton + ' partner-color3' }
      iconCls="fa-plus"
      label="Add Outline"
      onClick={() => {
        onAddOutlineClick(textDesignElement, props.allFonts);
      }}/>
  </React.Fragment>

}

function onAddOutlineClick(designElement: TextDesignElement, fontConfigs: any) {
  addOutline({
    designElement,
    fontConfigs
  } as AddOutlineOptions);
}

function onOutlineChange(designElement: TextDesignElement, incrementAtIndex:number, increment:number) {
  incrementOutline({
    designElement,
    increment,
    incrementAtIndex
  } as IncrementOutlineOptions);
}

function onOutlineRemoveClick(designElement: TextDesignElement, isActiveFillTarget: boolean) {
  const options: RemoveOutlineOptions = {
    designElement,
    isActiveFillTarget
  };

  removeOutline(options);
}

export default OutlineManager;
