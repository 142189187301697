export interface Polling {
  lastRequestPollingStart: number,
  isPollingActive: boolean,
  pollingResult: any
}

export const initialPolling = (props?: Polling ) => {
  return { 
      lastRequestPollingStart: 0,
      isPollingActive: false,
      pollingResult: null
  }
}