import { getInDecorationAreaName } from '../../usecases/actions/canvas';
import { DesignElement } from './DesignElement';

export interface UserArtDesignElement extends DesignElement {
  docId: string;
  fileLocation?: string;
  imageLink?: string;
}

// User-Defined Type Guard
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
export function isUserArtDesignElement(designElement: DesignElement): designElement is UserArtDesignElement {
  return (designElement as UserArtDesignElement).type === 'USER_ART';
}

// From figure helper

export function userArtDesignElementFromFileUpload (designElement: DesignElement, docId: string, fileLocation: string): UserArtDesignElement {
  return <UserArtDesignElement>Object.assign({}, designElement, {
    docId: docId,
    fileLocation: fileLocation,
    hidden: false,
    type: 'USER_ART', 
    scale: 1
  });
}

export function userArtDesignElementFromFigure (canvasId: string, figure: Cx.Figure): UserArtDesignElement {
  const decorationAreaId = getInDecorationAreaName(figure);

  return {
    canvasId,
    docId: getUserArtDocIdOnFigure(figure),
    fileLocation: getUserArtFileLocationOnFigure(figure),
    hidden: figure.hidden,
    // @ts-ignore
    imageLink: figure.imageLink,
    decorationAreaId,
    type: 'USER_ART',
    scale: 1,
    dpi: getDpiFromUploadImg(figure)
  } as UserArtDesignElement;
}

export function getUserArtThumbnailUrl (userArtElement: UserArtDesignElement, size: number): string {
  // @ts-ignore
  return Cx.Core.Document.thumbnailUrl({
    Doc: { Id: userArtElement.docId },
    Format: 'png',
    Height: size,
    Width: size
  });
}

// File location helpers

export function setUserArtDocIdOnFigure (figure: Cx.Figure, docId: string) {
  figure.metadata.set('userArtDocId', docId);
}

export function getUserArtDocIdOnFigure (figure: Cx.Figure) {
  return figure.metadata.get('userArtDocId');
}

export function setUserArtFileLocationOnFigure (figure: Cx.Figure, fileLocation: string) {
  figure.metadata.set('userArtFileLocation', fileLocation);
}

export function getUserArtFileLocationOnFigure (figure: Cx.Figure): string {
  return figure.metadata.get('userArtFileLocation');
}

export function figureIsUserArt (figure: Cx.Figure): boolean {
  return getUserArtFileLocationOnFigure(figure) ? true : false;
}

export function setDpiFromUploadImg (figure: Cx.Figure, dpi: number) {
  figure.metadata.set('dpi', dpi);
}

export function getDpiFromUploadImg (figure: Cx.Figure): boolean {
  return figure.metadata.get('dpi');
}