import { RootState } from '../../Application';
import { Condition } from '../../screen/Condition';
import { CustomizeTab } from '../Gui/CustomizeTab';
import { GuiSteps } from '../Gui/GuiSteps';
import { hasRosterTypeDecorationAreas } from './DecorationArea';

export const isCoachLoggedIn = new Condition({
  check: (state) => {
    return state.Session.coachesKey?.length > 0 ;
  },
  failMessage: 'Coach is not logged in'
});

export const isDesignReady = new Condition({
  check: (state) => {
    return state.CanvasFullState.isReady;
  },
  failMessage: 'Design is not ready'
});

export const isCanvasInited = new Condition({
  check: (state) => {
    return state.CanvasFullState.inited;
  },
  failMessage: 'Canvas is not inited'
});

export const hasDesign = new Condition({
  check: (state) => {
    return state.CanvasFullState.hasDesignFigures;
  },
  failMessage: 'No Design'
});

export const isOrbitMode = new Condition({
  check: (state) => {
    return state.CanvasFullState.isOrbitMode;
  },
  failMessage: 'Not in orbit mode'
});

export const isPanMode = new Condition({
  check: (state) => {
    return state.CanvasFullState.isPanMode;
  },
  failMessage: 'Not in pan mode'
});

export const canRedo = new Condition({
  check: (state) => {
    return state.CanvasFullState.canRedo;
  },
  failMessage: 'Redo not available'
});

export const canUndo = new Condition({
  check: (state) => {
    return state.CanvasFullState.canUndo;
  },
  failMessage: 'Undo not available'
});

export const canZoom = new Condition({
  check: (state) => {
    return state.CanvasFullState.canZoom;
  },
  failMessage: 'Zoom not available'
});

export const canClickOnFillTarget = new Condition({
  check: (state: RootState) => {
    const tab = state.GUI.activeCustomizeTab;

    return state.GUI.activeStep === GuiSteps.CUSTOMIZE &&
    (tab === CustomizeTab.COLORZONES || tab === CustomizeTab.NAME_NUMBER);
  },
  failMessage: ''
});

export const hasActiveFillTarget = new Condition({
  check: (state: RootState) => {
    return state.GUI.activeFillTarget ? true : false;
  },
  failMessage: 'No fill target selected'
});

export const canPreviewRoster = new Condition({
  check: (state: RootState) => {
    return hasRosterTypeDecorationAreas(state.CanvasFullState.decorationAreas);
  },
  failMessage: 'Unable to preview'
});