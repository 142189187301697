import React from 'react';
import { hideModal, removeProduct } from '../../../app/initApp';
import Button from './Button';

// Styles
import styles from './RemoveProductPopup.module.css';

interface Props {
  itemNumber: string;
}

const RemoveProductPopup = (props: Props) => {

  function confirmClick() {
    removeProduct({ ItemNumber: props.itemNumber });
    hideModal();
  }

  return (
    <>
      <div className={styles.removeContainer}>
        <div className={styles.removeBody}>
          Are you sure you want to delete this item?
        </div>
        <div className={styles.removeButtons}>
          <Button
            className={styles.cancelButton + ' ui-gray btn-primary'}
            label="CANCEL"
            onClick={() => { hideModal() }} />
          <Button
            className={styles.confirmButton + ' ui-primary btn-primary partner-background-color1'}
            label="CONFIRM"
            onClick={() => { confirmClick() }} />
        </div>
      </div>
    </>
  )
}

export default RemoveProductPopup;
