import { isDesignReady } from '../domain/Design/Conditions';
import { DesignElement } from '../domain/Design/DesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { updateDecorationArea$ } from './actions/updateDecorationArea$';
import { UseCase } from './usecase/UseCase';

export interface UpdateDecorationAreaOptions {
  designElement: DesignElement | DesignElement[],
  clearGroupDecorationAreas?: boolean
}

export const updateDecorationAreaUseCase: UseCase = {
  type: 'UPDATE_DECORATION_AREA',

  checkConditions: (state) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: ( options: UpdateDecorationAreaOptions ) => {
    return updateDecorationArea$(options.designElement, options.clearGroupDecorationAreas).then(() => {
      return Cx.resolve(options);
    });
  }
};

/**
 * @category Can
 */
export function canUpdateDecorationArea( state ) {
  return updateDecorationAreaUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function updateDecorationArea( options: UpdateDecorationAreaOptions ) {
  app.runUseCase( updateDecorationAreaUseCase, options );
}
