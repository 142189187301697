import { coachHasSessionUseCase } from '../usecases/coachHasSession';
import { createCoachUseCase } from '../usecases/createCoach'
import {dismissLoginErrorUseCase} from '../usecases/dismissLoginError';
import { requestPhoneCodeUseCase } from '../usecases/login/requestPhoneCode';
import {resetLoginUseCase} from '../usecases/resetLogin';
import { signOutUseCase } from '../usecases/signOut'
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { verifyPhoneCodeUseCase } from '../usecases/login/verifyPhoneCode';
import { reportMessage } from '../../gui/util/rollbar';

export interface PhoneVerificationState {
  // attemptsLeft: number;
  code: string;
  errorMessage: string;
  inSystem: boolean;
  phone: string;
  sendFailed: boolean;
  sendingCode: boolean;
  sendingSuccess:boolean;
  verifyFailed: boolean;
  verifySuccess: boolean;
  verifyingCode: boolean;
}

const initState: PhoneVerificationState = {
  // attemptsLeft: 0,
  code: '',
  errorMessage: undefined,
  inSystem: false,
  phone: undefined,
  sendFailed: false,
  sendingCode: false,
  sendingSuccess: false,
  verifyFailed: false,
  verifySuccess: false,
  verifyingCode: false
};

const clearPreviousResult = (state) => {
  return Object.assign({}, state, {
    errorMessage: initState.errorMessage,
    sendFailed: initState.sendFailed,
    sendSuccess: initState.sendingSuccess,
    verifyFailed: initState.verifyFailed,
    verifySuccess: initState.verifySuccess
  });
};

export function PhoneVerificationReducer (state: PhoneVerificationState = initState, actionResult: UseCaseResult): PhoneVerificationState {

  switch (actionResult.type) {

    // TODO: resendPhoneCodeUseCase
      // Don't want to reset state in this case, maybe set requestInProgress but that's it.
      // It would be same as requestPhoneCode but not using initState to reset state, use state

    case resetLoginUseCase.type:
    {
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if(actionResult.success()){
        return Object.assign({}, clearPreviousResult(state));
      }

      return state;
    }

    case dismissLoginErrorUseCase.type:
    {
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if(actionResult.success()){
        return Object.assign({}, clearPreviousResult(state));
      }

      return state;
    }

    case requestPhoneCodeUseCase.type:
      if (actionResult.start()) {
        return Object.assign({}, clearPreviousResult(state), {
          // sendFailed: false,
          sendingCode: true
        });
      }

      if (actionResult.failure() || actionResult.cancelled()) {
        if (actionResult.failure()) {
          reportMessage(actionResult.type, actionResult.rejectionReason)
        }

        const rejectionReason = actionResult.rejectionReason;
        const errorMessage = (rejectionReason && rejectionReason.UserMessage) && rejectionReason.UserMessage;

        return Object.assign({}, state, {
          errorMessage,
          sendFailed: true,
          sendingCode: false
        });
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          // attemptsLeft: actionResult.data.attemptsLeft,
          inSystem: actionResult.data.inSystem,
          phone: actionResult.data.phone,
          sendingCode: false,
          sendingSuccess: true
        });
      }

      return state;

    case createCoachUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        if (!actionResult.data.success) {
          const error = actionResult.data.response.data.error ;
          if (error === 'Phone already exists') {
            return Object.assign({}, state, {
              phone: actionResult.data.phone
            })
          }
        }
      }
      return state;

    case verifyPhoneCodeUseCase.type:
      if (actionResult.start()) {
        return Object.assign({}, clearPreviousResult(state), {
          verifyingCode: true
        });
      }

      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)

        const reason = actionResult.rejectionReason;

        return Object.assign({}, state, {
          // attemptsLeft: reason.attemptsLeft || 0,
          errorMessage: reason.errorMessage,
          verifyFailed: true,
          verifyingCode: false
        });
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          // attemptsLeft: 0,
          errorMessage: undefined,
          verifyFailed: false,
          verifySuccess: true,
          verifyingCode: false
        });
      }

      return state;

    case coachHasSessionUseCase.type:
    case signOutUseCase.type:
      if (actionResult.success()) {
        return Object.assign({}, initState);
      }

      return state ;
  }

  return state ;
}
