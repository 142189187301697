import { backendClient } from '../../../gui/services/backend';
import { app } from '../../initApp';
import { UseCase } from '../usecase/UseCase';
import SearchFactory from '../../../gui/services/search'

interface LoadPartnerUseCaseOptions {
  partnerKey: string;
}

export const initPartnerWithColorsUseCase:UseCase = {
  type: 'INIT_PARTNER_WITH_COLORS',

  run: (options: LoadPartnerUseCaseOptions) => {
    return backendClient.get<any>({
      endPoint: 'partners',
      method: 'readByKey',
      payload: { 'key' : options.partnerKey }
    })
    .then((r) => {
      const partner = r.Partner[0];

      if (partner) {
        // @ts-ignore
        Cx.User.setSession(partner.cadworxSessionId, false, false);

        const colorSearchService = new SearchFactory("Color")
        return colorSearchService.search().then(function(colors) {
          return Cx.resolve({
            partnerData: r,
            colors:  colors
          });
        });
      }

      return Cx.reject('Partner not found');
    });
  }
};

export function initPartnerWithColors(options: LoadPartnerUseCaseOptions){
  app.runUseCase(initPartnerWithColorsUseCase, options);
}
