import { RosterItem } from '../../domain/Roster/RosterItem';
import { app } from '../../initApp';
import { UseCase } from '../usecase/UseCase';

export interface validateRosterItemsUseCaseOptions {
  itemNumber: string,
  rosterItem: RosterItem[]
}

export const validateRosterItemsUseCase: UseCase = {
  type: 'VALIDATE_ROSTER_ITEMS',

  run: (options: validateRosterItemsUseCaseOptions) => {
    return Cx.resolve(options);
  }
};

/**
 * @category Use Case
 */
export function validateRosterItems( options: validateRosterItemsUseCaseOptions ) {
  app.runUseCase( validateRosterItemsUseCase, options );
}