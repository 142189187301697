import React from 'react';
import { RosterItem } from '../../../../app/domain/Roster/RosterItem';
import { showRosterItemEditor, updateRosterItem } from '../../../../app/initApp';
import DisplayColumn from './columns/DisplayColumn';
import NumberInputColumn from './columns/NumberInputColumn';
import SelectInputColumn from './columns/SelectInputColumn';
import TextInputColumn from './columns/TextInputColumn';

interface Props {
  columns: any;
  isMobile: boolean;
  itemNumber: string;
  rosterItem: RosterItem;
}

const RosterGridColumns = (props: Props) => {
  const columns = props.columns;
  const isMobile = props.isMobile;
  const itemNumber = props.itemNumber;
  const rosterItem = props.rosterItem;
  const onRowSelected = () => {
    if (isMobile) {
      showRosterItemEditor({
        props: { columns, rosterItem }
      });
    }
  };

  return <React.Fragment>
    {columns.map(function (columnCfg) {
      const columnProps = {
        columnCfg,
        key: rosterItem.Id + columnCfg.id,
        onChange: () => updateRosterItem({ itemNumber, rosterItem }),
        onClick: undefined,
        onFocus: () => {
          onRowSelected();
        },
        rosterItem
      };

      if (isMobile) {
        return <DisplayColumn {...columnProps} onClick={() => {
          onRowSelected();
        }} />
      }

      else {
        switch (columnCfg.editor.type) {
          case 'number': return <NumberInputColumn {...columnProps} />;
          case 'select': return <SelectInputColumn {...columnProps} />;
          default: return <TextInputColumn {...columnProps} />;
        }
      }
    })}
  </React.Fragment>

}

export default RosterGridColumns;
