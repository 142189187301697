import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { coachContactInfo } from './createCoachContactInfo';

export interface selectCoachContactInfoUseCaseOptions {
  coachContactInfo: coachContactInfo
}

export const selectCoachContactInfoUseCase: UseCase = {
  type: 'SELECT_COACH_CONTACT_INFO',

  run: ( options: selectCoachContactInfoUseCaseOptions ) => {
    return Cx.resolve({ coachContactInfo: options.coachContactInfo }); 
  }
};

export function selectCoachContactInfo( options: selectCoachContactInfoUseCaseOptions ) {
  app.runUseCase( selectCoachContactInfoUseCase, options );
}
