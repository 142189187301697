import React from 'react';
import Field from './Field';
import Input from './Input';

interface Props {
  className?:string;
  disabled?: boolean;
  label?:string;
  labelOnTop?:boolean;
  onChange?:any;
  onFocus?:any;
  onKeyUp?:any;
  placeholder?:string;
  value?:string;
  type?:string;
}

const TextInput = (props:Props) => {
  const changeHandler = props.onChange ? (e) => {
    props.onChange(e.target.value);
  } : undefined;

  return (
    <Field className={props.className} label={props.label} labelOnTop={props.labelOnTop}>
      <Input
        onChange={changeHandler}
        onFocus={props.onFocus}
        onKeyUp={props.onKeyUp}
        placeholder={props.placeholder}
        value={props.value}
        type={props.type ?? 'text'}
        disabled={props.disabled}
        name="text_input" />
    </Field>
  );

}

export default TextInput;