import { FillTarget } from './FillTarget';
import { TextDesignElement } from './TextDesignElement';

export interface OutlineFillTarget extends FillTarget {
  canvasId: string;
  designArea: string;
  outlineIndex: number;
  textDesignElement: TextDesignElement;
}

// User-Defined Type Guard
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
export function fillTargetIsOutline (target: FillTarget): boolean {
  const t = target as OutlineFillTarget;

  return t && t.type && t.type === 'OUTLINE' &&
    t.canvasId !== undefined &&
    t.designArea !== undefined &&
    t.outlineIndex !== undefined &&
    t.textDesignElement !== undefined ? true : false;
}

export function outlineFillTarget (textDesignElement: TextDesignElement, outlineIndex: number): OutlineFillTarget {
  return {
    canvasId: textDesignElement.canvasId,
    designArea: textDesignElement.decorationAreaId,
    outlineIndex,
    textDesignElement: {...textDesignElement},
    type: 'OUTLINE'
  };
}

export const cloneOutlineFillTarget = (props: OutlineFillTarget): OutlineFillTarget => {
  return {
    canvasId: props.canvasId,
    designArea: props.designArea,
    outlineIndex: props.outlineIndex,
    textDesignElement: {...props.textDesignElement},
    type: props.type
  };
}