import { fromPairs } from 'lodash';
import React, {useState }from 'react';
import { isPatternBrush } from '../../../../app/domain/Pattern/Pattern'
import { GetThumbImgFromXml$ } from '../../../../app/usecases/actions/pattern'

interface Props {
  brush: Cx.Brush;
  onClick?: Function;
}

const FillArrow = (props: Props) => {
  const brush = props.brush;
  const onClick = props.onClick;
  const isPattern = isPatternBrush(props.brush);

  if (isPattern) {
    return <PatternArrow brush={brush} onClick={onClick} />
  }

  return <SolidArrow brush={brush} onClick={onClick} />

}

export default FillArrow;

const PatternArrow = (props) => {
  const thumbSize = 60;
  const [img, setImage] = useState(null);
  // @ts-ignore
  // const url = Cx.Core.Brush.thumbnailUrl({
  //   // @ts-ignore
  //   Brush: { Cdl: props.brush.toXmlString() },
  //   Height: thumbSize,
  //   Width: thumbSize
  // });
  //this breaks for long xml values which contains colormaps as GET won't allow such a length

  GetThumbImgFromXml$(props.brush.toXmlString(), thumbSize, thumbSize )
  .then(function(img) {
    setImage(img);
  })

  return img ? <svg height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.91 16.17" onClick={props.onClick}>
    <clipPath id="arrow">
      <polygon points="0 0 16.92 0 22.91 8.12 16.92 16.17 0 16.17 6.1 8.12 0 0"/>
    </clipPath>
    <image
      clipPath="url(#arrow)"
      height={thumbSize}
      href={img}
      width={thumbSize} />
    <polygon
      fill="transparent"
      stroke="#CCCCCC"
      strokeWidth="1"
      points="0 0 16.92 0 22.91 8.12 16.92 16.17 0 16.17 6.1 8.12 0 0" /> </svg>: <div></div>
  
}

const SolidArrow = (props) => {
  const style = {
    color: '#' + props.brush.color.rgb,
    height: '16px'
  };

  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.91 16.17" style={style} onClick={props.onClick}>
    <polygon
      fill="currentColor"
      stroke="#CCCCCC"
      strokeWidth="1"
      points="0 0 16.92 0 22.91 8.12 16.92 16.17 0 16.17 6.1 8.12 0 0" />
  </svg>
}