import { reportMessage } from '../../gui/util/rollbar';
import { ErrorState, initialState, typedAction } from './Base';

const errorElement = (message: string ) => {
  return typedAction('GENERAL_ERROR', { errorMessage: message } )
}

type errorMessageAction = ReturnType<typeof errorElement >;

export function ErrorHandlerReducer(state = initialState.errorState, action: errorMessageAction) : ErrorState {
  switch (action.type) {
    case 'GENERAL_ERROR':
    {
      reportMessage(action.type, action.data.errorMessage);

      return Object.assign({}, state, {
        errorMessage: action.data.errorMessage
      });
    }
    default:
      return Object.assign({}, state, { }); ;
  }
}
