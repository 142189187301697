import { filterForNameFonts, filterForNumberFonts, findFontByName, parseFonts } from '../domain/Font/Font';
import { initFontListWithConfigUseCase } from '../usecases/init/initFontListWithConfig';
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import global from '../../gui/services/global'
import { reportMessage } from '../../gui/util/rollbar';
export interface FontReducerState {
  allFonts: any;
  defaultCAFont: any;
  defaultNameFont: any;
  defaultNumberFont: any;  
  nameFonts: any;
  numberFonts: any;
  hiddenFontsIds: any;
};

const initState = {
  allFonts: [],
  defaultCAFont: undefined,
  defaultNameFont: undefined,
  defaultNumberFont: undefined,  
  nameFonts: [],
  numberFonts: [],
  hiddenFontsIds: []
};

export function FontReducer (state: FontReducerState = initState, actionResult: UseCaseResult): FontReducerState {

  switch (actionResult.type) {

    case initFontListWithConfigUseCase.type:
    {
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }
      if (actionResult.success()) {
        // @ts-ignore - TODO: payload not defined on actionResult (should be data?)
        const fontsConfig = actionResult.data.config.Items.length > 0 ? JSON.parse(actionResult.data.config.Items[0].Data) : {} 
        const allFonts = parseFonts(actionResult.data.fonts, fontsConfig);
        const nameFonts = filterForNameFonts(allFonts);
        const numberFonts = filterForNumberFonts(allFonts);
        const hiddenFontsIds = getHiddenFontIds(allFonts);

        return Object.assign({}, state, {
          allFonts,
          defaultCAFont: findFontByName(global.DEFAULT_CA_FONT, nameFonts),
          defaultNameFont: findFontByName(global.DEFAULT_NAME_FONT, nameFonts),
          defaultNumberFont: findFontByName(global.DEFAULT_NUMBER_FONT, numberFonts),
          nameFonts,
          numberFonts,
          hiddenFontsIds
        });
      }
      return state ;
    }
  }

  return state;
}

function getHiddenFontIds(fontList: any)
{
  return fontList.filter((f) => { return f.data.hidden }).map((f)=> { return f.value });
}