import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

interface setTeamColorUseCaseOptions {
  color:any
  index:number
}

export const setTeamColorUseCase: UseCase = {
  type: 'SET_TEAM_COLOR',

  // checkConditions: ( state ) => {
  //   return Condition.evaluate(state, [isDesignReady, isFigureSelected]);
  // },

  run: ( options: setTeamColorUseCaseOptions ) => {
    return Cx.resolve({
      color: options.color,
      index: options.index
    });
  }
};

/**
 * @category Can
 */
// export function canSetTeamColor( state ) {
//   return setTeamColorUseCase.checkConditions( state ).isAllowed;
// }

/**
 * @category Use Case
 */
export function setTeamColor( options: setTeamColorUseCaseOptions ) {
  app.runUseCase( setTeamColorUseCase, options );
}
