import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { CanvasHelper } from '../../canvas/helpers/canvas';

export interface resetCanvasSelectionUseCaseOptions {
  canvasId:string,
}

export const resetCanvasSelectionUseCase: UseCase = {
  type: 'RESET_CANVAS_SELECTION',

  run: ( options: resetCanvasSelectionUseCaseOptions) => {
    CanvasHelper.resetSelection(options.canvasId);
    return Cx.resolve();
  }
};

export function resetCanvasSelection( options: resetCanvasSelectionUseCaseOptions) {
  app.runUseCase( resetCanvasSelectionUseCase, options );
}


