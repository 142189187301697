import { coachHasSession } from '../domain/Login/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { getOrderByKey$ } from './actions/getOrderByKey$';
import { UseCase } from './usecase/UseCase';

interface GetSubmittedOrderUseCaseOptions {
  ordersKey: string;
}

export const getSubmittedOrderUseCase:UseCase = {
  type: 'GET_SUBMITTED_ORDER',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [coachHasSession]);
  },

  run: (options: GetSubmittedOrderUseCaseOptions) => {
    return getOrderByKey$(options.ordersKey)
    .then((data) => {
      if (data.Order && data.OrderStatus) {
        return Cx.resolve({
          order: data.Order[0],
          orderStatus: data.OrderStatus[0]
        });
      }

      else {
        return Cx.reject('Order not found');
      }
    });
  }
};

export function canGetSubmittedOrder(state): boolean {
  return getSubmittedOrderUseCase.checkConditions( state ).isAllowed;
}

export function getSubmittedOrder(options: GetSubmittedOrderUseCaseOptions){
  app.runUseCase(getSubmittedOrderUseCase, options).catch(function () {
    // Do nothing. just prevent uncaught reject
  });
}
