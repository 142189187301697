import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './PatternCombo.module.css'
import ThumbChiclet from './ThumbChiclet'
import ColorChiclet from './ColorChiclet'
import uniqueKey from '../../util/uniqueKey';
import { setSelectedPatternColorIndex, setSelectedPatternColorIndexUseCaseOptions } from '../../../app/usecases/setSelectedPatternColorIndex'
import { RootState } from '../../../app/Application';
import { FillTarget } from '../../../app/domain/Design/FillTarget'
import { BrushFactoryOptions, BrushFactoryType } from '../../util/brushFactory';
import { setFill, SetFillUseCaseOptions } from '../../../app/usecases/setFill';

interface Props {
  selectedPatternDocumentId: string
  selectedPatternMetadata: any;
  selectedPatternSelectedColorSet: any
  selectedPatternColors: any
  selectedMap: any;
  initialPatternColorZones: any;
  activeFillTarget: FillTarget;
  activeFillTargetBrush: Cx.Brush;
  activeZoneIndex?: number ;
  hidden?: boolean;
  //funcs
  onColorClick?: Function;  
  onPatternZoneClick: Function;
  onTupleClick: Function;
}

const PatternCombo = (props: Props) => {

  return (
    <div className={props.hidden ? styles.patternComboHidden : styles.patternCombo}>
      <div className={styles.header}>
        SELECT A COLOR TO CUSTOMIZE OR SELECT FROM A POPULAR COLOR COMBINATION
      </div>
      <div className={styles.body}>
        {props.selectedPatternSelectedColorSet.map( (c,i) => {
          return <ColorChiclet 
            className={styles.colorChiclet}
            index={i}
            key={uniqueKey()}
            color={c} 
            selected={ props.activeZoneIndex == i} 
            onColorClick={(colorZone: Cx.Color, zoneIndex: number) => {
              props.onPatternZoneClick(zoneIndex);
            }}
            title={c.spotColorName} />
        })}
        <div className={styles.spacer}></div>
        {props.selectedPatternColors.map( (t,i) => {
          return <ThumbChiclet 
            index={i}
            className={styles.thumbChiclet}
            key={uniqueKey()}
            colorSet={t.Color}
            initialColors={props.selectedPatternColors[0].Color}
            initialZones={props.initialPatternColorZones}
            docId={props.selectedPatternDocumentId} 
            selected={ isMatchingColorSet(t.Color, props.selectedPatternSelectedColorSet) } 
            config={props.selectedPatternMetadata} 
            onColorClick={(docId, Tuple, thumbIndex) => {
              props.onTupleClick(Tuple);
            }}
            title={'Color set #'+String(i+1)} />
        })}
      </div>
    </div>
  );
};

function isMatchingColorSet(patternColors, selectedColors)
{
  const patternColorsList = patternColors.map((p) => p.ColorName );
  const selectedColorsList = selectedColors.map((p) => p.spotColorName );
  return patternColorsList.sort().join(',') === selectedColorsList.sort().join(',')
}

//TODO: refactor this using GUI.activePattern only
const mapStateToProps = (state: RootState, props: Props) => {
  const selectedPatternColors = state.Patterns.selectedPatternConfig.colors ;
  const selectedPatternMetadata = state.Patterns.selectedPatternConfig.metadata;
  const selectedColorSet = state.GUI.activePattern.activeColors;
  const selectedMap = state.GUI.activePattern.colorIndexZoneMap;

  return {
    selectedPatternDocumentId: state.Patterns.selectedPatternConfig.seletedPatternId,
    selectedPatternColors,
    selectedPatternMetadata,
    selectedPatternSelectedColorSet: selectedColorSet,
    initialPatternColorZones: state.Patterns.selectedPatternConfig.defaultColorZones,
    activeZoneIndex: state.GUI.activePattern.activeColorSetIndex,
    activeFillTarget: state.GUI.activeFillTarget,
    activeFillTargetBrush: state.GUI.activeFillTargetBrush,
    selectedMap
  }  
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, ownProps, stateProps, {

    onPatternZoneClick: (zoneIndex: number) => {
      const options : setSelectedPatternColorIndexUseCaseOptions = { 
        patternColorIndex: zoneIndex
      }
      setSelectedPatternColorIndex(options);
    },

    onTupleClick: (tuples) => {
      //create a new brush with selected color tuple and affect active target
      //do tint replacement internally
      //finally call setFill
      const brushOptions: BrushFactoryOptions = {
        docId: stateProps.selectedPatternDocumentId,
        type: BrushFactoryType.PATTERN,
        target: tuples.map( t => t.Item1 ), //Item1 is target color,
        map: stateProps.selectedMap
      };
      
      const setFillOptions: SetFillUseCaseOptions = {
        brushOptions: brushOptions,
        fillTarget: stateProps.activeFillTarget
      };

      setFill(setFillOptions);
    }

  });
}

export default connect(mapStateToProps, null, mergeProps)(PatternCombo);
