import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

interface ToggleRosterItemEditorUseCaseOptions {
  props?: any;
}

export const showRosterItemEditorUseCase:UseCase = {
  type: 'TOGGLE_ROSTER_ITEM_EDITOR',

  run: (options: ToggleRosterItemEditorUseCaseOptions) => {
    return Cx.resolve(options);
  }
};

export function showRosterItemEditor(options: ToggleRosterItemEditorUseCaseOptions) {
  app.runUseCase(showRosterItemEditorUseCase, options);
}
