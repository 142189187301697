import { RootState } from '../Application';
import { isConfigReady } from '../domain/Config/Conditions';
import { hasActiveProduct } from '../domain/Order/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';

export const showNameNumberTabUseCase:UseCase = {
  type: 'SHOW_NAME_NUMBER_TAB',

  checkConditions: ( state: RootState ) => {
    return Condition.evaluate(state, [isConfigReady, hasActiveProduct]);
  },

  run: () => {
    // @ts-ignore
    return Cx.resolve();
  }
};

export function canShowNameNumberTab(state: RootState) {
  return showNameNumberTabUseCase.checkConditions( state ).isAllowed;
}

export function showNameNumberTab(){
  app.runUseCase(showNameNumberTabUseCase);
}
