import React from 'react';
import {Component} from 'react';
import styles from './App.module.css';
import Main from './gui/components/Main';
import Confirmation from './gui/components/Confirmation'
import { queryString }  from './gui/util/queryString'

class App extends Component {
  public render() {
    let loginHash = queryString("hash"); //This is used so the confirmation page will ping the backend with the emailed hash and verified it's valid
    let batch = Boolean(queryString("batch"));
    const nonce = !! batch; // bobert: can be removed anytime - test line
    const orderId = queryString("app.orderId")
    const initialOrderOpenAsCopy = true ; //queryString("app.openAsCopy") != undefined ; 
    return (
        <div className={styles.App}>
          { loginHash ? 
            <Confirmation hash={loginHash} /> : 
            <Main isBatchProcessing={batch} 
              orderId={orderId} 
              initialOrderOpenAsCopy={initialOrderOpenAsCopy} 
              /> 
            }
        </div>
    );
  }
}

export default App;
