import { Units } from '../../../../../canvas/helpers/units';
import React from 'react';
import { Outline } from '../../../../../app/domain/Design/Outline';
import PlusMinusField from '../../../misc/field/PlusMinusField';
import FillEditor from '../FillEditor';

import styles from './OutlineEditor.module.css';

interface Props {
  label: string;
  onFillClick: any;
  onPlusMinusClick: any;
  onRemoveClick: any;
  outline: Outline;
  selected: boolean;
};

const OutlineEditor = (props: Props) => {
  const onRemoveClick = props.onRemoveClick ? () => props.onRemoveClick(props.selected) : null;

  return (
    <div className="outline-editor">
      <FillEditor
        brush={props.outline.Brush}
        label={props.label}
        onClick={props.onFillClick}
        onRemoveClick={onRemoveClick}
        selected={props.selected} />
      <PlusMinusField
        className={styles.field}
        label="Thickness"
        onMinusClick={() => props.onPlusMinusClick(-0.01)}
        onPlusClick={() => props.onPlusMinusClick(0.01)}
        unit="&quot;"
        value={Units.toUser(props.outline.Offset)} />
    </div>
  );

}

export default OutlineEditor;