import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { priceByOrder$ } from './actions/priceByOrder$'

export interface UpdateAfterRemovingProductOptions {
  products: any;
  partnerKey: string;
  stateTax?: string;
}


export const updateAfterRemovingProductUseCase: UseCase = {
  type: 'UPDATE_AFTER_REMOVING_PRODUCT',

  run: (options: UpdateAfterRemovingProductOptions) => {
    const clonedProducts = Cx.cloneDeep(options.products);

    // @ts-ignore
    return priceByOrder$(clonedProducts, options.partnerKey, options.stateTax, 'coach')
      .then((priceModel) => {
        return Cx.resolve({ ...options, priceModel });
      });
  }
};

export function updateOrderAfterRemoving(options: UpdateAfterRemovingProductOptions ) {
  app.runUseCase( updateAfterRemovingProductUseCase, options );
}