import { app } from '../initApp';
import { priceByOrder$ } from './actions/priceByOrder$';
import { UseCase } from './usecase/UseCase';

interface getSalesTaxByZipUseCaseOptions {
    products: any;
    partnerKey: string;
    stateTax: string;
}

export const getSalesTaxByZipUseCase: UseCase = {
    type: 'GET_SALES_TAX_COACH_CONTACT_ZIP',

    run: (options: getSalesTaxByZipUseCaseOptions) => {
        // @ts-ignore
        return priceByOrder$(options.products, options.partnerKey, options.stateTax, 'coach')
            .then((priceModel) => {
                return Cx.resolve({ ...options, priceModel });
            });
    }
};

export function getSalesTaxByZip(options: getSalesTaxByZipUseCaseOptions) {
    app.runUseCase(getSalesTaxByZipUseCase, options);
}