import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

interface RemoveProductPopupUseCaseOptions {
  props?: any;
}

export const removeProductPopupUseCase: UseCase = {
  type: 'SHOW_REMOVE_PRODUCT_POPUP',

  run: (options: RemoveProductPopupUseCaseOptions) => {
    return Cx.resolve(options);
  }
};

export function showRemoveProductPopup(options: RemoveProductPopupUseCaseOptions) {
  app.runUseCase(removeProductPopupUseCase, options);
}
