import { RootState } from '../Application';
import { isConfigReady } from '../domain/Config/Conditions';
import { hasActiveGrayGood } from '../domain/Order/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';

export const showDesignStylesStepUseCase:UseCase = {
  type: 'SHOW_DESIGN_STYLES_STEP',

  checkConditions: ( state: RootState ) => {
    return Condition.evaluate(state, [isConfigReady, hasActiveGrayGood]);
  },

  run: () => {
    // @ts-ignore
    return Cx.resolve();
  }
};

export function canShowDesignStylesStep(state: RootState) {
  return showDesignStylesStepUseCase.checkConditions( state ).isAllowed;
}

export function showDesignStylesStep(){
  app.runUseCase(showDesignStylesStepUseCase);
}
