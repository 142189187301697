import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { createCoachContactInfo$ } from './actions/login'

export interface coachContactInfo {
  id: string;
  name: string,
  email: string,
  phone: string,
  organizationName: string,
  streetAddress: string,
  city: string,
  state: string,
  zip: string
}

export interface createCoachContactInfoUseCaseOptions {
  coachesKey: string,
  contactInfo: coachContactInfo,
}

export const createCoachContactInfoUseCase: UseCase = {
  type: 'CREATE_COACH_CONTACT_INFO',

  run: (options: createCoachContactInfoUseCaseOptions) => {
    return createCoachContactInfo$(options.coachesKey, options.contactInfo).then((c) => {
      options.contactInfo.id = c.id;

      return Cx.resolve(Object.assign({
        success: true
      }, options));
    }
      , (e) => {
        let r = Object.assign({ success: false, requestFailed: true }, e)
        r = Object.assign(r, options);
        return Cx.resolve(r);
      })
  }
};

export function createCoachContactInfo(options: createCoachContactInfoUseCaseOptions) {
  app.runUseCase(createCoachContactInfoUseCase, options);
}
