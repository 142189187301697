import React, { useEffect, useState } from 'react';
import { coachContactInfo } from '../../../../app/usecases/createCoachContactInfo';
import { selectCoachContactInfo } from '../../../../app/usecases/selectCoachContactInfo';
import uniqueKey from '../../../util/uniqueKey';
import Button from '../../misc/Button'
import { hideModal, showContactInfo } from '../../../../app/usecases';
import Radio from '../../misc/field/Radio';
import { showCustomMsgPopup } from '../../../../app/usecases/customMsgPopupUseCase';
import { connect } from 'react-redux';
import { RootState } from '../../../../app/Application';

// Styles
import styles from './SelectCoachContactInfo.module.css';

export interface SelectCoachContactInfo {
  coachesKey: string,
  info: coachContactInfo[],
  products?: any[],
  partnerKey?: string
}

const SelectCoachContactInfo = (props: SelectCoachContactInfo) => {
  const [coach, setCoach] = useState<any>("");
  const [selected, setSelected] = useState<coachContactInfo>(null);
  const [stateTax, setStateTax] = useState<string>("");

  function confirmCoachContact() {
    showCustomMsgPopup({
      title: 'Order cannot be edited once submitted.',
      onClick: confirmAndSubmit,
      showCancel: true,
      showConfirm: true
    });
  }

  function confirmAndSubmit() {
    selectCoachContactInfo({ coachContactInfo: selected });
    hideModal();
  }

  function onRadioClick(coachInfo, index) {
    if (!coachInfo.id) {
      coachInfo.id = index;
    }

    setCoach(coachInfo.id);
    setSelected(coachInfo);
    setStateTax(coachInfo.zip);
  }

  const ContactInfoRow = (props) => {
    const coachInfo = props.selectedCoachInfo;
    return <div
      className={styles.coachInfoContainer}
      key={uniqueKey()}>
      <div className={styles.selectOption}>
        <Radio
          checked={coach === coachInfo.id}
          className={styles.radioField}
          label=""
          onClick={() => {
            onRadioClick(coachInfo, props.index);
          }} />
      </div>
      <div className={styles.personalInfo}>
        <span className={styles.mainText}><b>{coachInfo.name}</b></span>
        <span>{coachInfo.email}</span>
        <span>{coachInfo.phone} <i>(phone number)</i></span>
        <span>{coachInfo.organizationName}</span>
      </div>
      <div className={styles.addressInfo}>
        {coachInfo.streetAddress ? <span className={styles.mainText}><b>{coachInfo.streetAddress}</b></span> : null}
        {(coachInfo.city && coachInfo.state && coachInfo.zip) ? <span>{coachInfo.city}, {coachInfo.state} {coachInfo.zip}</span> : null}
      </div>
    </div>
  }

  return (
    <div>
      <div className={styles.coachWrapper}>
        {Object.values(props.info)
          .map((coachInfo: coachContactInfo, index: number) => {
            return <ContactInfoRow
              selectedCoachInfo={coachInfo}
              key={uniqueKey()}
              index={index}
            />;
          })}
      </div>
      <div className={styles.confirmButton}>
        <Button
          className={styles.addCoachInfo + ' partner-color1'}
          iconCls="fa-plus"
          label="Add Coach Info"
          onClick={() => {
            showContactInfo({ coachKey: props.coachesKey, coachContactInfo: null });
          }} />
        <Button
          className="ui-primary partner-background-color1 partner-border-color1"
          label="CONFIRM"
          disabled={coach?.length === 0}
          onClick={() => {
            confirmCoachContact();
          }} />
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    products: state.Order.products,
    partnerKey: state.Session.partnersKey
  };
};

export default connect(mapStateToProps)(SelectCoachContactInfo);