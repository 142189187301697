declare let Cx:any;

export class GeneralHelper {

  static isFunction(f) {
    return f && {}.toString.call(f) === '[object Function]';
  }

  static xor(a: any, b:any ): any 
  {
    return [
        ...a.filter(x => b.indexOf(x) === -1),
        ...b.filter(x => a.indexOf(x) === -1)
    ];
  }
  static size(v: any): number
  {
    return ( Array.isArray(v) ? v.length : 0 );
  }

  static isEmpty(v: any): boolean
  {
    return ( Array.isArray(v) && v.length > 0 );
  }

  static isNumber(value: string | number): boolean
  {
    return ((value != null) && !isNaN(Number(value.toString())));
  }

  static isProcess(item) {
    return (item instanceof Cx.Process);
  }

  static isTypesetText(figure) {
    return (GeneralHelper.isMultiLineText(figure) && figure.columnCount > 1);
  }

  static isMultiLineText(figure) {
    return (figure instanceof Cx.Figure.Text && figure.lines.length > 1);
  }

  static isRaster(figure) {
    return ( figure instanceof Cx.Figure.Raster );
  }

  static isClipArt(figure) {
    return (figure.xType == 'System.Clipart');
  }

  static isShape(figure) {
    return (figure.type && figure.type === 'Shape');
  }

  static isUserImport(figure) {
    return (figure.metadata.get('txUserImport'));
  }

  static layoutNameFigureOriginatedFrom(figure){
    return figure.metadata.get('fromTxLayout');
  };
  
  static hasAngle(figure) {
    const angle = figure.angle;
    return (GeneralHelper.isNumber(angle) && angle !== 0);
  }

  static isText(figure){
    return (figure instanceof Cx.Figure.Text); //Cx.Figure.TextBlock
  }

  static isMultiPartText(figure) {
    return (figure instanceof Cx.Text && figure.isMultiPart);
  }

  static isPenContour(process) {
    return (process instanceof Cx.Process.PenContour);
  }

  static isShadowOrRasterShadow(process) {
    return (process instanceof Cx.Process.Shadow || process instanceof Cx.Process.RasterShadow);
  }

  static isRasterShadowWithPen(process) {
    return (process instanceof Cx.Process.RasterShadow && !process.pen.empty());
  }

  static isFitEnvelope(process) {
    return (process instanceof Cx.Process.FitEnvelope);
  }

  static isFitEnvelopeStretched(fitEnvelope) {
    return (fitEnvelope.hPos === 'Stretched' && fitEnvelope.vPos === 'Stretched');
  }

  static isVerticalArch(process) {
    return (process instanceof Cx.Process.VerticalArch);
  }

  static isCapital(process) {
    return (process instanceof Cx.Process.Capital);
  }

  static isFitOnPath(process) {
    return (process instanceof Cx.Process.FitOnPath);
  }

  static isFitEllipse(process) {
    return (process instanceof Cx.Process.FitEllipse);
  }

  static hasLineDirection(figure) {
    return (typeof figure.lineDirection === 'function' && figure.lineDirection > 0);
  }

  static getProcessIndexByType(process){
    switch (true) {
      case process instanceof Cx.Process.Capital: // Bookends
        return 0;
      case process instanceof Cx.Process.FitEllipse: // Circle Text
      case process instanceof Cx.Process.FitOnPath: // Circle Text (see "D6 Text on Path Issues" https://groups.google.com/forum/#!topic/cadx/Wa-RgJUNPFs)
      case process instanceof Cx.Process.ClassicArc: // Arc
        return 1;
      case process instanceof Cx.Process.PenContour: // Outline
        return 2;
      case process instanceof Cx.Process.FitEnvelope: // Shape
      case process instanceof Cx.Process.VerticalArch: // Arch
        return 3;
      case process instanceof Cx.Process.RasterShadow: // Shadow
      case process instanceof Cx.Process.Shadow: // Shadow
        return 4;
      default:
        return -1;
    }
  };
  
  static addProcessToFigure(process, figure){
    //We still need this type of logic because order matters when adding processes.
    let processIndex = GeneralHelper.getProcessIndexByType(process);
    figure.processes().insert(processIndex, process);
    return figure;
  };
  
  //OLD SLOT 1, Bookends
  static isCharacterScaleProcess(process){
    if(this.isProcess(process))
      return (process instanceof Cx.Process.Capital);
  
    return (typeof process === 'string' && process === 'Capital');
  };
  
  //OLD SLOT 2

  static compatibleWithDirectionChange(process){
    return !(this.isCharacterScaleProcess(process) || this.isCharacterPlacementProcess(process) || this.isTextDeformationProcess(process));
  };
  
  static compatibleWithCharacterPlacementProcess(process){
    return !(this.isTextDeformationProcess(process)
            || this.isCharacterPlacementProcess(process)); //Can only have one character placement process
  };
  
  static compatibleWithTextDeformationProcess(process){
    return !(this.isCharacterPlacementProcess(process)
            || this.isTextDeformationProcess(process)); //Can only have one text deformation process
  };
  
  static processesCompatibleWithArchEffect(processes){
    return processes.every(function(p) {
      return (  p instanceof Cx.Process.VerticalArch
      || p instanceof Cx.Process.BookEnd
      || p instanceof Cx.Process.Capital
      || p instanceof Cx.Process.Shadow
      || p instanceof Cx.Process.RasterShadow
      || p instanceof Cx.Process.Contour
      || p instanceof Cx.Process.PenContour );
    });
  };

static incompatibleFigureProcesses(figure, newProcess){
  let figureProcesses = figure.processes().toArray();

  if(this.isCharacterPlacementProcess(newProcess)){
    return figureProcesses.filter(process => !this.compatibleWithCharacterPlacementProcess(process));
  }

  if(this.isTextDeformationProcess(newProcess)){
    return figureProcesses.filter(process => !this.compatibleWithTextDeformationProcess(process));
  }

  return [];
};

static compatibleFigureProcesses(figure, newProcess){
  let compatibleProcesses = figure.processes().toArray();

  if(this.isCharacterPlacementProcess(newProcess))
    return compatibleProcesses.filter( process => this.compatibleWithCharacterPlacementProcess(process) );

  if(this.isTextDeformationProcess(newProcess))
    return compatibleProcesses.filter( process => this.compatibleWithTextDeformationProcess(process) );

  return compatibleProcesses;
};

static isCharacterPlacementProcess(process){
  if(this.isProcess(process))
    return (process instanceof Cx.Process.FitEllipse // Circle Text
          || process instanceof Cx.Process.FitOnPath // Circle Text (see "D6 Text on Path Issues" https://groups.google.com/forum/#!topic/cadx/Wa-RgJUNPFs)
          || process instanceof Cx.Process.ClassicArc); // Arc)

  return (typeof process === 'string' && (process === 'FitEllipse' || process === 'FitOnPath' || process === 'ClassicArc'));
};

//OLD SLOT 4
static isTextDeformationProcess(process){
  if(this.isProcess(process))
    return (process instanceof Cx.Process.FitEnvelope // Shape
          || process instanceof Cx.Process.VerticalArch); // Arch

  return (typeof process === 'string' && (process === 'FitEnvelope' || process === 'VerticalArch'));
};

static sameTypeOfProcessOnFigure(figure, process){
  let processesOnFigure = figure.processes().toArray();

  let sameTypeOfProcessOnFigure = processesOnFigure.find(function(processOnFigure){
    return ((processOnFigure instanceof Cx.Process.FitEllipse && process instanceof Cx.Process.FitEllipse)
      || (processOnFigure instanceof Cx.Process.Capital && process instanceof Cx.Process.Capital)
      || (processOnFigure instanceof Cx.Process.FitOnPath && process instanceof Cx.Process.FitOnPath)
      || (processOnFigure instanceof Cx.Process.ClassicArc && process instanceof Cx.Process.ClassicArc)
      || (processOnFigure instanceof Cx.Process.PenContour && process instanceof Cx.Process.PenContour)
      || (processOnFigure instanceof Cx.Process.FitEnvelope && process instanceof Cx.Process.FitEnvelope)
      || (processOnFigure instanceof Cx.Process.VerticalArch && process instanceof Cx.Process.VerticalArch)
      || (processOnFigure instanceof Cx.Process.RasterShadow && process instanceof Cx.Process.RasterShadow)
      || (processOnFigure instanceof Cx.Process.RasterShadow && process instanceof Cx.Process.RasterShadow));
  });

  return sameTypeOfProcessOnFigure;
}
}
