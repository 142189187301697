import { stringStartsWith } from './stringStartsWith';

// Removes non digit chars
// Adds the country code
export function cleanPhoneNumber (phone: string): string {
  const countryCode = '+1';

  if (stringStartsWith(phone, countryCode)) {
    // Remove country code
    phone = phone.substring(countryCode.length, phone.length);
  }

  // Numbers only
  phone = phone.replace(/\D/g,'');

  return countryCode + phone;
}