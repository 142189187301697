import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';
import { showSavedOrders, showSubmittedOrders } from '../../../app/initApp';
import { signOut } from '../../../app/usecases/signOut';
import Button from './Button';

import styles from './MoreMenu.module.css';

interface Props {
  isLoggedIn: boolean;
}

const MoreMenu = (props: Props) => {
  const isLoggedIn = props.isLoggedIn;

  return <div className={styles.moreMenu}>
    <div className={styles.moreMenuButtons}>
      <Button
        className={styles.moreMenuButton}
        disabled={!isLoggedIn}
        iconCls="fas fa-folder"
        label="Saved Orders"
        onClick={() => {
          showSavedOrders();
        }} />
      <Button
        className={styles.moreMenuButton}
        disabled={!isLoggedIn}
        iconCls="fas fa-clipboard-check"
        label="Submitted Orders"
        onClick={() => {
          showSubmittedOrders();
        }} />
    </div>
    <div className={styles.logoutButtonRow}>
      <Button
        className={styles.logoutButton + ' partner-color1 partner-border-color1'}
        label="LOG-OUT"
        onClick={() => {
          signOut();
        }} />
    </div>
  </div>
}

function mapStateToProps(state: RootState) {
  const coachesKey = state.Session.coachesKey;

  return {
    isLoggedIn: coachesKey ? true : false
  };
}

export default connect(mapStateToProps, null)(MoreMenu);
