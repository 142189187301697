import React from 'react';
import styles from './LoginMessage.module.css';

interface Props {
  children: React.ReactFragment;
  goBack: boolean ;
}

export const LoginMessage = (props: Props) => {
  return (
    <div className={styles.signIn}>
      <div className={styles.message}>
        {props.children}
      </div>
    </div>
  )
}
