import { CanvasAreaFactory } from '../../canvas/CanvasArea'
import { DecorationArea, isBackNumberDecorationArea, isSingleGraphicDecorationArea, isMultiGraphicDecorationArea, isNameDecorationArea, isSleeveNumberDecorationArea, isRibNumberDecorationArea, isCADecorationArea } from '../domain/Design/DecorationArea';
import { DesignElement } from '../domain/Design/DesignElement';
import { defaultBackNumberDesignElement, defaultCADesignElement, defaultNameDesignElement, defaultSleeveNumberDesignElement, defaultRibNumberDesignElement, updateTextDesignElementFromFigure } from '../domain/Design/TextDesignElement';
import { figureIsUserArt, userArtDesignElementFromFigure } from '../domain/Design/UserArtDesignElement';
import { getFigureInDecorationArea } from '../usecases/actions/canvas';
import { addProductUseCase } from '../usecases/addProduct';
import { editProductUseCase } from '../usecases/editProduct';
import { emitCanvasEventUseCase } from '../usecases/emitCanvasEvent';
import { openSavedOrderUseCase } from '../usecases/openSavedOrder';
import { ToggleDecorationAreaDisabledOptions, toggleDecorationAreaHiddenUseCase } from '../usecases/toggleDecorationAreaHidden';
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { loadSubmittedOrderUseCase } from '../usecases/loadSubmittedOrder';
import { clearDecorationAreaUseCase } from '../usecases/clearDecorationArea'
import { copySubmittedOrderUseCase } from '../usecases/copySubmittedOrder';
import { reportMessage } from '../../gui/util/rollbar';

const initState: DecorationArea = {
  DesignElement: undefined,
  Figure: undefined,
  Id: undefined,
  Label: undefined,
  Type: undefined,
  Active: true
};

export function DecorationAreaReducer (state: DecorationArea = initState, actionResult: UseCaseResult): DecorationArea {

  switch (actionResult.type) {

    case addProductUseCase.type:
    case editProductUseCase.type:
    case openSavedOrderUseCase.type:
    case loadSubmittedOrderUseCase.type:
    case copySubmittedOrderUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success()) {
        const newState = Object.assign({}, state);

        newState.DesignElement = getDefaultDesignElement(newState, actionResult);
        return updateStateFromFigure(newState);
      }

      return state;

    case emitCanvasEventUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success()) {
        return updateStateFromFigure(state);
      }

      return state;

    case clearDecorationAreaUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success()) {
        return updateStateFromFigure(state);
      }

      return state;
  

    case toggleDecorationAreaHiddenUseCase.type:
      if (actionResult.failure())
        reportMessage(actionResult.type, actionResult.rejectionReason);;

      if (actionResult.success()) {
        const data: ToggleDecorationAreaDisabledOptions = actionResult.data;

        if (data.decorationArea.Id === state.Id) {
          return updateStateFromFigure(state);
        }
      }

      return state;
  }

  return state;

}

function getDefaultDesignElement (state: DecorationArea, actionResult: UseCaseResult): DesignElement {
  const designElement: DesignElement = {
    canvasId: actionResult.data.canvasId,
    decorationAreaId: state.Id
  };

  if (isCADecorationArea(state)) {
    return defaultCADesignElement(designElement);
  }

  if (isNameDecorationArea(state)) {
    return defaultNameDesignElement(designElement);
  }

  if (isBackNumberDecorationArea(state)) {
    return defaultBackNumberDesignElement(designElement);
  }

  if (isSleeveNumberDecorationArea(state)) {
    return defaultSleeveNumberDesignElement(designElement);
  }

  if (isRibNumberDecorationArea(state)) {
    return defaultRibNumberDesignElement(designElement);
  }

  if (isSleeveNumberDecorationArea(state)) {
    return defaultSleeveNumberDesignElement(designElement);
  }

  if (isSingleGraphicDecorationArea(state)) {
    return designElement;
  }

  if (isMultiGraphicDecorationArea(state)) {
    return designElement;
  }

  return designElement;
}

function updateStateFromFigure (state: DecorationArea) {
  if (!state.DesignElement) {
    return state;
  }

  const canvasId = state.DesignElement.canvasId;
  const canvas = CanvasAreaFactory.getCanvasById(canvasId);
  const figure: any = getFigureInDecorationArea(canvas, state.Id);

  if ( figure ) {
    if ( figure.isText ) {
      const textFigure = updateTextDesignElementFromFigure(state.DesignElement, figure)
      return Object.assign({}, state, {
        DesignElement: textFigure
      });
    }

    else if ( figureIsUserArt(figure) ) {
      return Object.assign({}, state, {
        DesignElement: userArtDesignElementFromFigure(canvasId, figure)
      });
    }
  }

  return state;
}