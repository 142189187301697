import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

interface SetInitialPartnerUseCaseOptions {
  partnerKey: string;
}

export const setInitialPartnerUseCase: UseCase = {
  type: 'SET_INITIAL_PARTNER',

  run: ( options: SetInitialPartnerUseCaseOptions ) => {
    return Cx.resolve({
      partnerKey: options.partnerKey
    });
  }
};

/**
 * @category Can
 */
export function canSetInitialPartner( state ) {
  return setInitialPartnerUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setInitialPartner( options: SetInitialPartnerUseCaseOptions ) {
  app.runUseCase( setInitialPartnerUseCase, options );
}
