import { isEmpty } from 'lodash';
import React from 'react';
import uniqueKey from '../../util/uniqueKey';
import ThumbChiclet from './ThumbChiclet'
import styles from './ThumbsCarousel.module.css';
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';

interface Props {
  activeDoc?: string;
  config?: any;
  docList: any[]
  hidden?: boolean;
  onColorClick: Function;
}

const ThumbsCarousel = (props: Props) => {
  if( isEmpty(props.docList) )
    return null;

  return <div className={props.hidden ? styles.carouselHidden : styles.carousel}>
    <Chiclets
      activeColor={props.activeDoc}
      config={props.config}
      docList={props.docList}
      onClick={props.onColorClick} />
  </div>;

};

const Chiclets = (props) => {
  const docId = props.activeColor;

  return props.docList.map( (doc, i) => {
    const selected = docId ? doc.Id === docId : false;
    let metadata = doc.Config && doc.Config.metadata;

    if (!metadata) {
      metadata = { ThumbDPI : 96, ThumbHeight : 50, ThumbWidth : 50, ThumbZoom : 1 };
    }

    return <ThumbChiclet
      index={i}
      className={styles.thumbChiclet}
      config={metadata}
      key={uniqueKey()}
      docId={doc.Id}
      onColorClick={props.onClick}
      selected={selected}
      title={doc.Name} />;
  });
}

const mapStateToProps = (state: RootState) => {
  return {
    activeDoc: state.GUI.activePattern ? state.Patterns.selectedPatternConfig.seletedPatternId : undefined
  };
};

export default connect(mapStateToProps)(ThumbsCarousel);
