import { Units } from '../../../canvas/helpers/units';
import { TextDesignElement } from '../../domain/Design/TextDesignElement';
import { getOutlineSum } from './font';
import { updateTextDesignElement$ } from './updateTextDesignElement$';

export function removeOutline$ (designElement: TextDesignElement) {
  designElement.outlines.pop();
  designElement.outlineProportions.pop();
  // use pre calculated (or config value) text separation
  if(!designElement.fontIsScript)
    designElement.spacing = +(Units.toUser(getOutlineSum(designElement))).toFixed(2) ;

  // update on figure
  return updateTextDesignElement$(designElement);
}