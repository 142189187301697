import { UPLOAD_IMAGE_URL } from '../../../backendApi/backendApi';

export function uploadLogo$ (file: File, coachesKey: string, partnersKey: string) {
  const formData = new FormData();

  formData.append('coachesKey', coachesKey);
  formData.append('partnersKey', partnersKey);
  formData.append('file', file);

  return fetch(UPLOAD_IMAGE_URL, { body: formData, method: 'POST' })
  .then(response => response.json())
  .then(data => { return Cx.resolve(data) });
}