import { isDesignReady } from '../../domain/Design/Conditions';
import { defaultRosterItem, RosterItem } from '../../domain/Roster/RosterItem';
import { app } from '../../initApp';
import { Condition } from '../../screen/Condition';
import { UseCase } from '../usecase/UseCase';

interface UpdateRosterItemUseCaseOptions {
  itemNumber: string;
  rosterItem?: RosterItem;
}

export const updateRosterItemUseCase: UseCase = {
  type: 'UPDATE_ROSTER_ITEM',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: ( options: UpdateRosterItemUseCaseOptions ) => {
    const rosterItem = defaultRosterItem(options.rosterItem);

    return Cx.resolve({
      itemNumber: options.itemNumber,
      rosterItem
    });
  }
};

/**
 * @category Can
 */
export function canUpdateRosterItem( state ) {
  return updateRosterItemUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function updateRosterItem( options: UpdateRosterItemUseCaseOptions ) {
  app.runUseCase( updateRosterItemUseCase, options );
}
