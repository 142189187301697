import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';


export const setBatchModeUseCase:UseCase = {
  type: 'SET_BATCH_MODE',

  run: () => {
    return Cx.resolve()
  }
};


export default function setBatchMode(){
  app.runUseCase(setBatchModeUseCase);
}
