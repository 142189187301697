import { isConfigReady } from '../domain/Config/Conditions';
import { hasActiveProduct } from '../domain/Order/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';

interface RemoveProductUseCaseOptions {
  ItemNumber: string;
}

export const removeProductUseCase: UseCase = {
  type: 'REMOVE_PRODUCT',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isConfigReady, hasActiveProduct]);
  },

  run: ( options: RemoveProductUseCaseOptions ) => {
    return Cx.resolve(options);
  }
};

/**
 * @category Can
 */
export function canRemoveProduct( state ) {
  return removeProductUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function removeProduct( options: RemoveProductUseCaseOptions ) {
  app.runUseCase( removeProductUseCase, options );
}
