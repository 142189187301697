import { coachHasSession } from '../domain/Login/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { getCoachDataReadByKey$ } from './actions/getCoachDataReadByKey$';
import { UseCase } from './usecase/UseCase';

interface getCoachDataReadByKeyUseCaseOptions {
  coachesKey: string;
}

export const getCoachDataReadByKeyUseCase:UseCase = {
  type: 'GET_COACH_DATA_READ_BY_KEY',

  // checkConditions: ( state ) => {
  //   return Condition.evaluate(state, [coachHasSession]);
  // },

  run: (options: getCoachDataReadByKeyUseCaseOptions) => {
    return getCoachDataReadByKey$(options.coachesKey)
    .then((data) => {
      return Cx.resolve({
        coachData: data
      });
    });
  }
};

export function getCoachDataReadByKey(options: getCoachDataReadByKeyUseCaseOptions){
  app.runUseCase(getCoachDataReadByKeyUseCase, options);
}
