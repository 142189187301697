import { app } from '../initApp';
import { CanvasAreaFactory } from '../../canvas/CanvasArea';
import { UseCase } from './usecase/UseCase';

interface zoomOutUseCaseOptions {
  canvasId:string
}

export const zoomOutUseCase:UseCase = {
  type: 'ZOOM_OUT',

  run: (options: zoomOutUseCaseOptions) => {
    let canvas = CanvasAreaFactory.getCanvasById(options.canvasId);
    canvas.zoomOut(1.5);
    return canvas.render$();
  }
};

export function zoomOut(options: zoomOutUseCaseOptions){
  app.runUseCase(zoomOutUseCase, options);
}
