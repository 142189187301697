import { Product } from '../domain/Product/Product';
import { addProductUseCase } from '../usecases/addProduct';
import { editProductUseCase } from '../usecases/editProduct';
import { removeProductUseCase } from '../usecases/removeProduct';
import { editRosterUseCase } from '../usecases/editRoster';
import { emitCanvasEventUseCase } from '../usecases/emitCanvasEvent';
import { openSavedOrderUseCase } from '../usecases/openSavedOrder';
import { loadSubmittedOrderUseCase } from '../usecases/loadSubmittedOrder';
import { addRosterItemUseCase } from '../usecases/roster/addRosterItem';
import { addMultipleRosterItemsUseCase } from '../usecases/roster/addMultipleRosterItems';
import { setActiveProductItemNumberUseCase } from '../usecases/setActiveProductItemNumber';
import { previewRosterItemUseCase } from '../usecases/roster/previewRosterItem';
import { removeRosterItemUseCase } from '../usecases/roster/removeRosterItem';
import { updateRosterItemUseCase } from '../usecases/roster/updateRosterItem';
import { showAddRosterStepUseCase } from '../usecases/showAddRosterStep';
import { showReviewStepUseCase } from '../usecases/showReviewStep';
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { ProductReducer } from './ProductReducer';
import { copySubmittedOrderUseCase } from '../usecases/copySubmittedOrder';
import { selectShippingMethodUseCase } from '../usecases/selectShippingMethod';
import { selectStoreLocationUseCase } from '../usecases/selectStoreLocation';
import { initPartnerWithColorsUseCase } from '../usecases/init/initPartnerWithColors';
import { validateRosterItemsUseCase } from '../usecases/roster/validateRosterItems';
import { setGrayGoodUseCase } from '../usecases/setGrayGood';
import { getSalesTaxByZipUseCase } from '../usecases/getSalesTaxByZip';
import { getPriceByOrderUseCase } from '../usecases/getPriceByOrder';
import { reportMessage } from '../../gui/util/rollbar';
import { prepareOrderForSavingUseCase } from '../usecases/prepareOrderForSaving';
import { updateAfterRemovingProductUseCase } from '../usecases/updateAfterRemovingProduct';

export interface OrderReducerState {
  activeItemNumber: string;
  originallyCopiedFrom: string;
  products: Product[];
  shippingMethod: any;
  storeLocation: any; 
  allShippingOptions: any;
  orderShippingOptions: any;
  titalItems: number;
  totalPrice: number;
  totalCost: number;
  totalSalesTax: number;
  totalSizePriceOffset: number;
  minOrderFee: number;
  isLoadedOrder: boolean;
  stateSalesTax: number;
  priceModel: any;
  orderStateSnippet: any;
  shippingAndProductPrice: number;
  totalProductsPrice: number;
}

const initState: OrderReducerState = {
  activeItemNumber: undefined,
  originallyCopiedFrom: undefined,
  shippingMethod: undefined,
  storeLocation: undefined,
  allShippingOptions: undefined,
  orderShippingOptions: undefined,
  products: [],
  titalItems: 0,
  totalPrice: 0,
  totalCost: 0,
  totalSalesTax: 0,
  totalSizePriceOffset: 0,
  minOrderFee: 0,
  isLoadedOrder: false,
  stateSalesTax: 0,
  priceModel: undefined,
  orderStateSnippet: undefined,
  shippingAndProductPrice: 0,
  totalProductsPrice: 0
};

export function OrderReducer(state: OrderReducerState = initState, actionResult: UseCaseResult): OrderReducerState {

  switch (actionResult.type) {

    case initPartnerWithColorsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const shippingOptions = actionResult.data.partnerData.Partner[0].shippingOptions;
        return Object.assign({}, state, {
          allShippingOptions: shippingOptions,
          orderShippingOptions: shippingOptions,
          storeLocation: {
            "name": actionResult.data.partnerData.Partner[0].address1
            ,"address1":actionResult.data.partnerData.Partner[0].address1
            ,"address2":actionResult.data.partnerData.Partner[0].address2
            ,"postalCode":actionResult.data.partnerData.Partner[0].postalCode
            ,"city":actionResult.data.partnerData.Partner[0].city
            ,"state":actionResult.data.partnerData.Partner[0].state
            ,"salesTaxType":actionResult.data.partnerData.Partner[0].salesTaxType
          },
        })
      }

      return state;

    case prepareOrderForSavingUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const orderStateSnippet = actionResult.data.orderStateSnippet;
        const products = actionResult.data.orderState;
        let newState = Object.assign(state, {
          orderStateSnippet,
          products
        });

        //addTotalsToState(newState);

        return newState;
      }
      return state;

    case loadSubmittedOrderUseCase.type:
    case openSavedOrderUseCase.type:
    case copySubmittedOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const savedOrder = actionResult.data.savedOrder;
        const products = savedOrder.orderState;
        let newState = Object.assign({}, initState, {
          activeItemNumber: actionResult.data.product.ItemNumber,
          originallyCopiedFrom: actionResult.data.originallyCopiedFrom,
          allShippingOptions: state.allShippingOptions,
          orderShippingOptions: state.orderShippingOptions,
          isLoadedOrder: true,
          products
        });

        //addTotalsToState(newState);

        return newState;
      }

      return state;

    case selectShippingMethodUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        let newState = Object.assign({}, state, {
          shippingMethod: actionResult.data
        });
        updateShippingMethod(newState);
        return newState;
      }
      return state;

    case selectStoreLocationUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        let newState = Object.assign({}, state, {
          storeLocation: actionResult.data,
          orderStateSnippet:  { ... state.orderStateSnippet, storeLocation: actionResult.data }
        });
        return newState;
      }
      return state;

    case setActiveProductItemNumberUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          activeItemNumber: actionResult.data
        });
      }

      return state;

    case addProductUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const product = actionResult.data.product;
        const newProduct = ProductReducer(product, actionResult);
        newProduct.ItemNumber = state.activeItemNumber;

        //copy roster from any product of the same id
        // const existingProduct = state.products.filter((p) => { return p.Id = product.Id });
        // if (existingProduct && existingProduct.length > 0 )
        //   newProduct.RosterItems = existingProduct[0].RosterItems ;

        const newState = Object.assign({}, state, {
          activeItemNumber: state.activeItemNumber,
          products: [
            ...state.products,
            newProduct
          ]
        });

        //addTotalsToState(newState);
        return newState;
      }

      return state;


    case removeProductUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {

        let newActiveProductItemNumber = undefined;

        const newProducts = state.products.filter(function (p) {
          return p.ItemNumber !== actionResult.data.ItemNumber
        });

        if (state.activeItemNumber == actionResult.data.ItemNumber) {
          if (newProducts.length > 0)
            newActiveProductItemNumber = newProducts[0].ItemNumber;
        } else {
          if (newProducts.length > 0)
            newActiveProductItemNumber = state.activeItemNumber;
        }

        const newState = Object.assign({}, state, {
          activeItemNumber: newActiveProductItemNumber,
          products: newProducts
        });

        return newState;
      }

      return state;

    case editProductUseCase.type:
    case openSavedOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        let newState = Object.assign({}, state, {
          products: products
        });
        //addTotalsToState(newState);
        return newState;
      }

      return state;

    case editRosterUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        return Object.assign({}, state, {
          activeProductItemNumber: actionResult.data.product.ItemNumber,
          products: products
        });
      }

      return state;

    case addMultipleRosterItemsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        const newState = Object.assign({}, state, {
          products: products
        });

        //addTotalsToState(newState);
        return newState;
      }

      return state;

    case addRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        const newState = Object.assign({}, state, {
          products: products
        });

        //addTotalsToState(newState);
        return newState;
      }

      return state;

    case updateRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        const newState = Object.assign({}, state, {
          products: products
        });

        //addTotalsToState(newState);
        return newState;
      }

      return state;

    case removeRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        const newState = Object.assign({}, state, {
          products: products
        });

        //addTotalsToState(newState);
        return newState;
      }

      return state;

    case emitCanvasEventUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          products: state.products.map((p) => {
            if (p.ItemNumber === state.activeItemNumber) {
              return ProductReducer(p, actionResult);
            }

            return p;
          })
        });
      }

      return state;

    case previewRosterItemUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }
      if (actionResult.start() || actionResult.failure() || actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        return Object.assign({}, state, {
          products: products
        });
      }

      return state;

    case showAddRosterStepUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        return Object.assign({}, state, {
          products: products
        });
      }

      return state;

    case showReviewStepUseCase.type:
    case updateAfterRemovingProductUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult)).filter((p) => { return p.RosterItems.length > 0 });
        const newState = Object.assign({}, state, {
          products: products,
          priceModel: actionResult.data.priceModel.priceModel
        });
        addTotalsToState(newState, actionResult.data.priceModel.priceModel);
        return newState;
      }

      return state;

    case validateRosterItemsUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const products = state.products.map(p => ProductReducer(p, actionResult));
        const newState = Object.assign({}, state, {
          products: products
        });

        return newState;
      }

      return state;

    case setGrayGoodUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const newState = Object.assign({}, state, {
          isLoadedOrder: false
        });

        return newState;
      }

      return state;

    case getSalesTaxByZipUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const salesTax = actionResult.data.priceModel.priceModel.tax;
        const newState = Object.assign({}, state, {
          stateSalesTax: salesTax ?? 0,
          priceModel: actionResult.data.priceModel.priceModel
        });

        addTotalsToState(newState, newState.priceModel);
        return newState;
      }
      return state;

    case getPriceByOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
      }

      if (actionResult.success()) {
        const currentState = state;
        addTotalsToState(state, actionResult.data.priceModel.priceModel);
        return currentState;
      }

      return state;
  }

  return state;
}

function addTotalsToState(state: any, totals) {
  //this is now comming from the pricing API
  state.totalProductsPrice = totals.price;
  state.totalCount = totals.count;
  //state.totalCost = totals.cost; front end shouldn't know about costs
  state.totalSizePriceOffset = totals.offset;
  state.minOrderFee = totals.minFee;
  state.totalSalesTax = totals.tax;
  state.totalPrice = totals.price + state.shippingAndProductPrice;
  updateOrderShippingOptions(state); //changing totals or price, update shipping options
  updateShippingMethod(state);
}

function updateShippingMethod(state: any) {
  let selectedShippingPrice = state.shippingMethod ? parseFloat(state.shippingMethod.retail) : 0;
  state.shippingAndProductPrice = state.totalCount > 0 ? (state.totalProductsPrice + selectedShippingPrice) : 0;
  state.totalPrice = state.shippingAndProductPrice;
}

function updateOrderShippingOptions(state: any) {
  const totalItemsCount = state.totalCount;
  if (state.allShippingOptions) {
    const allOptions = state.allShippingOptions;

    let qtys = allOptions.map(o => parseInt(o.qty))
    qtys = qtys.sort(function (a, b) {
      return a - b;
    });

    qtys = qtys.filter(function (item, pos) {
      return qtys.indexOf(item) == pos;
    }); //order and filter repetitions

    let firstBiggerThanIndex = -1;
    qtys.forEach((q, i) => {
      if (firstBiggerThanIndex == -1 && q >= totalItemsCount)
        firstBiggerThanIndex = i
    });
    const lower = (firstBiggerThanIndex > 0) ? qtys[firstBiggerThanIndex - 1] : 0;
    const upper = qtys[firstBiggerThanIndex];
    //now filter by boundaries
    state.orderShippingOptions = allOptions.filter((q) => parseInt(q.qty) > lower && parseInt(q.qty) <= upper);
    if (state.orderShippingOptions.length == 0 && allOptions.length > 0)
      state.orderShippingOptions = [allOptions[allOptions.length - 1]];

    if (state.shippingMethod && state.orderShippingOptions.indexOf(state.shippingMethod) == -1) //option is not available anymore, reset
      state.shippingMethod = undefined;
  }
}