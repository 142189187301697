import { UseCase } from './usecase/UseCase';
import { app } from '../initApp';

export interface grayGoodPageNumIncrementUseCaseOptions {
  pageNum: number;
}

export const grayGoodPageNumIncrementUseCase: UseCase = {
  type: 'GRAY_GOOD_PAGE_NUM_INCREMENT',

  run: ( options: grayGoodPageNumIncrementUseCaseOptions ) => {
    return Cx.resolve({
      activeGrayGoodPageNum: options.pageNum + 1 
    });
  }
};


export function canGrayGoodPageNumIncrement( state ) {
  return state.activeGrayGoodPageNum + 1 <= state.activeGrayGoodPageTotal
}
/**
 * @category Use Case
 */
export default function grayGoodPageNumIncrement( options: grayGoodPageNumIncrementUseCaseOptions ) {
  app.runUseCase( grayGoodPageNumIncrementUseCase, options );
}
