import { RootState } from '../Application';
import { isDesignReady } from '../domain/Design/Conditions';
import { TextDesignElement  } from '../domain/Design/TextDesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { updateTextDesignElement$ } from './actions/updateTextDesignElement$';
import { Validator } from '../screen/Validator';
import { UseCase } from './usecase/UseCase';
import { Units } from '../../canvas/helpers/units'
import { getDecorationArea, getInDecorationAreaName } from '../usecases/actions/canvas';
import { CanvasAreaFactory } from '../../canvas/CanvasArea';

export interface IncrementTextSpacingOptions {
  designElement: TextDesignElement;
  increment: number;
}

const isValidIncrement = new Validator({
  check: (options:IncrementTextSpacingOptions) => {
    const designElement = options.designElement;
    const value = designElement.spacing + options.increment;

    const canvas = CanvasAreaFactory.getCanvasById(options.designElement.canvasId);
    const areaId = options.designElement.decorationAreaId;  

    const target = <any>getDecorationArea(canvas, areaId);

    const figure = <any>canvas.figures.find((f) => {
      return getInDecorationAreaName(f) === areaId;
    });

    if (value < 0) {
      return 'increment spacing expects greater or requal than zero values';
    }

    if ( figure.bounds_().width + Units.toDocument(options.increment) > target.bounds_().width ) {
      return 'increment spacing exceeds target design area boundaries'
    }

    return undefined;
  }
});

export const incrementTextSpacingUseCase: UseCase = {
  type: 'INCREMENT_TEXT_SPACING',

  checkConditions: (state: RootState) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: ( options: IncrementTextSpacingOptions ) => {
    const designElement = options.designElement;
    const value = designElement.spacing + options.increment;

    if(!designElement.fontIsScript)
      designElement.spacing = +value;

    // update on figure
    return updateTextDesignElement$(designElement).then(() => {
      return Cx.resolve(options);
    });
  }
};

/**
 * @category Can
 */
export function canIncrementTextSpacing( state: RootState ) {
  return incrementTextSpacingUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function incrementTextSpacing( options: IncrementTextSpacingOptions ) {
  const ruleResult = Validator.evaluate(options, [isValidIncrement]);

  if(ruleResult.isAllowed){
    app.runUseCase( incrementTextSpacingUseCase, options );
  }
}
