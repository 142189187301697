import { initPartnerWithColorsUseCase } from '../usecases/init/initPartnerWithColors';
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { ConfigState, initialState } from './Base';
import { setJerseySchemmeColorSelectedUseCase } from '../usecases/setJerseySchemmeColorSelected'
import { swapJerseyColorListUseCase } from '../usecases/swapJerseyColorList'
//import { grayGoodGetThumb3DUseCase } from '../usecases/grayGoodGetThumb3DUseCase'
import { reportMessage } from '../../gui/util/rollbar';
export function ConfigReducer(state = initialState.config, action: UseCaseResult) : ConfigState {
  switch (action.type) {

    // case grayGoodGetThumb3DUseCase.type:
    //   if (action.success()) {
    //     return {
    //       ...state,
    //       jerseyThumbColorMap: {
    //         ...state.jerseyThumbColorMap, [action.data.thumb3DKey]: action.data.createdDocId
    //       }
    //     }
    //   }
    //   return state ;

    case initPartnerWithColorsUseCase.type:
      if (action.failure())
        reportMessage(action.type, action.rejectionReason);

      if (action.success()) {
        let colors = getColors(action.data.colors).sort((a, b) => (a.name > b.name) ? 1 : -1);
        colors = sanitaizeColors(colors);    
        const jerseyColors = completeSpotColorsForJerseyColorConfig(action.data.partnerData, colors)    

        return Object.assign({}, state, {
          // @ts-ignore - TODO: payload not defined on action (should be data?)
          backendConfig: action.data.partnerData,
          colorList: colors,
          jerseyDefaultColors: jerseyColors,
          jerseyAppliedDefaultColors: Cx.cloneDeep(jerseyColors)
        });
      }

      return state;

    case setJerseySchemmeColorSelectedUseCase.type:
      if (action.failure())
        reportMessage(action.type, action.rejectionReason);

      if (action.success()) {

        let jerseyColors = state.jerseyDefaultColors ;
        const changedColor = { RGB: action.data.color.rgb, SpotColorName: action.data.color.spotColorName }
        jerseyColors[action.data.index] = changedColor ;

        return Object.assign({}, state, {
          // @ts-ignore - TODO: payload not defined on action (should be data?)
          jerseyDefaultColors: Cx.cloneDeep(jerseyColors)
        });
      }

      return state;

    case swapJerseyColorListUseCase.type:
      if (action.failure())
        reportMessage(action.type, action.rejectionReason);

      if (action.success()) {
        return {
          ...state, 
          jerseyAppliedDefaultColors: Cx.cloneDeep(state.jerseyDefaultColors)
        }
      }

    return state ;

    default:
      return Object.assign({}, state, { }); ;
  }
}

function getColors (colorList): Cx.Color[] {
  return colorList.map((data) => {
    return colorFromCore(data);
  });
}

function sanitaizeColors(colorList): Cx.Color[] {
  return colorList.map((c) => {
    c.name = sanitizeColorName(c.name);
    return c ;
  });
}

function sanitizeColorName(name) {
  return name.substring(name.indexOf('TS_')+3, name.length).replaceAll('_', ' ')
}

function completeSpotColorsForJerseyColorConfig(partnerData, colors) {
  let schemeColors = [];
  Object.keys(partnerData.PartnerBranding[0].jerseyDefaultColors).forEach((colorKey) => {
    const color = partnerData.PartnerBranding[0].jerseyDefaultColors[colorKey].replace('#','');
    let schemeColor = { RGB : color, SpotColorName: ''}
    const matchingColor = colors.filter( (c) => { return c.rgb.toUpperCase() === color.toUpperCase() } );
    if (matchingColor.length > 0 )
      schemeColor.SpotColorName = matchingColor[0].spotColorName 
    schemeColors.push(schemeColor);
  });
  return schemeColors ;
}

function colorFromCore (data): Cx.Color {
  const argb = data.ARGB;

  return new Cx.Color({
    alpha: +(parseInt(argb.substring(0, 2), 16) / 255).toFixed(2),
    cmyk: null,
    name: data.Name,
    owner: {}, // Preserve ownership
    rgb: argb.substring(2, 8).toUpperCase(),
    spotColorName: data.SpotColorName
  });
}