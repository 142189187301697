import { coachHasSession$ } from './login';

export async function getCoachSessionData$ (sessionToken: string) {
  const coachHasSessionResult = await coachHasSession$(sessionToken);
  const hasSession = coachHasSessionResult.hasSession;

  if (!hasSession){
    return Promise.reject('Coach does not have session');
  }

  const coach = coachHasSessionResult.coach;

  return {
    coach,
    hasSession,
    sessionToken: coachHasSessionResult.sessionToken
  };
}

// TODO: Load on demand when needed, don't make this part of getting/validating the coaches session.
// async function getOrderData$ (coachesKey: string) {
//   const asyncOrderDataFunctions$ = [
//     // getSession$(),
//     getOrderStatesByCoachesKey$(coachesKey),
//     getOrdersByCoachesKey$(coachesKey)
//   ];
//
//   const result = await Cx.whenAll(asyncOrderDataFunctions$);
//   return {
//     savedOrders: result[1],
//     // session: result[0],
//     submittedOrders: result[2]
//   };
// }
