import React from 'react';
import { Product, getValidThumbUrl, CxRenderThumbnail } from '../../../app/domain/Product/Product';
import Button from '../misc/Button';
import DisplayRosterGrid from './roster/DisplayRosterGrid';
import styles from './ReviewItem.module.css';
import { isMobile } from '../../../gui/util/isMobile';
import global from '../../../gui/services/global';
import { uniqueId } from 'lodash';

interface Props {
  product: Product;
  onEditDesignClick?: Function; // Optionally show edit button
  onEditRosterClick?: Function; // Optionally show edit button
  onDeleteProductClick?: Function; // Optionally show delete button
  cadworxSessionId?: string;
}

const ReviewItem = (props: Props) => {
  const product = props.product;
  const cadworxSessionId = props.cadworxSessionId;
  const designStyle = product.DesignStyle;
  const designStyleName = designStyle ? designStyle.Name : '';
  const grayGood = product.GrayGood;
  const grayGoodName = grayGood ? grayGood.Name : '';
  const showMobile: boolean = isMobile();
  const price = product.Price;
  const rosterItems = product.RosterItems;
  const totalItems = product.RosterItems?.length;
  const imgContainer: string = showMobile ? styles.imgContainerMobile : styles.imgContainer;

  const frontThumbModel: CxRenderThumbnail = {
    DocId: product.SavedDocId,
    Size: global._SNAPSHOT_THUMB_SIZE_,
    Camera: 'Front',
    cadworxSessionId,
    type: 'img'
  }

  const backThumbModel: CxRenderThumbnail = {
    DocId: product.SavedDocId,
    Size: global._SNAPSHOT_THUMB_SIZE_,
    Camera: 'Back',
    cadworxSessionId,
    type: 'img'
  }

  const sizes = Array.from(product.RosterItems
    .reduce((m, { Size, Quantity }) =>
      m.set(Size, (m.get(Size) || 0) + Number(Quantity)), new Map),
    ([size, quantity]) => ({ size, quantity }));

  function handleOnLoad(e) {
    const imgElement = e.target;
    imgElement.parentElement.firstElementChild.style.display = 'none';
  }

  return (<>
    {showMobile
      ?
      <div className={styles.reviewItemMobile}>
        <div className={styles.reviewContainer}>
          <div className={styles.thumbFront}>
            <div className={styles.spinnerContainer}>
              <span className={styles.thumbLoading + ' fas fa-circle-notch fa-spin'}></span>
            </div>
            <img src={getValidThumbUrl(frontThumbModel, product)} onLoad={handleOnLoad} />

          </div>
          <div className={styles.productDescription}>
            <div className={styles.productName}>
              <div className={styles.grayGood + ' partner-color2'}>{grayGoodName}</div>
            </div>
            <div className={styles.designStyle}>{designStyleName}</div>
            <div className={styles.divider}></div>
            <div className={styles.quantity}>{totalItems} ITEMS</div>
            <div className={styles.pruductPrice}>
              <div className={styles.price}>${price} Each</div>
              <div className={styles.subTotalLabel}>SubTotal: <span className={styles.subTotalValue}>${product.TotalPrice?.toFixed(2)}</span></div>
            </div>
          </div>
        </div>
        <div
          className={styles.sizesSummary}>
          SIZES: {sizes.map((item: any, i: number) => {
            return <span
              className={styles.sizes}
              key={uniqueId()}>
              {item.size} - <b>{item.quantity}</b>{i === (sizes.length - 1) ? '' : ','}
            </span>
          })}
        </div>
        <div className={styles.rosterGridContainer}>
          <DisplayRosterGrid className={styles.columnTwo} rosterItems={rosterItems} product={product} /></div>
        <div className={styles.mobileFooterButtons}>
          <EditDesignButton
            className={styles.footerEditDesignButton}
            onClick={props.onEditDesignClick} />
          <span className={styles.separator}>|</span>
          <EditRosterButton
            className={styles.editRosterButton}
            onClick={props.onEditRosterClick} />
          <span className={styles.separator}>|</span>
          <DeleteProductButtonMobile
            className={styles.deleteButton}
            onClick={props.onDeleteProductClick} />
        </div>
      </div>
      :
      <><div className={styles.reviewItem}>
        <div className={styles.itemHeader}>
          <div className={styles.grayGood + ' partner-color2'}>{grayGoodName}</div>
          <div className={styles.price}>${price} Each</div>
          <div className={styles.subTotalLabel}>SubTotal: <span className={styles.subTotalValue}>${product.TotalPrice?.toFixed(2)}</span></div>
        </div>
        <div className={styles.designStyle}>{designStyleName}</div>
        <div className={styles.itemNumber}>Item Number: {product.ItemNumber}</div>

        <div className={styles.removeItem}>
          <DeleteProductButton
            className={styles.deleteButton}
            onClick={props.onDeleteProductClick} />
        </div>
        <div className={styles.body}>
          <div className={styles.bodyColumnOne}>
            <div className={imgContainer}>
              <div className={styles.spinnerContainer}>
                <span className={styles.thumbLoading + ' fas fa-circle-notch fa-spin'}></span>
              </div>
              <img src={getValidThumbUrl(frontThumbModel, product)} onLoad={handleOnLoad} />
              <img src={getValidThumbUrl(backThumbModel, product)} onLoad={handleOnLoad} />
            </div>
          </div>
          <div className={styles.rosterGridTable}>
            <DisplayRosterGrid className={styles.columnTwo} rosterItems={rosterItems} product={product} />
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.columnOne}>
            <EditDesignButton
              className={styles.footerEditDesignButton}
              onClick={props.onEditDesignClick} />
          </div>
          <div className={styles.columnTwo}>
            <EditRosterButton
              className={styles.editRosterButton}
              onClick={props.onEditRosterClick} />
          </div>
        </div>
      </div></>
    }
  </>)
};

const DeleteProductButton = (props) => {
  if (props.onClick) {
    return <Button
      className={props.className + ' partner-color1'}
      iconCls="fa-trash-alt"
      label="Remove This Item"
      onClick={props.onClick} />
  }

  return null;
}

const EditDesignButton = (props) => {
  if (props.onClick) {
    return <Button
      className={props.className + ' partner-color1'}
      label="Edit Design"
      onClick={props.onClick} />
  }

  return null;
}

const EditRosterButton = (props) => {
  if (props.onClick) {
    return <Button
      className={props.className + ' partner-color1'}
      label="Edit Roster"
      onClick={props.onClick} />
  }

  return null;
}

const DeleteProductButtonMobile = (props) => {
  if (props.onClick) {
    return <Button
      className={props.className + ' partner-color1'}
      label="Remove"
      onClick={props.onClick} />
  }

  return null;
}

export default ReviewItem;