import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

interface SetInitialCoachUseCaseOptions {
  coachKey: string;
}

export const setInitialCoachUseCase: UseCase = {
  type: 'SET_INITIAL_Coach',

  run: ( options: SetInitialCoachUseCaseOptions ) => {
    return Cx.resolve({
      coachKey: options.coachKey
    });
  }
};

/**
 * @category Can
 */
export function canSetInitialCoach( state ) {
  return setInitialCoachUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function setInitialCoach( options: SetInitialCoachUseCaseOptions ) {
  app.runUseCase( setInitialCoachUseCase, options );
}
