import { uniqueId } from 'lodash';
import React, { useRef, useState } from 'react';
import { SavedOrder } from '../../../app/domain/Order/SavedOrder';
import { getValidThumbUrl } from '../../../app/domain/Product/Product';
import { formatDate } from '../../util/formatDate';

// Styles
import styles from './SavedOrdersSummary.module.css';

interface Props {
  className: string;
  onOrderClick: Function;
  orders: SavedOrder[];
  cadworxSessionId?: string;
}

const SavedOrdersSummary = (props: Props) => {
  const orders = props.orders;
  const [invalidIds, setInvalidIds] = useState([]);
  const img: any = useRef([]);

  function hideDivById(id) {
    setInvalidIds(invalidIds => [...invalidIds, id]);
  }

  function checkInvalidId(id) {
    return invalidIds.indexOf(id) > -1 ? "none" : "";
  }

  function handleOnLoad(e) {
    const imgElement = e.target;
    imgElement.parentElement.firstElementChild.style.display = 'none';
  }

  return <div className={props.className || ''}>
    {
      orders.length > 0
        ?
        <React.Fragment>
          <div className={styles.orderItemHeader}>
            <div className={styles.header}></div>
            <div className={styles.header}>ITEMS</div>
            <div className={styles.header}>TOTAL</div>
            <div className={styles.header}>LAST UPDATED</div>
          </div>
          {orders.map((savedOrder: SavedOrder) => {
            const date = formatDate(savedOrder.lastUpdatedOn);
            const columnData = getColumnData(savedOrder);
            const products = columnData?.products;
            const onClick = () => props.onOrderClick(savedOrder);

            return <div className={styles.orderItem} key={savedOrder.id} onClick={onClick} style={{ display: checkInvalidId(savedOrder.id) }}>
              <div className={styles.thumbListPreview}>
                <div className={styles.spinnerContainer}>
                  <span className={styles.thumbLoading + ' fas fa-circle-notch fa-spin'}></span>
                </div>
                {products && products.map(function (product: any, index: number) {
                  return <img
                      ref={el => img.current[0] = el}
                      onLoad={handleOnLoad}
                      onError={(event) => { hideDivById(savedOrder.id) }}
                      key={uniqueId()}
                      className={styles.imageColumn}
                      src={getValidThumbUrl({
                        Size: 100,
                        Camera: 'Front',
                        DocId: product.savedDocId,
                        cadworxSessionId: props.cadworxSessionId,
                        type: "img"
                      }, savedOrder.orderState[index])} alt="front" />                 
                })}
              </div>
              <div className={styles.column}>
                <span className={styles.mobileHeader}>ITEMS</span>
                {columnData.productCount}
              </div>
              <div className={styles.column}>
                <span className={styles.mobileHeader}>TOTAL</span>
                $ {columnData.total}
              </div>
              <div className={styles.column}>
                <span className={styles.mobileHeader}>LAST UPDATED</span>
                {date}
              </div>
            </div>
          })}
        </React.Fragment>
        :
        <div className={styles.noItemsFound}>No items found...</div>
    }
  </div>
}

function getColumnData(order: SavedOrder) {
  const orderSnippet: any = order.orderStateSnippet || [];
  let total = 0;

  orderSnippet?.products?.forEach((p) => {
    if (p.total)
      Cx.warning('total property on order snippet no longer supported.');

    total += p.totalPrice;
  });

  return {
    products: orderSnippet?.products,
    productCount: orderSnippet?.products?.length,
    total
  };
}

export default SavedOrdersSummary;
