import React from 'react';
import Field from './Field';
import Input from './Input';

/**
 * pattern="-?([0-9]+([.][0-9]*)?|[.][0-9]+)"
 *
 * Pattern Matches:
 * No decimal point (i.e. an integer value)
 * Digits both before and after the decimal point (e.g. 0.35 , 22.165)
 * Digits before the decimal point only (e.g. 0. , 1234.)
 * Digits after the decimal point only (e.g. .0 , .5678)
 *
 * The following are not allowed:
 * A decimal point on its own
 * A signed decimal point with no digits (i.e. +. or -.)
 * + or - on their own
 * An empty string
 */

interface Props {
  canvasId?: string;
  decorationAreaId?: string;
  className?: string;
  label?: string;
  onChange?: any;
  onFocus?: any;
  placeholder?: string;
  value: string;
}

const NumberInput = (props:Props) => {
  return (
    <Field className={props.className} label={props.label}>
      <Input
        onChange={(e) => {
          const value: any = e.target.value;

          if (!isNaN(value)) {
            props.onChange(value);
          }
        }}
        onFocus={props.onFocus}
        onKeyDown={(e) => {
          // Allow numbers, tab, backspace, delete, left and right arrows
          const key = e.keyCode || e.which;
          const allowedKeys = [8, 9, 37, 39, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
          const isAllowed = e.ctrlKey === false && e.shiftKey === false && allowedKeys.indexOf(key) !== -1;

          if (!isAllowed) {
            if (e.preventDefault) e.preventDefault();
            e.returnValue = false;
          }
        }}
        pattern="-?([0-9]+([.][0-9]*)?|[.][0-9]+)"
        placeholder={props.placeholder}
        value={props.value}
        name="nm_number" />
    </Field>
  );

}

export default NumberInput;