/* global Cx */

import { report } from "../util/rollbar";

export const getContainersFromLocalStorage = ( container, hash ) => {
  if ( Cx.Storage.isAvailable() ) {
    return Cx.Storage.getObject( 'ubdesigner2.' + container + '.' + hash );
  }
};

export const saveContainersToLocalStorage = ( container, hash, data ) => {
  if ( Cx.Storage.isAvailable() ) {
    const key = 'ubdesigner2.' + container + '.' + hash;
    try {
      Cx.Storage.setObject( key, data );
      Cx.Storage.setObject( key + '-timestamp', Date.now() );
    } catch ( e ) {
      localStorage.clear();
      report(e);
    }
  }
};

export const saveAccessStart = (hash) => {
  saveContainersToLocalStorage('access', hash, 'pending');
}

export const saveAccessDelete = (hash) => {
  saveContainersToLocalStorage('access', hash, 'completed');
}

export const succeedAccessStart = (hash) => {
  saveContainersToLocalStorage('access', hash, 'success');
}

export const checkForAccess = (hash) => {
  let val = getContainersFromLocalStorage('access', hash)
  return val && val == 'success';
}

// export default class Storage {
//
//   public static Instance( cx = false ) {
//     if ( cx ) {
//       return Cx.Storage;
//     }
//     else {
//       // need more space than 5MBs
//
//       if ( 'storage' in navigator && 'estimate' in navigator.storage ) {
//
//       }
//       else if ( 'webkitTemporaryStorage' in navigator && 'queryUsageAndQuota' in navigator.webkitTemporaryStorage ) {
//
//       }
//
//       return {
//         setObject: function( key, val ) {
//
//         }
//         , getObject: function( key ) {
//
//         }
//       };
//     }
//   }
//
//   public static getContainersFromLocalStorage( container, hash ) {
//     if ( Cx.Storage.isAvailable() ) {
//       return Storage.Instance( true ).getObject( 'ubdesigner2.' + container + '.' + hash );
//     }
//   }
//
//   public static saveContainersToLocalStorage( container, hash, patternContainers ) {
//     if ( Cx.Storage.isAvailable() ) {
//       const key = 'ubdesigner2.' + container + '.' + hash;
//       try {
//         Storage.Instance( true ).setObject( key, patternContainers );
//         Storage.Instance( true ).setObject( key + '-timestamp', Date.now() );
//       } catch ( e ) {
//         localStorage.clear();
//       }
//     }
//   }
// }
