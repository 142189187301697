import global from '../gui/services/global';
import { Application } from './Application';
import {checkForSessionAtStartup} from './domain/Session/checkForSessionAtStartup';
import { initPartnerWithColors } from './usecases';
import { initFontListWithConfig } from './usecases/init/initFontListWithConfig';
import { loadPatterns } from './usecases/loadPatterns';
import initJerseyThumbColorMap from './usecases/initJerseyThumbColorMap'
import { queryString }  from '../gui/util/queryString'
import { setInitialPartner } from './usecases/setInitialPartner';

const app = Application();
export { app };

// We choose what to export in UseCases/index.ts
export * from './usecases';

// Init code
const pk = queryString("pk") ;
initPartnerWithColors({
  partnerKey: pk
});
setInitialPartner({
  partnerKey: pk
})
initFontListWithConfig({});
initJerseyThumbColorMap();
loadPatterns({});
checkForSessionAtStartup();
