import { isDesignReady } from '../domain/Design/Conditions';
import { TextDesignElement  } from '../domain/Design/TextDesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { removeOutline$ } from './actions/removeOutline$';
import { UseCase } from './usecase/UseCase';

export interface RemoveOutlineOptions {
  designElement: TextDesignElement;
  isActiveFillTarget: boolean;
}

export const removeOutlineUseCase: UseCase = {
  type: 'REMOVE_OUTLINE',

  checkConditions: (state) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: ( options: RemoveOutlineOptions ) => {
    const designElement = options.designElement;
    const isActiveFillTarget = options.isActiveFillTarget;

    return removeOutline$(designElement)
    .then(() => {
      return Cx.resolve({
        designElement,
        isActiveFillTarget
      });
    });
  }
};

/**
 * @category Can
 */
export function canRemoveOutline( state ) {
  return removeOutlineUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function removeOutline( options: RemoveOutlineOptions ) {
  app.runUseCase( removeOutlineUseCase, options );
}
