import { hasCoachesKey } from '../domain/Login/Conditions';
import { SavedOrder } from '../domain/Order/SavedOrder';
import { Product } from '../domain/Product/Product';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { getInitialFillTarget } from './actions/getInitialFillTarget';
import { loadCanvasDocument$ } from './actions/loadCanvasDocument$';
import { UseCase } from './usecase/UseCase';
import { getQueryStringJson } from '../../gui/util/queryString';

export interface OpenSavedOrderUseCaseOptions {
  canvasId: string;
  editDesign?: boolean;
  editRoster?: boolean;
  product: Product;
  savedOrder: SavedOrder;
}

export const openSavedOrderUseCase: UseCase = {
  type: 'OPEN_SAVED_ORDER',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [hasCoachesKey]);
  },

  run: ( options: OpenSavedOrderUseCaseOptions ) => {
    const canvasId = options.canvasId;
    const fillTarget = getInitialFillTarget(canvasId);
    const product = options.product;
    let savedDocId = product.SavedDocId;
    let sessionId ;

    const snapshotImage = product.FrontSnapshot?.Image; 
    if(snapshotImage) //pull doc info and credentials from snapshot image
    {
      Cx.info("STAT - Used querystring thumb params approach for backwards compat")
      const params = getQueryStringJson(snapshotImage);
      savedDocId = params.model.Doc.Id ;
      sessionId = params.ident.SessionId; 
    }

    return loadCanvasDocument$(canvasId, savedDocId, sessionId)
    .then((loadResult) => {
      return Cx.resolve({
        canvasId,
        docId: loadResult.docId,
        editDesign: options.editDesign,
        editRoster: options.editRoster,
        fillTarget,
        product,
        rosterSizeMap: loadResult.rosterSizeMap,
        rosterSizeOptions: loadResult.rosterSizeOptions,
        savedOrder: options.savedOrder
      });
    });
  }
};

/**
 * @category Can Use Case
 */
export function canOpenSavedOrder(state): boolean {
  return openSavedOrderUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function openSavedOrder( options: OpenSavedOrderUseCaseOptions ) {
  app.runUseCase( openSavedOrderUseCase, options );
}
