import { CanvasAreaFactory } from '../../canvas/CanvasArea';
import { isDesignReady, isPanMode } from '../domain/Design/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';

interface SetOrbitModeOptions {
  canvasId: string;
}

export const setOrbitModeUseCase:UseCase = {
  type: 'SET_ORBIT_MODE',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady/*, isPanMode*/]);
  },

  run: (options: SetOrbitModeOptions) => {
    const canvas = CanvasAreaFactory.getCanvasById(options.canvasId);
    canvas.setOrbitMode();
    return canvas.render$();
  }
};

export function canSetOrbitMode(state): boolean {
  return setOrbitModeUseCase.checkConditions( state ).isAllowed;
}

export function setOrbitMode(options: SetOrbitModeOptions){
  app.runUseCase(setOrbitModeUseCase, options);
}
