import { SubmittedOrder, submittedOrdersFromActionResult } from '../domain/Order/SubmittedOrder';
import { getSubmittedOrderUseCase } from '../usecases/getSubmittedOrder';
import { loadSubmittedOrdersUseCase } from '../usecases/loadSubmittedOrders';
import { submitOrderUseCase } from '../usecases/submitOrder';
import { UseCaseResult } from '../usecases/usecase/UseCaseResult';
import { reportMessage } from '../../gui/util/rollbar';
export interface SubmittedOrdersReducerState {
  orderItemLoading: boolean;
  orders: SubmittedOrder[];
  ordersLoaded: boolean;
  ordersLoading: boolean;
}

const initState = {
  orderItemLoading: false,
  orders: Array<SubmittedOrder>(),
  ordersLoaded: false,
  ordersLoading: false
};

export function SubmittedOrdersReducer (state: SubmittedOrdersReducerState = initState, actionResult: UseCaseResult): SubmittedOrdersReducerState {

  switch (actionResult.type) {

    case submitOrderUseCase.type:
    case loadSubmittedOrdersUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)
        return Object.assign({}, initState);
      }

      if (actionResult.start()) {
        return Object.assign({}, initState, {
          ordersLoading: true
        });
      }

      if (actionResult.success()) {
        return Object.assign({}, state, {
          orders: submittedOrdersFromActionResult(actionResult.data.submittedOrders),
          ordersLoaded: true,
          ordersLoading: false
        });
      }

      return state;

    case getSubmittedOrderUseCase.type:
      if (actionResult.failure()) {
        reportMessage(actionResult.type, actionResult.rejectionReason)    
        return Object.assign({}, state, {
          orderItemLoading: false
        });
      }

      if (actionResult.start()) {
        return Object.assign({}, state, {
          orderItemLoading: true
        });
      }

      if (actionResult.success()) {
        const order = actionResult.data.order;

        return Object.assign({}, state, {
          orderItemLoading: false,
          orders: state.orders.map((item) => {
            if (item.order.id === order.id) {
              return Object.assign({}, item, {
                orderState: order.orderState,
                orderStatus: actionResult.data.orderStatus
              });
            }

            return item;
          })
        });
      }

      return state;
  }

  return state;

}
