import { app } from '../initApp';
import { requestForAccessByGoogle$ } from './actions/login';
import { UseCase } from './usecase/UseCase';

export interface RequestGoogleLoginOptions {
  hash: string;
  returnUrl: string;
}

export const requestGoogleLoginUseCase: UseCase = {
  type: 'REQUEST_GOOGLE_LOGIN',

  run: ( options: RequestGoogleLoginOptions ) => {
    const hash = options.hash;

    return requestForAccessByGoogle$(options.returnUrl, hash)
    .then(() => {
      return Cx.resolve({ hash });
    });
  }
};

export function requestGoogleLogin( options: RequestGoogleLoginOptions ) {
  app.runUseCase( requestGoogleLoginUseCase, options );
}
