import { getOrderStatesByCoachesKeyUrl } from "../../../backendApi/backendApi";

export async function getOrderStatesByCoachesKey$ (key: string, partnersKey: string) {
  const url = getOrderStatesByCoachesKeyUrl(key, partnersKey);
  const resp = await fetch(url, {
    credentials: 'include'
  })

  if(resp.ok) {
    const jsonResp = await resp.json();
    return Promise.resolve(jsonResp)
  }

  return Promise.reject('Problem with getOrderStatesByCoachesKey');
}