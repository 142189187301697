import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const resetCanvasUseCase: UseCase = {
  type: 'RESET_CANVAS',

  run: () => {
    return Cx.resolve();
  }
};

/**
 * @category Use Case
 */
export function resetCanvas() {
  app.runUseCase( resetCanvasUseCase );
}
