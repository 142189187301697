import React from 'react';

interface Props {
  message: string;
}

const Alert = (props: Props) => {

  const style = {
    padding: '0.5em',
    width: '20em'
  };

  return <div style={style}>{props.message}</div>

}

export default Alert;