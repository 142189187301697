import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const dismissLoginErrorUseCase: UseCase = {
  type: 'DISMISS_LOGIN_ERROR',

  run: async ( ) => {
    // Nothing to do, just keeps UI dev consistent, calls a use case.
    // Just dispatches "DISMISS_LOGIN_ERROR".
    return Cx.resolve();
  }
};

export function dismissLoginError( ) {
  app.runUseCase( dismissLoginErrorUseCase );
}

