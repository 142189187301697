import { uniqueId } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { SubmittedOrder } from '../../../app/domain/Order/SubmittedOrder';
import { Product, getValidThumbUrl } from '../../../app/domain/Product/Product';
import { getSubmittedOrder } from '../../../app/initApp';
import { copySubmittedOrder } from '../../../app/usecases/copySubmittedOrder'
import { formatDate } from '../../util/formatDate';
import { isMobile } from '../../util/isMobile';
import useMedia from '../../util/useMedia';
import Button from '../misc/Button';

// Styles
import styles from './OrdersSummary.module.css';

interface Props {
  className: string;
  onOrderClick: Function;
  orders: SubmittedOrder[];
  partnerData?: any;
}

let initPageSize = 10;
const loading = [];

const maybeLoadOrder = (item: SubmittedOrder) => {
  const ordersKey = item.order.ordersKey;

  if (!item.orderStatus && loading.indexOf(ordersKey) === -1) {
    loading.push(ordersKey);
    getSubmittedOrder({ ordersKey });
  }
}

const OrdersSummary = (props: Props) => {
  const [currentCount, setCurrentCount] = useState(initPageSize);
  const orders = props.orders.slice(0, currentCount);
  const disableMoreButton = orders.length === props.orders.length;
  const [selectedOrder, setSelectedOrder] = useState<string>("");
  const [isOrderSelected, setIsOrderSelected] = useState<boolean>(false);
  let container = props.className || '';
  let matchMedia = useMedia("(max-width: 500px)");
  const mobile = (isMobile() || matchMedia) ? true : false;
  const [currentTop, setCurrentTop] = useState<any>(null);
  const img: any = useRef([]);

  function loadMore() {
    const size = currentCount + 2;
    // Cache so we have keep the size between modal showings
    initPageSize = size;
    setCurrentCount(size);
  }

  function handleClick(e, submittedOrder, key) {
    const target = e.currentTarget;
    const ordersScrollTarget = target.parentNode.parentElement.scrollTop;
    if (target) {
      let viewportOffset = target.getBoundingClientRect();
      let top = mobile ? viewportOffset.top : (ordersScrollTarget > 0 ? (viewportOffset.top - 70) : 'unset');
      setCurrentTop(top);
    }
    setIsOrderSelected(true);
    setSelectedOrder(key);
    props.onOrderClick(submittedOrder.order.ordersKey);
  }

  useEffect(() => {
    orders.forEach((item) => {
      maybeLoadOrder(item);
    });
  }, [currentCount]);

  function handleOnLoad(e) {
    const imgElement = e.target;
    imgElement.parentElement.firstElementChild.style.display = 'none';
  }

  container += ' ' + (isOrderSelected ? styles.noClick : '') + ' ' + styles.footerMargin;

  return <div className={container}>
    {
      props.orders.length > 0
        ?
        <React.Fragment>
          <div className={styles.orderItemHeader}>
            <div className={styles.header} style={{ width: "16.5%" }}></div>
            <div className={styles.header}>ITEMS</div>
            <div className={styles.header}>TOTAL</div>
            <div className={styles.header}>DATE</div>
            <div className={styles.header}>STATUS</div>
            <div className={styles.header}>COPY</div>
          </div>
          {orders.map((submittedOrder: SubmittedOrder, index: number) => {
            const date = formatDate(submittedOrder.createdOn);
            const columnData = getColumnData(submittedOrder);
            const products = submittedOrder?.orderState;
            const partner = props.partnerData;
            const key = submittedOrder.id;

            const Copy = () => {
              return <Button
                className={styles.loadMoreButton + ' partner-background-color1'}
                label="COPY"
                onClick={(e) => {
                  copySubmittedOrder({ ordersKey: submittedOrder.order.ordersKey })
                }} />
            }

            const Columns = () => {
              return <>
                <div className={styles.column}>
                  <span className={styles.mobileHeader}>ITEMS</span>
                  {columnData.productCount}
                </div>
                <div className={styles.column}>
                  <span className={styles.mobileHeader}>TOTAL</span>
                  $ {columnData.total.toFixed(2)}
                </div>
                <div className={styles.column}>
                  <span className={styles.mobileHeader}>DATE</span>
                  {date}
                </div>
                <div className={styles.column}>
                  <span className={styles.mobileHeader}>STATUS</span>
                  {columnData.orderStatus}
                </div>
                {!mobile ? <div className={styles.column}>
                  <span className={styles.mobileHeader}>COPY</span>
                  <div className={styles.copyBtn}>
                    <Copy />
                  </div>
                </div> : null}
              </>
            }

            const Loader = () => {
              return <>
                {key === selectedOrder
                  ?
                  <div
                    className={styles.selectedOrder} style={{ top: currentTop }}>
                    <i className='icon fas fa-circle-notch fa-spin'></i>
                    <span>Loading Order</span>
                  </div>
                  : null
                }
              </>
            }

            return <>
              {mobile
                ?
                <div className={styles.orderItem} key={key} onClick={(e) => handleClick(e, submittedOrder, key)}>
                  <div className={styles.thumbContainer}>
                    {products && products.map(function (product: any, index: number) {
                      return (index <= 2) && <img
                        key={uniqueId()}
                        className={styles.imageColumn}
                        src={getValidThumbUrl({
                          Size: 100,
                          Camera: 'Front',
                          DocId: product.SavedDocId,
                          cadworxSessionId: partner.cadworxSessionId,
                          type: "img"
                        }, submittedOrder.orderState[index])} alt="front" />
                    })}
                  </div>
                  <div className={styles.itemContainer}>
                    <Columns />
                    <div className={styles.column}>
                      <span className={styles.mobileHeader}>COPY</span>
                      <div className={styles.copyBtn}>
                        <div
                          className={styles.copyLink}
                          onClick={(e) => {
                            copySubmittedOrder({ ordersKey: submittedOrder.order.ordersKey })
                          }}
                        ><span
                          className="fas fa-copy partner-color1"></span> COPY</div>
                      </div>
                    </div>
                  </div>

                  <Loader />
                </div>
                :
                <div className={styles.orderItem} key={key} onClick={(e) => handleClick(e, submittedOrder, key)}>
                  <div className={styles.thumbListPreview}>
                    <div className={styles.spinnerContainer}>
                      <span className={styles.thumbLoading + ' fas fa-circle-notch fa-spin'}></span>
                    </div>
                    {products && products.map(function (product: any, index: number) {
                      return <img
                        ref={el => img.current[0] = el}
                        onLoad={handleOnLoad}
                        key={uniqueId()}
                        className={styles.imageColumn}
                        src={getValidThumbUrl({
                          Size: 100,
                          Camera: 'Front',
                          DocId: product.SavedDocId,
                          cadworxSessionId: partner.cadworxSessionId,
                          type: "img"
                        }, submittedOrder.orderState[index])} alt="front" />
                    })}
                  </div>
                  <Columns />
                  <Loader />
                </div>
              }
              {index !== (orders.length - 1) ? <div className={styles.orderDivider}></div> : null}</>
          })}
          <div className={styles.divider}></div>
          <div className={styles.loadMore}>
            <Button
              className={styles.loadMoreButton + ' partner-background-color1'}
              disabled={disableMoreButton}
              label="LOAD MORE..."
              onClick={() => {
                loadMore();
              }} />
          </div>
        </React.Fragment>
        :
        <div className={styles.noItemsFound}>No items found...</div>
    }
  </div>
}

function getColumnData(order: SubmittedOrder) {
  const orderState = order.orderState || [];
  const orderStatus = order.orderStatus;
  let productCount = 0;
  let total = 0;

  orderState.forEach((productData: Product) => {
    productCount += productData.TotalQuantity;
    total += productData.TotalPrice;
  });

  total = parseFloat(total.toFixed(2));

  return {
    orderStatus: orderStatus ? orderStatus.displayNames.general : 'Loading...',
    productCount,
    total
  };
}

export default OrdersSummary;