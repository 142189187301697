import React, { useState } from 'react';
import { requestGoogleLogin, RequestGoogleLoginOptions, requestForAccessByEmail, RequestForAccessByEmailOptions, requestPhoneCode, RequestPhoneCodeOptions } from '../../../../app/initApp';
import { getLoginReturnUrl } from '../../../../gui/util/queryString'
import { validateEmail } from '../../../util/validateEmail';
import { validatePhone } from '../../../util/validatePhone';
import Button from '../../misc/Button';
import TextInput from '../../misc/field/TextInput';

import styles from './LoginSignIn.module.css';

interface Props {
  requestFailed?: boolean;
  requestInProgress?: boolean;
  email?: string;
  token: string;
  errorMessage?: string;
  partnerInfo?: any;
  // requestingForAccess?: boolean;
}

export const LoginSignIn = (props: Props) => {

  const [fieldValue, setFieldValue] = useState(props.email);
  const [fieldValueIsEmail, setFieldValueIsEmail] = useState(false);
  const [fieldValueIsPhone, setFieldValueIsPhone] = useState(false);

  const [requestingMagicLink, setRequestingMagicLink] = useState(false);

  const requestInProgress = props.requestInProgress;
  const fieldLabel = "Phone or Email"; //'Email or Phone';
  const fieldIsValid = fieldValueIsEmail || fieldValueIsPhone;
  const disable = requestingMagicLink || requestInProgress;
  const buttonDisabled = disable || !fieldIsValid;
  const buttonIconCls = requestInProgress ? 'fas fa-circle-notch fa-spin' : 'fas fa-arrow-alt-circle-right';
  const buttonLabel = requestInProgress ? 'LOOKING FOR YOU...' : 'CONTINUE';

  function onKeyUp (event) {
    const value = event.target.value;
    const isEmail = validateEmail(value);
    const isPhone = validatePhone(value);

    setFieldValue(value);

    if (event.keyCode === 13) {
      onSignInClick();
    } else {
      setFieldValueIsEmail(isEmail);
      setFieldValueIsPhone(isPhone);
    }
  }

  function onSignInClick () {
    if (!buttonDisabled) {
      if (fieldValueIsEmail) {
        onLoginByEmail(fieldValue, props.token, getLoginReturnUrl(), props.partnerInfo);
        setRequestingMagicLink(true);
      }
      else if (fieldValueIsPhone) {
        onLoginByPhone(fieldValue);
        setFieldValue('');
      }
    }
  }

  function onSignInWithGoogleClick () {
    onLoginByGoogle(props.token, getLoginReturnUrl());
  }

  if (!navigator.cookieEnabled) {
    return <div className={styles.loginMain}>
      <div className={styles.parentcontent}>
        <div className={styles.cookiesDisclaimer}>
          Please enable cookies and try again
        </div>
      </div>
    </div>
  }

  return (
    <div className={styles.loginMain}>
      <div className={styles.parentcontent}>
        <div className={styles.loginMainTitle + ' partner-color1'}>Sign In / Create Account</div>
        <div className="provider">
          <div className={styles.inputContainer + ' partner-color2'}>
            <TextInput
              className={fieldIsValid ? styles.loginField : styles.loginFieldInvalid}
              disabled={disable}
              label={fieldLabel}
              labelOnTop={true}
              onKeyUp={onKeyUp}
              value={fieldValue} />
          </div>
          { props.errorMessage && 
            <div>
              <div className={styles.errorMessageContainer}>
              <div className={styles.errorTitle}>Verification failed!</div>
                <div className={styles.errorMessage}>{props.errorMessage}</div>
              </div>
            </div>
          }
          <div className={styles.buttonContainer}>
            <Button
              className={styles.loginButton + ' partner-color1 partner-border-color1'}
              disabled={buttonDisabled}
              iconCls={buttonIconCls}
              label={buttonLabel}
              onClick={onSignInClick} />
            {/*<div className={styles.buttonSep}>*/}
            {/*  <div className={styles.buttonSepLabel}>OR</div>*/}
            {/*</div>*/}
            {/*<Button*/}
            {/*  className={styles.googleLoginButton}*/}
            {/*  iconCls="fab fa-google"*/}
            {/*  label="SIGN-IN WITH GOOGLE"*/}
            {/*  onClick={onSignInWithGoogleClick} />*/}
          </div>
        </div>
      </div>
    </div>
  );
}

function onLoginByEmail (email, hash, returnUrl, partnerInfo) {
  const options: RequestForAccessByEmailOptions = { email, hash, returnUrl, partnerInfo };
  requestForAccessByEmail(options);
}

function onLoginByGoogle (hash, returnUrl) {
  const options: RequestGoogleLoginOptions = { hash, returnUrl };
  requestGoogleLogin(options);
}

function onLoginByPhone (phone: string) {
  const options: RequestPhoneCodeOptions = { phone };
  requestPhoneCode(options);
}
