import React, { useState } from 'react';
import styles from './ThumbChiclet.module.css'
import { GetThumbImg$ } from '../../../app/usecases/actions/pattern'
import { GetSpotColorTuplesTargets, GetSpotColorTuples } from '../../../app/domain/Pattern/colorZoneHelper'

interface Props {
  docId: string,
  selected: boolean;
  title: string,
  colorSet?: any,
  initialColors?: any,
  initialZones?: any,
  onColorClick?: Function,
  className?: string,
  config: any,
  index: number
}

const ThumbChiclet = (props: Props) => {
  const [img, setImage] = useState(null);

  const selected = props.selected;
  const checkmarkClassName = selected ? styles.checkmark : styles.checkmarkHidden;
  const className = props.className + ' ' + styles.tooltip + (selected ? ' ' + styles.selected : '');
  let colorTuple = null ;
  let colorTarget = null ;

  if ( props.initialZones && props.initialZones.length > 0)
  {
    colorTuple = GetSpotColorTuples(props.colorSet, props.initialColors, props.initialZones.map( (z) => z.value )); //thumbnail interface, uses literal color tuples
    colorTarget = GetSpotColorTuplesTargets(props.colorSet, props.initialZones.map( (z) => z.value)); //canvas replacement color interface, uses color zones
  }

  GetThumbImg$( props.docId
    , colorTuple
    , true
    , props.config.ThumbZoom
    , props.config.ThumbWidth
    , props.config.ThumbHeight
    , props.config.ThumbDPI
    , false ).then( (img) => {
      setImage(img);
    });
  return (
    img ?
    <div
      style={{backgroundImage: `url(${ img })` }}
      className={className}
      onClick={() => props.onColorClick(props.docId, colorTarget, props.index)}
      title={props.title}>
        <span className={styles.tooltiptext}>{props.title}</span>
        <span className={checkmarkClassName + ' fas fa-check'}></span>
    </div> :
    <div>Thumb loading...</div>
  );
};

export default ThumbChiclet;
