import { Units } from "./units";
import { GeneralHelper } from "./general"
import { CanvasAreaFactory } from "../CanvasArea"

declare const Cx:any;
declare const Cadx:any;

export class CanvasHelper {

  static resetSelection(canvasId:string) {
    let canvas = CanvasAreaFactory.getCanvasById(canvasId);
    canvas.selectedFigures = [];
  }

  static zoomOutToAll$(canvas:any, zoomMargin:number = 70){
   // let canvas = canvasArea.canvas;
    let activePageFigures = canvas.activePageFigures;
    // This errors as canvas.background is undefined
    // let figures = Cx.concat(activePageFigures, CanvasHelper.getBackgroundShapes(canvas));
  
    return canvas.zoomToFit$({
      figures: activePageFigures,
      margin: zoomMargin
    });
  }

  static addLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');

    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    if (hex === '000000') {
      return '999999';
    }

    lum = lum || 0;

    // convert to decimal and change luminosity
    let rgb = '';

    let c;
    let i;

    for (i = 0; i < 3; i++) {
      // to int
      c = parseInt(hex.substr(i * 2, 2), 16);
      // add lum
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      // append string
      rgb += (`00${c}`).substr(c.length);
    }

    return rgb;
  }

  static _calcSpacingPercent(origSpacing, adjustment) {
    adjustment = Number(adjustment);

    //Is the original spacing value a percentage?
    //var isPercentage = (origSpacing.indexOf('%') != -1);

    //Remove '%' for math.
    origSpacing = Number(origSpacing.slice(0, -1));

    let newSpacing = origSpacing + adjustment;

    //If new spacing is less than minimum -100%, make it the lowest possible value (-100).
    if (newSpacing < -100) newSpacing = -100;

    return `${newSpacing.toString()}%`;
  }

  //Percentage and absolute adjustment should be passed since we can't know which value type we will have and we don't have information to do conversion.
  //Depending on format, that is how the text spacing will be adjusted using either the percentage or absolute value.
  //See group email "[TX] Corel and CadX text"
  static _calcSpacing(origSpacing, percentAdjustment, absoluteAdjustment) {
    percentAdjustment = Number(percentAdjustment);
    absoluteAdjustment = Number(absoluteAdjustment);

    //Helper to get result as percent and do check if it is less than 100% (requirement for all spacing options).
    const resultAsPercent = orig => {
      let newSpacing = orig + percentAdjustment;

      //If new spacing is less than the minimum -100%, make it the lowest possible value (-100%).
      if (newSpacing < -100) newSpacing = -100;

      return `${newSpacing.toString()}%`;
    };

    //If the value is 0 (default) then return result as percentage.
    if (origSpacing == '0.0' || origSpacing == 0) {
      return resultAsPercent(Number(origSpacing));
    }

    //Is the original spacing value a percentage?
    const isPercentage = (GeneralHelper.isFunction(origSpacing.indexOf) && origSpacing.includes('%'));

    //If the original value is a percentage, we do the calculation with percentages and return value as a percentage.
    if (isPercentage) {
      //Remove '%' for math and convert to Number from String
      origSpacing = Number(origSpacing.slice(0, -1));
      return resultAsPercent(origSpacing);
    }

    //If the original value was not a percentage we use the absoluteAdjustment and send back an absolute value.
    origSpacing = parseFloat(origSpacing);

    //If for some reason the value is not a number, return as a percentage based off 0 as a starting point.
    if (isNaN(origSpacing)) {
      origSpacing = 0;
      return resultAsPercent(origSpacing);
    }

    //If the value is an absolute value, do a basic absolute adjustment.
    let absoluteValue = origSpacing + absoluteAdjustment;

    //If an absolute value goes negative, we convert it to a percentage.  This might cause a jump for the user but maintains 100% fidelity.
    //The user is already adjusting spacing, they won't mind.
    if (absoluteValue < 0) absoluteValue = '-5%';

    return absoluteValue;
  }

  static adjustTextSpacing(figures, percentage, absolute) {
    let containedMultiPartText;

    figures.foreach(figure => {
      //Cadx.assert(figure instanceof Cadx.Figure.Text, "Can only adjust spacing of TextBlock and multipart text not allowed");
      //this should be checked before
      // if (figure instanceof Cadx.Figure.Text && figure.isMultiPart()) {
      //   containedMultiPartText = true;
      // }

      const newCharSpacing = CanvasHelper._calcSpacing(figure.extraCharSpacing, percentage, absolute);
      const newWordSpacing = CanvasHelper._calcSpacing(figure.extraWordSpacing, percentage, absolute);

      figure.extraCharSpacing = newCharSpacing ;
      figure.extraWordSpacing = newWordSpacing ;
    });
  }

  static adjustLineSpacing(figures, percentage, absolute) {
    figures.foreach( figure => {
      const newSpacing = CanvasHelper._calcSpacing(figure.extraLineSpacing, percentage, absolute);
      figure.extraLineSpacing(newSpacing);
    });
  }

  static adjustColumnSpacing(figures, percentage, absolute) {
    figures.foreach( figure => {
      const origSpacing = figure.columnSpacing;
      let newSpacing;

      // If the spacing is percentage, adjust by percentage
      if (GeneralHelper.isFunction(origSpacing.indexOf) && origSpacing.includes('%')) {
        newSpacing = CanvasHelper._calcSpacingPercent(origSpacing, percentage);
      }
      // If the spacing is not a percentage, adjust by increment similar in size to percentage (i.e 10% = 1)
      else {
        let origColumnSpacing = parseFloat(figure.columnSpacing);
        if (isNaN(origColumnSpacing)) origColumnSpacing = 0;

        newSpacing = origColumnSpacing + Math.round(absolute);
        if (newSpacing < 0) newSpacing = 0;
      }

      figure.columnSpacing(newSpacing);
    });
  }

  static isClipArt(figure) {
    return (figure._xType == 'System.Clipart');
  }

  static isShape(figure) {
    return (figure.type && figure.type === 'Shape');
  }

  static isGroup(figure) {
    return (figure.type && figure.type === 'Group');
  }

  static isProxyGroup(figure) {
    return (figure.type && figure.type === 'ProxyGroup');
  }

  static isGroupOfBasicShapes(group) {
    return group.figures.every(f => {
      if (f instanceof Cx.Shape) {
        return f.processes.length === 0;
      } else if (f instanceof Cx.Group) {
        return CanvasHelper.isGroupOfBasicShapes(f);
      }
      return false;
    });
  }

  // Use with care, comparing colors like this could lead to subtle bugs
  // Please use Cadx.Color.areEqual or Cadx.Color.areEqualUntagged
  // This fails: Cadx.Color.areEqual()
  static colorsAreEqual(colorA, colorB) {
    const nameA = colorA.name;
    let rgbA = colorA.rgb;
    const nameB = colorB.name;
    let rgbB = colorB.rgb;

    Cadx.assert(rgbA && rgbB, "RGB values are missing, assumed by CanvasHelper.colorsAreEqual");

    if (rgbA) rgbA = rgbA.toLowerCase;
    if (rgbB) rgbB = rgbB.toLowerCase;

    return ((!GeneralHelper.isEmpty(nameA) && ! GeneralHelper.isEmpty(nameB) && nameA === nameB) || rgbA === rgbB);
  }

  static colorOrder(a, b) {
    const ast = a.showThrough;
    const bst = b.showThrough;
    if( ast ) return bst ? 0 : 1;
    if( bst ) return -1 ; // ShowThrough are always at the end
    const an = a.name;
    const bn = b.name;
    if( an && bn ) return an.localeCompare(bn);
    if( an ) return -1;
    if (bn) return 1;

    let rgbA = a.rgb;
    let rgbB = b.rgb;

    Cadx.assert(rgbA && rgbB, "RGB values are missing, required for CanvasHelper.colorOrder");

    if (rgbA) rgbA = rgbA.toLowerCas
    if (rgbB) rgbB = rgbB.toLowerCase;

    return rgbA.localeCompare(rgbB);
  }

  // Functions that recurse over all the figures including
  // the ones inside groups

  static recursiveForEach(figures, func) {
    figures.foreach( f => {
      func(f);
      if (f instanceof Cadx.Figure.Group) {
        CanvasHelper.recursiveForEach(f.figures,func);
      }
    });
  }

  static recursiveCollect(figures, func) {
    let r = [];
    CanvasHelper.recursiveForEach(figures,f => {
      const cf = func(f);
      if (cf) {
        if (Array.isArray(cf)) {
          r = f.union(cf);
        }
        else {
          r.push(cf);
        }
      }
    });
    return r;
  }

  static everyClipart(figures) {
    return CanvasHelper.recursiveCollect(figures, f => f.xType == 'System.Clipart' ? f : null);
  }

  static everyTextBlock(figures) {
    return CanvasHelper.recursiveCollect(figures,f => f instanceof Cadx.Figure.Text ? f : null);
  }

  static hasRaster(figures) {
    return figures.some(CanvasHelper.isRaster) || figures.some(CanvasHelper.hasRasterInsidePowerclip);
  }

  static optimize$(doc) {
    const cdl = doc.toXmlString();

    const docModel = {
      Cdl: cdl
    };

    return Cadx.Core.request$('Core2/Doc/Optimize', docModel)
    .then(optimized => {
      const doc = Cadx.Document.fromXmlString(optimized.Cdl);
      return Cx.resolve(doc);
    });

    //[UPGRADE] When d6 can use latest Core
    //return Cadx.Core.Document.optimize$(docModel)
    //  .then(function(optimizedDocModel) {
    //    return Cx$.Deferred.resolve(Cadx.Document.fromXmlString(optimizedDocModel.Cdl));
    //  });
  }

  //TODO
  // static createWatermarkText$(text) {
  //   // This static will create a cache of already created watermark texts
  //   // We avoid reprocessing the newly created texts each time we open the
  //   // dialog saving an expensive server call delay
  //   // const watermarkTextFactory = Cx_.memoize( text => {
  //   //   const textBlock = Cx.TextBlock({
  //   //     brush: Cx.Brush({ color: Cx.Color({ alpha: .2, rgb: '000000' }) })
  //   //     , font: new Cadx.Font({ familyName: 'Arial' })
  //   //     , fontSize: 12.7
  //   //     , text
  //   //   });
  //   //   textBlock.pin( Cx.Point(0,0) );
  //   //   textBlock.rotateAround(textBlock.pin, 45);
  //   //   return textBlock;
  //   // }); // TODO

  //   return watermarkTextFactory(text).update$()
  //   .then(figure => Cx.resolve(figure.clone()));
  // }

  // Swap figure, keeping bounds and angle
  // Generic doc replace had issues
  static replaceFigureInLayer$(layer, oldFigure, newFigure) {
    newFigure.angle(oldFigure.angle);

    return oldFigure.bounds$()
    .then(bounds => newFigure.bounds$(bounds))
    .then(() => {
      layer.figures(newFigure);

      //Cross layer replace not working right when main design elements are deleted... not sure about this.
      //Acts like layer does not exist...
      //doc.replace(oldFigure, newFigure);
    });
  }

  static getVectorNodeCount(vectorFigure) {
    const _getVectorNodeCount = figure => {
      let figureNodeCount = 0;

      figure.polyregions.foreach( polyregion => {
        polyregion.regions.foreach( region => {
          region.curves.each( curve => {
            figureNodeCount += curve.convexHull.length;
          });
        });
      });

      return figureNodeCount;
    };

    if(!CanvasHelper.isGroup(vectorFigure))
      return _getVectorNodeCount(vectorFigure);

    // Get node count for group of vectors.
    let totalNodeCountInGroup = 0;

    vectorFigure.figures.foreach( figureInGroup => {
      if(!CanvasHelper.isRaster(figureInGroup)) totalNodeCountInGroup += _getVectorNodeCount(figureInGroup);
    });

    return totalNodeCountInGroup;
  }

  static isRaster(figure) {
    return (figure.type === 'Raster');
  }

  static hasRasterInsidePowerclip(figure) {
    const powerClipContents = figure.powerClipContents;
    for(let i = 0, len = GeneralHelper.size(powerClipContents); i < len; i++){
      const powerClip = powerClipContents[i];
      if(powerClip.figures.some( GeneralHelper.isRaster)) return true;
    }

    return false;
  }

  static getColorAtPointerEvent(canvas, event) {
    const cxPointClicked = canvas.viewPoint(event);
    return canvas.colorAtViewPoint(cxPointClicked);
  }

  static getFiguresFromCdlString$(cdl) {
    return Cx.Document.fromXmlString$(cdl)
    .then(result => Cx.resolve(result.figures));
  }

  static getBackgroundShapes(canvas) {
    const figures = canvas.background.activePageFigures;

    // Filter out everything except for the background shapes.
    return figures.filter( f => f.type == 'Shape');
  }

  static addFiguresToCanvasCenter$(figures, canvas) {
    return Cx.center$(figures, canvas)
    .then(() => {
      canvas.add(figures);
      return Cx.resolve(figures);
    });
  }

  static syncCanvasWithContainer$(canvas, options:any) {
    //Default margin
    if(isNaN(options.zoomMargin)){
      options.zoomMargin = 20;
    }

    canvas.updateSize();

    return canvas.zoomToFit$({
      margin: options.zoomMargin
    })
    .then(() => canvas.render$());
  }

  //UI will have resize handler setup but we can't rely on the timing of that event when canvas is being setup in new element
  //This fixes the infamous "can't do anything" bug when canvases are being setup and shown in a new element all at once
  //Found that resizing browser always fixed the issue, the canvas was not properly synced to it's parent size
  //Not 100% clear to me why this is, everything appears OK but interaction with the canvas is off.
  //For example, you click off to the right and you select a figure on the left...
  static syncCanvasSizeWithContainer$(canvas, options) {
    var options = options || { waitFor: 200 };

    return Cx.createPromise((resolve, reject) => {
      const updateSize$ = () => {
        canvas.updateSize();
        return canvas.document().dryCommit$();
      };

      if(options.waitFor){
        setTimeout(() => {
          updateSize$()
          .then(resolve, reject);
        }, options.waitFor);
      }
      else{
        updateSize$()
        .then(resolve, reject);
      }
    });
  }

  static isLayerName(layer, name) {
    return layer.name === name;
  }

  static getLayerByName(name, layers) {
    return layers.find ( layer => CanvasHelper.isLayerName(layer, name) );
  }

  static getLayerByNameFromView(name, docView) {
    const layers = docView.layers;
    return CanvasHelper.getLayerByName(name, layers);
  }

  static isProcess(item) {
    return (item instanceof Cadx.Process);
  }

  static hasPen(figure) {
    return ( figure.pen instanceof Cadx.Pen && figure.pen.width > 0 )
  }

  static findPenContour(processes) {
    const penContourEffect = Cx.findByType(processes, Cadx.Process.PenContour);
    return penContourEffect;
  }

  static uniqueColorZoneCount(figures) {
    return GeneralHelper.size(Cx.uniqueColorZones(figures));
  }

  static cloneColorZones(colorZones) {
    const clones = [];

    colorZones.foreach( colorZone => {
      const clone = {};

      for (const property in colorZone) {
        clone[property] = colorZone[property];
      }

      clones.push(clone);
    });

    return clones;
  }

  static disableScaling() {
    //Cx.Figure.condition('scalable', function(figure){ return false; });
    Cx.Figure._condition['scalable'] = figure => false;
  }

  static enableScaling() {
    //Cx.Figure.condition('scalable', function(figure){ return true; }); //This composes/adds conditions on top of each other.
    Cx.Figure._condition['scalable'] = figure => true;
  }

  static disableRotation() {
    //Cx.Figure.condition('rotatable', function(figure){ return false; });
    Cx.Figure._condition['rotatable'] = figure => false;
  }

  static enableRotation() {
    //Cx.Figure.condition('rotatable', function(figure){ return true; });
    Cx.Figure._condition['rotatable'] = figure => true;
  }

  static disableMovement() {
    //Cx.Figure.condition('movable', function(figure){ return false; });
    Cx.Figure._condition['movable'] = figure => false;
  }

  static enableMovement() {
    //Cx.Figure.condition('movable', function(figure){ return true; });
    Cx.Figure._condition['movable'] = figure => true;
  }

  static documentFromCdl(cdl) {
    return Cadx.Document.fromXmlString(cdl);
  }

  static setSelectToolActive(canvas) {
    canvas.tool(Cx.Tool.Select());
  }

  static setPanToolActive(canvas) {
    canvas.tool(Cx.Tool.Pan());
  }

  static setZoomToolActive(canvas) {
    canvas.tool(Cx.Tool.Zoom());
  }
  //_ after explicitly specifies method is sync (not async) and you call when you know canvas state is updated (i.e. after commit$)
  //During canvas changes you would need to use the async method, so you ensure you're getting the updated size
  static sizeOfFigures_(figures) {
    const bounds = Cx.bounds_(figures);
    const height = bounds.height;
    const width = bounds.width;

    return { height, width };
  }

  static sizeOfFiguresInUserUnits_(figures) {
    const size = CanvasHelper.sizeOfFigures_(figures);

    const heightInUserUnit = (size.height > 0) ? Units.toUser(size.height) : 0;
    const widthInUserUnit = (size.width > 0) ? Units.toUser(size.width) : 0;

    return { width: widthInUserUnit, height: heightInUserUnit };
  }

  static sizeOfFigures$(figures) {
    return Cx.bounds$(figures)
    .then(bounds => {
      const height = bounds.height;
      const width = bounds.width;

      return Cx.resolve({ height, width });
    });
  }

  static sizeOfFiguresInUserUnits$(figures) {
    return CanvasHelper.sizeOfFigures$(figures)
    .then(size => {
      const heightInUserUnit = (size.height > 0) ? Units.toUser(size.height) : 0;
      const widthInUserUnit = (size.width > 0) ? Units.toUser(size.width) : 0;

      return Cx.resolve({ width: widthInUserUnit, height: heightInUserUnit });
    });
  }

  static loadDocument$( canvasId:string, docId:string, cb?: Function, cameraState?: string, size?:number )
  {
    let canvas = CanvasAreaFactory.getCanvasById(canvasId);
    let canvasSize = size ? size : 128;
    if ( canvas )
    {
      if ( canvas instanceof Cx.Canvas ) //2D
      {
        return CanvasHelper.loadDocument2D$(canvas, docId)
        .then( () => {
          (<Cx.Canvas>canvas).updateSize(size, size);
    
          return CanvasHelper.zoomOutToAll$(canvas)
            .then( () => {
              return (<Cx.Canvas>canvas).render$().then( () => {
                return (<Cx.Canvas>canvas).commit$();
              });
            });
        })
      }
      else if ( canvas instanceof Cx.Canvas ) { //3D
        return Cx.Core.Document.read$({ id: docId })
        .then( (docModel) => {
          var doc = Cx.Document.fromXmlString(docModel.Cdl);
          let canvas3d = <Cx.Canvas>canvas ;

          if (typeof cb === 'function') {
            const onLoaded = () => {
              // @ts-ignore
              canvas3d.un('loaded', onLoaded);
              cb();
            };
      
            canvas3d.on('loaded', onLoaded);
          }

          // if (cameraState)
          //   (<Cx.Canvas>canvas).setCameraState(cameraState); //TODO exception
          // return canvas3d.showDocument$(doc).then( () => {
          //   return canvas3d.render$().then( () => {
          //     return canvas3d.commit$();
          //   });
          // });
          return canvas3d.showDocument$(doc);
        });
      }
    } else {
      return Cx.reject();
    }
  }

  static clear3DView(canvasId: string)
  {
    const canvas = CanvasAreaFactory.getCanvasById(canvasId);
    if (canvas && canvas.document && canvas.document.figures )
    {
      canvas.object3D.dispose();
      canvas.redraw('3D');
    }
  }

  static loadCdl$(canvasId: string, cdl: string, cb?: Function)
  {
    const canvas = CanvasAreaFactory.getCanvasById(canvasId);
    const canvas3d = <Cx.Canvas>canvas;
    const doc = Cx.Document.fromXmlString(cdl);

    if (typeof cb === 'function') {
      const onLoaded = () => {
        // @ts-ignore
        canvas3d.un('loaded', onLoaded);
        cb();
      };

      canvas3d.on('loaded', onLoaded);
    }

    return canvas3d.showDocument$(doc);
  }

  static preview3DDoc(elId:string, docId:string)
  {
    Cx.createPreview3D(elId,docId);
  }

  static loadDocument2D$(canvas:any, docId:string)
  {
    return Cx.Core.Document.read$({ id: docId }).then( (docModel) => {
      let doc = Cx.Document.fromXmlString(docModel.Cdl);
      return Cx.center$( doc.activePageFigures, canvas )
      .then( () => {
        let zoomToFitArgs = {
          figures: doc.activePageFigures, //TODO: Needs to include background shapes like "zoom out to all"
          margin: 5 //TODO: AppGlobals.ZOOM_MARGIN... pass this in as option
        };
        return (<Cx.Canvas>canvas).zoomToFit$(zoomToFitArgs);
      });
    });
  }

  static setBackgroundColor$(canvas:any, color:string )
  {
    (<Cx.Canvas>canvas).backgroundColor = color ;
  }

  static getUniqueDocumentZones(canvas:any, filterBySpotColor:boolean = false )
  {
    let zones = Cx.uniqueColorZones(canvas.document.figures);
    if ( filterBySpotColor )
      zones = zones.filter( (z) => z.value.spotColorName );
    return Array.isArray(zones) ? zones : [zones] ;
  }

  static updateCanvasColorZones$(canvas, zones, colors)
  {
    zones.forEach((z,i)=> {
      Cx.changeColorZone(canvas.document.figures, z, colors[i]);
    });
    return canvas.commit$().then( () => {
      return canvas.document.render$();
    });
  }

  static replaceColorZoneColor(canvas:any, sourceColorZone:Cx.ColorZone, targetColor: string, spotColorName:string )
  {
    let zones = CanvasHelper.getUniqueDocumentZones(canvas) ;
    for ( let i = 0 ; i < zones.length ; i ++)
    {
      let targetZone = zones[i];
      if ( JSON.stringify(targetZone) === JSON.stringify(sourceColorZone) )
      {
        let newZoneColor = Cx.Color.fromRgb( targetColor );          
        newZoneColor.spotColorName = spotColorName;
        newZoneColor.tint = targetZone.value.tint;

        Cx.changeColorZone(canvas.document.figures, targetZone, newZoneColor);
      }
        
    }
    return canvas.commit$().then( () => {
      return canvas.document.render$();
    });
  }

  static replacePenZoneColorByZoneId$(canvas:any, sourceColorZoneId:string, targetPen: Cx.Pen )
  {
    canvas.figures.forEach( (f,i) => {
      let zoneName = f.metadata.get("ubZoneName") ;
      if ( zoneName == sourceColorZoneId )
      { 
        canvas.figures[i].polyregions.forEach( (p, j) => {
          canvas.figures[i].polyregions[j].pen = targetPen
        });        
      }      
    });

    return canvas.commit$().then( () => {
      return canvas.document.render$();
    });
  }

  static replaceBrushZoneColorByZoneId$(canvas:any, sourceColorZoneId:string, targetBrush: Cx.Brush )
  {
    canvas.figures.forEach( (f,i) => {
      let zoneName = f.metadata.get("ubZoneName") ;
      if ( zoneName == sourceColorZoneId )
      { 
        canvas.figures[i].polyregions.forEach( (p, j) => {
          canvas.figures[i].polyregions[j].brush = targetBrush
        });        
      }      
    });

    return canvas.commit$().then( () => {
      return canvas.document.render$();
    });
  }

  static replaceColorZoneColorByZoneId$(canvas:any, sourceColorZoneId:string, targetColor: string, spotColorName:string )
  {
    canvas.figures.forEach( (f,i) => {
      let zoneName = f.metadata.get("ubZoneName") ;
      if ( zoneName == sourceColorZoneId )
      { 
        let newColor = Cx.Color.fromRgb( targetColor );          
        newColor.spotColorName = spotColorName;
        newColor.tint = f.brush.color.tint;
        canvas.figures[i].polyregions.forEach( (p, j) => {
          canvas.figures[i].polyregions[j].brush = new Cx.Brush({ color: newColor }) ;
        });        
      }      
    });

    return canvas.commit$().then( () => {
      return canvas.document.render$();
    });
  }

  static selectUniqueZoneByColor(canvas:any, color:string, spotColorName:string)
  {
    let zones = CanvasHelper.getUniqueDocumentZones(canvas);
    let zone = zones.filter( z => ( z.value.spotColorName === spotColorName ) && ( z.value.rgb === color ) );
    return Array.isArray(zone) ? zone[0] : undefined ;
  }

  static selectUniqueZoneById(canvas:any, id:string)
  {
    let zones = CanvasHelper.getUniqueDocumentZones(canvas);
    let zone = zones.filter( z => ( z.id === id) );
    return Array.isArray(zone) ? zone[0] : undefined ;
  }
}