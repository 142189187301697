import { DesignElement } from '../../domain/Design/DesignElement';
import { isTextDesignElement, TextDesignElement } from '../../domain/Design/TextDesignElement';
import { isUserArtDesignElement } from '../../domain/Design/UserArtDesignElement';
import { updateTextDesignElement$ } from './updateTextDesignElement$';
import { updateUserArtDesignElement$, updateMultipleUserArtDesignElement$ } from './updateUserArtDesignElement$';

export function updateDecorationArea$ (designElement: DesignElement | DesignElement[], clearGroupDecorationAreas?: boolean) {
  if (!Array.isArray(designElement))
  {
    designElement = <DesignElement>designElement ;
    if (isTextDesignElement(designElement)) {
      return updateTextDesignElement$(designElement as TextDesignElement);
    }
  
    else if (isUserArtDesignElement(designElement)) {
      return updateUserArtDesignElement$(designElement, clearGroupDecorationAreas);
    }
  }
  else {
    let designElements = <DesignElement[]>designElement ;
    // @ts-ignore
    return updateMultipleUserArtDesignElement$(designElements);
  }

  // else if (isClipartDesignElement(designElement)) {
  //   const cliparDesignElement = designElement as ClipartDesignElement ;
  //     return Cx.Core.Document.read$({ Id: cliparDesignElement.docId }).then( (d) => {
  //       const doc = Cx.Core.Document.fromXmlString(d.Cdl);

  //       // @ts-ignore TODO: Expects 4 arguments, sending 3.  options does not exist on DesignElement
  //       return setFigureInDecorationArea$( options.designElement.canvasId, doc.figure[0], options.designElement.options );
  //     });
  // }

  return Cx.resolve();
}