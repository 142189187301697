
import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { initJerseyThumbColorMap$ } from './actions/initJerseyThumbColorMap$';

export const initJerseyThumbColorMapUseCase: UseCase = {
  type: 'INIT_JERSEY_THUMB_COLOR_MAP',

  run:() => {
    return initJerseyThumbColorMap$()
    .then((r) => {
      return Cx.resolve({ jerseyThumbColorMap: r.Data });
    })
  }
};

export default function initJerseyThumbColorMap() {
  app.runUseCase( initJerseyThumbColorMapUseCase, {});
}
