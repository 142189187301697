import {apiPath, sendApiPostRequest, getFullUrl} from './backendApi';

interface RequestAccessByPhonePayload {
  phone: string
}

interface RequestAccessByEmailPayload {
  email: string,
  returnUrl: string,
  partnerInfo?: any
}

export const requestAccessByPhone = async (payload: RequestAccessByPhonePayload) => {
  return await sendApiPostRequest(apiPath.coachRequestAccessByPhone, payload);
};

export const requestAccessByEmail = async (payload: RequestAccessByEmailPayload) => {
  return await sendApiPostRequest(apiPath.coachRequestAccessByEmail, payload);
};

interface VerifyPhoneCodePayload {
  phone: string,
  code: string
}

interface VerifyEmailHashPayLoad {
  email: string,
  hash: string
}

export const verifyPhoneCode = async (payload: VerifyPhoneCodePayload) => {
  const fullPath = getFullUrl(apiPath.verifyPhoneCode);
  const resp = await fetch(fullPath, {
    body: JSON.stringify(payload),
    // credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });

  // The use case itself should just fail here I believe.
  return resp.json();
};

export const verifyEmailHash = async (payload: VerifyEmailHashPayLoad) => {
  const fullPath = getFullUrl(apiPath.verifyEmailCode);
  const resp = await fetch(fullPath, {
    body: JSON.stringify(payload),
    // credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'POST'
  });

  // The use case itself should just fail here I believe.
  return resp.json();
};

