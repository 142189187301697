import { cloneDeep } from 'lodash';
import { DesignStyle } from '../Design/DesignStyle';
import { GrayGood } from '../Design/GrayGood';
import { RosterFieldMap } from '../Roster/RosterFieldMap';
import { RosterItem } from '../Roster/RosterItem';
import { RosterSizeMap } from '../Roster/RosterSizeMap';

export interface ProductSnapshot {
  DocId: string;
  Image: string;
}

export interface Product {
  BackSnapshot?: ProductSnapshot;
  FrontSnapshot?: ProductSnapshot;
  ColorZoneNames?: string[];
  DecorationAreaNames?: string[]; // TODO: rename to DecorationAreaIds
  DesignStyle?: DesignStyle;
  GrayGood?: GrayGood;
  Id?: string;
  ItemNumber?: string;
  OriginalDocId?: string;
  Price?: number;
  Cost?: number;
  RosterFieldMap?: RosterFieldMap[];
  RosterItems?: RosterItem[];
  RosterItemsErrorMessage?: string;
  RosterSizeMap?: RosterSizeMap[];
  RosterSizeOptions?: string[];
  SavedDocId?: string;
  TotalQuantity?: number;
  TotalPrice?: number;
  CostPrice?: number;
  TotalSizePriceOffset?: number;
  RosterHasErrors?: boolean;
};

export interface CxRenderThumbnail {
  DocId: string;
  Size: number;
  Camera: string;
  cadworxSessionId: string;
  type: "base64" | "img";
}

export const defaultProduct = (props?: Product): Product => {
  const designStyle = props ? props.DesignStyle : undefined;
  const id = designStyle ? designStyle.DocId : undefined;

  return {
    ColorZoneNames: new Array<string>(),
    DecorationAreaNames: new Array<string>(),
    DesignStyle: undefined,
    GrayGood: undefined,
    Id: id,
    ItemNumber: '',
    OriginalDocId: id,
    Price: 0, // TODO: PricePerRosterItem?
    Cost: 0, // TODO: CostPerRosterItem?
    RosterFieldMap: new Array<RosterFieldMap>(),
    RosterItems: new Array<RosterItem>(),
    RosterItemsErrorMessage: undefined,
    RosterSizeMap: new Array<RosterSizeMap>(),
    RosterSizeOptions: new Array<string>(),
    SavedDocId: undefined,
    TotalPrice: 0,
    TotalQuantity: 0,
    RosterHasErrors: false,
    ...props
  };
}

export const cloneProduct = (props: Product): Product => {
  const clone: Product = cloneDeep(props);
  return clone;
}

export const productBackThumbnailUrl = (product: Product, imageSize: number): string => {
  const docId = product.BackSnapshot.DocId;

  if (docId) {
    return Cx.Core.Doc3D.thumbnailUrl({
      Doc: { Id: docId },
      Format: 'png',
      Height: imageSize,
      Width: imageSize
    });
  }

  return undefined;
}

export const productFrontThumbnailUrl = (product: Product, imageSize: number): string => {
  // const docId = product.FrontSnapshot.DocId;

  // if (docId) {
  //   return Cx.Core.Doc3D.thumbnailUrl({
  //     Doc: { Id: docId },
  //     Format: 'png',
  //     Height: imageSize,
  //     Width: imageSize
  //   });
  // }

  return undefined;
}

export function getValidThumbUrl(cx3dRenderObj, orderStateObj)
{
  const snapShot = cx3dRenderObj.Camera == 'Front' ? orderStateObj.FrontSnapshot?.Image : orderStateObj.BackSnapshot?.Image
  return (snapShot || thumbnailUrlCxRenderUrl(cx3dRenderObj) )
}

export function thumbnailUrlCxRenderUrl(model: CxRenderThumbnail) {
  return `https://cx-3d-render.azureedge.net/render?docId=${model.DocId}&cameraState=${model.Camera}&cadworxSessionId=${model.cadworxSessionId}&type=${model.type}`;
};

export const activeProductFromApplicationState = (state): Product => {
  if (!state)
    return undefined;

  const orderState = state.Order;
  const id = orderState.activeItemNumber;

  return orderState.products.find((p) => {
    return p.ItemNumber === id;
  });
}
