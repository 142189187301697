import React from 'react';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Provider } from 'react-redux';
import { app } from '../../../app/initApp';

const Portal = ({children}) => {
  const mount = document.getElementById('modalRoot');
  const el = document.createElement('div');

  useEffect(() => {
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el, mount]);

  return createPortal(<Provider store={app.getReduxStore()}>
    {children}
  </Provider>, el)
};

export default Portal;
