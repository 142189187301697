import { RootState } from '../../Application';
import { Condition } from '../../screen/Condition';

export const coachHasSession = new Condition({
  check: (state: RootState) => {
    return state.Session.sessionToken ? true : false;
  },
  failMessage: 'Coach does not have session'
});

export const hasCoachesKey = new Condition({
  check: (state: RootState) => {
    return state.Session.coachesKey ? true : false;
  },
  failMessage: 'CoachesKey not found'
});

export const hasPartnerKey = new Condition({
  check: (state: RootState) => {
    return state.Session.partnersKey ? true : false;
  },
  failMessage: 'partnersKey not found'
});
