import { RootState } from '../../Application';
import { Condition } from '../../screen/Condition';
import { CustomizeTab } from './CustomizeTab';

export const activeTabIsColorZones = new Condition({
  check: (state: RootState) => {
    return state.GUI.activeCustomizeTab === CustomizeTab.COLORZONES;
  },
  failMessage: 'Not in Color Zones tab'
});

export const activeTabIsNameNumber = new Condition({
  check: (state: RootState) => {
    return state.GUI.activeCustomizeTab === CustomizeTab.NAME_NUMBER;
  },
  failMessage: 'Not in Name & Number tab'
});