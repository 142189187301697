/* global Cx */

import SparkMD5 from 'spark-md5';
import {getContainersFromLocalStorage, saveContainersToLocalStorage} from './storage';

const defaultOptions = { 
    Document : {
      Limit: 999,
      Match: {
        Archived: false,
        Owned: false,
        Shared: true,
        // Tags: [{ Name: '/FaceMaskModels/layouts/Products' }]
        Tags: [{Name: '/ZPrivate_FaceMaskModels/layouts/Products'}]
      },
      SortBy: 'Modified',
      Start: 0
    },

    Patterns : {
      Limit: 999,
      Match: {
        Archived: false,
        Owned: false,
        Shared: true,
        Tags: [{Name: '/PRIVATE/designer/dev'}]
      },
      Archived: false,
      SortBy: 'Modified',
      Start: 0
    },

    Color : {
      Limit: 999,
      Match: {
        Archived: false,
        Owned: false,
        Shared: true,
        Tags: [{Name: '/DESIGNER/PRIVATE/TS_COLORS_10_06_2020_V1'}]
      },
      SortBy: 'Modified',
      Start: 0
    },

    Doc3D : {
      Limit: 100,
      Match: {
        Owned: false,
        Shared: true,
        Tags: [{Name: '/FaceMaskModels/layouts/Products'}]
      },
      SortBy: 'Name',
      SortDir: 'Desc',
      Start: 0,
      Text: ''
    },

    Clipart : {
      Limit: 21,
      Match: {
        Owned: false,
        Shared: true,
        Tags: [{Name: '/designer/cliparts'}]
      },
      SortBy: 'Name',
      SortDir: 'Desc',
      Start: 0,
      Text: ''
    },

    Texture : {
      Limit: 50,
      Match: {
        Owned: false,
        Shared: true,
        Tags: [{Name: '/designer/textures'}]
      },
      SortBy: 'Name',
      SortDir: 'Desc',
      Start: 0,
      Text: ''
    },

  Font : {
    Limit: 150,
    Match: {
      Archived: false,
      Owned: false,
      Shared: true,
      Tags: [{Name: '/designer/PRIVATE/CustomFonts/Test/Names', Owned: true, Shared: false, Type: 'Font'}
        , {Name: '/designer/PRIVATE/CustomFonts/Test/Numbers', Owned: true, Shared: false, Type: 'Font'}]
    },
    SortBy: 'Name',
    SortDir: 'Asc',
    Start: 0
  }
}

const defaultEndpoints = { 
  Document : 'Document',
  Patterns : 'Document',
  Color : 'Color',
  Doc3D : 'Doc3D',
  Clipart : 'Clipart',
  Texture : 'Texture',
  Font : 'Font'
}

export default class SearchFactory {

  public type: string;
  
  public options: any;
  public searchMethod: Function;

  constructor( type ) {
    this.type = type;
    this.options = defaultOptions[ this.type ];
    this.searchMethod = Cx.Core[ defaultEndpoints[this.type] ].search$;
  }

  public hash() {
    return SparkMD5.hash( JSON.stringify( this.options ) + '-' + this.type );
  }

  public search( options?: any ) {
    const hash = this.hash();
    this.options = options || defaultOptions[ this.type ];
    const type = this.type;
    const cache = getContainersFromLocalStorage( type, hash );

    if ( cache && Array.isArray( cache ) && cache.length > 0 ) {
      // We have a cached value to return but we're going to update the cache in the "background"
      this.searchMethod( this.options ).then( ( r ) => {
        saveContainersToLocalStorage( type, hash, r.Items );
      } );

      return Cx.resolve( cache );
    }
    else {
      return this.searchMethod( this.options ).then( ( r ) => {
        saveContainersToLocalStorage( type, hash, r.Items );
        return Cx.resolve( r.Items );
      } );
    }
  }
}
