export const downloadSizeGuidePDF = (pdfUrl, filename) => {
  fetch(pdfUrl)
    .then(res => {
      return res.blob();
    }).then(blob => {
      const link = document.createElement('a');

      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }).catch(err => console.error(err));
};