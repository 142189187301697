import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RosterItem } from '../../../../app/domain/Roster/RosterItem';
import { updateRosterItem } from '../../../../app/initApp';
import NumberInput from '../../../../gui/components/misc/field/NumberInput';
import SelectInput from '../../../../gui/components/misc/field/SelectInput';
import TextInput from '../../../../gui/components/misc/field/TextInput';
import Button from '../../misc/Button';
import styles from './RosterItemEditor.module.css';
import { hideModal } from '../../../../app/initApp';
import { uniqueId } from 'lodash';

interface Props {
  columns: any;
  itemNumber?: string;
  rosterItem: RosterItem;
}

const RosterItemEditor = (props: Props) => {
  const columns = props.columns;
  const itemNumber = props.itemNumber;
  const rosterItem = props.rosterItem;
  const doneButtonLabel: string = 'Done';
  const [selected, setSelected] = useState("");

  function updateSelectedValue(value) {
    setSelected(value);
  }

  return (
    <div className={styles.rosterItemEditor}>
      {columns.map((columnCfg, index) => {
        const key = rosterItem.Id + columnCfg.id;
        let fieldValue = rosterItem[columnCfg.field];
        
        switch (columnCfg.editor.type) {
          case 'number':
            return <NumberInput
              className={styles.field}
              key={key}
              label={columnCfg.header}
              onChange={(value) => {
                if (value !== fieldValue) {
                  rosterItem[columnCfg.field] = value;
                  updateRosterItem({ itemNumber, rosterItem });
                }
              }}
              value={fieldValue} />;

          case 'select':
            return <SelectInput
              className={styles.field}
              handler={(value) => {
                rosterItem[columnCfg.field] = value;
                updateRosterItem({ itemNumber, rosterItem });
                updateSelectedValue(value);
              }}
              key={uniqueId()}
              label={columnCfg.header}
              options={columnCfg.editor.options}
              optionsHeader={columnCfg.header}
              value={fieldValue} />;

          default:
            return <TextInput
              className={styles.field}
              key={key}
              label={columnCfg.header}
              onChange={(value) => {
                if (value !== fieldValue) {
                  rosterItem[columnCfg.field] = value;
                  updateRosterItem({ itemNumber, rosterItem });
                }
              }}
              value={fieldValue} />;
        }
      })}

      <div className={styles.doneButtonRow}>
        <Button
          className={styles.doneButton + ' ui-primary partner-background-color1 partner-border-color1'}
          label={doneButtonLabel}
          onClick={() => {
            hideModal();
          }} />
      </div>
    </div>
  )

}

const mapStateToProps = (state, props) => {
  const product = state.Order.products.filter(p => p.ItemNumber === state.Order.activeItemNumber).shift();
  const items = product ? product.RosterItems : [];
  const rosterItem = items.find(item => item.Id === props.rosterItem.Id);

  const newProps = {
    itemNumber: product.ItemNumber,
    rosterItem
  }

  return newProps;
}

export default connect(mapStateToProps, undefined)(RosterItemEditor);
