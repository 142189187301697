import { RootState } from '../Application';
import { isDesignReady } from '../domain/Design/Conditions';
import { hasActiveProduct, hasValidRosterItems } from '../domain/Order/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';
import { priceByOrder$ } from './actions/priceByOrder$';

export interface ShowReviewStepOptions {
  products: any;
  partnerKey: string;
  stateTax: string;
}


export const showReviewStepUseCase: UseCase = {
  type: 'SHOW_REVIEW_STEP',

  checkConditions: (state: RootState) => {
    return Condition.evaluate(state, [isDesignReady, hasActiveProduct, hasValidRosterItems]);
  },

  run: (options: ShowReviewStepOptions) => {
    const clonedProducts = Cx.cloneDeep(options.products);

    // @ts-ignore
    return priceByOrder$(clonedProducts, options.partnerKey, options.stateTax, 'coach')
      .then((priceModel) => {
        return Cx.resolve({ ...options, priceModel });
      });
  }
};

export function canShowReviewStep(state: RootState) {
  return showReviewStepUseCase.checkConditions(state).isAllowed;
}

export function showReviewStep(options: ShowReviewStepOptions) {
  app.runUseCase(showReviewStepUseCase, options);
}
