import React from 'react';
import Field from './Field';

import styles from './PlusMinusField.module.css';

interface Props {
  className?: string;
  disabled?: boolean;
  label: string;
  onMinusClick: any;
  onPlusClick: any;
  unit?: string;
  value: number;
}

const PlusMinusField = (props:Props) => {
  let className = 'plus-minus-field';
  const disabled = props.disabled;
  const value = props.value;
  const onMinusClick = disabled ? () => {} : () => props.onMinusClick(value);
  const onPlusClick = disabled ? () => {} : () => props.onPlusClick(value);

  if (props.className) {
    className += ' ' + props.className;
  }

  if (disabled) {
    className += ' ' + styles.disabled;
  }

  return (
    <Field className={className}>
      <label>{props.label}</label>
      <div className={styles.plusMinusInput}>
        <div
          className={styles.plusMinusIcon + ' fas fa-minus-circle'}
          onClick={onMinusClick}></div>
        <div className={styles.plusMinusValue}>{props.value} {props.unit || ''}</div>
        <div
          className={styles.plusMinusIcon + ' fas fa-plus-circle'}
          onClick={onPlusClick}></div>
      </div>
    </Field>
  );

}

export default PlusMinusField;