export function sortBySize(a, b) {
    const weights = {
        'S': 1,
        'M': 2,
        'L': 3,
        'X': 4,
        '2': 5,
        '3': 6,
        '4': 7,
        '5': 8
    };

    const firstLetterA = weights[a.value[0]] ? a.value[0] : a.value[1];
    const firstLetterB = weights[b.value[0]] ? b.value[0] : b.value[1];

    if (typeof (firstLetterA) == "number" && typeof (firstLetterB) == "number")
        return a - b;
    else
        return weights[firstLetterA] - weights[firstLetterB];
}

// [].sort(sortBySize);