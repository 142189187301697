import { isDesignReady } from '../domain/Design/Conditions';
import { DesignElement } from '../domain/Design/DesignElement';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { bump$ } from './actions/bump$';
import { UseCase } from './usecase/UseCase';

interface BumpDownOptions {
  designElement: DesignElement;
}

export const bumpDownUseCase:UseCase = {
  type: 'BUMP_DOWN',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: (options: BumpDownOptions) => {
    return bump$(options.designElement, 'down', 5);
  }
};

export function canBumpDown(state): boolean {
  return bumpDownUseCase.checkConditions( state ).isAllowed;
}

export function bumpDown(options: BumpDownOptions){
  app.runUseCase(bumpDownUseCase, options);
}
