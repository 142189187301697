import React, {useState} from 'react';
import TextInput from '../../misc/field/TextInput'
import Button from '../../misc/Button'
import styles from './Login.module.css';
import global from '../../../../gui/services/global'
import { createCoach, createCoachUseCaseOptions } from '../../../../app/usecases/createCoach';
import { getLoginReturnUrl } from '../../../../gui/util/queryString'
import { cleanPhoneNumber } from '../../../../app/utility/cleanPhoneNumber';

interface Props{
  token: string,
  email: string,
  phone: string,
  errorMessage?: string
}

export const LoginCreateUser = (props: Props) => {  
  const [name, handleNameChange] = useState('');
  const [phone, handlePhoneChange] = useState(props.phone);
  const [email, handleEmailChange] = useState(props.email);
  const [userCreateInitied, handleUserCreateInited] = useState(false);

  const handleCreateCoach = ( email, name, phone, cadworxid, returnUrl) => {   
    handleUserCreateInited(true); 
    phone = cleanPhoneNumber(phone);
    const options : createCoachUseCaseOptions = {
        cadworxid,
        email,
        name,
        phone
    }
    createCoach(options);
  }

  return (
    <div className={styles.loginMain}>
      <div className={styles.parentcontent}>
        <div className={styles.loginMainTitle + ' partner-color1'}>NEW USER</div>
        <div className={styles.loginTitle + ' partner-color2'}>SIGN UP TO CONTINUE</div>
        <div className="provider">
          <div className={styles.inputContainer + ' partner-color2'}>
            <TextInput className={styles.loginField} label="Email" onChange={ handleEmailChange } labelOnTop={true} value={email} disabled={props.email && props.email.length > 0}></TextInput>
          </div>
          <div className={styles.inputContainer + ' partner-color2'}>
            { userCreateInitied ? name :<TextInput className={styles.loginField} label="Name" onChange={ handleNameChange } labelOnTop={true} value={name} disabled={userCreateInitied}></TextInput> }
          </div>
          <div className={styles.inputContainer + ' partner-color2'}>
            <TextInput className={styles.loginField} label="Phone" onChange={ handlePhoneChange } labelOnTop={true} value={phone} disabled={props.phone && props.phone.length > 0}></TextInput>
          </div>
          <div>
            { userCreateInitied && <p className={styles.userCreatedMessage}> Creating user ... </p> }
          </div>
          { props.errorMessage && 
            <div>
              <div className={styles.errorMessageContainer}>
              <div className={styles.errorTitle}>Verification failed!</div>
                <div className={styles.errorMessage}>{props.errorMessage}</div>
              </div>
            </div>
          }
          <div className={styles.buttonContainer}>
            <Button onClick={ () => { handleCreateCoach( email, name, phone, global._CADWORX_ANONYMOUS_COACH_, getLoginReturnUrl()) }}
              className={styles.loginButtonRed + ' partner-color1 partner-border-color1'}
              label="CREATE USER"
              disabled={userCreateInitied}></Button>
          </div>
        </div>
      </div>
    </div>
  )
}
