import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export interface selectStoreLocationUseCaseOptions {
  storeLocation: any
}

export const selectStoreLocationUseCase: UseCase = {
  type: 'SELECT_STORE_LOCATION',

  run: ( options: selectStoreLocationUseCaseOptions ) => {
    return Cx.resolve(options.storeLocation)
  }
};


export function selectStoreLocation( options: selectStoreLocationUseCaseOptions ) {
  app.runUseCase( selectStoreLocationUseCase, options );
}
