import { isDesignReady } from '../domain/Design/Conditions';
import { DecorationArea, isCADecorationArea, isNameDecorationArea, isNumberDecorationArea, isRibNumberDecorationArea, uniqueDecorationAreasByType } from '../domain/Design/DecorationArea';
import { Font } from '../domain/Font/Font';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { setCanvasCameraToBack } from './actions/canvas';
import { initDecorationAreas$ } from './actions/initDecorationAreas$';
import { UseCase } from './usecase/UseCase';
import { CanvasHelper } from '../../canvas/helpers/canvas'

interface InitDecorationAreasUseCaseOptions {
  canvasId: string;
  decorationAreas: DecorationArea[];
  defaultCAFont: Font;
  defaultNameFont: Font;
  defaultNumberFont: Font;
  allFonts: any;
  isLoadedOrder: boolean;
}

export const initDecorationAreasUseCase:UseCase = {
  type: 'INIT_DECORATION_AREAS',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: (options: InitDecorationAreasUseCaseOptions) => {
    const canvasId = options.canvasId;
    const defaultCAFont = options.defaultCAFont;
    const defaultNameFont = options.defaultNameFont;
    const defaultNumberFont = options.defaultNumberFont;
    const allFonts = options.allFonts;
    const nameOrNumberDecorationAreas = options.decorationAreas.filter((item) => {
      return isCADecorationArea(item) || isNameDecorationArea(item) || isNumberDecorationArea(item) || isRibNumberDecorationArea(item) ? true : false;
    });
    const decorationAreas = nameOrNumberDecorationAreas; //uniqueDecorationAreasByType(nameOrNumberDecorationAreas);
    const isLoadedOrder = options.isLoadedOrder ;

    return initDecorationAreas$(canvasId, decorationAreas, defaultCAFont, defaultNameFont, defaultNumberFont, allFonts, isLoadedOrder)
    .then(() => {
      setCanvasCameraToBack(canvasId);
      CanvasHelper.resetSelection(canvasId);
      return Cx.resolve({ initedDecorationAreas: decorationAreas });
    });
  }
};

export function canInitDecorationAreas(state): boolean {
  return initDecorationAreasUseCase.checkConditions( state ).isAllowed;
}

export function initDecorationAreas(options: InitDecorationAreasUseCaseOptions){
  app.runUseCase(initDecorationAreasUseCase, options);
}
