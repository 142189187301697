import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { loadCanvasDocument$ } from './actions/loadCanvasDocument$';

export interface LoadCanvasCdlOptions {
  canvasId: string;
  docId: string;
}

export const loadCanvasDocIdUseCase: UseCase = {
  type: 'LOAD_CANVAS_DOC_ID',

  run: ( options: LoadCanvasCdlOptions ) => {
    return loadCanvasDocument$(options.canvasId, options.docId)
    .then(() => {
      return Cx.resolve(options);
    });
  }
};

/**
 * @category Use Case
 */
export function loadCanvasDocId( options: LoadCanvasCdlOptions ) {
  app.runUseCase( loadCanvasDocIdUseCase, options );
}
