import React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DecorationArea } from '../../../../app/domain/Design/DecorationArea';
import { activeProductFromApplicationState } from '../../../../app/domain/Product/Product';
import { RosterItem, rosterItemHasDefaultValues } from '../../../../app/domain/Roster/RosterItem';
import { addRosterItem, canPreviewRosterItem, previewRosterItem, removeRosterItem } from '../../../../app/initApp';
import global from '../../../services/global';
import Button from '../../misc/Button';
import { getColumnConfig } from './columnConfig';
import RosterGridColumns from './RosterGridColumns';
import { showImportRosterPopup } from '../../../../app/usecases/showImportRosterPopup';
import { downloadSizeGuidePDF } from '../../../util/fileDownload';

// Styles
import './RosterGrid.css';

interface Props {
  canPreviewRosterItem: boolean;
  numOfPlayers?: number;
  columnCfg: any;
  decorationAreas: DecorationArea[];
  hidden: boolean;
  onPreviewClick: Function;
  onRemoveClick: Function;
  itemNumber: string;
  rosterItems: RosterItem[];
  rosterSizeOptions: [];
  grayGoodName: string;
  activeSport: any;
}

const mq = window.matchMedia('(max-width: 500px)');

const RosterGrid = (props: Props) => {
  const [activeRosterItem, setActiveRosterItem] = useState(undefined);
  const [isMobile, setIsMobile] = useState(false);
  const columns = props.columnCfg;
  const itemNumber = props.itemNumber;
  const rosterItems = props.rosterItems;
  const rosterSizeOptions = props.rosterSizeOptions;
  const className = 'roster-grid table' + (props.hidden ? ' hidden' : '');
  const grayGoodName = props.grayGoodName;
  const activeSport = props.activeSport;
  const [pdfUrl, setPdfUrl] = useState<string>("");

  useEffect(() => {
    if (activeSport) {
      switch (activeSport?.Name.toLowerCase()) {
        case "hockey":
          setPdfUrl(process.env.PUBLIC_URL + "/sizingguide_hockey.pdf");
          break;
        default:
          break;
      }
    }
  }, [activeSport]);

  const displayErrorMsg = props.rosterItems.find((item) => {
    return item.errorMessage ? Object.values(item.errorMessage).some(function (e: any) { return e?.length > 0 }) : false;
  });

  useEffect(() => {
    const handler = function (mq) {
      setIsMobile(mq.matches);
    }

    setIsMobile(mq.matches);
    mq.addListener(handler);
    return () => mq.removeListener(handler);
  });

  return (
    <div className={className}>
      <div className="add-wrap">
        <div className='column-left'>
          <span className="player-count">{props.numOfPlayers} PLAYER{props.numOfPlayers > 0 && 'S'}</span>
          <AddButton itemNumber={itemNumber} rosterSizeOptions={rosterSizeOptions} />
        </div>
        <div className='column-right'>
          {pdfUrl.length > 0 && <div className="footer partner-color2 partner-border-color2">
            <span className="text partner-color1">UNSURE OF SIZING?</span>
            <span className="download-link" onClick={() => downloadSizeGuidePDF(pdfUrl, `Size_Guide_${activeSport?.Name}`)}>DOWNLOAD SIZE GUIDE</span>
          </div>}
          <Button
            className="ui-primary import-btn partner-background-color1"
            iconCls="fa-upload"
            label="IMPORT ROSTER"
            onClick={() => {
              showImportRosterPopup({
                props: { itemNumber, rosterSizeOptions, columns, grayGoodName, activeSport }
              });
            }} />
        </div>
      </div>
      {
        displayErrorMsg ?
          <div className="alert-message">
            Some rows contain invalid data or their fields are empty. Please review the highlighted items below.
          </div>
          : null
      }
      <span className="names-msg">* Roster names are case sensitive</span>
      <div className="table-wrap">
        <table>
          <thead>
            <tr>
              <th className="button-column"></th>
              {columns.map(function (columnCfg) {
                return <th className={columnCfg.id + ' partner-color2'} key={columnCfg.id}>{columnCfg.header.toLowerCase() === "name" ? columnCfg.header + "*" : columnCfg.header}</th>;
              })}
              <th className="button-column"></th>
            </tr>
          </thead>
          <tbody>
            {rosterItems.map(function (item, index) {
              const errorMessage = item.errorMessage;
              const hasErrors = errorMessage ? Object.values(errorMessage).some(function (e: any) { return e?.length > 0 }) : false;
              const activeRosterItemId = activeRosterItem ? activeRosterItem.Id : undefined;
              const previewDisabled = !props.canPreviewRosterItem || !canPreview(item) || hasErrors;
              const removeDisabled = !canRemove(item, index, rosterItems.length)
              const rowIsActive = item.Id === activeRosterItemId;
              const rowClassname = (rowIsActive ? ' active partner-border-color1' : (hasErrors ? ' invalid-row' : 'valid-row'));

              return <tr className={rowClassname} key={item.Id}>
                <td className="button-column">
                  <Button
                    className="preview-button partner-color1"
                    disabled={previewDisabled}
                    iconCls="fa-eye"
                    onClick={() => {
                      props.onPreviewClick(item);
                      setActiveRosterItem(item);
                    }}
                    tabIndex={-1} />
                </td>
                <RosterGridColumns
                  columns={columns}
                  isMobile={isMobile}
                  itemNumber={itemNumber}
                  rosterItem={item} />
                <td className="button-column">
                  <Button
                    className="remove-button partner-color1"
                    disabled={removeDisabled}
                    iconCls="fa-trash-alt"
                    onClick={() => {
                      props.onRemoveClick(item, itemNumber);
                    }}
                    tabIndex={-1} />
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </div>
  );

}

const AddButtonClick = (props) => {
  let options = {
    itemNumber: props.itemNumber
  }
  if (props.rosterSizeOptions)
    Object.assign(options, { rosterItem: { Size: '' } })

  addRosterItem(options);
}

const AddButton = (props) => {
  return <Button
    className="add-button partner-color3"
    iconCls="fa-plus"
    label="Add Player"
    onClick={() => {
      AddButtonClick(props);
    }} />;
};

const isNameValid = (rosterItem) => {
  return rosterItem.Name.length > 0;
}

const isNumberValid = (rosterItem) => {
  return Number.isInteger(Number(rosterItem.Number));
}

const canPreview = (rosterItem) => {
  return isNameValid(rosterItem) || isNumberValid(rosterItem) ? true : false;
}

const canRemove = (rosterItem, index, count) => {
  // Always leave an empty row
  return !(index === count - 1 && rosterItemHasDefaultValues(rosterItem));
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const itemNumber = stateProps.itemNumber;

  return Object.assign({}, ownProps, stateProps, {
    onPreviewClick: (rosterItem: RosterItem) => {
      previewRosterItem({
        canvasId: global.__MAIN_CANVAS_ID__,
        decorationAreas: stateProps.decorationAreas,
        rosterItem
      });
    },
    onRemoveClick: (rosterItem: RosterItem) => {
      removeRosterItem({ itemNumber, rosterItem });
    }
  });
}

function getValidNumberOfPlayers(rosterItems: RosterItem[]) {
  const validRoster = rosterItems.filter((r) => { return (r.Name.length > 0 || r.Number.length > 0) });
  return validRoster ? validRoster.length : 0;
}

const mapStateToProps = (state, props) => {
  const product = activeProductFromApplicationState(state);
  const decorationAreas = state.CanvasFullState.decorationAreas;
  const rosterItems = product ? product.RosterItems : [];
  const rosterSizeOptions = product ? product.RosterSizeOptions : [];
  const activeSport = state.GUI.activeSport;

  return {
    numOfPlayers: getValidNumberOfPlayers(rosterItems),
    canPreviewRosterItem: canPreviewRosterItem(state),
    columnCfg: product ? getColumnConfig(product) : [],
    decorationAreas,
    itemNumber: product ? product.ItemNumber : undefined,
    rosterItems,
    rosterSizeOptions,
    grayGoodName: product ? product.GrayGood?.Name : undefined,
    activeSport
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(RosterGrid);
