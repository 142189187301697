import { getOrdersByCoachesKeyUrl } from '../../../backendApi/backendApi';

export async function getOrdersByCoachesKey$ (key: string, partnersKey: string) {
  const url = getOrdersByCoachesKeyUrl(key, partnersKey);
  const resp = await fetch(url, { "mode": "cors" });

  if(resp.ok) {
    const jsonResp = await resp.json();
    return Promise.resolve(jsonResp)
  }

  return Promise.reject('Problem with getOrdersByCoachesKey');
}