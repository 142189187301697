import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';
import uniqueKey from '../../util/uniqueKey';
import ColorChiclet from './ColorChiclet'

import styles from './ColorsCarousel.module.css';

interface Props {
  activeColorSpotName?: string;
  colorList: Cx.Color[];
  disabled?: boolean;
  hidden?: boolean;
  onColorClick: Function;
}

const ColorsCarousel = (props: Props) => {
  const className = props.disabled ?
    styles.colorsCarouselDisabled :
    (props.hidden ? styles.colorsCarouselHidden : styles.colorsCarousel);

  return <div className={className}>
    <List
      activeColorSpotName={props.activeColorSpotName}
      disabled={props.disabled}
      colorList={props.colorList}
      onColorClick={props.onColorClick} />
  </div>;

};

const List = (props) => {
  return props.colorList.map( (color, i) => {
    const selected = props.activeColorSpotName?.length > 0 && color.spotColorName == props.activeColorSpotName ;
    const onColorClick = props.disabled ? () => {} : props.onColorClick;

    return <ColorChiclet
      className={styles.colorChiclet}
      index={i}
      key={uniqueKey()}
      color={color}
      onColorClick={onColorClick}
      selected={selected}
      title={color.name} />;
  });

}

const mapStateToProps = (state: RootState) => {
  const brush = state.GUI.activeFillTargetBrush;
  const isPattern = state.GUI.activeFillTargetBrushIsPattern;
  let activeColorSpotName = undefined ;

  if (brush)
    activeColorSpotName = !isPattern ? brush.color.spotColorName : state.GUI.activePattern.activeColors[state.GUI.activePattern.activeColorSetIndex]?.spotColorName;

  return { activeColorSpotName };
};

export default connect(mapStateToProps)(ColorsCarousel);
