import { CanvasAreaFactory } from '../../canvas/CanvasArea';
import { isDesignReady } from '../domain/Design/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { UseCase } from './usecase/UseCase';

interface zoomInUseCaseOptions {
  canvasId:string
}

export const zoomInUseCase:UseCase = {
  type: 'ZOOM_IN',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [isDesignReady]);
  },

  run: (options: zoomInUseCaseOptions) => {
    const canvas = CanvasAreaFactory.getCanvasById(options.canvasId);
    canvas.zoomIn(1.5);
    return canvas.render$();
  }
};

export function canvasZoomIn(state) {
  return zoomInUseCase.checkConditions( state ).isAllowed;
}

export function zoomIn(options: zoomInUseCaseOptions){
  app.runUseCase(zoomInUseCase, options);
}
