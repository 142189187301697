import { OrderState } from './OrderState';
import { OrderStateSnippet } from './OrderStateSnippet';
import { OrderStatus } from './OrderStatus';

export interface SubmittedOrder {
  archived: boolean;
  coachId: string;
  coachesKey: string;
  id: string;
  createdOn: string;
  order: {
    id: string;
    ordersKey: string;
  };
  orderState: OrderState;
  orderStateSnippet: OrderStateSnippet;
  orderStatus: OrderStatus;
  customerInfoKey: string;
}

export function submittedOrdersFromActionResult (orders): SubmittedOrder[] {
  const items = orders.map((item): SubmittedOrder => {
    return {
      archived: item.archived,
      coachId: item.coachId,
      coachesKey: item.coachesKey,
      createdOn: item.createdOn,
      id: item.id,
      order: item.order,
      orderState: item.orderState,
      orderStateSnippet: item.orderStateSnippet,
      orderStatus: item.orderStatus,
      customerInfoKey: item.customerInfoKey
    };
  });

  // Sort in descending order by createdOn
  return items.sort((a, b) => Date.parse(b.createdOn) - Date.parse(a.createdOn));
}