import { RosterItem } from '../../domain/Roster/RosterItem';
import { app } from '../../initApp';
import { UseCase } from '../usecase/UseCase';

export interface removeRosterItemUseCaseOptions {
  itemNumber: string,
  rosterItem: RosterItem
}

export const removeRosterItemUseCase: UseCase = {
  type: 'REMOVE_ROSTER_ITEM',

  run: (options: removeRosterItemUseCaseOptions) => {
    return Cx.resolve(options);
  }
};

/**
 * @category Use Case
 */
export function removeRosterItem( options: removeRosterItemUseCaseOptions ) {
  app.runUseCase( removeRosterItemUseCase, options );
}
