import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const resetSubmittedOrderUseCase:UseCase = {
  type: 'RESET_SUBMITTED_ORDER',

  run: () => {
    return Promise.resolve();
  }
};

export function resetSubmittedOrder(){
  app.runUseCase(resetSubmittedOrderUseCase);
}
