import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export const showMoreMenuUseCase:UseCase = {
  type: 'SHOW_MORE_MENU',

  run: () => {
    return Cx.resolve();
  }
};

export function showMoreMenu() {
  app.runUseCase(showMoreMenuUseCase);
}
