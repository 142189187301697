import { coachHasSession } from '../domain/Login/Conditions';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { getOrdersByCoachesKey$ } from './actions/getOrdersByCoachesKey$';
import { UseCase } from './usecase/UseCase';

interface LoadSubmittedOrdersUseCaseOptions {
  coachesKey: string;
  partnersKey: string;
}

export const loadSubmittedOrdersUseCase:UseCase = {
  type: 'LOAD_SUBMITTED_ORDERS',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [coachHasSession]);
  },

  run: (options: LoadSubmittedOrdersUseCaseOptions) => {
    return getOrdersByCoachesKey$(options.coachesKey, options.partnersKey)
    .then((data) => {
      return Cx.resolve({
        submittedOrders: data
      });
    });
  }
};

export function canLoadSubmittedOrders(state): boolean {
  return loadSubmittedOrdersUseCase.checkConditions( state ).isAllowed;
}

export function loadSubmittedOrders(options: LoadSubmittedOrdersUseCaseOptions){
  app.runUseCase(loadSubmittedOrdersUseCase, options);
}
