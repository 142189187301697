import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';
import { priceByOrder$ } from '../usecases/actions/priceByOrder$';

interface getPriceByOrderUseCaseOptions {
  products: any, 
  partnerKey: string, 
  stateTax: string,
  priceModelType: string
}


export const getPriceByOrderUseCase: UseCase = {
    type: 'GET_PRICE_BY_ORDER',
    run: (options: getPriceByOrderUseCaseOptions) => {
      const clonedProducts = Cx.cloneDeep(options.products);

      return priceByOrder$(clonedProducts
        , options.partnerKey
        , options.stateTax
        , options.priceModelType)
      .then((priceModel) => { 
        return Cx.resolve({
          priceModel
        });
      })
    }
};

export function getPriceByOrder(options: getPriceByOrderUseCaseOptions) {
    app.runUseCase(getPriceByOrderUseCase, options);
}