import React, { createRef, useEffect, useState } from 'react';
import uniqueKey from '../../../../util/uniqueKey';

import styles from './SelectInputMenu.module.css';

interface Props {
  onItemSelect: Function;
  options: any;
  selectedOption?: any;
}

const SelectInputMenu = (props: Props) => {
  const selectedOption = props.selectedOption;
  const menuRef: any = createRef();
  const [className, setClassName] = useState(styles.menu);
  const [selected, setSelected] = useState("");

  function updateSelectedValue(value) {
    setSelected(value);
  }

  useEffect(() => {
    const newClassName = getClassName(menuRef.current);

    if (newClassName !== className) {
      setClassName(newClassName);
    }
  }, [props.options]);

  return <div className={className + ' partner-border-color2'} ref={menuRef}>
    <div className={styles.list}>
      {props.options.map((item) => {
        const selectItem = selectedOption ? item.value === selectedOption.value : false;

        return <div
          className={selectItem ? styles.selectedMenuItem : styles.menuItem}
          key={uniqueKey()}
          onClick={() => {
            props.onItemSelect(item.value)
            updateSelectedValue(item.value)
          }}>{item.text}</div>
      })}
    </div>
    <div className={styles.arrow + ' partner-border-bottom-color2'}></div>
  </div>

}

function getClassName(menu) {
  const scrollParent = getScrollParent(menu.parentNode);
  const parentBox = scrollParent.getBoundingClientRect();
  const menuBox = menu.getBoundingClientRect();

  if (menuBox.bottom > parentBox.bottom) {
    return styles.menuTop;
  } else if (menuBox.top > parentBox.top) {
    return styles.menuBottom;
  } else if (menuBox.left > parentBox.left) {
    return styles.menuRight;
  }

  return styles.menuLeft;
}

function getScrollParent(node) {
  const isElement = node instanceof HTMLElement;
  const overflowY = isElement && window.getComputedStyle(node).overflowY;
  const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';

  if (!node) {
    return null;
  } else if (isScrollable && node.scrollHeight >= node.clientHeight) {
    return node;
  }

  return getScrollParent(node.parentNode) || document.scrollingElement || document.body;
}

export default SelectInputMenu;