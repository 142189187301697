import { app } from '../initApp';
import { UseCase } from './usecase/UseCase';

export interface selectShippingMethodUseCaseOptions {
  shippingMethod: any
}

export const selectShippingMethodUseCase: UseCase = {
  type: 'SELECT_SHIPPING_METHOD',

  run: ( options: selectShippingMethodUseCaseOptions ) => {
    return Cx.resolve(options.shippingMethod)
  }
};


export function selectShippingMethod( options: selectShippingMethodUseCaseOptions ) {
  app.runUseCase( selectShippingMethodUseCase, options );
}
