import { hasCoachesKey, coachHasSession, hasPartnerKey } from '../domain/Login/Conditions';
import { Product } from '../domain/Product/Product';
import { app } from '../initApp';
import { Condition } from '../screen/Condition';
import { prepareOrderForSaving$ } from './actions/prepareOrderForSaving$';
import { UseCase } from './usecase/UseCase';

export interface PrepareOrderForSavingUseCaseOptions {
  partnerStyleData: any;
  products: Product[];
  shippingMethod: any;
  storeLocation: any; 
  contactInfo: any;
  partnerInfo: any; 
  retailTotal: number;
  costTotal: number;
  salesTax: number;
  minOrderFee: number;
}

export const prepareOrderForSavingUseCase: UseCase = {
  type: 'PREPARE_ORDER_FOR_SAVING',

  checkConditions: ( state ) => {
    return Condition.evaluate(state, [hasCoachesKey, coachHasSession, hasPartnerKey]);
  },

  run: ( options: PrepareOrderForSavingUseCaseOptions ) => {

    return prepareOrderForSaving$(options.products
                                , options.partnerStyleData
                                , options.shippingMethod
                                , options.storeLocation
                                , options.contactInfo
                                , options.partnerInfo
                                , options.retailTotal
                                , options.costTotal
                                , options.salesTax
                                , options.minOrderFee )
    .then((result) => {
      return Cx.resolve({
          orderState: result.orderState,
          orderStateSnippet: result.orderStateSnippet,
      });

    })
    .catch((response: any) => {
      Cx.reject();
    })
  }
};

/**
 * @category Can Use Case
 */
export function canPrepareOrderForSaving(state): boolean {
  return prepareOrderForSavingUseCase.checkConditions( state ).isAllowed;
}

/**
 * @category Use Case
 */
export function prepareOrderForSaving( options: PrepareOrderForSavingUseCaseOptions ) {
  app.runUseCase( prepareOrderForSavingUseCase, options );
}
