import React, { createRef } from 'react';

// This class resolves the issue of input losing cursor position on user input
interface Props {
  className?: string;
  onChange: Function;
  onFocus?: any;
  onKeyDown?: any;
  onKeyUp?: any;
  pattern?: string;
  placeholder?: string;
  value?: any;
  type?: string;
  disabled?: boolean;
  name?: string;
  key?: string;
  onKeyPress?: Function;
}

class Input extends React.Component<Props> {

  private inputRef: any;
  private name: string;

  constructor(props) {
    super(props);
    this.inputRef = createRef();
    this.name = props.name;
    this.focus = this.focus.bind(this);
  }

  focus() {
    this.inputRef.current.focus();
  }

  private fixCursorPosition() {
    //This solution will not work in chrome with type='number'
    const node = this.inputRef.current;
    const oldLength = node.value.length;
    const oldIdx = node.selectionStart;
    const newValue = this.props.value;
    node.value = newValue || '';
    const newIdx = Math.max(0, node.value.length - oldLength + oldIdx);
    node.selectionStart = node.selectionEnd = newIdx;
  }

  public render() {
    // Important to have the value={undefined} as an override as the value
    // is set from componentDidUpdate to fix losing cursor position
    // @ts-ignore
    return (<React.Fragment><input type="text" key={this.name} {...this.props} ref={this.inputRef} value={undefined} defaultValue={this.props.value} /></React.Fragment>);
  }

}

export default Input;