import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../app/Application';
import SavedOrdersDetail from './SavedOrdersDetail';
import SavedOrdersSummary from './SavedOrdersSummary';

// Styles
import styles from './SavedOrders.module.css';

const SavedOrders = (props) => {
  const [activeSavedOrder, setActiveSavedOrder] = useState(null);
  const detailClassName = activeSavedOrder ? '' : styles.hidden;
  const loadingClassName = props.ordersLoaded ? styles.hidden : '';
  const summaryClassName = !activeSavedOrder && props.ordersLoaded ? '' : styles.hidden;

  return <div className={styles.savedOrders}>
    <SavedOrdersDetail
      className={detailClassName}
      onBackClick={() => setActiveSavedOrder(undefined)}
      savedOrder={activeSavedOrder}
      cadworxSessionId={props.cadworxSessionId} />
    <SavedOrdersSummary
      className={summaryClassName}
      onOrderClick={(savedOrder) => setActiveSavedOrder(savedOrder)}
      orders={props.orders}
      cadworxSessionId={props.cadworxSessionId} />
    <div className={loadingClassName}>Loading...</div>
  </div>
}

const mapStateToProps = (state: RootState) => {
  const orderState = state.SavedOrders;
  const cadworxSessionId = state.Session.partnerCadworxSessionId;

  return {
    orders: orderState.orders,
    ordersLoaded: orderState.ordersLoaded,
    cadworxSessionId: cadworxSessionId,
  }
}

export default connect(mapStateToProps, null)(SavedOrders);